import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {tt} from "@lib/helpers"

const AppBookingsPackageLaneNewLane = ({data}) => {
  const [__count, __countSet] = React.useState(1)

  const {t} = data

  return (
    <div className="AppBookingsNew__ticket">
      <div className="AppBookingsNew__ticket__top">
        <div className="AppBookingsNew__ticket__top__left">
          <h3 className="AppBookingsNew__ticket__top__left__h3">
            {get(t, "current.ticket.h3")}
          </h3>

          <p className="AppBookingsNew__ticket__top__left__p">
            {tt(t, "current.ticket.p", {count: get(data, "bookings_limit")})}
          </p>
        </div>

        <div className="AppBookingsNew__ticket__top__right">
          <select
            className="AppSelect"
            name="bookings_count"
            value={__count}
            onChange={(e) => {
              __countSet(get(e, "target.value"))
            }}
          >
            {Array.from(Array(get(data, "bookings_limit", 0))).map((x, i) => (
              <option key={i}>{i + 1}</option>
            ))}
          </select>

          <p className="AppBookingsNew__ticket__top__right__p">
            {get(t, "current.ticket.unit")}
          </p>
        </div>
      </div>

      <div className="AppBookingsNew__ticket__bottom">
        <p className="AppBookingsNew__ticket__bottom__p">
          {get(t, "current.ticket.total")}
        </p>

        <strong className="AppBookingsNew__ticket__bottom__strong">
          {__count * get(data, "bulk_points", 0)}pt
        </strong>
      </div>
    </div>
  )
}

AppBookingsPackageLaneNewLane.propTypes = {}

export default AppBookingsPackageLaneNewLane
