import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import Graph from "@components/molecules/Graph"
import moment from "moment"

type PropTypes = ComponentProps<typeof Graph>

export default {
  title: "Molecules/Graph",
  component: Graph,
} as Meta

const Template: Story<PropTypes> = (args) => <Graph {...args} />

const data = [...Array(30)].map((_, index) => {
  return {
    key: moment().subtract(index, "day").format("YYYY-MM-DD"),
    values: [Math.random() * 100],
  }
})

export const Default = Template.bind({})
Default.args = {
  followClientHeight: true,
  isLoaded: true,
  data,
  initialHeight: 300,
}
