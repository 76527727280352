import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"
import * as __axios from "axios"

import {tt} from "@lib/helpers"

const AppMfaIdentityVerificationStep2 = ({data}) => {
  const {t} = data

  const [__file, __fileSet] = React.useState()
  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <React.Fragment>
      <h1 className="App__h1">{tt(t, "current.title_2")}</h1>

      <p
        className="AppMfaIdentityVerification__p"
        dangerouslySetInnerHTML={{__html: tt(t, "current.label1_2")}}
      />

      <form
        className={className("AppMfaIdentityVerification__form", {
          "AppForm--overlay": __isLoading,
        })}
        onSubmit={(e) => {
          e.preventDefault()
          const $els = get(e, "target.elements")

          const _formData = new FormData()

          if (__file) {
            __isLoadingSet(true)

            _formData.append(
              "authenticity_token",
              document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
            )

            _formData.append(
              "user[identity_image_type]",
              get($els, "identity_image_type.value")
            )

            _formData.append("user[identity_image]", __file)

            __axios
              .post(get(data, "url"), _formData)
              .then((res) => {
                window.location.href = get(res, "data.redirect_url")
              })
              .catch((e) => {
                window.location.reload()
              })
          }
        }}
      >
        <input
          type="hidden"
          name="identity_image_type"
          defaultValue={get(data, "identity_image_type")}
        />

        <label className="AppMfaIdentityVerification__form__label">
          <input
            type="file"
            className="Input"
            onChange={(e) => {
              __fileSet(get(e, "target.files.0"))
            }}
          />

          <div className="AppMfaIdentityVerification__preview">
            <svg
              className="AppMfaIdentityVerification__preview__svg"
              viewBox="0 0 1 1"
            />

            {__file && (
              <img
                className="AppMfaIdentityVerification__preview__img"
                src={window.URL.createObjectURL(__file)}
              />
            )}
          </div>

          <span className="AppMfaIdentityVerification__form__label__span">
            {tt(t, "current.link_2")}
          </span>
        </label>

        <button
          className="Button Button--large"
          disabled={!__file || __isLoading}
        >
          {__isLoading ? "Loading …" : tt(t, "current.button_2")}
        </button>
      </form>
    </React.Fragment>
  )
}

AppMfaIdentityVerificationStep2.propTypes = {}

export default AppMfaIdentityVerificationStep2
