import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"

import Nav from "@components/molecules/Nav"

type PropTypes = ComponentProps<typeof Nav>

export default {
  title: "Molecules/Nav",
  component: Nav,
} as Meta

const Template: Story<PropTypes> = (args) => <Nav {...args} />

export const Talkport = Template.bind({})
Talkport.args = {
  code: "talkport",
  nav_links: [...Array(10)].map((_, index) => {
    return {
      active: true,
      href: `https://example.com/${index}`,
      label: `Link${index}`,
      pinned: index % 2 === 0,
    }
  }),
  root_path: "/",
  agency_admin: true,
  enable_admin_line_link: "example.com",
  create_package_link: "example.com",
  app_version: "v1",
}

export const Say = Template.bind({})
Say.args = {
  code: "say",
  nav_links: [...Array(10)].map((_, index) => {
    return {
      active: true,
      href: `https://example.com/${index}`,
      label: `Link${index}`,
      pinned: index % 2 === 0,
    }
  }),
  root_path: "/",
  agency_admin: true,
  enable_admin_line_link: "example.com",
  create_package_link: "example.com",
  app_version: "v1",
}
