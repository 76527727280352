import React from "react"
import className from "classnames"

type PropTypes = {
  // flash = [["success", "pat+1@nanameue.jpにログインしています"]]
  flash: ["danger" | "success", string][]
  is_publisher_page: boolean
}

const AppAlert: React.FC<PropTypes> = ({flash, is_publisher_page}) => {
  const [__flash, __flashSet] = React.useState(() => {
    return flash
  })

  const [__isFaded, __isFadedSet] = React.useState(false)
  const ___TO = React.useRef<number>()

  const fadeOut = () => {
    __isFadedSet(true)

    window.setTimeout(() => {
      __flashSet([])
    }, 500)
  }

  React.useEffect(() => {
    ___TO.current = window.setTimeout(() => {
      fadeOut()
    }, 10000)
  }, [])

  if (!__flash) {
    return null
  }

  return (
    <a
      className={className("AppAlert", {
        "AppAlert--absolute": is_publisher_page,
        "AppAlert--faded": __isFaded,
      })}
      onClick={() => {
        window.clearTimeout(___TO.current)
        fadeOut()
      }}
    >
      <div className="kontainer-app">
        {__flash.map((x, i) => (
          <div
            key={i}
            className={className("AppAlert__a", `AppAlert__a--${x[0]}`)}
          >
            <div className="kontainer-app">{x[1]}</div>
          </div>
        ))}
      </div>
    </a>
  )
}

export default AppAlert
