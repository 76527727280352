import React from "react"

const PrettyJson: React.FC<{
  data: any
}> = ({data}) => {
  const stringified = React.useMemo(
    () => JSON.stringify(data, undefined, 4),
    [data]
  )
  return (
    <div className="PrettyJson">
      <textarea
        style={{width: "100%"}}
        disabled
        rows={40}
        value={stringified}
      />
    </div>
  )
}

export default PrettyJson
