import React, {useState, Fragment} from "react"
import PropTypes from "prop-types"
import className from "classnames"
import get from "lodash/get"

import {axios} from "@lib/helpers"

const DisconnectButton = ({link, locale}) => {
  const [__isActiveModal, __isActiveModalSet] = useState(false)

  return (
    <Fragment>
      <a
        className="Button Button--small Button--icon-check"
        onClick={(e) => {
          e.preventDefault()
          __isActiveModalSet(true)
        }}
      >
        {get(locale, "connected")}
      </a>

      <div
        className={className("ModalFormInputProduct", {
          "ModalFormInputProduct--active": __isActiveModal,
        })}
      >
        <a
          className="ModalFormInputProduct__overlay"
          onClick={() => {
            __isActiveModalSet(false)
          }}
        />

        <div className="ModalFormInputProduct__panel">
          <h3
            className="ModalFormInputProduct__panel__h3"
            dangerouslySetInnerHTML={{__html: get(locale, "modal.title")}}
          />

          <div className="ModalFormInputProduct__panel__buttons">
            <a
              className="Button Button--orange"
              onClick={() => {
                axios.delete(link).then(() => {
                  window.location.reload()
                })
              }}
            >
              {get(locale, "modal.ok")}
            </a>

            <a
              className="Button Button--white"
              onClick={() => {
                __isActiveModalSet(false)
              }}
            >
              {get(locale, "modal.cancel")}
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

DisconnectButton.propTypes = {
  link: PropTypes.string.isRequired,
  locale: PropTypes.object.isRequired,
}

export default DisconnectButton
