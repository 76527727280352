import React from "react"
import get from "lodash/get"
import map from "lodash/map"
import className from "classnames"

import {tt, axios} from "@lib/helpers"

import Graph from "@components/molecules/Graph"

import img from "@images/admin2/AdminAgenciesDetails__header__img.svg"

const AdminAgenciesDetails = ({
  data,
}: {
  data: {
    agency: any
    events: {
      title: string
      agency_event_path: string
      description: string
    }[]
    t: any
  }
}) => {
  const {agency, t} = data

  return (
    <div className="AdminAgenciesDetails">
      <div className="kontainer">
        <div className="AdminAgenciesDetails__wrapper">
          <div className="AdminAgenciesDetails__header">
            <img className="AdminAgenciesDetails__header__img" src={img} />

            <div className="AdminAgenciesDetails__header__right">
              <h2 className="AdminAgenciesDetails__header__right__h2">
                {get(agency, "name")}
              </h2>

              <p className="AdminAgenciesDetails__header__right__p">
                <a
                  className="AdminAgenciesDetails__header__right__p__a"
                  href={`mailto:${get(agency, "contact_email")}`}
                >
                  {get(agency, "contact_email")}
                </a>
              </p>
            </div>

            <a className="Button" href={get(data, "page_path")}>
              {tt(t, "current.details.go_to_agency_page")}
            </a>
          </div>

          <p className="AdminAgenciesDetails__p">{get(agency, "memo")}</p>

          <div className="AdminAgenciesDetails__details">
            <p className="AdminAgenciesDetails__details__p">
              {tt(t, "current.details.start_date")}：
              {get(data, "stats_numbers.joined_since")}
            </p>
          </div>

          {false && (
            <div className="AdminAgenciesDetails__section">
              <h3 className="AdminAgenciesDetails__section__h3">
                {tt(t, "current.sections.power.h3")}
              </h3>

              <dl className="AdminAgenciesDetails__section__dl">
                {[
                  {
                    dt: tt(t, "current.sections.power.data.0"),
                    dd: "TBD",
                  },
                  {
                    dt: tt(t, "current.sections.power.data.1"),
                    dd: "TBD",
                  },
                  {
                    dt: tt(t, "current.sections.power.data.2"),
                    dd: "TBD",
                  },
                ].map((x, i) => (
                  <div
                    className="AdminAgenciesDetails__section__dl__item"
                    key={i}
                  >
                    <dt className="AdminAgenciesDetails__section__dl__item__dt">
                      {get(x, "dt")}
                    </dt>

                    <dd className="AdminAgenciesDetails__section__dl__item__dd">
                      {get(x, "dd")}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          )}

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.events.h3")}
            </h3>

            {data?.events?.length > 0 ? (
              <dl className="AdminAgenciesDetails__events">
                {data?.events?.map?.((x, i) => (
                  <div className="AdminAgenciesDetails__events__item" key={i}>
                    <dt className="AdminAgenciesDetails__events__item__dt">
                      {x?.title}
                    </dt>

                    <a
                      className="Button Button--xsmall"
                      href={x?.agency_event_path}
                    >
                      {tt(t, "current.sections.events.event_page")}
                    </a>

                    {x?.description && (
                      <dd className="AdminAgenciesDetails__events__item__dd">
                        {x?.description}
                      </dd>
                    )}
                  </div>
                ))}
              </dl>
            ) : (
              <div className="Placeholder">No events</div>
            )}
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.publishers.h3")}
            </h3>

            <AdminAgenciesDetailsPublishers data={data} />
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.talkport_sales.h3")}
            </h3>

            <dl className="AdminAgenciesDetails__section__dl">
              {[
                {
                  dt: tt(t, "current.sections.talkport_sales.dl.0.dt"),
                  dd: get(
                    data,
                    "stats_numbers.total_sales",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talkport_sales.dl.1.dt"),
                  dd: get(
                    data,
                    "stats_numbers.current_month_sales",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talkport_sales.dl.2.dt"),
                  dd: get(
                    data,
                    "stats_numbers.last_month_sales",
                    0
                  ).toLocaleString(),
                },
              ].map((x, i) => (
                <div
                  className="AdminAgenciesDetails__section__dl__item"
                  key={i}
                >
                  <dt className="AdminAgenciesDetails__section__dl__item__dt">
                    {get(x, "dt")}
                  </dt>

                  <dd className="AdminAgenciesDetails__section__dl__item__dd">
                    {get(x, "dd")}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.sales.h3")}
            </h3>

            <AdminAgenciesDetailsSales data={data} />
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.talents.h3")}
            </h3>

            <dl className="AdminAgenciesDetails__section__dl AdminAgenciesDetails__section__dl--dual">
              {[
                {
                  dt: tt(t, "current.sections.talents.dl.0.dt"),
                  dd: get(
                    data,
                    "stats_numbers.publishers_count",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talents.dl.1.dt"),
                  dd: get(
                    data,
                    "stats_numbers.current_month_active_publishers",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talents.dl.2.dt"),
                  dd: get(
                    data,
                    "stats_numbers.last_month_active_publishers",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talents.dl.3.dt"),
                  dd: get(
                    data,
                    "stats_numbers.last_2months_active_publishers",
                    0
                  ).toLocaleString(),
                },
              ].map((x, i) => (
                <div
                  className="AdminAgenciesDetails__section__dl__item"
                  key={i}
                >
                  <dt className="AdminAgenciesDetails__section__dl__item__dt">
                    {get(x, "dt")}
                  </dt>

                  <dd className="AdminAgenciesDetails__section__dl__item__dd">
                    {get(x, "dd")}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.talks.h3")}
            </h3>

            <dl className="AdminAgenciesDetails__section__dl AdminAgenciesDetails__section__dl--dual">
              {[
                {
                  dt: tt(t, "current.sections.talks.dl.0.dt"),
                  dd: get(
                    data,
                    "stats_numbers.total_spots_count",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talks.dl.1.dt"),
                  dd: get(
                    data,
                    "stats_numbers.last_month_spots_count",
                    0
                  ).toLocaleString(),
                },
              ].map((x, i) => (
                <div
                  className="AdminAgenciesDetails__section__dl__item"
                  key={i}
                >
                  <dt className="AdminAgenciesDetails__section__dl__item__dt">
                    {get(x, "dt")}
                  </dt>

                  <dd className="AdminAgenciesDetails__section__dl__item__dd">
                    {get(x, "dd")}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.spots.h3")}
            </h3>

            <AdminAgenciesDetailsSpots data={data} />
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.buyers.h3")}
            </h3>

            <AdminAgenciesDetailsBuyers data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

AdminAgenciesDetails.propTypes = {}

export default AdminAgenciesDetails

class AdminAgenciesDetailsPublishers extends React.Component<{data: any}, any> {
  static propTypes = {}

  static defaultProps = {}

  defaultState = {
    __publishers: {
      isLoading: true,
    },
  }

  state = this.defaultState

  componentDidMount = () => {
    this.loadPublishers()
  }

  loadPublishers = (options = {sort_by: null, sort_dir: null}) => {
    const {sort_by, sort_dir} = options

    axios
      .get(get(this.props, "data.details_publishers_admin_agency_path"), {
        sort_by: sort_by
          ? sort_by
          : get(this.state, "__publishers.data.meta.sort_by"),
        sort_dir: sort_dir
          ? sort_dir
          : get(this.state, "__publishers.data.meta.sort_dir"),
        page: Number(get(this.state, "__publishers.data.meta.page", 0)) + 1,
      })
      .then((res: any) => {
        this.setState((prevState) => ({
          __publishers: {
            isLoading: false,
            data: {
              ...get(res, "data"),
              data: [
                ...get(prevState, "__publishers.data.data", []),
                ...get(res, "data.data"),
              ],
            },
          },
        }))
      })
  }

  render = () => {
    const {t} = get(this.props, "data")

    return (
      <div
        className="AdminAgenciesDetails__table"
        cypress-target="admin-dashboard-publishers-table"
      >
        {get(this.state, "__publishers.isLoading") &&
        get(this.state, "__publishers.data.data.length", 0) == 0 ? (
          <div className="Placeholder">Loading …</div>
        ) : !get(this.state, "__publishers.isLoading") &&
          get(this.state, "__publishers.data.data.length", 0) == 0 ? (
          <div className="Placeholder">No data</div>
        ) : (
          <React.Fragment>
            <div
              className="AdminAgenciesDetails__table__wrapper"
              style={{
                gridTemplateColumns:
                  "minmax(calc(var(--global-space) * 10), 1fr) max-content max-content max-content",
              }}
            >
              <div className="AdminAgenciesDetails__table__row">
                {[
                  {
                    label: tt(t, "current.sections.publishers.table.headers.0"),
                    sort_by: "name",
                  },
                  {
                    label: tt(t, "current.sections.publishers.table.headers.1"),
                    sort_by: "revenue",
                  },
                  {
                    label: tt(t, "current.sections.publishers.table.headers.2"),
                    sort_by: "spots_count",
                  },
                  {
                    label: tt(t, "current.sections.publishers.table.headers.4"),
                    sort_by: "followers_count",
                  },
                ].map((x, i) => (
                  <div key={i} className="AdminAgenciesDetails__table__col">
                    <a
                      className={className(
                        "AdminAgenciesDetails__table__col__a-header",
                        {
                          "AdminAgenciesDetails__table__col__a-header--desc":
                            get(x, "sort_by") ==
                            get(this.state, "__publishers.data.meta.sort_by"),
                          "AdminAgenciesDetails__table__col__a-header--disabled":
                            !get(x, "sort_by"),
                        }
                      )}
                      onClick={() => {
                        const oldMeta = get(
                          this.state,
                          "__publishers.data.meta"
                        )

                        this.setState(this.defaultState, () => {
                          this.loadPublishers({
                            sort_by: get(x, "sort_by"),
                            sort_dir:
                              get(x, "sort_by") == get(oldMeta, "sort_by") &&
                              get(oldMeta, "sort_dir") == "desc"
                                ? "asc"
                                : "desc",
                          })
                        })
                      }}
                    >
                      {get(x, "label")}
                    </a>
                  </div>
                ))}
              </div>

              {(get(this.state, "__publishers.data.data") || []).map(
                (x: any, i: number) => (
                  <div className="AdminAgenciesDetails__table__row" key={i}>
                    <div className="AdminAgenciesDetails__table__col">
                      <a
                        className="AdminAgenciesDetails__table__col__agency"
                        href={get(x, "link")}
                      >
                        <svg
                          className="AdminAgenciesDetails__table__col__agency__svg"
                          viewBox="0 0 40 40"
                          style={{
                            backgroundImage: `url(${get(x, "icon_image_url")})`,
                          }}
                        />

                        <span className="AdminAgenciesDetails__table__col__agency__span">
                          {get(x, "custom_name")}
                        </span>
                      </a>
                    </div>

                    {[
                      {
                        label: get(x, "revenue", 0).toLocaleString(),
                      },
                      {
                        label: get(x, "spots_count", 0).toLocaleString(),
                      },
                      {
                        label: get(x, "followers_count", 0).toLocaleString(),
                      },
                    ].map((y, j) => (
                      <div className="AdminAgenciesDetails__table__col" key={j}>
                        <div className="AdminAgenciesDetails__table__col__p">
                          {get(y, "label")}
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>

            {!get(this.state, "__publishers.data.meta.is_last_page") && (
              <a
                className={className("AdminAgenciesDetails__table__a", {
                  "AdminAgenciesDetails__table__a--disabled": get(
                    this.state,
                    "__publishers.isLoading"
                  ),
                })}
                onClick={this.loadPublishers}
              >
                {get(this.state, "__publishers.isLoading")
                  ? "Loading …"
                  : tt(t, "current.load_more")}
              </a>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

class AdminAgenciesDetailsSales extends React.Component<{data: any}, any> {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __sales: {
      isLoading: true,
    },
  }

  componentDidMount = () => {
    axios
      .get(get(this.props, "data.details_spots_sales_admin_agency_path"))
      .then((res: any) => {
        this.setState({
          __sales: {
            isLoading: false,
            data: map(get(res, "data"), (x) => {
              return {
                values: [get(x, "value")],
                key: get(x, "date"),
              }
            }),
          },
        })
      })
  }

  render = () => {
    return (
      <Graph
        initialHeight={220}
        data={get(this.state, "__sales.data") || []}
        isLoaded={!get(this.state, "__sales.isLoading")}
      />
    )
  }
}

class AdminAgenciesDetailsSpots extends React.Component<{data: any}, any> {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __sales: {
      isLoading: true,
    },
  }

  componentDidMount = () => {
    axios
      .get(get(this.props, "data.details_spots_admin_agency_path"))
      .then((res: any) => {
        this.setState({
          __sales: {
            isLoading: false,
            data: map(get(res, "data"), (x) => {
              return {
                values: [get(x, "value")],
                key: get(x, "date"),
              }
            }),
          },
        })
      })
  }

  render = () => {
    return (
      <Graph
        initialHeight={220}
        data={get(this.state, "__sales.data") || []}
        isLoaded={!get(this.state, "__sales.isLoading")}
      />
    )
  }
}

class AdminAgenciesDetailsBuyers extends React.Component<{data: any}, any> {
  static propTypes = {}

  static defaultProps = {}

  defaultState = {
    __buyers: {
      isLoading: true,
    },
  }

  state = this.defaultState

  componentDidMount = () => {
    this.loadBuyers()
  }

  loadBuyers = () => {
    axios
      .get(get(this.props, "data.details_buyers_admin_agency_path"), {
        page: Number(get(this.state, "__buyers.data.meta.page", 0)) + 1,
      })
      .then((res: any) => {
        this.setState((prevState) => ({
          __buyers: {
            isLoading: false,
            data: {
              ...get(res, "data"),
              data: [
                ...get(prevState, "__buyers.data.data", []),
                ...get(res, "data.data"),
              ],
            },
          },
        }))
      })
  }

  render = () => {
    const {t} = get(this.props, "data")

    return (
      <div className="AdminAgenciesDetails__table">
        {get(this.state, "__buyers.isLoading") &&
        get(this.state, "__buyers.data.data.length", 0) == 0 ? (
          <div className="Placeholder">Loading …</div>
        ) : !get(this.state, "__buyers.isLoading") &&
          get(this.state, "__buyers.data.data.length", 0) == 0 ? (
          <div className="Placeholder">No data</div>
        ) : (
          <React.Fragment>
            <div
              className="AdminAgenciesDetails__table__wrapper"
              style={{
                gridTemplateColumns: "1fr",
              }}
            >
              {(get(this.state, "__buyers.data.data") || []).map(
                (x: any, i: number) => (
                  <div className="AdminAgenciesDetails__table__row" key={i}>
                    <div className="AdminAgenciesDetails__table__col">
                      <a
                        className="AdminAgenciesDetails__table__col__agency"
                        href={get(x, "link")}
                      >
                        <svg
                          className="AdminAgenciesDetails__table__col__agency__svg"
                          viewBox="0 0 40 40"
                          style={{
                            backgroundImage: `url(${get(x, "icon_image_url")})`,
                          }}
                        />

                        <span className="AdminAgenciesDetails__table__col__agency__span">
                          {get(x, "name")}
                        </span>
                      </a>
                    </div>
                  </div>
                )
              )}
            </div>

            {!get(this.state, "__buyers.data.meta.is_last_page") && (
              <a
                className={className("AdminAgenciesDetails__table__a", {
                  "AdminAgenciesDetails__table__a--disabled": get(
                    this.state,
                    "__buyers.isLoading"
                  ),
                })}
                onClick={() => {
                  this.loadBuyers()
                }}
              >
                {get(this.state, "__buyers.isLoading")
                  ? "Loading …"
                  : tt(t, "current.load_more")}
              </a>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}
