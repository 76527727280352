import styled from "styled-components"

export const CardInput = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;

  ::-webkit-input-placeholder {
    -webkit-text-security: none;
    color: rgb(117, 117, 117);
    font-weight: bold;
  }
`

export const CardBrandInput = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  display: inline-block;
  width: 15%;
  border-radius: 8px 0px 0px 8px;
  padding-left: 0px;

  ::-webkit-input-placeholder {
    -webkit-text-security: none;
    color: rgb(117, 117, 117);
    font-weight: bold;
  }
`

export const CardNumberInput = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  display: inline-block;
  width: 60%;
  border-radius: 0px;
  padding-right: 0px;
  padding-left: 0px;

  ::-webkit-input-placeholder {
    -webkit-text-security: none;
    color: rgb(117, 117, 117);
    font-weight: bold;
  }
`

export const CardExpiry = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  display: inline-block;
  width: 15%;
  border-radius: 0px;
  padding-right: 0px;
  padding-left: 0px;

  ::-webkit-input-placeholder {
    -webkit-text-security: none;
    color: rgb(117, 117, 117);
    font-weight: bold;
  }
`

export const CardCvc = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  display: inline-block;
  width: 10%;
  border-radius: 0px 8px 8px 0px;
  padding-left: 0px;

  ::-webkit-input-placeholder {
    -webkit-text-security: none;
    color: rgb(117, 117, 117);
    font-weight: bold;
  }
`

export const CardInlineInput = styled.div`
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  display: block !important;
  background: transparent !important;
  position: relative !important;
  opacity: 1 !important;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  line-height: 25px;
  height: 25px;
`

export const CardBrandField = styled.div`
  left: 0;
  pointer-events: none;
  z-index: 2;
  height: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 25px;
`

export const CardFieldsWrapper = styled.div`
  left: 2em;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-property: left, right;
  overflow: hidden;
  position: absolute;
  right: 0;
  white-space: nowrap;
`

export const CardNumberField = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  line-height: 25px;
  height: 25px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation: native-autofill-out 1ms;
  background-color: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 75%;

  :focus {
    outline: none;
  }
`

export const CardExpiryField = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  line-height: 25px;
  height: 25px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation: native-autofill-out 1ms;
  background-color: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 15%;

  :focus {
    outline: none;
  }
`

export const CardCvcField = styled.input`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo,
    sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
  line-height: 25px;
  height: 25px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation: native-autofill-out 1ms;
  background-color: transparent;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 10%;

  :focus {
    outline: none;
  }
`
