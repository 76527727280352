import React, {useState, useRef, useEffect} from "react"
import PropTypes from "prop-types"
import {get, omitBy} from "lodash"
import className from "classnames"
import {axios} from "@lib/helpers"

import {NoNameFunctionReturnVoid} from "types/common"
import {formDataType} from "types/pointPurchase"
import {newCardStateType} from "types/creditCard"

import initCardState from "states/initCardState"

import {
  validateInput,
  newFormInputs,
  cardFormData,
} from "utils/creditCardValidator"
import fetchCardToken from "utils/fetchCardToken"

import {FormInputs} from "components/creditCard/FormInputs"

type NewProps = {
  shopid: string
  path: string
  form: Map<string, string>
  section: Map<string, string>
}
const New = ({shopid, path, form, section}: NewProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [newCardState, setNewCardState] = useState<newCardStateType | null>(
    initCardState
  )

  const onFormInputsChangeHandler = (value: string, id: string) => {
    validateInput(value, id, newCardState, setNewCardState)
  }

  useEffect(() => {
    setHasErrors(
      !!newCardState.cardNameErrors ||
        !!newCardState.cardNumberErrors ||
        !!newCardState.cardExpiryErrors ||
        !!newCardState.cardCvcErrors
    )
  }, [newCardState])

  const submitCardFormData = (token: string) => {
    axios
      .post(path, {
        card: cardFormData(token),
      })
      .then((res2) => {
        const wid = get(res2, "data.data.worker_id")

        const poller = () => {
          axios
            .get(`/payments/poll_worker/?type=attach&worker_id=${wid}`)
            .then((res3) => {
              if (get(res3, "data.data.status") != "complete") {
                window.setTimeout(() => {
                  poller()
                }, 1000)
              } else {
                window.location.href = path
              }
            })
            .catch((e) => {
              window.location.reload()
            })
        }

        poller()
      })
      .catch((e) => {
        window.location.reload()
      })
  }

  return (
    <div className="AppPaymentsNew">
      <form
        className={className("AppForm", {
          "AppForm--overlay": isLoading,
        })}
        onSubmit={(e) => {
          e.preventDefault()

          setIsLoading(true)
          fetchCardToken(shopid, newCardState, submitCardFormData)
        }}
      >
        <FormInputs
          formInputs={newFormInputs(section, newCardState)}
          formLabels={form}
          onChangeHandler={onFormInputsChangeHandler}
        />
        <div className="AppForm__row">
          <button
            className="Button Button--large"
            disabled={isLoading || hasErrors}
          >
            {isLoading ? get(form, "loading") : get(form, "save")}
          </button>
        </div>

        <div className="AppForm__row">
          <a className="Button Button--large Button--white-purple" href={path}>
            {get(form, "back")}
          </a>
        </div>
      </form>
    </div>
  )
}

export default New
