import "core-js/stable"
import "regenerator-runtime/runtime"

import "../stylesheets/application.scss"
import "../stylesheets/custom_template.scss"

import {setTimezoneCookie} from "@lib/helpers"

require.context("../images", true, /\.(png|jpg|jpeg|svg)$/)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

import Rails from "rails-ujs"
Rails.start()
global.Rails = Rails

var moment = require("moment-timezone")
moment.locale("ja")
moment.tz.setDefault(moment.tz.guess(true))

import "agora-rtc-sdk-ng"

setTimezoneCookie()
