import React from "react"
import PropTypes from "prop-types"
import className from "classnames"

import {tt} from "@lib/helpers"

const AgencySettingSubmitButton = ({t}) => {
  const [__showModal, __showModalSet] = React.useState(false)
  const [__agencyId, __agencyIdSet] = React.useState("")

  React.useEffect(() => {
    const agencyIdInput = document.getElementById("agency_custom_id")
    const handler = (e) => __agencyIdSet(e.target.value)

    agencyIdInput.addEventListener("blur", handler)

    return () => {
      agencyIdInput.removeEventListener("blur", handler)
    }
  }, [])

  const showModal = (e) => {
    e.preventDefault()
    __showModalSet(true)
  }

  const hideModal = (e) => {
    e.preventDefault()
    __showModalSet(false)
  }

  return (
    <React.Fragment>
      <button className="Button" onClick={showModal}>
        {t.save}
      </button>

      <ModalConfirmation
        show={__showModal}
        onClose={hideModal}
        t={t}
        agencyId={__agencyId}
      />
    </React.Fragment>
  )
}

AgencySettingSubmitButton.propTypes = {
  t: PropTypes.object,
}

const ModalConfirmation = ({show, onClose, t, agencyId}) => {
  React.useEffect(() => {
    const handler = (e) => {
      // Escape key
      if (e.keyCode === 27) {
        onClose(e)
      }
    }
    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  }, [])

  return (
    <div
      className={className("ModalMenus ModalMenus--confirmation", {
        "ModalMenus--active": show,
      })}
    >
      <a className="ModalMenus__overlay" onClick={onClose} />

      <div className="ModalMenus__container">
        <div className="ModalMenus__body">
          <h3>{tt(t, "title", {agency_id: agencyId})}</h3>
          <p>{t.body}</p>

          <button type="submit" className="Button">
            {t.ok}
          </button>

          <button className="Button Button--link" onClick={onClose}>
            {t.cancel}
          </button>
        </div>
      </div>
    </div>
  )
}

ModalConfirmation.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.object,
  agencyId: PropTypes.string,
}

export default AgencySettingSubmitButton
