import * as React from "react"
// import className from 'classnames'
import countryCodes from "country-codes-list"

type CountryData = {
  officialLanguageCode: string
  countryCallingCode: number
  countryNameEn: string
  countryNameLocal: string
  countryCode: string
}

const FormInputCountryCallingCode = ({
  data,
}: {
  data: {phone_locale: string}
}) => {
  const [__phoneLocale, __phoneLocaleSet] = React.useState(data.phone_locale)

  return (
    <React.Fragment>
      <input
        type="hidden"
        name="user[language_code]"
        value={(countryCodes?.all?.() as CountryData[])
          ?.find((x) => x?.countryCode?.toLowerCase?.() == __phoneLocale)
          ?.officialLanguageCode?.slice?.(0, 2)}
        onChange={() => {
          return null
        }}
      />

      <select
        className="AppSelect"
        name="user[phone_locale]"
        value={__phoneLocale}
        onChange={(e) => {
          __phoneLocaleSet(e.target.value)
        }}
      >
        {countryCodes?.all?.()?.map?.((x: CountryData, i: number) => (
          <option value={x?.countryCode?.toLowerCase?.()} key={i}>
            +{x?.countryCallingCode} {x?.countryNameEn}
            {x?.countryNameLocal ? ` (${x?.countryNameLocal})` : ""}
          </option>
        ))}
      </select>
    </React.Fragment>
  )
}

export default FormInputCountryCallingCode
