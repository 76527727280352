import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import AppLanePackagesPrepare from "@components/molecules/AppLanePackagesPrepare"

type PropTypes = ComponentProps<typeof AppLanePackagesPrepare>

export default {
  title: "Molecules/AppLanePackagesPrepare",
  component: AppLanePackagesPrepare,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <AppLanePackagesPrepare {...args} />
)

export const Default = Template.bind({})
Default.args = {
  data: {
    package: {
      bulk_start_at: "",
      bulk_end_at: "",
    },
    QUEUEABLE_TIME: "",
    active_spot_lane_packages_package_path: "",
    start_at_and_end_at: {
      time: "",
    },
    spots: "",
    always_show_link: true,
    enable_web_call: true,
    link_path: "",
  },
}
