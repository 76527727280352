import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import find from "lodash/find"
import isNil from "lodash/isNil"
import className from "classnames"

const FormPublisherIds = ({data}) => {
  const [__publishers, __publishersSet] = React.useState(
    get(data, "event_publishers") || []
  )

  const getUnselectedPublishers = ({include} = {}) => {
    return (get(data, "agency_publishers") || []).filter(
      (x) =>
        (include && get(include, "publisher_id") == get(x, "publisher_id")) ||
        !find(
          __publishers,
          (y) =>
            !get(y, "_destroy") &&
            get(y, "publisher_id") == get(x, "publisher_id")
        )
    )
  }

  return (
    <div className="AdminEventsNew__publisher-ids">
      <div className="AdminEventsNew__publisher-ids__list">
        {__publishers.map((x, i) => (
          <div
            className="AdminEventsNew__publisher-ids__list__item"
            key={i}
            disabled={get(x, "_destroy")}
          >
            {[
              {
                key: "publisher_id",
                value: get(x, "publisher_id"),
              },
              {
                key: "id",
                value: get(x, "id"),
              },
              {
                key: "_destroy",
                value: Boolean(get(x, "_destroy")),
              },
            ].map((y, j) => {
              if (isNil(get(y, "value"))) {
                return null
              }

              return (
                <input
                  type="hidden"
                  className="Input"
                  name={`event[event_publishers_attributes][${i}][${get(
                    y,
                    "key"
                  )}]`}
                  value={get(y, "value")}
                  onChange={(e) => {}}
                  key={j}
                />
              )
            })}

            <select
              className="Select"
              value={get(x, "publisher_id")}
              onChange={(e) => {
                const selectingPublisher = find(
                  get(data, "agency_publishers"),
                  (y) => get(y, "publisher_id") == get(e, "target.value")
                )

                __publishersSet(
                  __publishers.map((y, j) => {
                    if (i == j) {
                      return {
                        ...y,
                        ...selectingPublisher,
                      }
                    }

                    return y
                  })
                )
              }}
            >
              {(() => {
                const unselectedPublishers = getUnselectedPublishers({
                  include: x,
                })

                return (
                  <React.Fragment>
                    {unselectedPublishers.map((y, j) => (
                      <option value={get(y, "publisher_id")} key={j}>
                        {get(y, "name")}
                      </option>
                    ))}
                  </React.Fragment>
                )
              })()}
            </select>

            <a
              className="Button Button--small Button--red"
              disabled={
                __publishers.filter((x) => !get(x, "_destroy")).length <= 1
              }
              onClick={() => {
                __publishersSet(
                  __publishers.map((y, j) => {
                    if (i == j) {
                      return {
                        ...y,
                        _destroy: true,
                      }
                    }

                    return y
                  })
                )
              }}
            >
              {get(data, "t.form.delete")}
            </a>
          </div>
        ))}
      </div>

      <div className="Buttons Buttons--jc-c">
        {(() => {
          const unselectedPublishers = getUnselectedPublishers()

          return (
            <a
              className="AdminEventsNew__publisher-ids__a"
              disabled={get(unselectedPublishers, "length", 0) == 0}
              onClick={() => {
                __publishersSet([
                  ...__publishers,
                  get(unselectedPublishers, "0"),
                ])
              }}
            >
              {get(data, "t.current.add_publishers")}
            </a>
          )
        })()}
      </div>
    </div>
  )
}

FormPublisherIds.propTypes = {}

export default FormPublisherIds
