import React from "react"
import isEmpty from "lodash/isEmpty"
import className from "classnames"

import {toHalfWidth} from "@lib/helpers"

type PropTypes = Partial<{
  value: number
  name: string
  errors: any
  placeholder: string
}>

const FormInputNumber: React.FC<PropTypes> = ({
  value,
  name,
  errors,
  placeholder,
}) => {
  return (
    <input
      className={className("Input", {
        "Input--error": !isEmpty(errors),
      })}
      type="text"
      name={name}
      defaultValue={value}
      onBlur={(e) => {
        e.target.value = toHalfWidth(e.target.value).replace(/\D/gi, "")
      }}
      placeholder={placeholder}
      maxLength={11}
      size={11}
    />
  )
}

export default FormInputNumber
