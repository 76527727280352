import * as React from "react"
import className from "classnames"
// import {useRouter} from 'next/router'
// import Link from 'next/link'
import * as __axios from "axios"

import {tt} from "@lib/helpers"

const AdminUploadedShippingsUpload = ({data}) => {
  const {t, admin_uploaded_shippings_path} = data

  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <form
      className={className("AdminUploadedShippingsIndex__upload", {
        "AdminUploadedShippingsIndex__upload--disabled": __isLoading,
      })}
    >
      <label>
        <span className="Button Button--icon-upload">
          {tt(t, __isLoading ? "loading" : "current.actions.upload")}
        </span>

        <input
          type="file"
          className="AdminUploadedShippingsIndex__upload__input"
          onChange={async (e) => {
            __isLoadingSet(true)

            const _formData = new FormData()

            _formData.append(
              "authenticity_token",
              document
                ?.querySelector('meta[name="csrf-token"]')
                ?.getAttribute("content") || ""
            )

            _formData.append(
              "uploaded_shipping[shipping_file]",
              e?.target?.files?.item?.(0)
            )

            try {
              await __axios.default.post(
                admin_uploaded_shippings_path,
                _formData
              )
            } finally {
              window.location.reload()
            }
          }}
        />
      </label>
    </form>
  )
}

export default AdminUploadedShippingsUpload
