import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import Loader from "@components/molecules/Loader"

type PropTypes = ComponentProps<typeof Loader>

export default {
  title: "Molecules/Loader",
  component: Loader,
} as Meta

const Template: Story<PropTypes> = (args) => <Loader {...args} />

export const Default = Template.bind({})
Default.args = {}
