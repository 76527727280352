import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios} from "@lib/helpers"

const AppUsersDelete = ({data}) => {
  const {t, current_user_id, user_delete_users_path} = data

  const [__indexStep, __indexStepSet] = React.useState(0)

  const [__errors, __errorsSet] = React.useState()
  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <div className="AppUsersDelete">
      {__indexStep == 0 && (
        <>
          <h1 className="App__h1">{get(t, "current.step1.title")}</h1>
          <p className="AppUsersDelete__p">{get(t, "current.step1.p")}</p>
          <p className="AppUsersDelete__p">{get(t, "current.step1.p2")}</p>
          <p className="AppUsersDelete__p">{get(t, "current.step1.p3")}</p>
          <br />
          <p className="AppUsersDelete__p">{get(t, "current.step1.p4")}</p>

          <a
            className="Button Button--large"
            onClick={() => {
              __indexStepSet(1)
            }}
          >
            {get(t, "current.step1.button")}
          </a>
        </>
      )}

      {__indexStep == 1 && (
        <>
          <h1 className="App__h1">{get(t, "current.step2.title")}</h1>
          <p className="AppUsersDelete__p">{get(t, "current.step2.p")}</p>

          <form
            className="AppForm"
            onSubmit={(e) => {
              e.preventDefault()

              __errorsSet()
              __isLoadingSet(true)

              axios
                .delete(user_delete_users_path, {
                  id: current_user_id,
                  reason: get(e, "target.elements.reason.value"),
                })
                .then(() => {
                  __indexStepSet(2)
                })
                .catch((e) => {
                  __errorsSet(get(e, "response.data.data.message"))
                })
                .finally(() => {
                  __isLoadingSet(false)
                })
            }}
          >
            <label className="AppForm__row">
              <div className="AppForm__label">
                <span className="AppForm__label__span">
                  {get(t, "current.step2.form.label")}
                </span>

                <span className="AppForm__label__required">
                  {get(t, "dictionary.required")}
                </span>
              </div>

              <div className="AppForm__value">
                <textarea
                  className="AppInput"
                  name="reason"
                  rows={5}
                  placeholder={get(t, "current.step2.form.placeholder")}
                />

                {__errors && (
                  <div className="attr_errors">
                    <div className="attr_errors__error">{__errors}</div>
                  </div>
                )}
              </div>
            </label>

            <div className="AppForm__row">
              <button className="Button Button--large" disabled={__isLoading}>
                {__isLoading
                  ? get(t, "dictionary.loading")
                  : get(t, "current.step2.button")}
              </button>
            </div>
          </form>
        </>
      )}

      {__indexStep == 2 && (
        <>
          <h1 className="App__h1">{get(t, "current.step3.title")}</h1>
          <p className="AppUsersDelete__p">{get(t, "current.step3.p")}</p>

          <a
            className="Button Button--large"
            onClick={() => {
              window.location.href = "/"
            }}
          >
            {get(t, "current.step3.button")}
          </a>
        </>
      )}
    </div>
  )
}

AppUsersDelete.propTypes = {}

export default AppUsersDelete
