import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import ModalMenus from "@components/molecules/ModalMenus"

type PropTypes = ComponentProps<typeof ModalMenus>

export default {
  title: "Molecules/ModalMenus",
  component: ModalMenus,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <ModalMenus {...args}>ModalInner</ModalMenus>
)

export const Default = Template.bind({})
Default.args = {
  show: true,
  title: "ModalTitle",
  onClose: () => {
    console.log("onClose")
  },
  isLoading: false,
}
