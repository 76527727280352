import React, {useState} from "react"
import {get} from "lodash"
import className from "classnames"

import {formInputsType, formInlineInputsType} from "types/creditCard"

import {getCardBrand} from "utils/creditCardValidator"

import {
  CardInput,
  CardBrandInput,
  CardNumberInput,
  CardExpiry,
  CardCvc,
  CardInlineInput,
  CardBrandField,
  CardFieldsWrapper,
  CardNumberField,
  CardExpiryField,
  CardCvcField,
} from "./StyledComponent"

import visaImg from "images/common/credit_cards/visa.svg"
import mastercardImg from "images/common/credit_cards/master.svg"
import jcbImg from "images/common/credit_cards/jcb.svg"
import amexImg from "images/common/credit_cards/amex.svg"
import dinersImg from "images/common/credit_cards/diners.svg"
import discoverImg from "images/common/credit_cards/discover.svg"

const getMaxLength = (id: string): number => {
  let maxLength = 32

  switch (id) {
    case "cardNumber":
      maxLength = 19
      break
    case "cardExpiry":
      maxLength = 7
      break
    case "cardCvc":
      maxLength = 4
      break
    default:
      break
  }

  return maxLength
}

const getPlaceholder = (id: string): string => {
  let placeholder = ""

  switch (id) {
    case "cardNumber":
      placeholder = "1234 1234 1234 1234"
      break
    case "cardExpiry":
      placeholder = "月/年"
      break
    case "cardCvc":
      placeholder = "CVC"
      break
    default:
      break
  }

  return placeholder
}

const getInputValue = (id: string, value: string): string => {
  let realValue = ""

  switch (id) {
    case "cardNumber":
      realValue = value
        .replace(/\D/g, "")
        .split(/(.{4})/)
        .filter((O) => O)
        .join(" ")
      break
    case "cardExpiry":
      realValue = value
        .replace(/\D/g, "")
        .split(/(.{2})/)
        .filter((O) => O)
        .filter((e, i, a) => i === 0 || i === a.length - 1)
        .join(" / ")
      break
    case "cardCvc":
      realValue = value.replace(/\D/g, "")
      break
    default:
      realValue = value
      break
  }

  return realValue
}
const getBrandImg = (value: string): string => {
  const brand = getCardBrand(value)

  switch (brand) {
    case "Visa":
      return visaImg
    case "MasterCard":
      return mastercardImg
    case "JCB":
      return jcbImg
    case "Diners Club":
      return dinersImg
    case "American Express":
      return amexImg
    case "Discover":
      return discoverImg
    default:
      return null
  }
}

export const FormInputs = ({
  formInputs,
  formLabels,
  onChangeHandler,
}: formInputsType) => {
  return (
    <React.Fragment>
      {formInputs.map((x, i) => (
        <div className="AppForm__row" key={i}>
          <div className="AppForm__label">
            <span className="AppForm__label__span">{get(x, "label")}</span>

            <span className="AppForm__label__required">
              {get(formLabels, "required")}
            </span>
          </div>

          <div
            className={className("AppForm__value", {
              field_with_errors: get(x, "errors"),
            })}
          >
            <CardInput
              type={get(x, "id") === "cardName" ? "text" : "tel"}
              className="AppInput"
              id={get(x, "id")}
              name={get(x, "id")}
              value={getInputValue(get(x, "id"), get(x, "value"))}
              onChange={(e) => onChangeHandler(e.target.value, get(x, "id"))}
              maxLength={getMaxLength(get(x, "id"))}
              required
              autoComplete="off"
              placeholder={getPlaceholder(get(x, "id"))}
            />
          </div>
          {get(x, "errors") && (
            <div className="attr_errors">
              <div className="attr_errors__error">{get(x, "errors")}</div>
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  )
}

export const InlineFormInputs = ({
  section,
  cardState,
  onChangeHandler,
}: formInlineInputsType) => {
  const [valid, setValid] = useState<boolean>(false)
  const fieldWithError = (): string => {
    return (
      valid &&
      (get(cardState, "cardNumberErrors") ||
        get(cardState, "cardExpiryErrors") ||
        get(cardState, "cardCvcErrors"))
    )
  }

  return (
    <React.Fragment>
      <div className="AppForm__row">
        <div
          className={className("AppForm__value", {
            field_with_errors: fieldWithError(),
          })}
        >
          <div className="AppInput">
            <CardInlineInput>
              <CardBrandField>
                {getBrandImg(cardState.cardNumber) ? (
                  <img
                    src={getBrandImg(cardState.cardNumber)}
                    height="25"
                    width="25"
                  />
                ) : (
                  defaultBrandSvg(cardState.cardNumberErrors, valid)
                )}
              </CardBrandField>
              <CardFieldsWrapper>
                <CardNumberField
                  type="tel"
                  id="cardNumber"
                  name="cardNumber"
                  className={
                    cardState.cardNumberErrors && valid ? "is-invalid" : ""
                  }
                  value={getInputValue("cardNumber", cardState.cardNumber)}
                  onChange={(e) => {
                    setValid(false)
                    onChangeHandler(e.target.value, "cardNumber")
                  }}
                  onBlur={() => setValid(true)}
                  maxLength={getMaxLength("cardNumber")}
                  required
                  autoComplete="off"
                  placeholder={getPlaceholder("cardNumber")}
                />
                <CardExpiryField
                  type="tel"
                  id="cardExpiry"
                  name="cardExpiry"
                  className={
                    cardState.cardExpiryErrors && valid ? "is-invalid" : ""
                  }
                  value={getInputValue("cardExpiry", cardState.cardExpiry)}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "cardExpiry")
                  }
                  maxLength={getMaxLength("cardExpiry")}
                  required
                  autoComplete="off"
                  placeholder={getPlaceholder("cardExpiry")}
                />
                <CardCvcField
                  type="tel"
                  id="cardCvc"
                  name="cardCvc"
                  value={getInputValue("cardCvc", cardState.cardCvc)}
                  onChange={(e) => onChangeHandler(e.target.value, "cardCvc")}
                  maxLength={getMaxLength("cardCvc")}
                  required
                  autoComplete="off"
                  placeholder={getPlaceholder("cardCvc")}
                />
              </CardFieldsWrapper>
            </CardInlineInput>
          </div>
          {fieldWithError() && (
            <div className="attr_errors">
              <div className="attr_errors__error">
                {get(section, fieldWithError())}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const defaultBrandSvg = (errors: string, valid: boolean) => {
  let fillColor = "#515e80"
  if (errors && valid) {
    fillColor = "#eb1c26"
  }

  return (
    <svg focusable="false" viewBox="0 0 32 21" height="100%">
      <g fill="none" fillRule="evenodd">
        <g fill={fillColor}>
          <g transform="translate(0 2)">
            <path
              d="M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity=".2"
            ></path>
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity=".3"
            ></path>
          </g>
          {errors && valid && (
            <g transform="translate(18)">
              <path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM6 3v4a1 1 0 0 0 2 0V3a1 1 0 0 0-2 0zm1 8.75a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"></path>
            </g>
          )}
        </g>
      </g>
    </svg>
  )
}
