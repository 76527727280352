import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import appLocale from "@locales/views/layouts/ja.yml"
import authLocale from "@locales/views/defaults/ja.yml"
import AppNav from "@components/molecules/AppNav"

type PropTypes = ComponentProps<typeof AppNav>

export default {
  component: AppNav,
  title: "molecules/AppNav",
} as Meta

const baseProps: PropTypes = {
  data: {
    service_information: {
      code: "Talkport",
      service_name: "Talkport（トークポート）",
      name: "Hoge | Talkport",
      tagline:
        "Talkport（トークポート）は憧れの人と1対1で話せるアプリです。その他、ライブチケット、物販、抽選、動画販売、トーク販売などタレントのための総合エンタメプラットフォームです。",
      url: "https://talkport.com",
      email: "info@talkport-app.com",
    },
    is_publisher_page: false,
    is_landing: false,
    links: {
      root_path: "example.com",
      actions: [
        {
          href: "example.com",
          label: "アクション1",
          klass: "example",
        },
        {
          href: "example.com",
          label: "アクション2",
          klass: "example",
        },
      ],
      helps: [
        {
          href: "example.com",
          label: "ヘルプ1",
          klass: "example",
        },
        {
          href: "example.com",
          label: "ヘルプ2",
          klass: "example",
        },
      ],
    },
    user: {
      data: {
        id: 1,
        passwordDigest: null,
        name: "ジョン・カビラ",
        createdAt: "2021-01-01",
        updatedAt: "2021-01-01",
        token: null,
        phoneNumber: "12345678901",
        email: "talkport@example.com",
        role: null,
        postalCode: "1008111",
        prefecture: "東京都",
        city: "千代田区",
        address1: "千代田1-1",
        address2: "皇居",
        resetDigest: null,
        resetSentAt: null,
        consentAcceptedAt: null,
        followersCount: null,
        followingsCount: null,
        stripeCustId: null,
        stripePaymentMethodId: null,
        discardedAt: null,
        googleSecret: null,
        mfaSecret: null,
        enableMfa: null,
        firstName: "ジョン",
        lastName: "カビラ",
        description: null,
        isAccountVerified: null,
        isIdentityVerified: null,
        identityImageType: "",
        ip: null,
        registeredIp: null,
      },
      followers_count: 100,
      followings_count: 100,
      icon: '<img src="https://1.bp.blogspot.com/-ZOg0qAG4ewU/Xub_uw6q0DI/AAAAAAABZio/MshyuVBpHUgaOKJtL47LmVkCf5Vge6MQQCNcBGAsYHQ/s1600/pose_pien_uruuru_woman.png" />',
      href: "example.com",
      unread_announcements: 100,
      is_buyer: true,
      is_identity_verified: true,
      is_identity_verifying: true,
      image_moderations_id_cards_last: {
        received_postback: true,
        declined: true,
      },
    },
    t: {
      version: "v1",
      application: appLocale.ja.layouts.application,
      auth: authLocale.ja.dictionary.auth,
    },
    followings_users_path: "",
    followers_users_path: "",
    users_announcements_path: "",
    identity_verification_mfa_path: "",
    app_version: "v1",
  },
}

const Template: Story<PropTypes> = (args) => <AppNav {...args} />
export const Default = Template.bind({})
Default.args = {
  ...baseProps,
}
