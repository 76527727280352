import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import PrettyJson from "@components/molecules/PrettyJson"

type PropTypes = ComponentProps<typeof PrettyJson>

export default {
  title: "Molecules/PrettyJson",
  component: PrettyJson,
} as Meta

const Template: Story<PropTypes> = (args) => <PrettyJson {...args} />

export const Default = Template.bind({})
Default.args = {
  data: {
    hoge: "fuga",
  },
}
