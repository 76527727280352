import * as React from "react"
import className from "classnames"
import {tt} from "@lib/helpers"
import get from "lodash/get"

const FormInvoice: React.FC<PropTypes> = ({data}) => {
  const [__inputValue, __inputValueSet] = React.useState(
    get(data, "invoice_number")
  )
  const [__checked, __checkedSet] = React.useState(
    get(data, "is_invoice_repricing")
  )
  const [__checkDisabled, __checkDisabledSet] = React.useState(
    get(data, "invoice_number") ? true : false
  )

  const {t, errors} = data

  const setValue = (val) => {
    __inputValueSet(val)
    if (val.length == 0) {
      __checkDisabledSet(false)
    } else {
      __checkDisabledSet(true)
      __checkedSet(false)
    }
  }

  return (
    <React.Fragment>
      <div className="AdminForm__row AdminForm__row--no-bdb">
        <label className="AdminForm__label" htmlFor="agency_invoice_number">
          <div className="AdminForm__label__span">
            {tt(t, "current.invoice_number")}
          </div>
        </label>
        <div className="AdminForm__value">
          <div className="AdminForm__value__control AdminForm__value__has-icon-left">
            <span className="AdminForm__value__icon AdminForm__value__icon--left">
              T
            </span>
            <div
              className={className({
                field_with_errors: Object.prototype.hasOwnProperty.call(
                  errors,
                  "invoice_number"
                ),
              })}
            >
              <input
                type="text"
                className="Input"
                maxLength="13"
                name="agency[invoice_number]"
                placeholder="1234567890123"
                value={__inputValue}
                onChange={(e) => setValue(e.currentTarget.value)}
              />
            </div>
          </div>
          {Object.prototype.hasOwnProperty.call(errors, "invoice_number") && (
            <div className="attr_errors">
              {errors["invoice_number"].map((error, i) => {
                return (
                  <div className="attr_errors__error" key={i}>
                    {`${tt(t, "current.invoice_number")}${error}`}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div className="AdminForm__row AdminForm__row--no-bdb">
        <label className="CheckBox CheckBox--secondary">
          <input
            type="hidden"
            name="agency[is_invoice_repricing"
            value={false}
          />
          <input
            type="checkbox"
            name="agency[is_invoice_repricing"
            checked={__checked}
            value={true}
            onChange={(e) => __checkedSet(!__checked)}
            disabled={__checkDisabled}
          />
          <span className="CheckBox__span">
            {tt(t, "current.is_invoice_repricing")}
          </span>
        </label>
      </div>
      <div className="AdminForm__row AdminForm__row--no-bdb"></div>
    </React.Fragment>
  )
}

export default FormInvoice
