const initCardState = {
  cardName: "",
  cardNameErrors: null,
  cardNumber: "",
  cardNumberErrors: null,
  cardExpiry: "",
  cardExpiryErrors: null,
  cardCvc: "",
  cardCvcErrors: null,
}

export default initCardState
