import React from "react"
// import PropTypes from "prop-types"
import {get, includes, isFinite} from "lodash"
import className from "classnames"
import * as queryString from "query-string"
import MobileDetect from "mobile-detect"

import {tt, axios} from "@lib/helpers"

class AppBookingsAfter extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  points = [
    {
      value: 5,
    },
    {
      value: 20,
    },
    {
      value: 50,
    },
    {
      value: 100,
    },
    {
      value: 300,
    },
  ]

  ___stripe = Stripe(get(this.props, "data.stripe_public_key"), {
    locale: get(this.props, "data.locale"),
  })

  ___paymentRequest = null
  ___cardElement = null

  $paypalButtonElement = React.createRef()
  $customInput = React.createRef()

  state = {
    __message: "",
    __isLoading: false,
    __supportBrowserPayment: false,
    __isMobileChrome: false,
    __isActiveCustom: Boolean(
      get(queryString.parse(window.location.search), "custom")
    ),
    __isChangingCustom: false,
    __customValue: get(queryString.parse(window.location.search), "custom")
      ? get(queryString.parse(window.location.search), "point")
      : 1,
  }

  mobileDetect = new MobileDetect(window.navigator.userAgent)

  getPointsToGive = () => {
    return Number(
      this.state.__isActiveCustom
        ? this.state.__customValue
        : get(
            queryString.parse(window.location.search),
            "point",
            get(this.points, "0.value")
          )
    )
  }

  givePoints = () => {
    axios
      .post(get(this.props, "data.gift_booking_path"), {
        gift: {
          point: this.getPointsToGive(),
          message: get(this.state, "__message"),
        },
      })
      .finally(() => {
        window.location.reload()
      })
  }

  purchasePoint = ({stripeData} = {}) => {
    this.setState(
      {
        __isLoading: true,
      },
      () => {
        let formData = {
          point_purchase: {
            point_to_purchase:
              this.getPointsToGive() - get(this.props, "data.has_points"),
          },
        }

        if (stripeData) {
          formData = {
            ...formData,
            ...stripeData,
          }
        }

        axios
          .post(get(this.props, "data.point_purchases_path"), formData)
          .then((res2) => {
            const id = get(res2, "data.data.id")

            const poller = () => {
              axios.get(`/point_purchases/${id}`).then((res3) => {
                if (includes(["success"], get(res3, "data.data.status"))) {
                  this.givePoints()
                } else if (includes(["error"], get(res3, "data.data.status"))) {
                  window.location.reload()
                } else {
                  window.setTimeout(() => {
                    poller()
                  }, 1000)
                }
              })
            }

            poller()
          })
      }
    )
  }

  getPointsToBuy = () => {
    return this.getPointsToGive() - get(this.props, "data.has_points")
  }

  componentDidMount = () => {
    const pointsToBuy = this.getPointsToBuy()

    if (pointsToBuy > 0) {
      this.___paymentRequest = this.___stripe.paymentRequest({
        country: "JP",
        currency: "jpy",
        total: {
          label: get(this.props, "data.package_title"),
          amount: pointsToBuy * get(this.props, "data.point_rate"),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      this.___paymentRequest.canMakePayment().then((result) => {
        if (result) {
          const isMobileChrome =
            this.mobileDetect.userAgent() &&
            this.mobileDetect.userAgent().toLowerCase() == "chrome"

          const createBrowserPaymentButton = () => {
            if (isMobileChrome) {
              return
            }

            this.___stripe
              .elements()
              .create("paymentRequestButton", {
                paymentRequest: this.___paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: "55px",
                  },
                },
              })
              .mount("#paymentRequestElement")
          }

          this.setState(
            {
              __supportBrowserPayment: true,
              __isMobileChrome: isMobileChrome,
            },
            createBrowserPaymentButton
          )
        }
      })

      // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
      this.___paymentRequest.on("paymentmethod", (ev) => {
        axios
          .post("/stripe_payment_intents", {
            point_to_purchase: pointsToBuy,
          })
          .then((res) => {
            const clientSecret = get(
              res,
              "data.data.payment_intent.client_secret"
            )

            this.___stripe
              .confirmCardPayment(
                clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
              )
              .then((confirmResult) => {
                const _purchasePoint = ({charge_id}) => {
                  ev.complete("success")

                  this.purchasePoint({
                    stripeData: {
                      stripe: {
                        charge_id,
                        request_method: ev.methodName,
                      },
                    },
                  })
                }

                if (confirmResult.error) {
                  ev.complete("fail")
                } else if (
                  confirmResult.paymentIntent.status === "requires_action"
                ) {
                  this.___stripe
                    .confirmCardPayment(clientSecret)
                    .then((result) => {
                      if (!result.error) {
                        _purchasePoint({
                          charge_id: confirmResult.paymentIntent.id,
                        })
                      } else {
                        ev.complete("fail")
                      }
                    })
                } else {
                  _purchasePoint({
                    charge_id: confirmResult.paymentIntent.id,
                  })
                }
              })
          })
          .catch(() => {
            ev.complete("fail")
          })
      })

      if (typeof paypal != "undefined" && paypal) {
        paypal
          .Buttons({
            style: {
              height: 55,
            },
            createOrder: () => {
              return axios
                .post("/paypal_payments", {
                  point_to_purchase: pointsToBuy,
                })
                .then((res) => {
                  return get(res, "data.data.order_id")
                })
                .catch(() => {
                  window.location.reload()
                })
            },
            onApprove: (data) => {
              this.setState(
                {
                  __isLoading: true,
                },
                () => {
                  axios
                    .post("/paypal_payments/capture", {
                      order_id: get(data, "orderID"),
                    })
                    .finally(() => {
                      this.givePoints()
                    })
                }
              )
            },
          })
          .render("#paypalButtonElement")
      }
    }
  }

  handleClickBrowserPayment = (e) => {
    e.preventDefault()
    this.___paymentRequest.show()
  }

  render = () => {
    const {data} = this.props
    const {t, default_payment_method_card, payments_path} = data
    const pointsToBuy = this.getPointsToBuy()

    return (
      <React.Fragment>
        <div
          className={className("AppBookingsShowAfterUser__form", {
            "AppForm--overlay": get(this.state, "__isLoading"),
          })}
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <div className="AppBookingsShowAfterUser__form__row">
            <h3 className="AppBookingsShowAfterUser__h3">
              {tt(t, "current.h3", {name: get(data, "publisher.name")})}
            </h3>

            <p className="AppBookingsShowAfterUser__small">
              {tt(t, "current.p", {points: get(data, "has_points")})}
            </p>
          </div>

          <div className="AppBookingsShowAfterPublisher__select-point">
            <div className="AppBookingsShowAfterPublisher__radios">
              <div className="AppBookingsShowAfterPublisher__radios__wrapper">
                {this.points.map((x, i) => (
                  <div className="AppBookingsShowAfterPublisher__radio" key={i}>
                    <a
                      className={className(
                        "AppBookingsShowAfterPublisher__radio__span",
                        {
                          "AppBookingsShowAfterPublisher__radio__span--active":
                            this.getPointsToGive() == get(x, "value") &&
                            !this.state.__isActiveCustom,
                        }
                      )}
                      href={`?${queryString.stringify({
                        point: get(x, "value"),
                      })}`}
                      onClick={() => {
                        this.setState({
                          __isLoading: true,
                        })
                      }}
                    >
                      {get(x, "value")}pt
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="AppBookingsShowAfterPublisher__select-point__custom"
              onClick={() => {
                this.setState(
                  {
                    __isActiveCustom: true,
                    __isChangingCustom: true,
                  },
                  () => {
                    this.$customInput?.current?.focus?.()
                  }
                )
              }}
            >
              <a
                className={className(
                  "AppBookingsShowAfterPublisher__radio__span",
                  {
                    "AppBookingsShowAfterPublisher__radio__span--large": true,
                    "AppBookingsShowAfterPublisher__radio__span--active":
                      this.state.__isActiveCustom,
                  }
                )}
              >
                {get(t, "payment_form.custom_amount")}
              </a>

              <input
                ref={this.$customInput}
                type="text"
                className="AppInput"
                value={this.state.__customValue}
                onChange={(e) => {
                  this.setState({
                    __customValue: e?.target?.value,
                  })
                }}
                onBlur={(e) => {
                  let _value = Number(get(e, "target.value"))

                  if (!isFinite(_value) || _value < 1) {
                    _value = 1
                  }

                  window.location.href = `?${queryString.stringify({
                    point: _value,
                    custom: 1,
                  })}`
                }}
              />

              <span className="AppBookingsShowAfterPublisher__select-point__custom__span">
                pt
              </span>
            </div>

            {(() => {
              let _pointsToGive = this.getPointsToGive()

              if (!isFinite(_pointsToGive)) {
                _pointsToGive = 0
              }

              let _text = tt(t, "current.form.point_to_yen", {
                yen: (_pointsToGive * 100).toLocaleString(),
              })

              let _isError = false

              if (_pointsToGive > 1000) {
                _isError = true
                _text = tt(t, "current.form.point_upper_error")
              } else if (_pointsToGive < 1) {
                _isError = true
                _text = tt(t, "current.form.point_lower_error")
              }

              return (
                <p
                  className={className(
                    "AppBookingsShowAfterPublisher__select-point__p",
                    {
                      "AppBookingsShowAfterPublisher__select-point__p--error":
                        _isError,
                    }
                  )}
                >
                  {_text}
                </p>
              )
            })()}
          </div>

          <label className="AppBookingsShowAfterPublisher__label">
            <span className="AppBookingsShowAfterPublisher__label__span">
              {tt(t, "current.form.message")}
            </span>

            <textarea
              className="AppInput"
              placeholder={tt(t, "current.form.message_placeholder")}
              rows={3}
              value={get(this.state, "__message")}
              onChange={(e) => {
                this.setState({
                  __message: get(e, "target.value"),
                })
              }}
            />
          </label>

          {pointsToBuy > 0 && (
            <React.Fragment>
              <div className="AppBookingsShowAfterUser__form__row">
                <h3 className="AppBookingsShowAfterUser__h3">
                  {tt(t, "point_purchase_form.purchase_point")}
                </h3>

                <p className="AppBookingsShowAfterUser__p">
                  {tt(t, "point_purchase_form.explanation", {
                    user_has_points: get(data, "has_points"),
                  })}
                </p>

                <div className="AppBookingsReserve__buttons">
                  <p className="AppInput">
                    {pointsToBuy}pt:{" "}
                    {pointsToBuy * get(this.props, "data.point_rate")}
                    {tt(t, "bookings_reserve_yen")}
                  </p>

                  <div
                    className={className("AppBookingsReserve__buttons__row", {
                      "AppBookingsReserve__buttons__row--pe-n":
                        this.state.__isChangingCustom,
                    })}
                  >
                    {this.state.__supportBrowserPayment ? (
                      this.state.__isMobileChrome ? (
                        <button
                          className="AppBookingsReserve__google-pay-button"
                          onClick={this.handleClickBrowserPayment}
                        >
                          <img
                            src={get(this.props, "data.imgs.google_pay")}
                            alt="Google Pay"
                          />{" "}
                          Pay
                        </button>
                      ) : (
                        <div
                          id="paymentRequestElement"
                          className="AppBookingsReserve__button"
                        ></div>
                      )
                    ) : null}

                    <div
                      id="paypalButtonElement"
                      ref={this.$paypalButtonElement}
                      className="AppBookingsReserve__button"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="AppBookingsReserve__box">
                <h3 className="AppBookingsReserve__h3">
                  {get(t, "point_purchase_form.payment_info")}
                </h3>

                <a
                  className="AppPayments__card AppPayments__card--single"
                  href={payments_path}
                >
                  {default_payment_method_card ? (
                    <React.Fragment>
                      <img
                        className="AppPayments__card__img"
                        src={get(default_payment_method_card, "img")}
                      />

                      <div className="AppPayments__card__right">
                        <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
                          <div className="AppPayments__card__right__top__wrapper">
                            <div className="AppPayments__card__right__top__number">
                              **** **** ****{" "}
                              {get(default_payment_method_card, "last4")}
                            </div>

                            <div className="AppPayments__card__right__top__brand">
                              {get(default_payment_method_card, "brand")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="AppPayments__card__right__top AppPayments__card__right__top--caret AppPayments__card__right__top--gc-1-3">
                      <div className="AppPayments__card__right__top__wrapper">
                        <div className="AppPayments__card__right__top__number">
                          {get(t, "payment_form.link")}
                        </div>
                      </div>
                    </div>
                  )}
                </a>
              </div>
            </React.Fragment>
          )}

          <a
            className={className("Button", {
              "Button--large": true,
              "Button--pe-n": this.state.__isChangingCustom,
              "Button--disabled":
                ((!default_payment_method_card ||
                  get(this.state, "__isLoading")) &&
                  this.getPointsToBuy() > 0) ||
                this.getPointsToGive() > 1000 ||
                this.getPointsToGive() < 1,
            })}
            onClick={() => {
              if (
                window.confirm(
                  tt(t, "current.form.confirm", {
                    point: this.getPointsToGive(),
                  })
                )
              ) {
                this.setState(
                  {
                    __isLoading: true,
                  },
                  () => {
                    if (pointsToBuy > 0) {
                      this.purchasePoint()
                    } else {
                      this.givePoints()
                    }
                  }
                )
              }
            }}
          >
            {tt(t, "current.form.submit")}
          </a>
        </div>
      </React.Fragment>
    )
  }
}

export default AppBookingsAfter
