import React from "react"
import className from "classnames"

type PropTypes = {
  flash: ["danger" | "success", string][]
}

const Alert: React.FC<PropTypes> = ({flash}) => {
  const [__flash, __flashSet] = React.useState(flash)

  if (!__flash || __flash.length <= 0) {
    return null
  }

  return (
    <div className="Alert">
      {__flash.map((x, i) => (
        <a
          key={i}
          className={className("Alert__a", `Alert__a--${x[0]}`)}
          onClick={() => {
            __flashSet(__flash.filter((_, j) => j !== i))
          }}
        >
          {x[1]}
        </a>
      ))}
    </div>
  )
}

export default Alert
