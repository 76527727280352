import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import DateTimePicker from "@components/molecules/DateTimePicker"
import moment from "moment"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof DateTimePicker>

export default {
  title: "Molecules/DateTimePicker",
  component: DateTimePicker,
} as Meta

const Template: Story<PropTypes> = (args) => <DateTimePicker {...args} />

export const Default = Template.bind({})
Default.args = {
  onChange: (something) => console.log(something),
  t: packageLocale.ja.packages.form.calendar,
  name: "name",
  $$defaultValue: moment(),
  __errors: {},
  eKey: "eKey",
  disabled: false,
  enableCreatableTime: true,
  updateStatesPerPropsChange: true,
  BEFORE_CALL_PREPARATION_TIME: 0,
}
