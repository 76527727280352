import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {tt} from "@lib/helpers"

const AppMfaIdentityVerificationStep1 = ({data}) => {
  const {t} = data

  const [__imageType, __imageTypeSet] = React.useState()

  return (
    <React.Fragment>
      <h1 className="App__h1">{tt(t, "current.title_1")}</h1>

      <p
        className="AppMfaIdentityVerification__p"
        dangerouslySetInnerHTML={{__html: tt(t, "current.label1_1")}}
      />

      <dl className="AppMfaIdentityVerification__dl">
        {(get(data, "identity_image_type") || []).map((x, i) => (
          <a
            className="AppMfaIdentityVerification__dl__item"
            key={i}
            onClick={() => {
              __imageTypeSet(get(x, "image_type"))
            }}
          >
            <div
              className={className(
                "AppMfaIdentityVerification__dl__item__image",
                {
                  "AppMfaIdentityVerification__dl__item__image--active":
                    __imageType == get(x, "image_type"),
                }
              )}
            >
              <svg
                className="AppMfaIdentityVerification__dl__item__image__svg"
                viewBox="0 0 1 1"
              />

              <img
                className="AppMfaIdentityVerification__dl__item__image__img"
                src={get(x, "img")}
              />
            </div>

            <dt className="AppMfaIdentityVerification__dl__item__dt">
              {get(x, "label")}
            </dt>
          </a>
        ))}
      </dl>

      <p
        className="AppMfaIdentityVerification__p"
        dangerouslySetInnerHTML={{__html: tt(t, "current.label3_1")}}
      />

      <a
        className="Button Button--large"
        disabled={!__imageType}
        href={`?step=2&identity_image_type=${__imageType}`}
      >
        {tt(t, "current.button_1")}
      </a>
    </React.Fragment>
  )
}

AppMfaIdentityVerificationStep1.propTypes = {}

export default AppMfaIdentityVerificationStep1
