import * as React from "react"
import get from "lodash/get"
import className from "classnames"

import {axios} from "@lib/helpers"

type ControlLink = {
  label: string
  href: string
  method?: "get" | "post" | "patch" | "put" | "delete"
  confirm?: string
  className?: string
  onClick?: () => void
}

type Props = {
  control_links: ControlLink[]
  label: string
}

const TableControls = ({control_links, label}: Props): React.ReactElement => {
  const [__isActive, __isActiveSet] = React.useState(false)

  return (
    <div className="Table__td__buttons" data-klass="Table__td__buttons">
      <a
        className="Table__td__buttons__handle"
        onClick={() => {
          __isActiveSet(true)
        }}
      />

      <a
        className={className("Table__td__buttons__overlay", {
          "Table__td__buttons__overlay--active": __isActive,
        })}
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div
        className={className("Table__td__buttons__panel", {
          "Table__td__buttons__panel--active": __isActive,
        })}
      >
        <div className="Table__td__buttons__panel__header">
          <h4 className="Table__td__buttons__panel__h4">{label}</h4>

          <a
            className="Table__td__buttons__panel__header__a"
            onClick={() => {
              __isActiveSet(false)
            }}
          />
        </div>

        <div className="Table__td__buttons__panel__links">
          {control_links.map((x, i) => (
            <a
              key={i}
              className={`Table__td__button ${get(x, "className") || ""}`}
              href={get(x, "href")}
              target={get(x, "target")}
              rel={get(x, "rel")}
              onClick={(e) => {
                if (get(x, "method")) {
                  e.preventDefault()

                  if (confirm(get(x, "confirm")) && x.method) {
                    axios[x.method](get(x, "href")).finally(() => {
                      window.location.reload()
                    })
                  }
                }

                if (x?.onClick) {
                  e.preventDefault()
                  x.onClick()
                }
              }}
            >
              {get(x, "label")}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TableControls
