import React, {useState, useEffect, Fragment} from "react"
import PropTypes from "prop-types"
import className from "classnames"
import get from "lodash/get"
import Loader from "@components/molecules/Loader"
import {axios} from "@lib/helpers"

const ShareToSNSButton = ({link, locale, text}) => {
  const [__shortenUrl, __shortenUrlSet] = useState("")
  const [__showModal, __showModalSet] = useState("") // 'share-link' | 'qrcode'
  const [__isLoading, __isLoadingSet] = useState(false)
  const [__qrcode, __qrcodeSet] = useState(null)
  const [__qrcodeSrc, __qrcodeSrcSet] = useState(null)

  const generateShortenUrl = async (url) => {
    return await axios.get("/shorten_words", {
      word: url,
    })
  }

  const closeModal = () => {
    __showModalSet("")
  }

  return (
    <Fragment>
      <a
        className="Button Button--icon-share"
        onClick={async (e) => {
          e.preventDefault()
          __showModalSet("share-link")
          __shortenUrlSet(link)

          // Disable this until our app supports dynamic links.
          // https://trello.com/c/05lRDzBF/1312-app-support-more-deep-link-paths-on-app?menu=filter&filter=member:phatodatawow
          // __isLoadingSet(true)
          // const response = await generateShortenUrl(link)
          // __shortenUrlSet(response.data.shorten_word)
          // __isLoadingSet(false)
        }}
      >
        {get(locale, "share_sns")}
      </a>

      <Modal
        show={__showModal === "share-link"}
        title={get(locale, "share_sns")}
        onClose={closeModal}
        isLoading={__isLoading}
      >
        <ul className="ModalFormInputProduct__panel__sns-list">
          <li>
            <a
              href={`http://twitter.com/intent/tweet?text=${text}&url=${__shortenUrl}`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li>
            <a
              href={`https://social-plugins.line.me/lineit/share?url=${__shortenUrl}`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              LINE
            </a>
          </li>
          <li>
            <a
              onClick={(e) => {
                document.addEventListener(
                  "copy",
                  (e) => {
                    e.preventDefault()
                    e.clipboardData.setData("text/plain", __shortenUrl)
                  },
                  {once: true}
                )
                document.execCommand("copy")
                alert("コピーしました")
              }}
            >
              URLをコピー
            </a>
          </li>
          <li>
            <a
              onClick={async (e) => {
                e.preventDefault()
                __showModalSet("qrcode")
                if (!__qrcodeSrc) {
                  __isLoadingSet(true)
                  const {data} = await axios.get("/qrcodes", {
                    link: __shortenUrl,
                  })
                  const svgUrl = URL.createObjectURL(
                    new Blob([data.qrcode], {type: "image/svg+xml"})
                  )
                  __qrcodeSrcSet(svgUrl)
                  __isLoadingSet(false)
                }
              }}
            >
              QRコードを表示
            </a>
          </li>
        </ul>
      </Modal>

      <Modal
        show={__showModal === "qrcode"}
        title="QRコード"
        onClose={closeModal}
        isLoading={__isLoading}
      >
        <img
          className="ModalFormInputProduct__qrcode"
          src={__qrcodeSrc}
          alt="QR Code"
        />
        <a className="Button" href={__qrcodeSrc} download>
          QRコードを保存
        </a>
      </Modal>
    </Fragment>
  )
}

ShareToSNSButton.propTypes = {
  link: PropTypes.string.isRequired,
  locale: PropTypes.object.isRequired,
}

export default ShareToSNSButton

const Modal = ({show, title, onClose, children, isLoading}) => {
  return (
    <div
      className={className("ModalFormInputProduct", {
        "ModalFormInputProduct--active": show,
      })}
    >
      <a className="ModalFormInputProduct__overlay" onClick={onClose} />

      <div className="ModalFormInputProduct__panel">
        <button
          className="ModalFormInputProduct__close-button"
          onClick={onClose}
        >
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h3 className="ModalFormInputProduct__panel__h3 ModalFormInputProduct__panel__h3--border">
          {title}
        </h3>

        {isLoading ? <Loader /> : children}
      </div>
    </div>
  )
}
