import {get, entries, nth} from "lodash"
import * as __axios from "axios"
import isNil from "lodash/isNil"
import filter from "lodash/filter"
import keyBy from "lodash/keyBy"
import mapValues from "lodash/mapValues"
import split from "lodash/split"
import Cookies from "js-cookie"

const applicationKey = "ebisu"
export const PAYMENTS_KONBINI_KEY = `_${applicationKey}_payments_konbini`
export const VIDEO_DEVICE_ID_KEY = `_${applicationKey}_video_device_id`
export const AUDIO_DEVICE_ID_KEY = `_${applicationKey}_audio_device_id`
export const VIDEO_FLIP_X_KEY = `_${applicationKey}_video_flip`
export const AUDIO_MUTE_KEY = `_${applicationKey}_audio_mute`
export const VIDEO_MUTE_KEY = `_${applicationKey}_video_mute`
export const FEATURE_ANNOUNCEMEN_IDS_KEY = `_${applicationKey}_feature_announcemen_ids`

export const setTimezoneCookie = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let expires = new Date()
  expires.setTime(expires.getTime() + 60 * 60 * 24)
  expires = expires.toGMTString()
  document.cookie = "timezone=" + timezone + "; Path=/"
}

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const tt = (t: any, key: string, args = {}) => {
  try {
    let text = get(t, `${key}`, key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        return result.replace(
          `%{${nth(current, 0)}}`,
          nth(current, 1).toString() || ""
        )
      }, text)
    }

    return text
  } catch (e) {
    return key
  }
}

// @ts-ignore
export const _axios = __axios.create({
  baseURL: "",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  responseType: "json",
})

const authenticity_token =
  document.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ||
  ""

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url: string, data?: any) => {
    return _axios.get(url, {
      params: data,
    })
  },

  post: (url: string, data?: any) => {
    return _axios.post(url, {
      authenticity_token,
      ...data,
    })
  },

  patch: (url: string, data?: any) => {
    return _axios.patch(url, {
      authenticity_token,
      ...data,
    })
  },

  put: (url: string, data?: any) => {
    return _axios.put(url, {
      authenticity_token,
      ...data,
    })
  },

  delete: (url: string, data?: any) => {
    return _axios.delete(url, {
      data: {
        authenticity_token,
        ...data,
      },
    })
  },
}

export const toHalfWidth = (value: string) => {
  return value.replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

// <input type="text" name="publisher_name" value="a" />
// <input type="text" name="purchaser_name" value="b" />
// => {publisher_name: "a", purchaser_name: "b"}
export const formInputsToObject = ({elements}) => {
  return mapValues(
    keyBy(
      filter(elements, (x) => get(x, "name") && !isNil(get(x, "value"))).map(
        (x) => ({
          name: get(x, "name"),
          value: get(x, "value"),
        })
      ),
      "name"
    ),
    "value"
  )
}

export const stripeStyle = {
  classes: {
    base: "AppInput",
  },
  style: {
    base: {
      fontFamily:
        "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo, sans-serif",
      fontSize: "15px",
      color: "rgba(0, 0, 0, 0.8)",
      fontWeight: "bold",
      lineHeight: "25px",
    },
  },
}

// https://docs.agora.io/en/Video/API%20Reference/web/interfaces/agorartc.stream.html#setbeautyeffectoptions
export const beautyEffectOptions = {
  lighteningContrastLevel: 2,
  lighteningLevel: 0.5,
  smoothnessLevel: 1,
  rednessLevel: 0,
}

export const getFeatureAnnouncemenIds = () => {
  return isNil(Cookies.get(FEATURE_ANNOUNCEMEN_IDS_KEY))
    ? []
    : split(Cookies.get(FEATURE_ANNOUNCEMEN_IDS_KEY), ",")
}

export const setFeatureAnnouncemenIds = ({
  featureAnnouncemenIds,
  latestAnnouncementId,
  e,
}) => {
  Cookies.set(
    FEATURE_ANNOUNCEMEN_IDS_KEY,
    [...featureAnnouncemenIds, latestAnnouncementId].join()
  )

  if (e) {
    if (e?.target?.getAttribute("target") == "_blank") {
      window.open(e?.target?.href)
    } else {
      // This is for Cypress.
      e.preventDefault()
    }

    window.location.reload()
  }
}

export const paymentNewFormStyle = {
  classes: {
    base: "AppInput",
  },
  style: {
    base: {
      fontFamily:
        "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans, Helvetica Neue, Hiragino Sans, Meiryo, sans-serif",
      fontSize: "15px",
      color: "rgba(0, 0, 0, 0.8)",
      fontWeight: "bold",
      lineHeight: "25px",
    },
  },
}
