import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputText from "@components/molecules/FormInputText"

type PropTypes = ComponentProps<typeof FormInputText>

export default {
  title: "Molecules/FormInputText",
  component: FormInputText,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputText {...args} />

export const Default = Template.bind({})
Default.args = {
  value: "example",
  name: "user[email]",
  placeholder: "This is placeholder",
  klass: "",
}
