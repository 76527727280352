import React from "react"
import get from "lodash/get"
import className from "classnames"
import moment from "moment"
import * as __axios from "axios"

import {axios} from "@lib/helpers"

import DateTimePicker from "@components/molecules/DateTimePicker"

class AdminBookingsEdit extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __isActiveModal: false,
    __modificationMethod: 0,
    __selectSpot: get(this.props, "data.spot"),
    __newSpotStartAt: moment().add(10, "minute").startOf("minute"),
  }

  changeSpot = (spotId) => {
    axios
      .put(get(this.props, "paths.update_booking_path"), {
        booking: {
          spot_id: spotId,
        },
      })
      .finally(() => {
        window.location = location.pathname
      })
  }

  render = () => {
    const spots = get(this.props, "data.available_spots")
    const publisher = get(this.props, "data.publisher")
    const user = get(this.props, "data.user")
    const spot = get(this.props, "data.spot")
    const t = get(this.props, "locale")

    return (
      <React.Fragment>
        <a
          className="Button Button--small"
          onClick={() => {
            this.setState({
              __isActiveModal: true,
            })
          }}
        >
          {get(t, "change")}
        </a>
        <div
          className={className("ModalFormEditBooking", {
            "ModalFormEditBooking--active": get(this.state, "__isActiveModal"),
          })}
        >
          <a
            className="ModalFormEditBooking__overlay"
            onClick={() => {
              this.setState({
                __isActiveModal: false,
              })
            }}
          />

          <div className="ModalFormEditBooking__panel">
            <h3 className="ModalFormEditBooking__panel__h3 ModalFormEditBooking__panel__h3--border">
              {get(t, "change")}
            </h3>

            <div className="AppForm">
              <div className="AppForm__row">
                <div className="AppForm__label">
                  <span className="AppForm__label__span">
                    {get(t, "method")}
                  </span>
                </div>

                <div className="AppForm__radios">
                  {[
                    {
                      label: get(t, "existing"),
                    },
                    {
                      label: get(t, "new_spot"),
                    },
                  ].map((x, i) => (
                    <label className="AppForm__radios__radio" key={i}>
                      <input
                        type="radio"
                        checked={get(this.state, "__modificationMethod") == i}
                        onChange={() => {
                          this.setState({
                            __modificationMethod: i,
                          })
                        }}
                      />

                      <div className="AppForm__radios__radio__label">
                        <div className="AppForm__radios__radio__label__wrapper">
                          <strong className="AppForm__radios__radio__label__strong">
                            {get(x, "label")}
                          </strong>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>

              {get(this.state, "__modificationMethod") == 0 && (
                <div className="AppForm__row">
                  <div className="AppForm__label">
                    <span className="AppForm__label__span">
                      {get(t, "replace")}
                    </span>
                  </div>

                  <div className="ModalFormEditBooking__panel__radios">
                    {spots.map((spot) => (
                      <label className="AppForm__radios__radio" key={spot.id}>
                        <input
                          type="radio"
                          checked={
                            get(this.state, "__selectSpot.id") ==
                            get(spot, "id")
                          }
                          onChange={() => {
                            this.setState({
                              __selectSpot: spot,
                            })
                          }}
                        />

                        <div className="AppForm__radios__radio__label">
                          <div className="AppForm__radios__radio__label__wrapper">
                            <p
                              className={className(
                                "AppForm__radios__radio__label__p",
                                {
                                  "AppForm__radios__radio__label__p--gray":
                                    !moment(get(spot, "start_at")).isSame(
                                      get(this.state, "__selectSpot.start_at"),
                                      "day"
                                    ),
                                }
                              )}
                            >
                              {get(spot, "name")}
                            </p>
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {get(this.state, "__modificationMethod") == 1 && (
                <React.Fragment>
                  <div className="AppForm__row">
                    <Label t={t} tKey="start_at" />

                    <div className="AppForm__value">
                      <DateTimePicker
                        t={get(t, "packages_form.calendar")}
                        name="start_date"
                        __errors={get(this.state, "__errors")}
                        eKey="start_date"
                        $$defaultValue={get(this.state, "__newSpotStartAt")}
                        onChange={({__$$selectingDateTime}) => {
                          this.setState({
                            __newSpotStartAt: moment(__$$selectingDateTime),
                          })
                        }}
                      />

                      <Error __error={get(this.state, "__errors.start_date")} />
                    </div>
                  </div>

                  <div className="AppForm__row">
                    <Label t={t} tKey="publisher" required={false} />

                    <p className="AdminForm__value">{publisher.name}</p>
                  </div>

                  <div className="AppForm__row">
                    <Label t={t} tKey="purchaser" required={false} />

                    <p className="AdminForm__value">{user.name}</p>
                  </div>

                  <div className="AppForm__row">
                    <Label t={t} tKey="spot_point" required={false} />

                    <p className="AdminForm__value">{spot.point}pt</p>
                  </div>

                  <div className="AppForm__row">
                    <Label t={t} tKey="duration" required={false} />

                    <p className="AdminForm__value">
                      {get(this.props, "data.call_duration")}
                    </p>
                  </div>
                </React.Fragment>
              )}

              <a
                className="Button Button--large"
                onClick={() => {
                  if (get(this.state, "__modificationMethod") == 0) {
                    if (
                      !moment(get(this.state, "__selectSpot.start_at")).isSame(
                        get(spot, "start_at"),
                        "day"
                      )
                    ) {
                      if (!window.confirm(get(t, "confirm_replace"))) {
                        return
                      }
                    }

                    this.changeSpot(get(this.state, "__selectSpot.id"))
                  } else {
                    axios
                      .post(get(this.props, "paths.create_spot_path"), {
                        spot: {
                          start_at: get(this.state, "__newSpotStartAt"),
                          seconds: spot.seconds,
                          point: spot.point,
                        },
                      })
                      .then((res) => {
                        if (res.data.success) {
                          this.changeSpot(res.data.spot.id)
                        }
                      })
                      .catch((e) => {
                        this.setState({
                          __errors: {
                            start_date: get(e, "response.data.error"),
                          },
                        })
                      })
                  }
                }}
              >
                {get(t, "save")}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AdminBookingsEdit

const Error = ({__error}) => {
  return (
    <React.Fragment>
      {__error && (
        <div className="attr_errors">
          <p className="attr_errors__error">{__error}</p>
        </div>
      )}
    </React.Fragment>
  )
}

const Label = ({t, tKey, text, required = true}) => {
  return (
    <div className="AppForm__label">
      <span className="AppForm__label__span">{text ? text : get(t, tKey)}</span>

      {required && (
        <span className="AppForm__label__required">{get(t, "required")}</span>
      )}
    </div>
  )
}
