import * as React from "react"
import className from "classnames"
import {isNil} from "lodash"

import {axios, tt} from "@lib/helpers"

const FormInputStatus = ({
  data,
}: {
  data: {
    update_path: string
    discarded_at: string
    t: any
    mode?: "agency" | "user"
    publisher_id?: number
  }
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)

  const {t, mode = "agency"} = data

  return (
    <div className="AdminForm__status">
      <a
        className={className("Button Button--small", {
          "Button--white-purple": isNil(data.discarded_at),
          "Button--orange-bordered": !isNil(data.discarded_at),
        })}
        onClick={() => {
          __isActiveSet(true)
        }}
      >
        {data.discarded_at
          ? tt(t, "current.status_inactive")
          : tt(t, "current.status_active")}
      </a>

      <a
        className={className("AdminForm__status__overlay", {
          "AdminForm__status__overlay--active": __isActive,
        })}
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div
        className={className("AdminForm__status__panel", {
          "AdminForm__status__panel--active": __isActive,
        })}
      >
        {[
          {
            label: tt(t, "current.status_active"),
            isActive: isNil(data.discarded_at),
            discarded: false,
            message: tt(t, "current.status_active_message"),
          },
          {
            label: tt(t, "current.status_inactive"),
            isActive: !isNil(data.discarded_at),
            discarded: true,
            message: tt(t, "current.status_inactive_message"),
          },
        ].map((x, i) => (
          <a
            key={i}
            className={className("AdminForm__status__panel__a", {
              "AdminForm__status__panel__a--active": x.isActive,
            })}
            onClick={() => {
              const _param = {
                discarded: x.discarded,
              }

              if (!x.isActive && confirm(x.message)) {
                axios
                  .put(
                    data.update_path,
                    mode == "user"
                      ? {
                          user: {
                            publisher_attributes: {
                              ..._param,
                              id: data.publisher_id,
                            },
                          },
                        }
                      : {
                          agency: _param,
                        }
                  )
                  .finally(() => {
                    window.location.reload()
                  })
              }
            }}
          >
            {x.label}
          </a>
        ))}
      </div>
    </div>
  )
}

export default FormInputStatus
