import React from "react"

type PropTypes = {
  message: string
}

const FormErrorMessage: React.FC<PropTypes> = ({message}) => {
  if (!message) return null

  return (
    <div className="attr_errors">
      <div className="attr_errors__error">{message}</div>
    </div>
  )
}

export default FormErrorMessage
