import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import NewAndEditPackage from "@components/molecules/NewAndEditPackage"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof NewAndEditPackage>

export default {
  title: "Molecules/NewAndEditPackage",
  component: NewAndEditPackage,
} as Meta

const Template: Story<PropTypes> = (args) => <NewAndEditPackage {...args} />

export const Default = Template.bind({})
Default.args = {
  data: {
    url: "/admin/packages",
    agency_setting: {
      id: 1,
      agency_id: 1,
      enable_2fa: false,
      enable_identity_verification: false,
      enable_agency_page: true,
      enable_chip: true,
      enable_coupon: false,
      enable_freecall: false,
      enable_goods: true,
      enable_hidden_inventory: false,
      enable_lane: true,
      enable_lottery: true,
      enable_minimum_fee: true,
      enable_online_live: true,
      enable_passcode: false,
      enable_point: false,
      enable_recording: false,
      enable_screenshot_restrictions: false,
      enable_seconds: true,
      enable_web_call: true,
      enable_without_interval: false,
      enable_store_live_video: false,
      enable_serial_code: false,
      enable_private_selling: false,
      minimum_fee: 0,
      created_at: "2021-05-19 07:48:23",
      updated_at: "2021-06-01 07:50:34",
    },
    default_minimum_fee: 5,
    point_package_select_options: [
      ["5pt: 500円", 1],
      ["10pt: 1000円", 2],
      ["100pt: 10000円", 3],
      ["500pt: 50000円", 4],
      ["1000pt: 100000円", 5],
    ],
    t: packageLocale.ja.packages.bulk_new,
    products: [],
    online_live_archive_periods: {
      アーカイブは残さない: 0,
      "１日間": 1,
      "３日間": 2,
      "１週間": 3,
      "２週間": 4,
    },
    suggestion_url: "http://localhost:3000/admin/spots/suggestion",
    BEFORE_CALL_PREPARATION_TIME: "5 minutes",
    with_publisher_selection: true,
    publishers: [
      {
        id: 1,
        user_id: 1,
        agency_id: 1,
        token: "01234567",
        custom_id: "",
        access_scope: "public",
        name: "Adam Smith",
        description: "this is description",
        icon_image_key: null,
        created_at: "2021-05-19 07:51:38",
        updated_at: "2021-05-19 07:51:38",
      },
    ],
    MAXIMUM_SPOTS_ALLOWED: 300,
  },
}
