import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import CopyFromExistingPackage from "@components/molecules/CopyFromExistingPackage"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof CopyFromExistingPackage>

export default {
  title: "Molecules/CopyFromExistingPackage",
  component: CopyFromExistingPackage,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <CopyFromExistingPackage {...args} />
)

export const Default = Template.bind({})
Default.args = {
  data: {
    t: {
      current: packageLocale.ja.packages.copy_from_existing,
    },
    past_index_packages_path: "",
  },
}
