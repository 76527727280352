import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import TableControls from "@components/molecules/TableControls"

type PropTypes = ComponentProps<typeof TableControls>

export default {
  title: "Molecules/TableControls",
  component: TableControls,
} as Meta

const Template: Story<PropTypes> = (args) => <TableControls {...args} />

export const Default = Template.bind({})
Default.args = {
  control_links: [...Array(10)].map((_, index) => {
    return {
      label: `label${index}`,
      href: `https://example.com/${index}`,
    }
  }),
  label: "label",
}
