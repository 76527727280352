import React, {useState, useRef} from "react"
import PropTypes from "prop-types"
import className from "classnames"
import {axios} from "@lib/helpers"
import * as queryString from "query-string"
import parse from "html-react-parser"
import {get} from "lodash"
import InputSerialCode from "./InputSerialCode"

type ReserveFormProps = {
  data: {
    poll_worker_path: string
    redirect_url: string
    form_url: string
    spot_token: string
    passcode: {
      required: boolean
      img_url: string
      messge: string
      placeholder: string
    }
    serialcodes: {
      required: boolean
      img_url: string
      title: string
      messge: string
      placeholder: string
    }
    precautions: {
      title: string
      list: Array<string>
    }
    onlineLive: {
      required: boolean
      title: string
      list: Array<string>
    }
    bookable: boolean
    submitText: string
    account_verification_mfa_path: string
  }
}

const ReserveForm = ({data}: ReserveFormProps) => {
  const {
    poll_worker_path,
    redirect_url,
    form_url,
    spot_token,
    passcode,
    serialcodes,
    precautions,
    onlineLive,
    bookable,
    submitText,
    account_verification_mfa_path,
  } = data
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const passcodeRef = useRef(null)

  return (
    <form
      className={className("AppForm", {
        "AppForm--overlay": isLoading,
      })}
      onSubmit={(e) => {
        e.preventDefault()

        setIsLoading(true)
        const passcodeVal = document.querySelector(
          "input[name='passcode']"
        )?.value
        axios
          .post(form_url, {
            spot_token: spot_token,
            passcode: passcodeVal,
          })
          .then((res) => {
            const wid = get(res, "data.data.worker_id")

            const poller = () => {
              axios
                .get(
                  `${poll_worker_path}/?spot_token=${spot_token}&worker_id=${wid}`
                )
                .then((res2) => {
                  const dataStatus = get(res2, "data.data.status")
                  if (dataStatus === "failed" || dataStatus === "interrupted") {
                    window.location.href = `${redirect_url}/?spot_token=${spot_token}&passcode=${passcodeVal}`
                  } else if (dataStatus != "complete") {
                    window.setTimeout(() => {
                      poller()
                    }, 5000)
                  } else {
                    window.location.href = `${redirect_url}/?spot_token=${get(
                      res2,
                      "data.data.spot_token"
                    )}&passcode=${passcodeVal}`
                  }
                })
                .catch((e) => {
                  window.location.reload()
                })
            }
            poller()
          })
          .catch((e) => {
            console.log(e.response)
            if (
              e.response?.data?.data?.error == "Exceptions::MfaUnauthorized"
            ) {
              window.location = account_verification_mfa_path
            } else {
              window.location.reload()
            }
          })
      }}
    >
      <input
        type="hidden"
        name="spot_token"
        id="spot_token"
        value={spot_token}
      />
      {passcode.required && (
        <div className="AppBookingsNew__password">
          <img
            className="AppBookingsNew__password__img"
            src={passcode.img_url}
          />
          <p className="AppBookingsNew__password__p">{passcode.messge}</p>
          <input
            type="text"
            id="passcode"
            name="passcode"
            className="AppBookingsNew__password__input"
            ref={passcodeRef}
            placeholder={passcode.placeholder}
          />
        </div>
      )}
      {serialcodes.required && (
        <React.Fragment>
          <div className="AppBookingsNew__serial">
            <img
              className="AppBookingsNew__serial-code__img"
              src={serialcodes.img_url}
            />
            <h3 className="AppBookingsNew__serial-code__h3">
              {serialcodes.title}
            </h3>
            <p className="AppBookingsNew__serial-code__p">
              {serialcodes.message}
            </p>
            <InputSerialCode
              name="passcode"
              maxLength="19"
              className="AppBookingsNew__serial-code__input"
              placeholder={serialcodes.placeholder}
            />
          </div>
        </React.Fragment>
      )}
      <div className="AppBookingsNew__precautions">
        <h3 className="AppBookingsNew__h3">{precautions.title}</h3>
        <ul className="AppBookingsNew__ul">
          {precautions.list.map((p, i) =>
            i === 1 ? (
              <li className="AppBookingsNew__ul__li" key={i}>
                <span className="AppBookingsNew__ul__li__span">{parse(p)}</span>
              </li>
            ) : (
              <li className="AppBookingsNew__ul__li" key={i}>
                {p}
              </li>
            )
          )}
        </ul>
        {onlineLive.required && (
          <React.Fragment>
            <h3 className="AppBookingsNew__h3">{onlineLive.title}</h3>
            <ul className="AppBookingsNew__ul">
              {onlineLive.list.map((p, i) => (
                <li className="AppBookingsNew__ul__li" key={i}>
                  {p}
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
      <button
        type="submit"
        className={
          bookable || isLoading
            ? "Button Button--large"
            : "Button Button--large Button--disabled"
        }
        disabled={!bookable || isLoading}
      >
        {submitText}
      </button>
    </form>
  )
}

ReserveForm.propTypes = {}

export default ReserveForm
