import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FeatureAnnouncementNotification from "@components/molecules/FeatureAnnouncementNotification"

type PropTypes = ComponentProps<typeof FeatureAnnouncementNotification>

export default {
  title: "Molecules/FeatureAnnouncementNotification",
  component: FeatureAnnouncementNotification,
} as Meta

const Template: Story<PropTypes> = () => <FeatureAnnouncementNotification />

export const Default = Template.bind({})
Default.args = {}
