import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios, tt} from "@lib/helpers"

const CopyFromExistingPackage = ({data}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const [__packages, __packagesSet] = React.useState({
    isLoading: true,
    data: [],
  })

  const loader = () => {
    axios
      .get(get(data, "past_index_packages_path"), {
        page: Number(get(__packages, "meta.page", 0)) + 1,
      })
      .then((res) => {
        __packagesSet({
          isLoading: false,
          data: [...get(__packages, "data"), ...get(res, "data.data")],
          meta: get(res, "data.meta"),
        })
      })
  }

  React.useEffect(() => {
    loader()
  }, [])

  if (get(__packages, "data.length", 0) == 0) {
    return null
  }

  const {t} = data

  return (
    <div className="CopyFromExistingPackage">
      <a
        className="Button Button--large Button--white-purple"
        onClick={() => {
          __isActiveSet(true)
        }}
      >
        {tt(t, "current.a")}
      </a>

      <div
        className={className("DraftPackage__modal", {
          "DraftPackage__modal--active": __isActive,
        })}
      >
        <a
          className="DraftPackage__modal__overlay"
          onClick={() => {
            __isActiveSet(false)
          }}
        />

        <div className="DraftPackage__modal__panel">
          <div className="DraftPackage__modal__panel__header">
            <h4 className="DraftPackage__modal__panel__header__h4">
              {tt(t, "current.h4")}
            </h4>

            <a
              className="DraftPackage__modal__panel__header__close"
              onClick={() => {
                __isActiveSet(false)
              }}
            />
          </div>

          <dl className="DraftPackage__modal__panel__dl">
            {(get(__packages, "data") || []).map((x, i) => (
              <div key={i} className="DraftPackage__modal__panel__dl__item">
                <a
                  className="DraftPackage__modal__panel__dl__item__dt"
                  href={get(x, "new_package_path")}
                >
                  {get(x, "record.title")}
                </a>

                <ul className="TalkCard__top__ul">
                  {(get(x, "labels") || []).map((y, j) => (
                    <li className="TalkCard__top__ul__li" key={j}>
                      {y}
                    </li>
                  ))}
                </ul>

                <dd className="DraftPackage__modal__panel__dl__item__dd">
                  {get(x, "start_at_and_end_at.date")}
                </dd>

                <time className="DraftPackage__modal__panel__dl__item__time">
                  {get(x, "start_at_and_end_at.time")}
                </time>
              </div>
            ))}
          </dl>

          {get(__packages, "data.length", 0) <
            get(__packages, "meta.count") && (
            <a
              className="DraftPackage__modal__panel__load-more"
              onClick={() => {
                loader()
              }}
            >
              {get(__packages, "isLoading")
                ? tt(t, "current.loading")
                : tt(t, "current.load_more")}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

CopyFromExistingPackage.propTypes = {}

export default CopyFromExistingPackage
