import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import isNil from "lodash/isNil"
import className from "classnames"
import {axios} from "@lib/helpers"

import logoTalkPort from "@images/common/services/talkport/logo.png"
import logoTalkPortWhite from "@images/common/services/talkport/logo_white.png"
import logoSay from "@images/common/services/say/icon.png"

type AppNavData = {
  service_information: Application.ServiceInformation
  is_publisher_page: boolean
  is_landing: boolean
  links: Application.Links
  user?: Application.User
  t: {
    version: string
    application: RailsI18n.T
    auth: RailsI18n.T
  }
  followings_users_path: string
  followers_users_path: string
  users_announcements_path: string
  identity_verification_mfa_path: string
  app_version: string
}

type PropTypes = {
  data: AppNavData
}

const AppNav: React.FC<PropTypes> = ({data}) => {
  const {
    service_information,
    is_publisher_page,
    is_landing,
    links,
    user,
    t,
    followings_users_path,
    followers_users_path,
    users_announcements_path,
    app_version,
  } = data

  const [__isActive, __isActiveSet] = React.useState(false)
  const [__isActivePublisherNav, __isActivePublisherNavSet] =
    React.useState(false)
  const $panel = React.useRef<HTMLDivElement>(null)
  const observer = React.useRef<IntersectionObserver>()

  React.useEffect(() => {
    observer.current = new IntersectionObserver(
      (elements) => {
        const $target = elements[0]

        __isActivePublisherNavSet(
          $target?.intersectionRatio == 0 &&
            $target?.boundingClientRect.top <= 0
        )
      },
      {
        rootMargin: "0px 0px",
      }
    )
  }, [])

  React.useEffect(() => {
    const $el = document.querySelector(".AppPublisher__handle")

    if (observer.current && $el) {
      observer.current.observe($el)
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  return (
    <div
      className={className(
        "AppNav",
        {
          "AppNav--landing": is_landing,
          "AppNav--publisher": is_publisher_page,
          "AppNav--publisher--active":
            is_publisher_page && __isActivePublisherNav,
        },
        `AppNav--${service_information.code}`
      )}
      data-version={app_version}
    >
      <div className="kontainer-app">
        <a className="AppNav__a" href={links.root_path}>
          <Logo
            service_information={service_information}
            is_publisher_page={is_publisher_page && !__isActivePublisherNav}
          />
        </a>

        <Handle
          __isActive={__isActive}
          __isActiveSet={__isActiveSet}
          is_publisher_page={is_publisher_page && !__isActivePublisherNav}
          $panel={$panel}
        />

        <div
          ref={$panel}
          className={className("AppNav__panel", {
            "AppNav__panel--active": __isActive,
          })}
        >
          <a
            className="AppNav__panel__overlay"
            onClick={() => {
              __isActiveSet(false)
            }}
          />

          <div className="kontainer-app">
            <div className="AppNav__panel__wrapper">
              <div className="AppNav__panel__header">
                <a className="AppNav__panel__header__a" href={links.root_path}>
                  <Logo service_information={service_information} />
                </a>

                <Handle
                  __isActive={__isActive}
                  __isActiveSet={__isActiveSet}
                  is_publisher_page={is_publisher_page}
                  $panel={$panel}
                />
              </div>

              <div className="AppNav__panel__gray">
                {links.sessions && (
                  <React.Fragment>
                    <a
                      className="AppNav__panel__gray__a"
                      href={links.sessions.login_path}
                      cypress-target="login"
                    >
                      {get(t, "auth.login")}
                    </a>

                    <a
                      className="AppNav__panel__gray__a AppNav__panel__gray__a--white"
                      href={links.sessions.new_user_path}
                    >
                      {get(t, "application.signup")}
                    </a>
                  </React.Fragment>
                )}

                {user?.data && (
                  <div className="AppNav__panel__gray__publisher">
                    <div className="AppNav__panel__gray__publisher__header">
                      <div
                        className="AppNav__panel__gray__publisher__header__img"
                        dangerouslySetInnerHTML={{
                          __html: user.icon,
                        }}
                      />

                      <div className="AppNav__panel__gray__publisher__header__right">
                        <p className="AppNav__panel__gray__publisher__p">
                          <span
                            className={className(
                              "AppNav__panel__gray__publisher__p__span",
                              {
                                "AppNav__panel__gray__publisher__p__span--verified":
                                  "is_buyer" in user &&
                                  user.is_buyer &&
                                  user.is_identity_verified,
                              }
                            )}
                          >
                            {user.data.name}
                          </span>
                        </p>

                        <div className="AppNav__panel__gray__publisher__details">
                          {"href" in user && user.href ? (
                            <div className="AppNav__panel__gray__publisher__details__wrapper">
                              <span className="AppNav__panel__gray__publisher__details__span">
                                {get(t, "application.AppNav.followers")}:
                              </span>

                              <a
                                className="AppNav__panel__gray__publisher__details__a"
                                href={followers_users_path}
                              >
                                <strong className="AppNav__panel__gray__publisher__details__strong">
                                  {user.followers_count?.toLocaleString()}
                                </strong>
                              </a>
                            </div>
                          ) : (
                            <div className="AppNav__panel__gray__publisher__details__wrapper">
                              <span className="AppNav__panel__gray__publisher__details__span">
                                {get(t, "application.AppNav.followings")}:
                              </span>

                              <a
                                className="AppNav__panel__gray__publisher__details__a"
                                href={followings_users_path}
                              >
                                <strong className="AppNav__panel__gray__publisher__details__strong">
                                  {user.followings_count?.toLocaleString()}
                                </strong>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>

                      {!("href" in user) && (
                        <a
                          className="AppNav__panel__gray__publisher__header__announcement"
                          href={users_announcements_path}
                        >
                          {user.unread_announcements > 0 && (
                            <span className="AppNav__panel__gray__publisher__header__announcement__span">
                              {user.unread_announcements > 99
                                ? "N"
                                : user.unread_announcements}
                            </span>
                          )}
                        </a>
                      )}
                    </div>

                    {"href" in user && user.href && (
                      <a
                        className="AppNav__panel__gray__publisher__a"
                        href={user.href}
                      >
                        {get(t, "application.publisher_page")}
                      </a>
                    )}
                  </div>
                )}
              </div>

              {/*
                // NOTE: if use false in this, ts can't correctly check object types, so it's comment out
                "is_buyer" in user &&
                user.is_buyer &&
                !user.is_identity_verified && (
                  <React.Fragment>
                    {(() => {
                      const _image_moderations_id_cards_last =
                        user.image_moderations_id_cards_last

                      const isVerificationPending = user.is_identity_verifying
                      const isVerificationFailed =
                        _image_moderations_id_cards_last.received_postback &&
                        _image_moderations_id_cards_last.declined

                      return (
                        <div className="AppNav__panel__verification">
                          <a
                            className={className(
                              "AppNav__panel__verification__a",
                              {
                                "AppNav__panel__verification__a--red": isVerificationFailed,
                              }
                            )}
                            href={data.identity_verification_mfa_path}
                          >
                            {isVerificationPending
                              ? get(t, "auth.verification.verification_pending")
                              : isVerificationFailed
                              ? get(t, "auth.verification.verification_failed")
                              : get(t, "auth.verification.verification_needed")}
                          </a>
                        </div>
                      )
                    })()}
                  </React.Fragment>
                )
              */}

              <div className="AppNav__panel__actions">
                {links.actions.map((link, i) => (
                  <a
                    key={i}
                    className={className(
                      "AppNav__panel__actions__a",
                      `AppNav__panel__actions__a--${link.klass}`
                    )}
                    href={link.href}
                    {...(link.external
                      ? {
                          target: "_blank",
                          rel: "nofollow noopener noreferrer",
                        }
                      : {})}
                    onClick={(e) => {
                      if (link.method) {
                        e.preventDefault()

                        axios[link.method](link.href).then((res: any) => {
                          window.location.href =
                            res?.data?.redirect_url || links.root_path
                        })
                      }
                    }}
                  >
                    <div className="AppNav__panel__actions__a__wrapper">
                      <span className="AppNav__panel__actions__a__span">
                        {link.label}
                      </span>

                      {!isNil(link.points) && (
                        <span className="AppNav__panel__actions__a__points">
                          {get(t, "application.your_points")}:{" "}
                          <span cypress-target="app-nav-current-point">
                            {link.points}
                          </span>
                        </span>
                      )}
                    </div>
                  </a>
                ))}
              </div>

              <div className="AppNav__panel__helps">
                {links.helps.map((link, i) => (
                  <a
                    key={i}
                    className="AppNav__panel__helps__a"
                    href={link.href}
                    {...(link.external
                      ? {
                          target: "_blank",
                          rel: "nofollow noopener noreferrer",
                        }
                      : {})}
                  >
                    {link.label}
                  </a>
                ))}
              </div>
              <div className="AppNav__panel__lang">
                <select
                  className="AppNav__panel__lang__select"
                  onChange={(e) => {
                    e.preventDefault()

                    if (e.target.value.length > 0) {
                      const query = window.location.search
                      const pathnames = window.location.pathname
                        .replace(/^\/(en|ja|th|ko|zh|tw)/, "")
                        .replace(/^\//, "")

                      window.location.href = `/${e.target.value}${
                        pathnames == "" ? "" : "/"
                      }${pathnames}${query}`
                    }
                  }}
                >
                  <option value="">Select language</option>

                  <option value="ja">Japanese</option>
                  <option value="en">English</option>
                  <option value="th">Thai</option>
                  <option value="ko">Korean</option>
                  <option value="zh">Simplified Chinese</option>
                  <option value="tw">Traditional Chinese</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppNav

type LogoPropTypes = {
  service_information: Application.ServiceInformation
  is_publisher_page?: boolean
}

const Logo: React.FC<LogoPropTypes> = ({
  service_information,
  is_publisher_page = false,
}) => {
  const isTalkPort = service_information.code == "talkport"
  const logo = isTalkPort
    ? is_publisher_page
      ? logoTalkPortWhite
      : logoTalkPort
    : logoSay

  return (
    <img
      className={className("AppNav__panel__header__a__img", {
        "AppNav__panel__header__a__img--talkport":
          isTalkPort && !is_publisher_page,
      })}
      src={logo}
      style={{
        width: `${isTalkPort ? (is_publisher_page ? 32 : 161) : 32}px`,
      }}
    />
  )
}

type HandlePropTypes = {
  __isActive: boolean
  __isActiveSet: (isActive: boolean) => void
  is_publisher_page: boolean
  $panel: React.RefObject<HTMLDivElement>
}

const Handle: React.FC<HandlePropTypes> = ({
  __isActive,
  __isActiveSet,
  is_publisher_page,
  $panel,
}) => {
  const onClickHandler = () => {
    if ($panel.current) {
      $panel.current.scroll({top: 0})
      __isActiveSet(!__isActive)
    }
  }
  return (
    <a
      className={className("hamburger", {
        "hamburger--elastic": true,
        "hamburger--white": is_publisher_page && !__isActive,
        "is-active": __isActive,
      })}
      onClick={onClickHandler}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </a>
  )
}
