import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"

import Alert from "@components/molecules/Alert"

type PropTypes = ComponentProps<typeof Alert>

export default {
  title: "Molecules/Alert",
  component: Alert,
} as Meta

const Template: Story<PropTypes> = (args) => <Alert {...args} />

export const Success = Template.bind({})
Success.args = {
  flash: [["success", "Card was brought."]],
}

export const Danger = Template.bind({})
Danger.args = {
  flash: [["danger", "Card was brought."]],
}
