import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputPassword from "@components/molecules/FormInputPassword"
import adminLocale from "@locales/views/admin/ja.yml"

type PropTypes = ComponentProps<typeof FormInputPassword>

export default {
  title: "Molecules/FormInputPassword",
  component: FormInputPassword,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputPassword {...args} />

export const Default = Template.bind({})
Default.args = {
  name: "user[password]",
  klass: "Input",
  placeholder: adminLocale.ja.admin.users.form.password,
}
