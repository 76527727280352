import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import SearchSuggestion from "@components/molecules/SearchSuggestion"

type PropTypes = ComponentProps<typeof SearchSuggestion>

export default {
  title: "Molecules/SearchSuggestion",
  component: SearchSuggestion,
} as Meta

const Template: Story<PropTypes> = (args) => <SearchSuggestion {...args} />

export const Default = Template.bind({})
Default.args = {
  query: "",
  query_key: "name",
  suggestion_url: "https://jsonplaceholder.typicode.com/users",
  label: "todo",
}
