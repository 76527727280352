import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import moment from "moment"
import YearMonthSelect from "@components/molecules/YearMonthSelect"

type PropTypes = ComponentProps<typeof YearMonthSelect>

export default {
  title: "Molecules/YearMonthSelect",
  component: YearMonthSelect,
} as Meta

const Template: Story<PropTypes> = (args) => <YearMonthSelect {...args} />

const mockDates = [...Array(10)].map((_, index) =>
  moment().subtract(-index, "month").format("YYYY-MM")
)

export const Default = Template.bind({})
Default.args = {
  data: {
    year_month: mockDates[0] || "",
    year_months: mockDates,
  },
  tab: 1,
}
