import * as React from "react"
import className from "classnames"

import {tt} from "@lib/helpers"

const AdminAgencyEditCoverColour = ({
  data,
}: {
  data: {
    name: string
    cover_color: string
    t: any
  }
}) => {
  const [__coverColour, __coverColourSet] = React.useState(data?.cover_color)
  const {t} = data

  return (
    <React.Fragment>
      <input
        type="hidden"
        value={__coverColour}
        onChange={() => {
          return null
        }}
        name={data.name}
      />

      <div className="AdminForm__value__cover-colours">
        <div className="AdminForm__value__cover-colours__choices">
          {[
            {
              key: "blue",
            },
            {
              key: "green",
            },
            {
              key: "yellow",
            },
            {
              key: "red",
            },
            {
              key: "pink",
            },
            {
              key: "black",
            },
          ].map((x, i) => (
            <a
              key={i}
              className={className(
                "AdminForm__value__cover-colours__choices__a",
                {
                  [`AdminForm__value__cover-colours__choices__a--${x.key}`]:
                    true,
                  "AdminForm__value__cover-colours__choices__a--active":
                    x.key == __coverColour,
                }
              )}
              onClick={() => {
                __coverColourSet(x.key)
              }}
            />
          ))}
        </div>

        <p
          className={className("AdminForm__value__cover-colours__example", {
            [`AdminForm__value__cover-colours__example--${__coverColour}`]:
              true,
          })}
        >
          {tt(t, "current.cover_color_example")}
        </p>
      </div>
    </React.Fragment>
  )
}

export default AdminAgencyEditCoverColour
