import React from "react"
import PropTypes from "prop-types"
import className from "classnames"
import {min, max} from "d3-array"
import {scaleTime, scaleLinear} from "d3-scale"
import {format} from "d3-format"
import moment from "moment-timezone"
import {debounce, isNil} from "lodash"

type PropTypes = {
  followClientHeight?: boolean
  data: {
    key: moment.MomentInput
    values: number[]
  }[]
  initialHeight: number
  isLoaded: boolean
}

const Graph: React.FC<PropTypes> = ({
  initialHeight,
  followClientHeight,
  data,
  isLoaded,
}) => {
  const $el = React.useRef<HTMLDivElement>(null)

  const [svgWidth, setSvgWidth] = React.useState(0)
  const [svgHeight, setSvgHeight] = React.useState(0)

  const setDimensions = React.useCallback(() => {
    const newWidth = $el.current?.clientWidth
    const newHeight = $el.current?.clientHeight || initialHeight
    if (newWidth) {
      setSvgWidth(newWidth)
    }
    setSvgHeight(
      followClientHeight && 0 < newHeight ? newHeight : initialHeight
    )
  }, [followClientHeight, initialHeight])

  React.useEffect(() => {
    setDimensions()
    const debounced = debounce(setDimensions, 500)
    window.addEventListener("resize", debounced)
    return () => window.removeEventListener("resize", debounced)
  }, [])

  const _margin = {
    top: 20,
    right: 20,
    bottom: 30,
    left: 70,
  }

  const width = svgWidth - _margin.left - _margin.right
  const height = svgHeight - _margin.top - _margin.bottom

  const actualMaxY = data.reduce((accm, x) => {
    const maxValue = max(x.values)
    return maxValue && accm < maxValue ? maxValue : accm
  }, 0)
  const actualMinY = data.reduce((accm, x, index) => {
    const minValue = min(x.values)
    if (index === 0) return minValue || accm
    return minValue && minValue < accm ? minValue : accm
  }, 0)
  const dataLength = data.length

  const xLinesCount = 5
  const lowerBound = actualMinY
  const upperBound =
    actualMaxY - lowerBound < xLinesCount
      ? lowerBound + xLinesCount - 1
      : actualMaxY

  const x = scaleTime()
    .domain([0, dataLength - 1])
    .rangeRound([0, width])

  const y = scaleLinear()
    .domain([lowerBound, upperBound])
    .rangeRound([height, 0])
    .clamp(true)

  return (
    <div className="Graph" ref={$el}>
      {dataLength > 1 ? (
        <svg width={svgWidth} height={svgHeight}>
          {svgWidth > 0 && (
            <g
              transform={`translate(${_margin.left}, ${_margin.top})`}
              className="Graph__graph-area"
            >
              {(() => {
                const offsetLeft = -25

                return (
                  <React.Fragment>
                    <g className="Graph__axis-xs">
                      {Array.from(Array(xLinesCount)).map((_, i) => {
                        const yBound = scaleLinear()
                          .domain([0, xLinesCount - 1])
                          .range([lowerBound, upperBound])

                        const yBounded = yBound(i)
                        const f = format(".4~s")

                        return (
                          <g key={i}>
                            <line
                              className={className("Graph__axis", {})}
                              x1={-10}
                              y1={yBounded ? y(yBounded) : undefined}
                              x2={width + 10}
                              y2={yBounded ? y(yBounded) : undefined}
                            />

                            <text
                              className="Graph__label"
                              x={offsetLeft}
                              y={!isNil(yBounded) ? y(yBounded) : undefined}
                            >
                              {!isNil(yBounded) ? f(yBounded) : ""}
                            </text>
                          </g>
                        )
                      })}
                    </g>
                  </React.Fragment>
                )
              })()}

              <g className="Graph__items">
                {[...Array(data[0]?.values.length || 0)].map((_, i) => {
                  const pathIndex = (data[0]?.values.length || 0) - 1 - i

                  return (
                    <g className="Graph__item" key={i}>
                      <path
                        className={className("Graph__path", {
                          [`Graph__path--${pathIndex}`]: true,
                        })}
                        d={data
                          .map((d, j) => {
                            const value = d.values[pathIndex] || 0
                            return `${j == 0 ? "M" : "L"}${x(j)},${y(value)}`
                          })
                          .join(" ")}
                      />

                      {data.map((d, j) => (
                        <circle
                          key={j}
                          cx={x(j)}
                          cy={y(d.values[pathIndex] || 0)}
                          r={4}
                          className={className("Graph__circle", {
                            [`Graph__circle--${pathIndex}`]: true,
                          })}
                        />
                      ))}
                    </g>
                  )
                })}
              </g>

              <g className="Graph__data">
                {data.map((d, i) => (
                  <g key={i} className="Graph__datum">
                    <text
                      data-i={i}
                      className={className("Graph__label-x", {
                        "Graph__label-x--active":
                          i == 0 ||
                          i == dataLength - 1 ||
                          dataLength <= 10 ||
                          i == Math.ceil(dataLength / 4) ||
                          i == Math.ceil(dataLength / 4) * 2 ||
                          i == Math.ceil(dataLength / 4) * 3,
                      })}
                      x={x(i)}
                      y={(y(0) || 0) + 20}
                    >
                      {moment(d.key).format("M/D")}
                    </text>
                  </g>
                ))}
              </g>
            </g>
          )}
        </svg>
      ) : (
        <div
          className="Graph__placeholder"
          style={{
            height: svgHeight,
          }}
        >
          {isLoaded ? "No data" : "Loading …"}
        </div>
      )}
    </div>
  )
}

Graph.propTypes = {}

export default Graph
