import {NoNameFunctionReturnVoid} from "types/common"
import {newCardStateType} from "types/creditCard"

const fetchCardToken = (
  shopid: string,
  cardState: newCardStateType,
  submitFormData: NoNameFunctionReturnVoid
) => {
  const __errorMessages = {
    0: "",
    100: "カード番号は必須です。",
    101: "正しいカード番号を入力してください。",
    102: "正しいカード番号を入力してください。",
    110: "有効期限は必須です。",
    111: "正しい有効期限を入力してください。",
    112: "正しい有効期限を入力してください。",
    113: "正しい有効期限を入力してください。",
    121: "正しいセキュリティーコードを入力してください。",
    122: "正しいセキュリティーコードを入力してください。",
    131: "正しいカード保有者氏名を入力してください。",
    132: "正しいカード保有者氏名を入力してください。",
    141: "正しいカード保有者氏名を入力してください。",
    142: "正しいカード保有者氏名を入力してください。",
    150: "正しいカード情報を入力してください",
    160: "ショップ ID 必須チェックエラー",
    161: "ショップ ID フォーマットエラー(14 桁以上)",
    162: "ショップ ID フォーマットエラー(半角英数字以外)",
    170: "公開鍵ハッシュ値必須チェックエラー",
    180: "ショップ ID または公開鍵ハッシュ値がマスターに存在しません",
    190: "カード情報(Encrypted)が復号できません",
    191: "カード情報(Encrypted)復号化後フォーマットエラー",
    200: "callback 必須チェックエラー",
    201: "Callback フォーマットエラー(半角英数字、アンダースコア、ピリオド以外を含む)",
    501: "トークン用パラメータ(id)が送信されていません",
    502: "トークン用パラメータ(id)がマスターに存在しません",
    511: "トークン用パラメータ(cardInfo)が送信されていません",
    512: "トークン用パラメータ(cardInfo)が復号できません",
    521: "トークン用パラメータ(key)が送信されていません",
    522: "トークン用パラメータ(key)が復号できません",
    531: "トークン用パラメータ(callBack)が送信されていません",
    541: "トークン用パラメータ(hash)が存在しません",
    551: "トークン用 apikey が存在しません ID",
    552: "トークン用 apikey が有効ではありません",
    701: "トークン用パラメータ(cardObject)が存在しません",
    901: "マルチペイメントシステムエラー",
    902: "処理が混み合っています",
  }
  const expire = cardState.cardExpiry
    .split(/(.{2})/)
    .filter((O) => O)
    .reverse()
    .join("")
  Multipayment.init(shopid)
  Multipayment.getToken(
    {
      cardno: cardState.cardNumber,
      expire: expire,
      securitycode: cardState.cardCvc,
      holdername: cardState.cardName,
      tokennumber: "1",
    },
    (response) => {
      if (response.resultCode != "000") {
        window.alert(
          `購入処理中にエラーが発生しました。 ${response.resultCode}: ${
            __errorMessages[Number(response.resultCode)]
          }`
        )
      } else {
        submitFormData(response.tokenObject.token[0])
      }
    }
  )
}

export default fetchCardToken
