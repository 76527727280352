import React from "react"
import get from "lodash/get"
import omit from "lodash/omit"
import * as queryString from "query-string"

import {axios} from "@lib/helpers"

let searchTO
export const useSearchSuggestion = ({
  query,
  query_key,
  suggestion_url,
  doNotSubmit,
}) => {
  const [__text, __textSet] = React.useState(query || "")
  const [__results, __resultsSet] = React.useState()
  const [__isSearching, __isSearchingSet] = React.useState(false)
  const [__isLoading, __isLoadingSet] = React.useState(false)

  // Always back to first page when search.
  const getQueryWithoutPage = () => {
    return omit(queryString.parse(window.location.search), "page")
  }

  React.useEffect(() => {
    if (__isSearching && get(__text, "length") > 0) {
      __resultsSet()
      __isLoadingSet(true)
      window.clearTimeout(searchTO)

      searchTO = window.setTimeout(() => {
        axios
          .get(suggestion_url, {
            [query_key]: __text,
          })
          .then((res) => {
            __resultsSet(get(res, "data"))
          })
          .finally(() => {
            __isLoadingSet(false)
          })
      }, 1000)
    }
  }, [__text, __isSearching])

  return {
    __text,
    __textSet,
    __results,
    __isSearching,
    __isSearchingSet,
    __isLoading,
    $results: (
      <React.Fragment>
        {__isSearching && get(__text, "length") > 0 && (
          <React.Fragment>
            <a
              className="SearchSuggestion__form__label__input__overlay"
              onClick={() => {
                __isSearchingSet(false)
              }}
            />

            <div className="SearchSuggestion__form__label__input__panel">
              {get(__results, "length") > 0 ? (
                <div className="SearchSuggestion__form__label__input__panel__results">
                  {(__results || []).map((x, i) => (
                    <a
                      key={i}
                      className="SearchSuggestion__form__label__input__panel__results__a"
                      href={`?${queryString.stringify({
                        ...getQueryWithoutPage(),
                        [query_key]: x,
                      })}`}
                      onClick={(e) => {
                        if (doNotSubmit) {
                          e.preventDefault()

                          __isSearchingSet(false)
                          __textSet(x)
                        }
                      }}
                    >
                      {x}
                    </a>
                  ))}
                </div>
              ) : (
                <p className="SearchSuggestion__form__label__input__panel__p">
                  {__isLoading ? "Loading …" : "Not found"}
                </p>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    ),
    getQueryWithoutPage,
  }
}
