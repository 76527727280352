import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"
import className from "classnames"
import includes from "lodash/includes"
import Cookies from "js-cookie"
import MobileDetect from "mobile-detect"

import {axios, tt, stripeStyle} from "@lib/helpers"

class AppBookingsReserve extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __isLoading: false,
    __cardElementErrors: null,

    __couponDiscount: null,
    __couponCode: "",
    __couponIsLoading: false,
    __couponErrors: null,

    __pointDiff:
      get(this.props, "data.user_has_points") -
      get(this.props, "data.required_point"),

    __supportBrowserPayment: false,
    __isMobileChrome: false,
  }

  mobileDetect = new MobileDetect(window.navigator.userAgent)

  ___stripe = Stripe(get(this.props, "data.stripe_public_key"), {
    locale: get(this.props, "data.locale"),
  })

  ___paymentRequest = null
  ___cardElement = null

  getCouponCodeAsAParam = () => {
    return omitBy(
      {
        coupon_code: get(this.state, "__couponDiscount")
          ? get(this.state, "__couponCode")
          : null,
      },
      (x) => !x
    )
  }

  book = () => {
    axios
      .post(get(this.props, "data.proceed_path"), this.getCouponCodeAsAParam())
      .then((res4) => {
        window.location.href = get(res4, "data.data.redirect_url")
      })
      .catch((e) => {
        window.location.reload()
      })
  }

  validate = ({callback}) => {
    this.setState(
      {
        __isLoading: true,
      },
      () => {
        axios
          .get(get(this.props, "data.validate_before_create_bookings_path"), {
            spot_token: get(this.props, "data.spot_token"),
            booking_token: get(this.props, "data.booking_token"),
          })
          .then(() => {
            callback()
          })
          .catch((e) => {
            window.location.reload()
          })
      }
    )
  }

  purchasePoint = ({stripeData} = {}) => {
    this.setState(
      {
        __isLoading: true,
      },
      () => {
        let formData = {
          point_purchase: {
            point_to_purchase: Math.abs(get(this.state, "__pointDiff")),
          },
        }

        if (stripeData) {
          formData = {
            ...formData,
            ...stripeData,
          }
        }

        axios
          .post(get(this.props, "data.point_purchases_path"), formData)
          .then((res2) => {
            const id = get(res2, "data.data.id")

            const poller = () => {
              axios.get(`/point_purchases/${id}`).then((res3) => {
                if (includes(["success"], get(res3, "data.data.status"))) {
                  this.book()
                } else if (includes(["error"], get(res3, "data.data.status"))) {
                  window.location.reload()
                } else {
                  window.setTimeout(() => {
                    poller()
                  }, 1000)
                }
              })
            }

            poller()
          })
      }
    )
  }

  componentDidMount = () => {
    const elements = this.___stripe.elements()

    if (get(this.state, "__pointDiff") < 0) {
      this.___paymentRequest = this.___stripe.paymentRequest({
        country: "JP",
        currency: "jpy",
        total: {
          label: get(this.props, "data.package_title"),
          amount: Math.abs(
            get(this.state, "__pointDiff") * get(this.props, "data.point_rate")
          ),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      this.___paymentRequest.canMakePayment().then((result) => {
        if (result) {
          const isMobileChrome =
            this.mobileDetect.userAgent() &&
            this.mobileDetect.userAgent().toLowerCase() == "chrome"

          const createBrowserPaymentButton = () => {
            if (isMobileChrome) {
              return
            }

            elements
              .create("paymentRequestButton", {
                paymentRequest: this.___paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: "55px",
                  },
                },
              })
              .mount("#paymentRequestElement")
          }

          this.setState(
            {
              __supportBrowserPayment: true,
              __isMobileChrome: isMobileChrome,
            },
            createBrowserPaymentButton
          )
        }
      })

      // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
      this.___paymentRequest.on("paymentmethod", (ev) => {
        this.validate({
          callback: () => {
            axios
              .post("/stripe_payment_intents", {
                point_to_purchase: Math.abs(get(this.state, "__pointDiff")),
              })
              .then((res) => {
                const clientSecret = get(
                  res,
                  "data.data.payment_intent.client_secret"
                )

                this.___stripe
                  .confirmCardPayment(
                    clientSecret,
                    {payment_method: ev.paymentMethod.id},
                    {handleActions: false}
                  )
                  .then((confirmResult) => {
                    const _purchasePoint = ({charge_id}) => {
                      ev.complete("success")

                      this.purchasePoint({
                        stripeData: {
                          stripe: {
                            charge_id,
                            request_method: ev.methodName,
                          },
                        },
                      })
                    }

                    if (confirmResult.error) {
                      ev.complete("fail")
                    } else if (
                      confirmResult.paymentIntent.status === "requires_action"
                    ) {
                      this.___stripe
                        .confirmCardPayment(clientSecret)
                        .then((result) => {
                          if (!result.error) {
                            _purchasePoint({
                              charge_id: confirmResult.paymentIntent.id,
                            })
                          } else {
                            ev.complete("fail")
                          }
                        })
                    } else {
                      _purchasePoint({
                        charge_id: confirmResult.paymentIntent.id,
                      })
                    }
                  })
              })
              .catch((e) => {
                ev.complete("fail")
              })
          },
        })
      })

      if (typeof paypal != "undefined" && paypal) {
        paypal
          .Buttons({
            style: {
              height: 55,
            },
            onClick: async (data, actions) => {
              // https://developer.paypal.com/docs/checkout/integration-features/validation/.
              // Cannot reuse `this.validate()` here. We need `actions.reject()`.
              return axios
                .get(
                  get(this.props, "data.validate_before_create_bookings_path"),
                  {
                    spot_token: get(this.props, "data.spot_token"),
                    booking_token: get(this.props, "data.booking_token"),
                  }
                )
                .catch((e) => {
                  window.setTimeout(() => {
                    window.location.reload()
                  }, 1000)

                  return actions.reject()
                })
            },
            createOrder: () => {
              return axios
                .post("/paypal_payments", {
                  point_to_purchase: Math.abs(get(this.state, "__pointDiff")),
                })
                .then((res) => {
                  return get(res, "data.data.order_id")
                })
                .catch((e) => {
                  window.location.reload()
                })
            },
            onApprove: (data) => {
              this.setState(
                {
                  __isLoading: true,
                },
                () => {
                  axios
                    .post("/paypal_payments/capture", {
                      order_id: get(data, "orderID"),
                    })
                    .then(() => {
                      this.book()
                    })
                    .catch(() => {
                      window.location.reload()
                    })
                }
              )
            },
          })
          .render("#paypalButtonElement")
      }

      if (!get(this.props, "data.default_payment_method_card")) {
        this.___cardElement = elements.create("card", {
          ...stripeStyle,
          hidePostalCode: true,
        })

        this.___cardElement.mount("#cardElement")

        this.___cardElement.on("change", (e) => {
          if (get(e, "error")) {
            this.setState({
              __cardElementErrors: get(e, "error.message"),
            })
          } else {
            this.setState({
              __cardElementErrors: null,
            })
          }
        })
      }
    }
  }

  handleClickBrowserPayment = (e) => {
    e.preventDefault()
    this.___paymentRequest.show()
  }

  render = () => {
    const {
      spot_token,
      purchasable,
      user_has_points,
      required_point,
      point_rate,
      t,
      btn_name,
      default_payment_method_card,
      payments_path,
      with_product,
      package_title,
      agency_setting,
      allow_coupon,
    } = get(this.props, "data")

    return (
      <React.Fragment>
        <div className="AppBookingsReserve__points-required">
          <div className="AppBookingsReserve__points-required__row">
            <p className="AppBookingsReserve__points-required__p">
              {get(t, "current.current_point")}
            </p>

            <strong className="AppBookingsReserve__points-required__strong">
              {user_has_points}pt
            </strong>
          </div>

          <div className="AppBookingsReserve__points-required__row">
            <p className="AppBookingsReserve__points-required__p">
              {get(t, "current.required_point")}
            </p>

            <strong className="AppBookingsReserve__points-required__strong">
              -{required_point}pt
            </strong>
          </div>

          {get(this.state, "__couponDiscount") && (
            <div className="AppBookingsReserve__points-required__row">
              <p className="AppBookingsReserve__points-required__p">
                {get(t, "current.coupon_discount")}
              </p>

              {(() => {
                const _point =
                  (get(this.state, "__couponDiscount") * required_point) / 100

                return (
                  <strong className="AppBookingsReserve__points-required__strong">
                    {~~_point}pt
                  </strong>
                )
              })()}
            </div>
          )}

          <div className="AppBookingsReserve__points-required__row AppBookingsReserve__points-required__row--total">
            <p className="AppBookingsReserve__points-required__p">
              {get(t, "current.after_purchasing_point")}
            </p>

            <strong className="AppBookingsReserve__points-required__strong">
              {get(this.state, "__pointDiff")}
              pt
            </strong>
          </div>
        </div>

        {with_product && (
          <React.Fragment>
            {[
              {
                h3: get(t, "shipping.sub_title"),
                text: get(with_product, "label.postal_code")
                  ? `${get(with_product, "label.postal_code")} ${get(
                      with_product,
                      "label.prefecture"
                    )} ${get(with_product, "label.city")} ${get(
                      with_product,
                      "label.address1"
                    )} ${get(with_product, "label.address2")}`
                  : get(t, "shipping.set_shipping_address"),
              },
              {
                h3: get(t, "current.shipping.name"),
                text: get(with_product, "label.last_name")
                  ? `${get(with_product, "label.last_name")} ${get(
                      with_product,
                      "label.first_name"
                    )}`
                  : get(t, "current.shipping.set_name"),
              },
              {
                h3: get(t, "current.shipping.tel"),
                text: `${
                  get(with_product, "label.phone_number") ||
                  get(t, "current.shipping.set_tel")
                }`,
              },
            ].map((x, i) => (
              <div className="AppBookingsReserve__box" key={i}>
                <h3 className="AppBookingsReserve__h3">{get(x, "h3")}</h3>

                <a
                  className="AppBookingsReserve__p AppBookingsReserve__p--caret"
                  href={get(with_product, "shipping_bookings_path")}
                >
                  <span className="AppBookingsReserve__p__span">
                    {get(x, "text")}
                  </span>
                </a>
              </div>
            ))}

            <div className="AppBookingsReserve__cautions">
              {(get(t, "current.shipping.cautions") || []).map((x, i) => (
                <p className="AppBookingsReserve__cautions__p" key={i}>
                  {x}
                </p>
              ))}
            </div>
          </React.Fragment>
        )}

        {agency_setting && agency_setting.enable_coupon && allow_coupon && (
          <div className="AppBookingsReserve__box">
            <h3 className="AppBookingsReserve__h3">
              {tt(t, "point_purchase_form.coupon_title")}
            </h3>

            <p className="AppBookingsReserve__p">
              {tt(t, "point_purchase_form.coupon_description")}
            </p>

            <form
              className="AppBookingsReserve__coupon"
              disabled={
                !isNil(get(this.state, "__couponDiscount")) ||
                get(this.state, "__couponIsLoading")
              }
              onSubmit={(e) => {
                e.preventDefault()

                this.setState(
                  {
                    __couponErrors: null,
                    __couponIsLoading: true,
                  },
                  () => {
                    axios
                      .get(`/coupons/${get(this.state, "__couponCode")}`, {
                        spot_token: get(this.props, "data.spot_token"),
                      })
                      .then((res) => {
                        const discount = get(res, "data.data.discount")
                        const required_point = get(
                          this.props,
                          "data.required_point"
                        )

                        const change = ~~((discount * required_point) / 100)

                        const updatedPointDiff =
                          get(this.state, "__pointDiff") + change

                        this.setState(
                          {
                            __couponDiscount: discount,
                            __pointDiff: updatedPointDiff,
                          },
                          () => {
                            if (this.___paymentRequest) {
                              this.___paymentRequest.update({
                                total: {
                                  label: get(this.props, "data.package_title"),
                                  amount: Math.abs(
                                    updatedPointDiff *
                                      get(this.props, "data.point_rate")
                                  ),
                                },
                              })
                            }
                          }
                        )
                      })
                      .catch((e) => {
                        this.setState({
                          __couponErrors: get(e, "response.data.result"),
                        })
                      })
                      .finally(() => {
                        this.setState({
                          __couponIsLoading: false,
                        })
                      })
                  }
                )
              }}
            >
              <div className="AppBookingsReserve__coupon__wrapper">
                <input
                  type="text"
                  className="AppBookingsReserve__coupon__input"
                  value={get(this.state, "__couponCode")}
                  onChange={(e) => {
                    this.setState({
                      __couponCode: get(e, "target.value"),
                    })
                  }}
                  required
                />

                <button
                  className="AppBookingsReserve__coupon__button"
                  disabled={get(this.state, "__couponCode.length") == 0}
                >
                  {tt(t, "point_purchase_form.coupon_button")}
                </button>
              </div>

              {get(this.state, "__couponErrors") && (
                <p className="AppBookingsReserve__coupon__errors">
                  {tt(
                    t,
                    `current.coupon_errors.${get(this.state, "__couponErrors")}`
                  )}
                </p>
              )}
            </form>
          </div>
        )}

        {get(this.state, "__pointDiff") >= 0 ? (
          <form
            className={className("AppForm", {
              "AppForm--overlay": get(this.state, "__isLoading"),
            })}
            onSubmit={(e) => {
              e.preventDefault()

              this.setState(
                {
                  __isLoading: true,
                },
                () => {
                  axios
                    .post(
                      get(purchasable, "bookings_path"),
                      this.getCouponCodeAsAParam()
                    )
                    .then((res) => {
                      window.location.href = get(res, "data.data.redirect_url")
                    })
                    .catch((e) => {
                      window.location.reload()
                    })
                }
              )
            }}
          >
            <button
              className="Button Button--large"
              disabled={get(this.state, "__isLoading")}
            >
              {get(this.state, "__isLoading")
                ? get(t, "dictionary.loading")
                : get(purchasable, "btn_name")}
            </button>
          </form>
        ) : (
          <React.Fragment>
            <div className="AppBookingsReserve__box">
              <h3 className="AppBookingsReserve__h3">
                {get(t, "point_purchase_form.purchase_point")}
              </h3>

              <p className="AppBookingsReserve__p">
                {tt(t, "point_purchase_form.explanation", {
                  user_has_points,
                })}
              </p>

              <div className="AppBookingsReserve__buttons">
                <p className="AppInput">
                  {Math.abs(get(this.state, "__pointDiff"))}pt:{" "}
                  {Math.abs(get(this.state, "__pointDiff") * point_rate)}
                  {get(t, "current.yen")}
                </p>

                <div className="AppBookingsReserve__buttons__row">
                  {this.state.__supportBrowserPayment ? (
                    this.state.__isMobileChrome ? (
                      <button
                        className="AppBookingsReserve__google-pay-button"
                        onClick={this.handleClickBrowserPayment}
                      >
                        <img
                          src={get(this.props, "data.imgs.google_pay")}
                          alt="Google Pay"
                        />{" "}
                        Pay
                      </button>
                    ) : (
                      <div
                        id="paymentRequestElement"
                        className="AppBookingsReserve__button"
                      ></div>
                    )
                  ) : null}

                  <div
                    id="paypalButtonElement"
                    className="AppBookingsReserve__button"
                  ></div>
                </div>
              </div>
            </div>

            <div className="AppForm__divider">
              <span className="AppForm__divider__span">
                {tt(t, "payment_form.or")}
              </span>
            </div>

            <div className="AppBookingsReserve__box">
              <h3 className="AppBookingsReserve__h3">
                {get(t, "point_purchase_form.payment_info")}
              </h3>

              {default_payment_method_card ? (
                <form
                  className={className("AppForm", {
                    "AppForm--overlay": get(this.state, "__isLoading"),
                  })}
                  onSubmit={(e) => {
                    e.preventDefault()

                    this.validate({
                      callback: this.purchasePoint,
                    })
                  }}
                >
                  <a
                    className="AppPayments__card AppPayments__card--single"
                    href={payments_path}
                  >
                    <img
                      className="AppPayments__card__img"
                      src={get(default_payment_method_card, "img")}
                    />

                    <div className="AppPayments__card__right">
                      <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
                        <div className="AppPayments__card__right__top__wrapper">
                          <div className="AppPayments__card__right__top__number">
                            **** **** ****{" "}
                            {get(default_payment_method_card, "last4")}
                          </div>

                          <div className="AppPayments__card__right__top__brand">
                            {get(default_payment_method_card, "brand")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>

                  <Buttons
                    t={t}
                    __isLoading={get(this.state, "__isLoading")}
                    btn_name={btn_name}
                  />
                </form>
              ) : (
                <form
                  className={className("AppForm", {
                    "AppForm--overlay": get(this.state, "__isLoading"),
                  })}
                  onSubmit={(e) => {
                    e.preventDefault()

                    this.validate({
                      callback: () => {
                        this.setState(
                          {
                            __isLoading: true,
                          },
                          () => {
                            this.___stripe
                              .createToken(this.___cardElement)
                              .then((res) => {
                                const card_token = get(res, "token.id")

                                if (card_token) {
                                  this.purchasePoint({
                                    stripeData: {
                                      stripe: {
                                        card_token,
                                      },
                                    },
                                  })
                                } else {
                                  this.setState({
                                    __isLoading: false,
                                  })
                                }
                              })
                          }
                        )
                      },
                    })
                  }}
                >
                  <div id="cardElement"></div>

                  {get(this.state, "__cardElementErrors") && (
                    <div className="attr_errors">
                      <div className="attr_errors__error">
                        {get(this.state, "__cardElementErrors")}
                      </div>
                    </div>
                  )}

                  <Buttons
                    t={t}
                    __isLoading={get(this.state, "__isLoading")}
                    btn_name={btn_name}
                  />
                </form>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default AppBookingsReserve

const Buttons = ({t, __isLoading, btn_name}) => {
  return (
    <button className="Button Button--large" disabled={__isLoading}>
      {__isLoading ? get(t, "dictionary.loading") : btn_name}
    </button>
  )
}
