import React from "react"
import get from "lodash/get"
import className from "classnames"

import placeholderCover from "@images/common/defaults/cover.png"
import placeholderIcon from "@images/common/defaults/icon.png"

type PropTypes = {
  image: string
  type: string
}

const FormInputImage: React.FC<PropTypes> = ({image, type}) => {
  const $el = React.useRef<HTMLDivElement>(null)
  const [__file, __fileSet] = React.useState()

  React.useEffect(() => {
    // Find its parent `.AdminForm__row`, then find a file input.
    const $input =
      $el?.current?.parentNode?.parentNode?.parentNode?.querySelector(
        'input[type="file"]'
      )

    if ($input) {
      $input.addEventListener("change", (e) => {
        __fileSet(get(e, "target.files.0"))
      })
    }
  }, [])

  const src = __file
    ? window.URL.createObjectURL(__file)
    : image
    ? image
    : type == "cover"
    ? placeholderCover
    : placeholderIcon

  return (
    <div className="AdminForm__value__image" ref={$el}>
      <img
        src={src}
        className={className("AdminForm__value__image__img", {
          "AdminForm__value__image__img--cover": type == "cover",
        })}
      />
    </div>
  )
}

FormInputImage.propTypes = {}

export default FormInputImage
