import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormErrorMessage from "@components/molecules/FormErrorMessage"

type PropTypes = ComponentProps<typeof FormErrorMessage>

export default {
  title: "Molecules/FormErrorMessage",
  component: FormErrorMessage,
} as Meta

const Template: Story<PropTypes> = (args) => <FormErrorMessage {...args} />

export const Default = Template.bind({})
Default.args = {
  message: "This is error message",
}
