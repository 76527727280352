import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import SearchableSelect from "@components/molecules/SearchableSelect"

type PropTypes = ComponentProps<typeof SearchableSelect>

export default {
  title: "Molecules/SearchableSelect",
  component: SearchableSelect,
} as Meta

const Template: Story<PropTypes> = (args) => <SearchableSelect {...args} />

export const Default = Template.bind({})
Default.args = {
  options: [...Array(10)].map((_, index) => {
    return {
      label: `label${index}`,
      value: `label${index}`,
    }
  }),
  placeholder: "placeholder",
  noOptionMessage: "no option",
}
