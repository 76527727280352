import * as React from "react"
import className from "classnames"

import {tt, axios} from "@lib/helpers"

import TableControls from "@components/molecules/TableControls"

import {ControlLink} from "types/common"

const IndexTableControls = ({
  data,
}: {
  data: {
    points_history_link: ControlLink
    edit_link: ControlLink
    login_link: ControlLink
    give_points_link: ControlLink
    label: string
    t: any
  }
}): JSX.Element => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const [__isLoading, __isLoadingSet] = React.useState(false)
  const {
    points_history_link,
    edit_link,
    login_link,
    give_points_link,
    label,
    t,
  } = data

  return (
    <React.Fragment>
      <TableControls
        control_links={[
          {
            href: points_history_link?.href,
            label: points_history_link?.label,
          },
          {
            href: edit_link?.href,
            label: edit_link?.label,
          },
          {
            href: login_link?.href,
            label: login_link?.label,
          },
          {
            href: give_points_link?.href,
            label: give_points_link?.label,
            className: "Table__td__button--purple",
            onClick: () => {
              __isActiveSet(true)
            },
          },
        ]}
        label={label}
      />

      <div
        className={className("ModalGivePointsToUser", {
          "ModalGivePointsToUser--active": __isActive,
        })}
      >
        <a
          className="ModalGivePointsToUser__overlay"
          onClick={() => {
            __isActiveSet(false)
          }}
        />

        <div className="ModalGivePointsToUser__panel">
          <div className="ModalGivePointsToUser__panel__header">
            <h4 className="ModalGivePointsToUser__panel__header__h4">
              {tt(t, "current.table.give_points_title", {name: label})}
            </h4>

            <a
              className="ModalGivePointsToUser__panel__header__close"
              onClick={() => {
                __isActiveSet(false)
              }}
            />
          </div>

          <form
            className="ModalGivePointsToUser__panel__form"
            onSubmit={(e) => {
              e.preventDefault()

              const point = (e?.target as HTMLFormElement)?.elements?.["point"]
                .value

              if (
                confirm(
                  tt(t, "current.table.give_points_notice", {
                    name: label,
                    point,
                  })
                )
              ) {
                __isLoadingSet(true)

                axios
                  .post(data?.give_points_link?.href, {
                    point,
                  })
                  .finally(() => {
                    window.location.reload()
                  })
              }
            }}
          >
            <div className="AdminForm__row AdminForm__row--no-bdb">
              <label className="AdminForm__label">
                <div className="AdminForm__label__span">{tt(t, "point")}</div>
                <div className="AdminForm__label__required">
                  {tt(t, "form.required")}
                </div>
              </label>

              <div className="AdminForm__value">
                <input
                  className="Input"
                  type="number"
                  name="point"
                  defaultValue={0}
                  min={0}
                  required
                />
              </div>
            </div>

            <div className="AdminForm__row AdminForm__row--no-bdb">
              <button
                className={className("Button Button--small", {
                  "Button--disabled": __isLoading,
                })}
              >
                {tt(t, __isLoading ? "form.loading" : "form.confirm")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndexTableControls
