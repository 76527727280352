import React from "react"
import className from "classnames"
import moment from "moment-timezone"
import {isEmpty} from "lodash"

import {tt} from "@lib/helpers"

const AdminSpotsIndexFilter = ({
  from_now,
  date,
  scope,
  support_required,
  supported,
  with_memo,
  with_monitor,
  publisher_name,
  t,
}: {
  from_now: string
  date: string
  scope: "" | "booked"
  support_required: string
  supported: string
  with_memo: string
  with_monitor: string
  publisher_name: string
  t: RailsI18n.T
}): JSX.Element => {
  const [__isCheckedFromNow, __isCheckedFromNowSet] = React.useState<boolean>(
    Boolean(from_now)
  )

  const [__isActive, __isActiveSet] = React.useState<boolean>(false)

  return (
    <div className="Filter">
      <div
        className={className("Filter__panel", {
          "Filter__panel--active": __isActive,
        })}
      >
        <div className="Filter__panel__status">
          <p className="Filter__form__label__span">{tt(t, "current.status")}</p>

          {!moment(date).isSame(moment(), "day") && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.date")}：<strong>{date}</strong>
            </p>
          )}

          {(scope ||
            from_now ||
            support_required ||
            supported ||
            with_memo) && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.status")}：
              <strong>
                {(() => {
                  const texts = [
                    {
                      text: tt(t, "current.filter.booked"),
                      isActive: scope == "booked",
                    },
                    {
                      text: tt(t, "current.filter.from_now"),
                      isActive: from_now,
                    },
                    {
                      text: tt(t, "current.filter.need_support"),
                      isActive: support_required,
                    },
                    {
                      text: tt(t, "current.filter.supported"),
                      isActive: supported,
                    },
                    {
                      text: tt(t, "current.filter.with_memo"),
                      isActive: with_memo,
                    },
                  ]

                  return texts
                    ?.filter((x) => x.isActive)
                    ?.map((x) => x.text)
                    ?.join(", ")
                })()}
              </strong>
            </p>
          )}

          {with_monitor && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.filter.monitoring")}：
              <strong>{tt(t, "current.filter.with_monitor")}</strong>
            </p>
          )}

          {publisher_name && (
            <p className="Filter__panel__status__p">
              配信者名：<strong>{publisher_name}</strong>
            </p>
          )}
        </div>

        <a
          className="Filter__panel__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        >
          <span
            className={className("Filter__panel__handle__span", {
              "Filter__panel__handle__span--active": __isActive,
            })}
          >
            {tt(t, "current.filter.handle")}
          </span>
        </a>

        <form
          className={className("Filter__form", {
            "Filter__form--active": __isActive,
          })}
        >
          {__isCheckedFromNow && (
            <input
              type="hidden"
              className="Input"
              name="timestamp"
              defaultValue={
                moment(date).startOf("day").unix() +
                moment().diff(moment().startOf("day"), "seconds")
              }
            />
          )}

          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.date")}
            </span>

            <div className="Filter__form__label__input">
              <input type="date" name="date" defaultValue={date} />
            </div>
          </label>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.status")}
            </span>

            <div className="Filter__form__label__input">
              <div className="Filter__form__label__input__checkboxes">
                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue=""
                    defaultChecked={isEmpty(scope)}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.all")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="radio"
                    name="scope"
                    defaultValue="booked"
                    defaultChecked={scope == "booked"}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.booked")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="checkbox"
                    name="from_now"
                    defaultValue="true"
                    checked={__isCheckedFromNow}
                    onChange={() => {
                      __isCheckedFromNowSet(!__isCheckedFromNow)
                    }}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.from_now")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="checkbox"
                    name="support_required"
                    defaultValue="true"
                    defaultChecked={Boolean(support_required)}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.need_support")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="checkbox"
                    name="supported"
                    defaultValue="true"
                    defaultChecked={Boolean(supported)}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.supported")}
                  </div>
                </label>

                <label className="CheckBox">
                  <input
                    type="checkbox"
                    name="with_memo"
                    defaultValue="true"
                    defaultChecked={Boolean(with_memo)}
                  />

                  <div className="CheckBox__span">
                    {tt(t, "current.filter.with_memo")}
                  </div>
                </label>
              </div>
            </div>
          </div>

          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.monitoring")}
            </span>

            <div className="Filter__form__label__input">
              <label className="CheckBox">
                <input
                  type="checkbox"
                  name="with_monitor"
                  defaultValue="true"
                  defaultChecked={Boolean(with_monitor)}
                />

                <div className="CheckBox__span">
                  {tt(t, "current.filter.with_monitor")}
                </div>
              </label>
            </div>
          </label>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span Filter__form__label__span--no-smo">
              &nbsp;
            </span>

            <div className="Filter__form__label__input">
              <button className="Button Button--small">
                {tt(t, "current.filter.submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminSpotsIndexFilter
