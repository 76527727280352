import * as React from "react"
import className from "classnames"
// import {useRouter} from 'next/router'
import {omit} from "lodash"
// import Link from 'next/link'
import * as queryString from "query-string"

import {tt} from "@lib/helpers"

const AdminShippingsIndexFilter = ({
  status,
  t,
}: {
  status: null | "to_be_shipped" | "shipped"
  t: RailsI18n.T
}) => {
  const [__status, __statusSet] = React.useState(status)
  const [__isActive, __isActiveSet] = React.useState<boolean>(false)

  return (
    <div className="Filter">
      <div
        className={className("Filter__panel", {
          "Filter__panel--active": __isActive,
        })}
      >
        <div className="Filter__panel__status">
          <p className="Filter__form__label__span">
            {tt(t, "current.filter.status.header")}
          </p>

          <p className="Filter__panel__status__p">
            {tt(t, "current.filter.status.header")}：
            <strong>{tt(t, `current.filter.status.${status ?? "all"}`)}</strong>
          </p>
        </div>

        <a
          className="Filter__panel__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        >
          <span
            className={className("Filter__panel__handle__span", {
              "Filter__panel__handle__span--active": __isActive,
            })}
          >
            {tt(t, "current.filter.handle")}
          </span>
        </a>

        <form
          className={className("Filter__form", {
            "Filter__form--active": __isActive,
          })}
          onSubmit={(e) => {
            e.preventDefault()

            window.location.href = `?${queryString.stringify({
              ...omit(queryString.parse(window.location.search), "status"),
              ...(__status
                ? {
                    status: __status,
                  }
                : {}),
            })}`
          }}
        >
          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.status.header")}
            </span>

            <div className="Filter__form__label__input">
              <div className="Filter__form__label__input__checkboxes">
                {[
                  {
                    status: null,
                    label: "all",
                  },
                  {
                    status: "to_be_shipped",
                  },
                  {
                    status: "shipped",
                  },
                ]?.map?.((x, i) => (
                  <label className="CheckBox" key={i}>
                    <input
                      type="checkbox"
                      checked={__status == x.status}
                      onChange={() => {
                        __statusSet(x.status)
                      }}
                    />

                    <div className="CheckBox__span">
                      {tt(t, `current.filter.status.${x.status ?? x.label}`)}
                    </div>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span Filter__form__label__span--no-smo">
              &nbsp;
            </span>

            <div className="Filter__form__label__input">
              <button className="Button Button--small">
                {tt(t, "current.filter.submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminShippingsIndexFilter
