import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const FormInputCheckBoxVideo = ({}) => {
  const $el = React.useRef()
  const $row = React.useRef()

  React.useEffect(() => {
    $row.current = $el.current.parentNode.parentNode

    const $checkBoxParent = $row.current.querySelector(
      "#package_disable_video_usable"
    )
    const $checkBoxChildPublisher = $row.current.querySelector(
      "#package_video_usable"
    )
    const $checkBoxChildUser = $row.current.querySelector(
      "#package_partner_video_usable"
    )

    $checkBoxParent.addEventListener("change", (e) => {
      $checkBoxChildUser.checked = get(e, "target.checked")
    })

    const onChange = (e) => {
      if (!$checkBoxChildPublisher.checked && !$checkBoxChildUser.checked) {
        $checkBoxParent.checked = false
      }
    }

    $checkBoxChildPublisher.addEventListener("change", onChange)
    $checkBoxChildUser.addEventListener("change", onChange)
  }, [])

  return <div className="AppPackagesBulkNew__react" ref={$el}></div>
}

FormInputCheckBoxVideo.propTypes = {}

export default FormInputCheckBoxVideo
