import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputNumber from "@components/molecules/FormInputNumber"

type PropTypes = ComponentProps<typeof FormInputNumber>

export default {
  title: "Molecules/FormInputNumber",
  component: FormInputNumber,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputNumber {...args} />

export const Default = Template.bind({})
Default.args = {
  value: 12345678901,
  name: "user[phone_number]",
  errors: [""],
  placeholder: "09012345678",
}
