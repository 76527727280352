import React from "react"
import ReactDOM from "react-dom"
import get from "lodash/get"
import entries from "lodash/entries"
import last from "lodash/last"
import isNil from "lodash/isNil"
import sortBy from "lodash/sortBy"
import startCase from "lodash/startCase"
import uniqBy from "lodash/uniqBy"
import className from "classnames"
import moment, {Moment} from "moment"
import momentTz from "moment-timezone"
import * as __axios from "axios"
import faker from "faker"
import queryString from "query-string"

import {axios, tt} from "@lib/helpers"

// @ts-ignore
import ModalSelectPublishers from "@components/molecules/ModalSelectPublishers"
import DateTimePicker from "@components/molecules/DateTimePicker"

type MasterProduct = {
  id: number
  name: string
}

type MasterProductSelected = {
  _id: number
  master_product_id: number
  amount: number
  product_id?: number
  is_existed?: boolean
  is_deleting?: boolean
}

type Spot = {
  _id: number
  $$spots_attributes_start_at: moment.Moment
  seconds: number
  interval: number
  point: number
}

type SpotExisting = {
  booking_path?: string
  bookings_reserving?: string
  _destroy?: 0 | 1
  id: number
  start_at: string
  start_at_and_end_at: {
    time: string
  }
  call_duration_humanize: string
  point: number
}

type NewAndEditPackageProps = {
  data: {
    url: string
    agency_setting: any
    default_minimum_fee: number
    point_package_select_options: any
    t: any
    online_live_archive_periods: any
    suggestion_url: string
    BEFORE_CALL_PREPARATION_TIME: number
    SIZE_ALLOWED: number[]
    point_to_price_rate: number
    master_products_path: string
    suggestion_master_products_path: string

    // Page-exclusive props.
    with_publisher_selection?: boolean
    publishers?: any
    MAXIMUM_SPOTS_ALLOWED?: number
    draft_url?: string
    draft_package_path?: string
    template_package_id?: any
    is_edit_package_page?: boolean
    package?: any
    package_product?: {
      description: string
      id: number
      memo: string
      name: string
      package_id: number
      shipping_at: string
      shipping_fee: number
      size: number
    }
    package_product_icon_image?: string
    package_product_master_products: MasterProduct[]
    package_product_master_products_selected: MasterProductSelected[]
    method?: string
    package_spots?: any
    package_thumbnail_image_url?: string
    package_path?: string
    package_sold?: boolean
  }
}

type NewAndEditPackageState = {
  __stepIndex: any
  __errors: any
  __errorsStep1: any
  __publisherIds: any
  __createByMode: any
  __packageTypeMode: any
  __point: any
  __bulkSeconds: any
  __bulkInterval: any
  __$$bulkStartAt: any
  __$$bulkEndAt: any
  __spots: Spot[]
  __spotsExisting: SpotExisting[]
  __disableVideo: any
  __disableVideoSelf: any
  __disableVideoPartner: any
  __isCheckedProduct: any
  __isCheckedHiddenInventory: boolean
  __isCheckedLottery: any
  __isCheckedLotteryPurchaseLimit: any
  __passcodeAndSerialsChoice: "" | "passcode" | "serials"
  __isActiveModal: boolean
  __masterProducts: {
    data: MasterProduct[]
    meta?: {
      page: number
      total: number
    }
  }
  __masterProductsSelected: MasterProductSelected[]
  __productShippingFee: number
  __file: any
  __fileProduct: any
  __isLoading: any
  __isDeleting: any
  __isDeletingSpots: boolean
  __saveAsDraft: any
  __previewFormData: null | {
    [key: string]: string
  }
}

class NewAndEditPackage extends React.Component<
  NewAndEditPackageProps,
  NewAndEditPackageState
> {
  static defaultProps = {}

  $formStep2 = React.createRef<HTMLFormElement>()
  $formPreview = React.createRef<HTMLFormElement>()

  state: NewAndEditPackageState = {
    __stepIndex: get(this.props, "data.is_edit_package_page") ? 1 : 0,
    __errors: null,
    __errorsStep1: null,
    __publisherIds: null,
    __createByMode:
      get(this.props, "data.draft_package_path") &&
      Number(get(this.props, "data.package.spots_count", 0)) == 0
        ? 1
        : 0,
    __packageTypeMode:
      get(this.props, "data.package.subtype") == "lane" ? 1 : 0,
    __point: get(this.props, "data.package")
      ? get(this.props, "data.package.bulk_points")
      : get(this.props, "data.agency_setting.enable_minimum_fee")
      ? get(this.props, "data.agency_setting.minimum_fee")
      : get(this.props, "data.default_minimum_fee"),
    __bulkSeconds: 0,
    __bulkInterval: 0,
    __$$bulkStartAt: null,
    __$$bulkEndAt: null,
    __spots: [],
    __spotsExisting: get(this.props, "data.package_spots") || [],
    __disableVideo: get(this.props, "data.package")
      ? !get(this.props, "data.package.video_usable") ||
        !get(this.props, "data.package.partner_video_usable")
      : false,
    __disableVideoSelf: get(this.props, "data.package")
      ? !get(this.props, "data.package.video_usable")
      : false,
    __disableVideoPartner: get(this.props, "data.package")
      ? !get(this.props, "data.package.partner_video_usable")
      : false,
    __isCheckedProduct: isNil(get(this.props, "data.package_product"))
      ? false
      : true,
    __isCheckedHiddenInventory:
      get(this.props, "data.package.subtype") == "hidden_spot",
    __isCheckedLottery: get(this.props, "data.package.subtype") == "lottery",
    __isCheckedLotteryPurchaseLimit: get(
      this.props,
      "data.package.lottery_purchase_limit"
    )
      ? true
      : false,
    __passcodeAndSerialsChoice:
      get(this.props, "data.package.passcode.length", 0) > 0
        ? "passcode"
        : get(this.props, "data.package.subtype") == "serial_codes"
        ? "serials"
        : "",
    __isActiveModal: false,
    __masterProducts: {
      data: this.props.data.package_product_master_products || [],
    },
    __masterProductsSelected:
      this.props.data.package_product_master_products_selected || [],
    __productShippingFee: this.props.data.package_product?.shipping_fee || 0,
    __file: null,
    __fileProduct: null,
    __isLoading: false,
    __isDeleting: false,
    __isDeletingSpots: false,
    __saveAsDraft: false,
    __previewFormData: null,
  }

  componentDidUpdate = (_: any, prevState: NewAndEditPackageState) => {
    if (get(prevState, "__stepIndex") != get(this.state, "__stepIndex")) {
      window.scroll({top: 0})

      this.setState({
        __errors: null,
      })
    }

    // An error should be cleared with a spot is added/removed.
    // Because the spot errors are based on index.
    if (get(prevState, "__spots.length") != get(this.state, "__spots.length")) {
      this.setState({
        __errors: null,
      })
    }
  }

  getMinimumFee = () => {
    return get(this.props, "data.agency_setting.enable_minimum_fee")
      ? get(this.props, "data.agency_setting.minimum_fee")
      : get(this.props, "data.default_minimum_fee")
  }

  isNotEnoughSpotOrExistingSpot = () => {
    const _spotsLength = get(this.props, "data.is_edit_package_page")
      ? this.state.__spotsExisting?.filter((x) => x?._destroy != 1)?.length || 0
      : get(this.state, "__spots.length", 0)

    return _spotsLength <= (this.state.__isCheckedHiddenInventory ? 3 : 1)
  }

  checkDisableVideo = () => {
    if (
      !get(this.state, "__disableVideoSelf") &&
      !get(this.state, "__disableVideoPartner")
    ) {
      this.setState({
        __disableVideo: false,
      })
    }
  }

  formatDuration = (x: any) => {
    const {t} = get(this.props, "data")

    return x < 60 || x == 90
      ? `${x}${get(t, "packages_bulk_new.duration.second")}`
      : `${x / 60}${get(t, "packages_bulk_new.duration.minute")}`
  }

  deleteSpot = ({
    spotId,
    immediatelyShowPopup,
  }: {
    spotId: any
    immediatelyShowPopup?: any
  }) => {
    this.setState((prevState: NewAndEditPackageState) => ({
      __isDeletingSpots: immediatelyShowPopup,
      __spotsExisting: (prevState?.__spotsExisting).map((y: any) => {
        if (spotId == get(y, "id")) {
          return {
            ...y,
            _destroy: immediatelyShowPopup ? 1 : y?._destroy ? 0 : 1,
          }
        }

        return y
      }),
    }))
  }

  onSubmitStep1 = (e: any) => {
    e.preventDefault()

    const data = get(this.props, "data")
    const {t} = data
    const els = get(e, "target.elements")

    this.setState(
      {
        __errorsStep1: null,
        __isLoading: true,
      },
      () => {
        // A small delay to let React re-render.
        window.setTimeout(() => {
          const _publisher_ids = get(els, "publisher_ids.value")

          let _errorsStep1 = {}

          const $$start_date = moment(get(els, "start_date.value"))
          const $$end_date = moment(get(els, "end_date.value"))
          const $number_of_spots = get(els, "number_of_spots")

          if (
            get(data, "with_publisher_selection") &&
            get(_publisher_ids, "length", 0) == 0
          ) {
            _errorsStep1 = {
              ..._errorsStep1,
              publisher_ids: get(t, "packages_form.publisher_ids"),
            }
          }

          if (
            (get(this.state, "__createByMode") == 1 ||
              get(this.state, "__packageTypeMode") == 1) &&
            $$start_date.isSameOrAfter($$end_date)
          ) {
            _errorsStep1 = {
              ..._errorsStep1,
              end_date: get(t, "packages_form.end_time"),
            }
          }

          if ($$start_date.isSameOrBefore(moment())) {
            _errorsStep1 = {
              ..._errorsStep1,
              start_date: tt(t, "packages_form.start_time"),
            }
          }

          if (
            $number_of_spots &&
            (get($number_of_spots, "value").match(/[^\d]/g) ||
              Number(get($number_of_spots, "value")) <= 0 ||
              Number(get($number_of_spots, "value")) >
                this.props.data.MAXIMUM_SPOTS_ALLOWED)
          ) {
            _errorsStep1 = {
              ..._errorsStep1,
              number_of_spots: tt(t, "packages_form.number_of_spots"),
            }
          }

          if (this.state.__point < this.getMinimumFee()) {
            _errorsStep1 = {
              ..._errorsStep1,
              point: "error", // There is already a built-in error message.
            }
          }

          const setStateCallback = () => {
            this.setState({
              __isLoading: false,
            })
          }

          if (get(Object.keys(_errorsStep1), "length") != 0) {
            this.setState(
              {
                __errorsStep1: _errorsStep1,
              },
              setStateCallback
            )
          } else {
            if (get(this.state, "__packageTypeMode") == 0) {
              let _spots: Spot[] = []

              const secondsAndIntervals =
                Number(get(els, "seconds.value")) +
                Number(get(els, "interval.value"))

              if (get(this.state, "__createByMode") == 0) {
                _spots = Array.from(
                  Array(Number(get($number_of_spots, "value")))
                ).map((_, i) => ({
                  _id: +new Date() + i,
                  $$spots_attributes_start_at: moment($$start_date).add(
                    i * secondsAndIntervals,
                    "seconds"
                  ),
                  seconds: Number(get(els, "seconds.value")),
                  interval: Number(get(els, "interval.value")),
                  point: Number(get(this.state, "__point")),
                }))
              } else {
                let $$spot_start_at = moment($$start_date)
                let $$spot_end_at = moment($$spot_start_at).add(
                  secondsAndIntervals,
                  "seconds"
                )

                do {
                  _spots = [
                    ..._spots,
                    {
                      _id: +new Date() + Number($$spot_start_at),
                      $$spots_attributes_start_at: $$spot_start_at,
                      seconds: Number(get(els, "seconds.value")),
                      interval: Number(get(els, "interval.value")),
                      point: Number(get(this.state, "__point")),
                    },
                  ]

                  $$spot_start_at = moment($$spot_start_at).add(
                    secondsAndIntervals,
                    "seconds"
                  )

                  $$spot_end_at = moment($$spot_end_at).add(
                    secondsAndIntervals,
                    "seconds"
                  )
                } while ($$spot_end_at.isSameOrBefore($$end_date))
              }

              this.setState(
                {
                  __publisherIds: _publisher_ids,
                  __spots: _spots,
                  __stepIndex: 1,
                  __bulkSeconds: Number(get(els, "seconds.value")),
                  __$$bulkStartAt: $$start_date,
                  __$$bulkEndAt: get(els, "end_date.value") ? $$end_date : null,
                  __bulkInterval: Number(get(els, "interval.value")),
                },
                setStateCallback
              )
            } else {
              this.setState(
                {
                  __publisherIds: _publisher_ids,
                  __stepIndex: 1,
                  __bulkSeconds: Number(get(els, "seconds.value")),
                  __$$bulkStartAt: $$start_date,
                  __$$bulkEndAt: $$end_date,
                },
                setStateCallback
              )
            }
          }
        }, 10)
      }
    )
  }

  generateFormData = ({els}: {els: any}) => {
    const data = get(this.props, "data")
    const _formData = new FormData()

    _formData.append(
      "authenticity_token",
      document
        ?.querySelector('meta[name="csrf-token"]')
        ?.getAttribute("content") || ""
    )

    let packageType = "normal_package"

    if (get(this.state, "__saveAsDraft")) {
      packageType = "draft_package"
    }

    if (get(this.state, "__publisherIds")) {
      _formData.append(
        `${packageType}[publisher_ids]`,
        get(this.state, "__publisherIds") || ""
      )
    }

    _formData.append(`${packageType}[title]`, get(els, "title.value"))

    _formData.append(
      `${packageType}[bookable_start_at]`,
      get(els, "enable_bookable_start_at.checked")
        ? get(els, "bookable_start_at.value")
        : ""
    )

    _formData.append(
      `${packageType}[bookable_end_at]`,
      get(els, "enable_bookable_end_at.checked")
        ? get(els, "bookable_end_at.value")
        : ""
    )

    _formData.append(
      `${packageType}[release_at]`,
      get(els, "enable_release_at.checked") ? get(els, "release_at.value") : ""
    )

    _formData.append(
      `${packageType}[allow_chip]`,
      get(data, "agency_setting.enable_chip")
        ? get(els, "allow_chip.checked")
        : false
    )

    _formData.append(
      `${packageType}[allow_monitor_video_record]`,
      get(data, "agency_setting.enable_store_live_video")
        ? get(els, "allow_monitor_video_record.checked")
        : false
    )

    if (get(data, "agency_setting.enable_screenshot_restrictions")) {
      _formData.append(
        `${packageType}[screenshot_restrictions]`,
        get(data, "agency_setting.enable_screenshot_restrictions")
          ? get(els, "screenshot_restrictions.checked")
          : false
      )
    }

    if (!get(this.props, "data.is_edit_package_page")) {
      _formData.append(
        `${packageType}[bulk_seconds]`,
        get(this.state, "__bulkSeconds").toString()
      )

      _formData.append(
        `${packageType}[bulk_interval]`,
        get(this.state, "__bulkInterval").toString()
      )

      _formData.append(
        `${packageType}[bulk_points]`,
        get(this.state, "__point")
      )
    }

    if (get(this.state, "__packageTypeMode") == 0) {
      _formData.append(
        `${packageType}[allow_multiple_purchases]`,
        get(els, "allow_multiple_purchases.checked") ? "0" : "1"
      )
      _formData.append(
        `${packageType}[purchase_limit]`,
        get(els, "purchase_limit.value")
      )

      if (get(data, "agency_setting.enable_passcode")) {
        _formData.append(
          `${packageType}[passcode]`,
          get(els, "passcode_and_serials.checked") &&
            get(this.state, "__passcodeAndSerialsChoice") == "passcode"
            ? get(els, "passcode.value")
            : ""
        )
      }

      _formData.append(
        `${packageType}[video_usable]`,
        get(this.state, "__disableVideoSelf") ? "0" : "1"
      )
      _formData.append(
        `${packageType}[partner_video_usable]`,
        get(this.state, "__disableVideoPartner") ? "0" : "1"
      )

      if (
        get(data, "agency_setting.enable_hidden_inventory") &&
        !get(this.props, "data.is_edit_package_page") &&
        this.state.__isCheckedHiddenInventory
      ) {
        _formData.append(`${packageType}[subtype]`, "hidden_spot")

        _formData.append(
          `${packageType}[first_spot_fee_id]`,
          get(els, "enable_first_spot_fee.checked")
            ? get(els, "first_spot_fee_id.value")
            : ""
        )

        _formData.append(
          `${packageType}[last_spot_fee_id]`,
          get(els, "enable_last_spot_fee.checked")
            ? get(els, "last_spot_fee_id.value")
            : ""
        )
      }

      if (get(data, "agency_setting.enable_recording")) {
        _formData.append(
          `${packageType}[allow_sell_video_record]`,
          get(els, "allow_sell_video_record.checked")
        )
        _formData.append(
          `${packageType}[recording_fee_id]`,
          get(els, "allow_sell_video_record.checked")
            ? get(els, "recording_fee_id.value")
            : null
        )
      }

      if (
        get(data, "agency_setting.enable_goods") &&
        get(this.state, "__isCheckedProduct")
      ) {
        this.state.__masterProductsSelected?.forEach((x) => {
          const _id = x._id

          if (x.is_existed) {
            _formData.append(
              `${packageType}[product_attributes][product_items_attributes][${_id}][id]`,
              x._id?.toString?.()
            )

            _formData.append(
              `${packageType}[product_attributes][product_items_attributes][${_id}][product_id]`,
              x.product_id?.toString?.()
            )
          }

          if (x.is_deleting) {
            _formData.append(
              `${packageType}[product_attributes][product_items_attributes][${_id}][_destroy]`,
              "1"
            )
          }

          _formData.append(
            `${packageType}[product_attributes][product_items_attributes][${_id}][master_product_id]`,
            x.master_product_id?.toString?.()
          )

          _formData.append(
            `${packageType}[product_attributes][product_items_attributes][${_id}][amount]`,
            x.amount?.toString?.()
          )
        })

        if (this.props.data?.package_product) {
          _formData.append(
            `${packageType}[product_attributes][id]`,
            this.props.data?.package_product?.id?.toString?.()
          )
        }

        _formData.append(
          `${packageType}[product_attributes][name]`,
          els["product.name"]?.value
        )

        _formData.append(
          `${packageType}[product_attributes][description]`,
          els["product.description"]?.value
        )

        if (this.state.__fileProduct) {
          _formData.append(
            `${packageType}[product_attributes][icon_image]`,
            this.state.__fileProduct || ""
          )
        }

        _formData.append(
          `${packageType}[product_attributes][shipping_fee]`,
          this.state.__productShippingFee.toString?.()
        )

        _formData.append(
          `${packageType}[product_attributes][size]`,
          els["product.size"]?.value
        )

        _formData.append(
          `${packageType}[product_attributes][shipping_at]`,
          els["product.shipping_at"]?.value
        )

        _formData.append(
          `${packageType}[product_attributes][memo]`,
          els["product.memo"]?.value
        )
      }

      if (get(data, "agency_setting.enable_online_live")) {
        _formData.append(
          `${packageType}[online_live_url]`,
          get(els, "enable_online_live_url.checked")
            ? get(els, "online_live_url.value")
            : ""
        )
        _formData.append(
          `${packageType}[online_live_archive_period]`,
          get(els, "online_live_archive_period.value")
        )
        _formData.append(
          `${packageType}[online_live_start_at]`,
          get(els, "online_live_start_at.value")
        )
        _formData.append(
          `${packageType}[online_live_title]`,
          get(els, "online_live_title.value")
        )
        _formData.append(
          `${packageType}[online_live_description]`,
          get(els, "online_live_description.value")
        )

        if (
          get(els, "enable_online_live_url.checked") &&
          get(this.state, "__file")
        ) {
          _formData.append(
            `${packageType}[thumbnail_image]`,
            get(this.state, "__file") || ""
          )
        }
      }

      if (get(data, "agency_setting.enable_identity_verification")) {
        _formData.append(
          `${packageType}[allow_identity_verification]`,
          get(data, "agency_setting.enable_identity_verification")
            ? get(els, "allow_identity_verification.checked")
            : false
        )
      }

      if (
        get(data, "agency_setting.enable_lottery") &&
        !get(this.props, "data.is_edit_package_page") &&
        get(this.state, "__isCheckedLottery")
      ) {
        _formData.append(`${packageType}[subtype]`, "lottery")

        if (get(this.state, "__isCheckedLotteryPurchaseLimit")) {
          _formData.append(
            `${packageType}[lottery_purchase_limit]`,
            get(els, "lottery_purchase_limit.value")
          )
        }
      }

      if (get(data, "agency_setting.enable_seconds")) {
        _formData.append(`${packageType}[allow_seconds]`, "1")
      }

      if (!get(this.state, "__saveAsDraft")) {
        get(this.state, "__spots").forEach((x: any) => {
          const _id = get(x, "_id")

          _formData.append(
            `${packageType}[spots_attributes][${_id}][start_at]`,
            (get(x, "$$spots_attributes_start_at") as Moment).format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          )

          _formData.append(
            `${packageType}[spots_attributes][${_id}][seconds]`,
            get(x, "seconds")
          )

          _formData.append(
            `${packageType}[spots_attributes][${_id}][point]`,
            get(x, "point")
          )
        })
      }

      const mergedAndSortedSpots = sortBy(
        [...get(this.state, "__spots"), ...this.state.__spotsExisting].map(
          (x) => {
            return {
              ...x,
              _$$spots_attributes_start_at: moment(
                get(x, "$$spots_attributes_start_at")
                  ? get(x, "$$spots_attributes_start_at")
                  : get(x, "start_at")
              ),
            }
          }
        ),
        (x) => get(x, "_$$spots_attributes_start_at")
      )

      _formData.append(
        `${packageType}[bulk_start_at]`,
        get(mergedAndSortedSpots, "0._$$spots_attributes_start_at")
      )

      const _lastSpot = last(mergedAndSortedSpots)

      _formData.append(
        `${packageType}[bulk_end_at]`,
        moment(get(_lastSpot, "_$$spots_attributes_start_at"))
          .add(get(_lastSpot, "seconds"), "seconds")
          .toString()
      )

      if (get(this.state, "__saveAsDraft")) {
        if (!get(this.state, "__$$bulkEndAt")) {
          _formData.append(
            `${packageType}[spots_count]`,
            get(this.state, "__spots.length", 0)
          )
        }
      }

      if (
        get(data, "agency_setting.enable_serial_code") &&
        !get(this.props, "data.is_edit_package_page") &&
        get(this.state, "__passcodeAndSerialsChoice") == "serials"
      ) {
        _formData.append(`${packageType}[subtype]`, "serial_codes")
      }

      if (get(data, "agency_setting.enable_private_selling")) {
        _formData.append(
          `${packageType}[allow_private_selling]`,
          get(data, "agency_setting.enable_private_selling")
            ? get(els, "allow_private_selling.checked")
            : false
        )
      }
    } else {
      _formData.append(`${packageType}[subtype]`, "lane")

      _formData.append(
        `${packageType}[bulk_start_at]`,
        (get(this.state, "__$$bulkStartAt") || "").toString()
      )

      _formData.append(
        `${packageType}[bulk_end_at]`,
        (get(this.state, "__$$bulkEndAt") || "").toString()
      )
    }

    return _formData
  }

  onSubmitStep2 = ({els}: {els: any}) => {
    const data = get(this.props, "data")

    this.setState(
      {
        __errors: null,
        __isLoading: true,
      },
      () => {
        // A small delay to let React re-render.
        window.setTimeout(() => {
          const _formData = this.generateFormData({els})

          // @ts-ignore
          __axios[get(data, "method", "post")](
            get(this.state, "__saveAsDraft")
              ? get(data, "draft_url")
              : get(data, "url"),
            _formData
          )
            .then(async (res: any) => {
              if (get(this.props, "data.draft_package_path")) {
                await axios.delete(get(this.props, "data.draft_package_path"))
              }

              window.location.href = get(res, "data.redirect_url")
            })
            .catch((e: any) => {
              this.setState({
                __isLoading: false,
                __errors: get(e, "response.data.errors"),
              })
            })
        }, 10)
      }
    )
  }

  render = () => {
    const data = get(this.props, "data")
    const {t} = data

    // @ts-ignore
    const gon = window.gon

    let availableDuration = [
      60 * 1,
      60 * 2,
      60 * 3,
      60 * 4,
      60 * 5,
      60 * 6,
      60 * 7,
      60 * 8,
      60 * 9,
      60 * 10,
      60 * 11,
      60 * 12,
      60 * 13,
      60 * 14,
      60 * 15,
      60 * 20,
      60 * 30,
      60 * 45,
      60 * 60,
    ]

    if (get(data, "agency_setting.enable_seconds")) {
      availableDuration = sortBy(
        [15, 30, 45, 90, ...availableDuration],
        (x) => x
      )
    }

    return (
      <React.Fragment>
        {!get(this.props, "data.is_edit_package_page") && (
          <div className="AppStepsNav">
            {Array.from(Array(2)).map((_, i) => (
              <span
                key={i}
                className={className("AppStepsNav__span", {
                  "AppStepsNav__span--active":
                    i == get(this.state, "__stepIndex"),
                })}
              >
                {i + 1}
              </span>
            ))}
          </div>
        )}

        <div
          className="AppPackagesBulkNew"
          data-step-index={get(this.state, "__stepIndex")}
        >
          <h1 className="App__h1">
            {get(this.props, "data.is_edit_package_page")
              ? get(t, "packages_edit.title")
              : get(t, "packages_bulk_new.title")}
          </h1>

          <form
            className={className("AppForm", {
              "AppForm--hidden": get(this.state, "__stepIndex") != 0,
              "AppForm--overlay": get(this.state, "__isLoading"),
            })}
            onSubmit={this.onSubmitStep1}
          >
            {get(data, "with_publisher_selection") && (
              <ModalSelectPublishers
                data={{
                  name: "publisher_ids",
                  // selected_publisher_ids: [1], // For debugging
                  suggestion_url: get(data, "suggestion_url"),
                  query_field: "publisher_name",
                  publishers: get(data, "publishers"),
                  t: {
                    bulk_new: get(t, "packages_bulk_new"),
                    select_user: get(t, "select_user"),
                  },
                }}
              />
            )}

            <Error
              eKey="publisher_ids"
              __errors={get(this.state, "__errorsStep1")}
            />

            {get(this.props, "data.agency_setting.enable_lane") && (
              <div className="AppForm__row">
                <Label t={t} tKey="packages_bulk_new.package_type.label" />

                <div className="AppForm__radios">
                  {[
                    {
                      key: "normal",
                      packageTypeMode: 0,
                    },
                    {
                      key: "lane",
                      packageTypeMode: 1,
                    },
                  ].map((x, i) => (
                    <label className="AppForm__radios__radio" key={i}>
                      <input
                        type="radio"
                        checked={
                          get(x, "packageTypeMode") ==
                          get(this.state, "__packageTypeMode")
                        }
                        onChange={() => {
                          this.setState({
                            __packageTypeMode: get(x, "packageTypeMode"),
                          })
                        }}
                      />

                      <div className="AppForm__radios__radio__label AppForm__radios__radio__label--ai-s">
                        <div className="AppForm__radios__radio__label__wrapper">
                          <strong className="AppForm__radios__radio__label__strong">
                            {get(
                              this.props,
                              `data.t.packages_bulk_new.package_type.radios.${get(
                                x,
                                "key"
                              )}.strong`
                            )}
                          </strong>

                          <span className="AppForm__radios__radio__label__span">
                            {get(
                              this.props,
                              `data.t.packages_bulk_new.package_type.radios.${get(
                                x,
                                "key"
                              )}.span`
                            )}
                          </span>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {get(this.state, "__packageTypeMode") == 0 && (
              <div className="AppForm__row">
                <Label t={t} tKey="packages_bulk_new.create_by_mode.label" />

                <div className="AppForm__radios">
                  {[
                    {
                      key: "number",
                    },
                    {
                      key: "time",
                    },
                  ].map((x, i) => (
                    <label className="AppForm__radios__radio" key={i}>
                      <input
                        type="radio"
                        checked={get(this.state, "__createByMode") == i}
                        onChange={() => {
                          this.setState({
                            __createByMode: i,
                          })
                        }}
                      />

                      <div className="AppForm__radios__radio__label AppForm__radios__radio__label--ai-s">
                        <div className="AppForm__radios__radio__label__wrapper">
                          <strong className="AppForm__radios__radio__label__strong">
                            {get(
                              this.props,
                              `data.t.packages_bulk_new.create_by_mode.radios.${get(
                                x,
                                "key"
                              )}.strong`
                            )}
                          </strong>

                          <span className="AppForm__radios__radio__label__span">
                            {get(
                              this.props,
                              `data.t.packages_bulk_new.create_by_mode.radios.${get(
                                x,
                                "key"
                              )}.span`
                            )}
                          </span>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <div className="AppForm__row">
              <Label t={t} tKey="packages_bulk_new.label2" />

              <div className="AppForm__value">
                <DateTimePicker
                  t={get(this.props, "data.t.packages_form.calendar")}
                  name="start_date"
                  __errors={get(this.state, "__errorsStep1")}
                  eKey="start_date"
                  $$defaultValue={
                    get(this.props, "data.package.bulk_start_at") &&
                    moment(
                      get(this.props, "data.package.bulk_start_at")
                    ).isAfter(moment())
                      ? moment(get(this.props, "data.package.bulk_start_at"))
                      : get(gon, "env") == "development"
                      ? moment()
                          .add(
                            60 * 2 +
                              get(
                                this.props,
                                "data.BEFORE_CALL_PREPARATION_TIME"
                              ),
                            "seconds"
                          )
                          .startOf("minute")
                      : moment().add(2, "hour").startOf("minute")
                  }
                  BEFORE_CALL_PREPARATION_TIME={
                    this.props.data?.BEFORE_CALL_PREPARATION_TIME
                  }
                  enableCreatableTime
                />

                <Error
                  __errors={get(this.state, "__errorsStep1")}
                  eKey="start_date"
                />
              </div>
            </div>

            {get(this.state, "__createByMode") == 0 &&
              get(this.state, "__packageTypeMode") == 0 && (
                <div className="AppForm__row">
                  <Label t={t} tKey="packages_bulk_new.label4" />

                  <div className="AppForm__value">
                    <input
                      type="number"
                      className={className("AppInput", {
                        "AppInput--error": get(
                          this.state,
                          "__errorsStep1.number_of_spots"
                        ),
                      })}
                      defaultValue={
                        get(this.props, "data.package.spots_count")
                          ? get(this.props, "data.package.spots_count")
                          : 1
                      }
                      name="number_of_spots"
                      min={1}
                      max={this.props.data.MAXIMUM_SPOTS_ALLOWED}
                      required
                    />

                    <Error
                      __errors={get(this.state, "__errorsStep1")}
                      eKey="number_of_spots"
                    />
                  </div>
                </div>
              )}

            {(get(this.state, "__createByMode") == 1 ||
              get(this.state, "__packageTypeMode") == 1) && (
              <div className="AppForm__row">
                <Label t={t} tKey="packages_bulk_new.label3" />

                <div className="AppForm__value">
                  <DateTimePicker
                    t={get(this.props, "data.t.packages_form.calendar")}
                    name="end_date"
                    __errors={get(this.state, "__errorsStep1")}
                    eKey="end_date"
                    $$defaultValue={
                      get(this.props, "data.package.bulk_end_at") &&
                      moment(
                        get(this.props, "data.package.bulk_end_at")
                      ).isAfter(moment())
                        ? moment(get(this.props, "data.package.bulk_end_at"))
                        : get(gon, "env") == "development"
                        ? moment()
                            .add(
                              60 * 7 +
                                get(
                                  this.props,
                                  "data.BEFORE_CALL_PREPARATION_TIME"
                                ),
                              "seconds"
                            )
                            .startOf("minute")
                        : moment().add(2, "hour").startOf("minute")
                    }
                    BEFORE_CALL_PREPARATION_TIME={
                      this.props.data?.BEFORE_CALL_PREPARATION_TIME
                    }
                    enableCreatableTime
                  />

                  <Error
                    __errors={get(this.state, "__errorsStep1")}
                    eKey="end_date"
                  />
                </div>
              </div>
            )}

            <div className="AppForm__row">
              <Label t={t} tKey="packages_bulk_new.label5" />

              <div className="AppForm__value">
                <div className="AppForm__value__duration">
                  <div className="AppForm__value__duration__col">
                    <div className="AppForm__value__duration__col__wrapper">
                      {get(this.state, "__packageTypeMode") == 0 && (
                        <select
                          className="AppSelect"
                          name="seconds"
                          defaultValue={get(data, "package.bulk_seconds")}
                        >
                          {availableDuration.map((x, i) => (
                            <option key={i} value={x}>
                              {this.formatDuration(x)}
                            </option>
                          ))}
                        </select>
                      )}

                      {get(this.state, "__packageTypeMode") == 1 && (
                        <select
                          className="AppSelect"
                          name="seconds"
                          defaultValue={
                            get(data, "package")
                              ? get(data, "package.bulk_seconds")
                              : get(data, "package.bulk_seconds")
                          }
                        >
                          {[15, 30, 60].map((x, i) => (
                            <option key={i} value={x}>
                              {this.formatDuration(x)}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="AppForm__value__duration__col">
                    <div className="AppForm__value__duration__col__wrapper">
                      <input
                        className={className("AppInput", {})}
                        type="number"
                        value={get(this.state, "__point")}
                        onChange={(e) => {
                          this.setState({
                            __point: get(e, "target.value"),
                          })
                        }}
                        min={this.getMinimumFee()}
                        cypress-target="point"
                      />

                      <span className="AppForm__value__duration__span">pt</span>
                    </div>

                    {(() => {
                      let text = `※ ${(
                        Number(get(this.state, "__point")) * 100
                      ).toLocaleString()}円です。`

                      let isError = false
                      const minimumFee = this.getMinimumFee()

                      if (get(this.state, "__point") < minimumFee) {
                        isError = true
                        if (get(data, "agency_setting.enable_freecall")) {
                          text = `※ ${minimumFee}pt以上で設定してください`
                        } else {
                          text = `※ ${minimumFee}pt以上で設定してください`
                        }
                      } else if (get(this.state, "__point") == 0) {
                        if (
                          get(data, "agency_setting.enable_freecall") ||
                          minimumFee == 0
                        ) {
                          text = "※ 無料です"
                        } else {
                          isError = true
                          text = `※ ${minimumFee}pt以上で設定してください`
                        }
                      }

                      return (
                        <p
                          className={className("AppForm__value__duration__p", {
                            "AppForm__value__duration__p--red": isError,
                          })}
                        >
                          {text}
                        </p>
                      )
                    })()}
                  </div>
                </div>
              </div>
            </div>

            {get(this.state, "__packageTypeMode") == 0 && (
              <div className="AppForm__row">
                <Label t={t} tKey="packages_bulk_new.label6" />

                <div className="AppForm__value">
                  <div className="AppForm__value__duration">
                    <div className="AppForm__value__duration__col">
                      <div className="AppForm__value__duration__col__wrapper">
                        {(() => {
                          const _secondsList = [
                            0,
                            15,
                            30,
                            ...Array.from(Array(59)).map(
                              (_, i) => (i + 1) * 60
                            ),
                          ]

                          return (
                            <select
                              className="AppSelect"
                              defaultValue={
                                isNil(data?.package?.bulk_interval)
                                  ? gon?.env == "development"
                                    ? 0
                                    : 60
                                  : data?.package?.bulk_interval
                              }
                              name="interval"
                            >
                              {_secondsList.map((seconds, i) => {
                                if (
                                  seconds == 0 &&
                                  !data?.agency_setting?.enable_without_interval
                                ) {
                                  return null
                                }

                                return (
                                  <option key={i} value={seconds}>
                                    {this.formatDuration(seconds)}
                                  </option>
                                )
                              })}
                            </select>
                          )
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="AppForm__row">
              <button
                className={className("Button", {
                  "Button--large": true,
                  "Button--disabled": get(this.state, "__isLoading"),
                })}
                cypress-target="new-package-next"
              >
                {get(this.state, "__isLoading")
                  ? get(t, "form.loading")
                  : get(t, "form.next")}
              </button>
            </div>
          </form>

          <form
            className={className("AppForm", {
              "AppForm--hidden": get(this.state, "__stepIndex") != 1,
              "AppForm--overlay": get(this.state, "__isLoading"),
            })}
            onSubmit={(e) => {
              e.preventDefault()
              const els = get(e, "target.elements")

              this.setState(
                {
                  __saveAsDraft: false,
                },
                () => {
                  this.onSubmitStep2({els})
                }
              )
            }}
            ref={this.$formStep2}
          >
            <div className="AppForm__row">
              <Label t={t} tKey="packages_form.label" />

              <div className="AppForm__value">
                <input
                  type="text"
                  className={className("AppInput", {
                    "AppInput--error": get(this.state, "__errors.title"),
                  })}
                  name="title"
                  defaultValue={
                    get(this.props, "data.package.title")
                      ? get(this.props, "data.package.title")
                      : get(gon, "env") == "development"
                      ? startCase(faker.lorem.words())
                      : ""
                  }
                  required
                />

                <Error __errors={get(this.state, "__errors")} eKey="title" />

                <div className="AppForm__value__examples">
                  <p className="AppForm__value__examples__p">
                    {get(t, "packages_form.example")}
                  </p>

                  <ul className="AppForm__value__examples__ul">
                    {Array.from(Array(3)).map((_, i) => (
                      <li key={i} className="AppForm__value__examples__ul__li">
                        {get(t, `packages_form.example${i + 1}`)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <h3 className="AppForm__h3">{get(t, "packages_form.sub_title")}</h3>

            <div className="AppForm__checkbox">
              <input
                type="checkbox"
                className="AppForm__checkbox__input"
                name="enable_release_at"
                id="enable_release_at"
                defaultChecked={
                  get(this.props, "data.package.release_at") ? true : false
                }
              />

              <div className="AppForm__checkbox__wrapper">
                <label
                  className="AppForm__checkbox__label"
                  htmlFor="enable_release_at"
                >
                  {tt(t, "packages_form.release_at.label")}
                </label>

                <p className="AppForm__checkbox__p">
                  {tt(t, "packages_form.release_at.p")}
                </p>

                <div className="AppForm__checkbox__content">
                  <div className="AppForm__row">
                    <Label t={t} tKey="package.release_at" />

                    <div className="AppForm__value">
                      <DateTimePicker
                        t={get(this.props, "data.t.packages_form.calendar")}
                        name="release_at"
                        __errors={get(this.state, "__errors")}
                        eKey="release_at"
                        $$defaultValue={
                          get(this.props, "data.package.release_at")
                            ? momentTz(
                                get(this.props, "data.package.release_at")
                              )
                            : momentTz().startOf("minute")
                        }
                        enablePast
                      />

                      <Error
                        __errors={get(this.state, "__errors")}
                        eKey="release_at"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="AppForm__checkbox">
              <input
                type="checkbox"
                className="AppForm__checkbox__input"
                name="enable_bookable_start_at"
                id="enable_bookable_start_at"
                defaultChecked={
                  get(this.props, "data.package.bookable_start_at")
                    ? true
                    : false
                }
              />

              <div className="AppForm__checkbox__wrapper">
                <label
                  className="AppForm__checkbox__label"
                  htmlFor="enable_bookable_start_at"
                >
                  {get(t, "packages_form.label2")}
                </label>

                <p className="AppForm__checkbox__p">
                  {get(t, "packages_form.explanation2")}
                </p>

                <div className="AppForm__checkbox__content">
                  <div className="AppForm__row">
                    <Label t={t} tKey="package.bookable_start_at" />

                    <div className="AppForm__value">
                      <DateTimePicker
                        t={get(this.props, "data.t.packages_form.calendar")}
                        name="bookable_start_at"
                        __errors={get(this.state, "__errors")}
                        eKey="bookable_start_at"
                        $$defaultValue={
                          get(this.props, "data.package.bookable_start_at")
                            ? momentTz(
                                get(
                                  this.props,
                                  "data.package.bookable_start_at"
                                )
                              )
                            : momentTz()
                                .subtract(65, "minutes")
                                .startOf("minute")
                        }
                      />

                      <Error
                        __errors={get(this.state, "__errors")}
                        eKey="bookable_start_at"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="AppForm__checkbox">
              <input
                type="checkbox"
                className="AppForm__checkbox__input"
                name="enable_bookable_end_at"
                id="enable_bookable_end_at"
                defaultChecked={
                  get(this.props, "data.package.bookable_end_at") ? true : false
                }
              />

              <div className="AppForm__checkbox__wrapper">
                <label
                  className="AppForm__checkbox__label"
                  htmlFor="enable_bookable_end_at"
                >
                  {get(t, "packages_form.label3")}
                </label>

                <p className="AppForm__checkbox__p">
                  {get(t, "packages_form.explanation3")}
                </p>

                <div className="AppForm__checkbox__content">
                  <div className="AppForm__row">
                    <Label t={t} tKey="package.bookable_end_at" />

                    <div className="AppForm__value">
                      <DateTimePicker
                        t={get(this.props, "data.t.packages_form.calendar")}
                        name="bookable_end_at"
                        __errors={get(this.state, "__errors")}
                        eKey="bookable_end_at"
                        $$defaultValue={
                          get(this.props, "data.package.bookable_end_at")
                            ? momentTz(
                                get(this.props, "data.package.bookable_end_at")
                              )
                            : momentTz(
                                get(
                                  last(get(this.state, "__spots")),
                                  "$$spots_attributes_start_at"
                                )
                              )
                                .subtract(
                                  get(gon, "env") == "development" ? 0 : 5,
                                  "minutes"
                                )
                                .startOf("minute")
                        }
                        updateStatesPerPropsChange
                      />

                      <Error
                        __errors={get(this.state, "__errors")}
                        eKey="bookable_end_at"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {get(this.state, "__packageTypeMode") == 0 && (
              <React.Fragment>
                <div className="AppForm__checkbox">
                  <input
                    type="checkbox"
                    className="AppForm__checkbox__input"
                    name="allow_multiple_purchases"
                    id="allow_multiple_purchases"
                    defaultChecked={
                      get(
                        this.props,
                        "data.package.allow_multiple_purchases"
                      ) === false
                        ? true
                        : false
                    }
                  />

                  <div className="AppForm__checkbox__wrapper">
                    <label
                      className="AppForm__checkbox__label"
                      htmlFor="allow_multiple_purchases"
                    >
                      {get(t, "packages_form.label10")}
                    </label>

                    <p className="AppForm__checkbox__p">
                      {get(t, "packages_form.explanation10")}
                    </p>

                    <div className="AppForm__checkbox__content">
                      <div className="AppForm__row">
                        <Label t={t} tKey="packages_form.explanation10_1" />

                        <div className="AppForm__value">
                          <input
                            type="number"
                            className="AppInput"
                            defaultValue={
                              get(this.props, "data.package.purchase_limit") ||
                              1
                            }
                            name="purchase_limit"
                            min={1}
                          />

                          <Error
                            __errors={get(this.state, "__errors")}
                            eKey="purchase_limit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {get(data, "agency_setting.enable_passcode") && (
                  <div
                    className={className("AppForm__checkbox", {
                      "AppForm__checkbox--disabled":
                        get(this.props, "data.is_edit_package_page") &&
                        get(this.state, "__passcodeAndSerialsChoice") ==
                          "serials",
                    })}
                  >
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      id="passcode_and_serials"
                      checked={
                        get(this.state, "__passcodeAndSerialsChoice") != ""
                      }
                      onChange={() => {
                        this.setState((prevState: NewAndEditPackageState) => ({
                          __passcodeAndSerialsChoice:
                            prevState.__passcodeAndSerialsChoice == ""
                              ? get(data, "agency_setting.enable_passcode")
                                ? "passcode"
                                : ""
                              : "",
                        }))
                      }}
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="passcode_and_serials"
                      >
                        {get(t, "packages_form.passcode_and_serials.label")}

                        <a
                          className="AppForm__checkbox__help"
                          href="https://www.notion.so/829e013fee474e878c73e52cf3bd8493"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        />
                      </label>

                      <p className="AppForm__checkbox__p">
                        {get(t, "packages_form.passcode_and_serials.p")}
                      </p>

                      <div className="AppForm__checkbox__content">
                        <div className="AppForm__checkbox">
                          <input
                            type="radio"
                            className="AppForm__checkbox__input AppForm__checkbox__input--radio"
                            name="passcode_and_serials_choice"
                            id="passcode_and_serials_choice_passcode"
                            value="passcode"
                            checked={
                              get(this.state, "__passcodeAndSerialsChoice") ==
                              "passcode"
                            }
                            onChange={() => {
                              this.setState({
                                __passcodeAndSerialsChoice: "passcode",
                              })
                            }}
                          />

                          <div className="AppForm__checkbox__wrapper">
                            <label
                              className="AppForm__checkbox__label"
                              htmlFor="passcode_and_serials_choice_passcode"
                            >
                              {get(
                                t,
                                "packages_form.passcode_and_serials.passcode.label"
                              )}
                            </label>

                            <p className="AppForm__checkbox__p">
                              {get(
                                t,
                                "packages_form.passcode_and_serials.passcode.p"
                              )}
                            </p>

                            <div className="AppForm__checkbox__content">
                              <div className="AppForm__row">
                                <Label
                                  t={t}
                                  tKey="packages_form.passcode_and_serials.passcode.span"
                                />

                                <div className="AppForm__value">
                                  <input
                                    type="text"
                                    className="AppInput"
                                    name="passcode"
                                    defaultValue={get(
                                      this.props,
                                      "data.package.passcode",
                                      ""
                                    )}
                                    required={
                                      get(
                                        this.state,
                                        "__passcodeAndSerialsChoice"
                                      ) == "passcode"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={className("AppForm__checkbox", {
                            "AppForm__checkbox--disabled":
                              get(this.props, "data.is_edit_package_page") ||
                              get(this.state, "__isCheckedLottery") ||
                              this.state.__isCheckedHiddenInventory,
                          })}
                        >
                          <input
                            type="radio"
                            className="AppForm__checkbox__input AppForm__checkbox__input--radio"
                            name="passcode_and_serials_choice"
                            id="passcode_and_serials_choice_serials"
                            value="serials"
                            checked={
                              get(this.state, "__passcodeAndSerialsChoice") ==
                              "serials"
                            }
                            onChange={() => {
                              this.setState({
                                __passcodeAndSerialsChoice: "serials",
                              })
                            }}
                          />

                          <div className="AppForm__checkbox__wrapper">
                            <label
                              className="AppForm__checkbox__label"
                              htmlFor="passcode_and_serials_choice_serials"
                            >
                              {get(
                                t,
                                "packages_form.passcode_and_serials.serial_code.label"
                              )}
                            </label>

                            <p className="AppForm__checkbox__p">
                              {get(
                                t,
                                "packages_form.passcode_and_serials.serial_code.p"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <FormInputEnableChip data={data} />

                <div className="AppForm__checkbox">
                  <input
                    type="checkbox"
                    className="AppForm__checkbox__input"
                    name="disable_video_usable"
                    id="disable_video_usable"
                    checked={get(this.state, "__disableVideo")}
                    onChange={(e) => {
                      const _value = get(e, "target.checked")

                      this.setState({
                        __disableVideo: _value,
                        __disableVideoSelf: _value,
                        __disableVideoPartner: _value,
                      })
                    }}
                  />

                  <div className="AppForm__checkbox__wrapper">
                    <label
                      className="AppForm__checkbox__label"
                      htmlFor="disable_video_usable"
                    >
                      {get(t, "packages_form.label5")}

                      <a
                        className="AppForm__checkbox__help"
                        href="https://www.notion.so/a8dea419ac184724aadb6595edf05486"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      />
                    </label>

                    <p className="AppForm__checkbox__p">
                      {get(t, "packages_form.explanation5")}
                    </p>

                    <div className="AppForm__checkbox__content">
                      <label className="AppForm__checkbox">
                        <input
                          type="checkbox"
                          className="AppForm__checkbox__input"
                          checked={get(this.state, "__disableVideoSelf")}
                          onChange={(e) => {
                            this.setState(
                              {
                                __disableVideoSelf: get(e, "target.checked"),
                              },
                              this.checkDisableVideo
                            )
                          }}
                        />

                        <span className="AppForm__checkbox__label">
                          {get(t, "packages_form.label5_1")}
                        </span>
                      </label>

                      <label className="AppForm__checkbox">
                        <input
                          type="checkbox"
                          className="AppForm__checkbox__input"
                          checked={get(this.state, "__disableVideoPartner")}
                          onChange={(e) => {
                            this.setState(
                              {
                                __disableVideoPartner: get(e, "target.checked"),
                              },
                              this.checkDisableVideo
                            )
                          }}
                        />

                        <span className="AppForm__checkbox__label">
                          {get(t, "packages_form.label5_2")}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                {get(data, "agency_setting.enable_hidden_inventory") && (
                  <div
                    className={className("AppForm__checkbox", {
                      "AppForm__checkbox--disabled":
                        get(this.props, "data.is_edit_package_page") ||
                        get(this.state, "__isCheckedLottery") ||
                        get(this.state, "__passcodeAndSerialsChoice") ==
                          "serials",
                    })}
                  >
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="enable_hidden_inventory"
                      id="enable_hidden_inventory"
                      checked={this.state.__isCheckedHiddenInventory}
                      onChange={(e) => {
                        // Allow this to be unchecked even there is less than 3 spots,
                        // in case a publisher changes one's mind.
                        if (
                          get(this.state, "__spots.length", 0) >= 3 ||
                          !get(e, "target.checked")
                        ) {
                          this.setState({
                            __isCheckedHiddenInventory: e?.target?.checked,
                            __spots: (get(this.state, "__spots") || []).map(
                              (x: any) => {
                                return {
                                  ...x,
                                  seconds: Number(
                                    get(this.state, "__bulkSeconds")
                                  ),
                                  point: Number(get(this.state, "__point")),
                                }
                              }
                            ),
                          })
                        } else {
                          this.setState({
                            __errors: {
                              spots:
                                "通話枠が不足しています。 3通話枠以上を作成してください。",
                            },
                          })
                        }
                      }}
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="enable_hidden_inventory"
                      >
                        {get(t, "packages_form.label9")}

                        <a
                          className="AppForm__checkbox__help"
                          href="https://www.notion.so/9f8d603d5b944094b75800e1510b9c60"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        />
                      </label>

                      <p className="AppForm__checkbox__p">
                        {get(t, "packages_form.explanation9_1")}
                      </p>

                      <Error
                        __errors={get(this.state, "__errors")}
                        eKey="spots"
                      />

                      <Error
                        __errors={get(this.state, "__errors")}
                        eKey="interval"
                      />

                      <div className="AppForm__checkbox__content">
                        <div className="AppForm__checkbox">
                          <input
                            type="checkbox"
                            className="AppForm__checkbox__input"
                            name="enable_first_spot_fee"
                            id="enable_first_spot_fee"
                            defaultChecked={
                              get(this.props, "data.package.first_spot_fee_id")
                                ? true
                                : false
                            }
                          />

                          <div className="AppForm__checkbox__wrapper">
                            <label
                              className="AppForm__checkbox__label"
                              htmlFor="enable_first_spot_fee"
                            >
                              {get(t, "packages_form.label9_1")}
                            </label>

                            <div className="AppForm__checkbox__content">
                              <div className="AppForm__row">
                                <Label
                                  t={t}
                                  tKey="packages_form.explanation9_2"
                                  required={false}
                                />

                                <div className="AppForm__value">
                                  <select
                                    className="AppSelect"
                                    name="first_spot_fee_id"
                                    defaultValue={get(
                                      this.props,
                                      "data.package.first_spot_fee_id"
                                    )}
                                  >
                                    {(
                                      get(
                                        data,
                                        "point_package_select_options"
                                      ) || []
                                    ).map((x: any, i: number) => (
                                      <option key={i} value={get(x, "1")}>
                                        {get(x, "0")}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="AppForm__checkbox">
                          <input
                            type="checkbox"
                            className="AppForm__checkbox__input"
                            name="enable_last_spot_fee"
                            id="enable_last_spot_fee"
                            defaultChecked={
                              get(this.props, "data.package.last_spot_fee_id")
                                ? true
                                : false
                            }
                          />

                          <div className="AppForm__checkbox__wrapper">
                            <label
                              className="AppForm__checkbox__label"
                              htmlFor="enable_last_spot_fee"
                            >
                              {get(t, "packages_form.label9_2")}
                            </label>

                            <div className="AppForm__checkbox__content">
                              <div className="AppForm__row">
                                <Label
                                  t={t}
                                  tKey="packages_form.explanation9_2"
                                  required={false}
                                />

                                <div className="AppForm__value">
                                  <select
                                    className="AppSelect"
                                    name="last_spot_fee_id"
                                    defaultValue={get(
                                      this.props,
                                      "data.package.last_spot_fee_id"
                                    )}
                                  >
                                    {(
                                      get(
                                        data,
                                        "point_package_select_options"
                                      ) || []
                                    ).map((x: any, i: number) => (
                                      <option key={i} value={get(x, "1")}>
                                        {get(x, "0")}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {get(data, "agency_setting.enable_recording") && (
                  <div className="AppForm__checkbox">
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="allow_sell_video_record"
                      id="allow_sell_video_record"
                      defaultChecked={get(
                        data,
                        "package.allow_sell_video_record",
                        false
                      )}
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="allow_sell_video_record"
                      >
                        {get(t, "packages_form.sell_recording")}
                      </label>

                      <p className="AppForm__checkbox__p">
                        {get(t, "packages_form.sell_recording_description")}
                      </p>

                      <div className="AppForm__checkbox__content">
                        <div className="AppForm__row">
                          <Label
                            t={t}
                            tKey="packages_form.sell_recording_fee"
                          />

                          <div className="AppForm__value">
                            <select
                              className={className("AppSelect", {
                                "AppInput--error": get(
                                  this.state,
                                  "__errors.recording_fee_id"
                                ),
                              })}
                              name="recording_fee_id"
                              defaultValue={get(
                                this.props,
                                "data.package.recording_fee_id"
                              )}
                            >
                              {(
                                get(data, "point_package_select_options") || []
                              ).map((x: any, i: number) => (
                                <option key={i} value={get(x, "1")}>
                                  {get(x, "0")}
                                </option>
                              ))}
                            </select>

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="recording_fee_id"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {get(data, "agency_setting.enable_goods") && (
                  <React.Fragment>
                    <div className="AppForm__checkbox">
                      <input
                        type="checkbox"
                        className={className("AppForm__checkbox__input", {
                          "AppForm__checkbox__input--disabled":
                            this.props.data?.is_edit_package_page,
                        })}
                        checked={get(this.state, "__isCheckedProduct")}
                        onChange={(e) => {
                          this.setState({
                            __isCheckedProduct: get(e, "target.checked"),
                          })
                        }}
                        id="package_with_goods"
                      />

                      <div className="AppForm__checkbox__wrapper">
                        <label
                          className="AppForm__checkbox__label"
                          htmlFor="package_with_goods"
                        >
                          {get(t, "packages_form.label6")}
                        </label>

                        <p className="AppForm__checkbox__p">
                          {get(t, "packages_form.explanation6")}
                        </p>

                        <div className="AppForm__checkbox__content">
                          <div className="AppForm__row">
                            <Label t={t} tKey="packages_form.product" />

                            <div className="AppForm__value">
                              <div className="AppPackagesBulkNew__product">
                                <dl className="AppPackagesBulkNew__product__dl">
                                  {this.state.__masterProductsSelected.map(
                                    (x, i) => {
                                      const _masterProduct =
                                        this.state.__masterProducts.data?.find(
                                          (y) => y.id == x.master_product_id
                                        )

                                      return (
                                        <div
                                          className="AppPackagesBulkNew__product__dl__item"
                                          key={i}
                                        >
                                          <dt
                                            className={className(
                                              "AppPackagesBulkNew__product__dl__item__dt",
                                              {
                                                "AppPackagesBulkNew__product__dl__item__dt--disabled":
                                                  x.is_deleting,
                                              }
                                            )}
                                          >
                                            {_masterProduct.name}
                                          </dt>

                                          <div className="AppPackagesBulkNew__product__dl__item__right">
                                            <span className="AppPackagesBulkNew__product__dl__item__right__span">
                                              {tt(
                                                t,
                                                "packages_bulk_new.master_products.amount"
                                              )}
                                            </span>

                                            <input
                                              type="number"
                                              className={className(
                                                "AppPackagesBulkNew__product__dl__item__right__input",
                                                {
                                                  "AppPackagesBulkNew__product__dl__item__right__input--input":
                                                    true,
                                                  "AppPackagesBulkNew__product__dl__item__right__input--disabled":
                                                    x.is_deleting,
                                                }
                                              )}
                                              value={x.amount}
                                              min={1}
                                              onChange={(e) => {
                                                const _value = e.target.value

                                                this.setState((prevState) => ({
                                                  __masterProductsSelected:
                                                    prevState.__masterProductsSelected.map(
                                                      (y) => {
                                                        if (
                                                          y.master_product_id ==
                                                          x.master_product_id
                                                        ) {
                                                          return {
                                                            ...y,
                                                            amount:
                                                              Number(_value),
                                                          }
                                                        }

                                                        return y
                                                      }
                                                    ),
                                                }))
                                              }}
                                            />

                                            {x.is_existed ? (
                                              <a
                                                className="AppPackagesBulkNew__product__dl__item__right__input"
                                                onClick={() => {
                                                  this.setState(
                                                    (prevState) => ({
                                                      __masterProductsSelected:
                                                        prevState.__masterProductsSelected.map(
                                                          (y) => {
                                                            if (
                                                              y.master_product_id ==
                                                              x.master_product_id
                                                            ) {
                                                              return {
                                                                ...y,
                                                                is_deleting:
                                                                  isNil(
                                                                    x.is_deleting
                                                                  )
                                                                    ? true
                                                                    : !x.is_deleting,
                                                              }
                                                            }

                                                            return y
                                                          }
                                                        ),
                                                    })
                                                  )
                                                }}
                                              >
                                                {tt(
                                                  t,
                                                  x.is_deleting
                                                    ? "packages_bulk_new.master_products.remove_cancel"
                                                    : "packages_bulk_new.master_products.remove"
                                                )}
                                              </a>
                                            ) : (
                                              <a
                                                className="AppPackagesBulkNew__product__dl__item__right__input"
                                                onClick={() => {
                                                  this.setState(
                                                    (prevState) => ({
                                                      __masterProductsSelected:
                                                        prevState.__masterProductsSelected.filter(
                                                          (y) =>
                                                            y.master_product_id !=
                                                            x.master_product_id
                                                        ),
                                                    })
                                                  )
                                                }}
                                              >
                                                {tt(
                                                  t,
                                                  "packages_bulk_new.master_products.remove"
                                                )}
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    }
                                  )}
                                </dl>

                                <div className="AppPackagesBulkNew__product__add">
                                  <a
                                    className="AppPackagesBulkNew__product__add__a"
                                    onClick={() => {
                                      this.setState({
                                        __isActiveModal: true,
                                      })
                                    }}
                                  >
                                    {get(t, "packages_form.select_product")}
                                  </a>
                                </div>
                              </div>

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.product_items"
                              />
                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.product_items.amount"
                              />
                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.base"
                              />
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label t={t} tKey="packages_form.product_name" />

                            <div className="AppForm__value">
                              <input
                                type="text"
                                name="product.name"
                                className={className("AppInput", {
                                  "AppInput--error": get(
                                    this.state,
                                    "__errors['product.name']"
                                  ),
                                })}
                                defaultValue={data.package_product?.name}
                              />

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.name"
                              />
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_description"
                            />

                            <div className="AppForm__value">
                              <textarea
                                className={className("AppInput", {
                                  "AppInput--error": get(
                                    this.state,
                                    "__errors['product.description']"
                                  ),
                                })}
                                name="product.description"
                                rows={5}
                                defaultValue={data.package_product?.description}
                              />

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.description"
                              />
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_icon_image"
                            />

                            <div className="AppForm__value">
                              <input
                                type="file"
                                cypress-target="product-icon-image"
                                className={className("AppInput", {
                                  "AppInput--error": get(
                                    this.state,
                                    "__errors['product.icon_image']"
                                  ),
                                })}
                                onChange={(e) => {
                                  this.setState({
                                    __fileProduct: e?.target?.files?.[0],
                                  })
                                }}
                              />

                              {(this.props.data?.package_product_icon_image ||
                                this.state.__fileProduct) && (
                                <img
                                  src={
                                    this.state.__fileProduct
                                      ? window.URL.createObjectURL(
                                          this.state.__fileProduct
                                        )
                                      : this.props.data
                                          .package_product_icon_image
                                  }
                                  className="AppForm__value__profile-image__img AppForm__value__profile-image__img--cover"
                                />
                              )}

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.icon_image"
                              />
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_shipping_fee"
                            />

                            <div className="AppForm__value">
                              <div className="AppPackagesBulkNew__product__shipping-fee">
                                <input
                                  type="number"
                                  name="product.shipping_fee"
                                  className={className("AppInput", {
                                    "AppInput--error": get(
                                      this.state,
                                      "__errors['product.shipping_fee']"
                                    ),
                                  })}
                                  min={0}
                                  value={this.state.__productShippingFee}
                                  onChange={(e) => {
                                    this.setState({
                                      __productShippingFee: Number(
                                        e?.target?.value
                                      ),
                                    })
                                  }}
                                />

                                <span className="AppPackagesBulkNew__product__shipping-fee__span">
                                  {tt(t, "spot_fields.yen")}
                                </span>
                              </div>

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.shipping_fee"
                              />
                            </div>
                          </div>

                          {false && (
                            <div className="AppForm__row">
                              <Label
                                t={t}
                                tKey="packages_form.product_sale_price"
                                required={false}
                              />

                              <div className="AppForm__value">
                                <span className="AppPackagesBulkNew__product__shipping-fee__span">
                                  {(
                                    Number(this.state.__productShippingFee) +
                                    [
                                      ...this.state.__spots,
                                      ...this.state.__spotsExisting,
                                    ]?.[0]?.point *
                                      data.point_to_price_rate
                                  ).toLocaleString()}{" "}
                                  {tt(t, "spot_fields.yen")}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_size"
                              required={false}
                            />

                            <div className="AppForm__value">
                              <select
                                className="AppSelect"
                                name="product.size"
                                defaultValue={data.package_product?.size}
                              >
                                {data?.SIZE_ALLOWED.map((x, i) => (
                                  <option key={i} value={x}>
                                    {x}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_shipping_at"
                            />

                            <div className="AppForm__value">
                              <DateTimePicker
                                t={get(
                                  this.props,
                                  "data.t.packages_form.calendar"
                                )}
                                __errors={get(this.state, "__errors")}
                                name="product.shipping_at"
                                eKey="product.shipping_at"
                                $$defaultValue={
                                  data.package_product?.shipping_at
                                    ? momentTz(
                                        data.package_product?.shipping_at
                                      )
                                    : momentTz().add(1, "day").startOf("day")
                                }
                                enableTime={false}
                              />

                              <Error
                                __errors={get(this.state, "__errors")}
                                eKey="product.shipping_at"
                              />
                            </div>
                          </div>

                          <div className="AppForm__row">
                            <Label
                              t={t}
                              tKey="packages_form.product_memo"
                              required={false}
                            />

                            <div className="AppForm__value">
                              <textarea
                                className={className("AppInput", {
                                  "AppInput--error": get(
                                    this.state,
                                    "__errors['product.memo']"
                                  ),
                                })}
                                name="product.memo"
                                rows={5}
                                defaultValue={data.package_product?.memo}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ModalNewAndEditPackageProduct
                      __masterProducts={this.state.__masterProducts}
                      __masterProductsSelected={
                        this.state.__masterProductsSelected
                      }
                      __isActiveModal={this.state.__isActiveModal}
                      setState={(nextState: NewAndEditPackageState) => {
                        this.setState(nextState)
                      }}
                      t={t}
                      master_products_path={data.master_products_path}
                      suggestion_master_products_path={
                        data.suggestion_master_products_path
                      }
                    />
                  </React.Fragment>
                )}

                {get(data, "agency_setting.enable_online_live") && (
                  <div className="AppForm__checkbox">
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="enable_online_live_url"
                      id="enable_online_live_url"
                      defaultChecked={
                        get(this.props, "data.package.online_live_url")
                          ? true
                          : false
                      }
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="enable_online_live_url"
                      >
                        {get(t, "packages_form.online_live_msg")}
                      </label>

                      <p className="AppForm__checkbox__p">
                        {get(t, "packages_form.online_live_msg2")}
                      </p>

                      <div className="AppForm__checkbox__content">
                        <div className="AppForm__row">
                          <Label t={t} tKey="packages_form.url" />
                          <div className="AppForm__details AppForm__details--collapse-mt">
                            {get(t, "packages_form.url_description")}
                          </div>

                          <div className="AppForm__value">
                            <input
                              type="text"
                              className={className("AppInput", {
                                "AppInput--error": get(
                                  this.state,
                                  "__errors.online_live_url"
                                ),
                              })}
                              placeholder="https://vimeo.com/"
                              name="online_live_url"
                              defaultValue={get(
                                this.props,
                                "data.package.online_live_url"
                              )}
                            />

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_url"
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label t={t} tKey="packages_form.start_at" />

                          <div className="AppForm__value">
                            <DateTimePicker
                              t={get(
                                this.props,
                                "data.t.packages_form.calendar"
                              )}
                              name="online_live_start_at"
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_start_at"
                              $$defaultValue={
                                get(
                                  this.props,
                                  "data.package.online_live_start_at"
                                )
                                  ? momentTz(
                                      get(
                                        this.props,
                                        "data.package.online_live_start_at"
                                      )
                                    )
                                  : momentTz().add(7, "days").startOf("day")
                              }
                            />

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_start_at"
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label t={t} tKey="packages_bulk_new.label9" />

                          <div className="AppForm__value">
                            <select
                              className={className("AppSelect", {
                                "AppInput--error": get(
                                  this.state,
                                  "__errors.online_live_archive_period"
                                ),
                              })}
                              defaultValue={get(
                                this.props,
                                "data.package.online_live_archive_period"
                              )}
                              name="online_live_archive_period"
                            >
                              {entries(
                                get(data, "online_live_archive_periods")
                              ).map((x, i) => (
                                <option key={i} value={get(x, "0")}>
                                  {get(x, "0")}
                                </option>
                              ))}
                            </select>

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_archive_period"
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label t={t} tKey="packages_form.online_live_title" />

                          <div className="AppForm__value">
                            <input
                              type="text"
                              className={className("AppInput", {
                                "AppInput--error": get(
                                  this.state,
                                  "__errors.online_live_title"
                                ),
                              })}
                              name="online_live_title"
                              defaultValue={get(
                                this.props,
                                "data.package.online_live_title"
                              )}
                            />

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_title"
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label
                            t={t}
                            tKey="packages_form.online_live_description"
                            required={false}
                          />

                          <div className="AppForm__value">
                            <textarea
                              className={className("AppInput", {
                                "AppInput--error": get(
                                  this.state,
                                  "__errors.online_live_description"
                                ),
                              })}
                              name="online_live_description"
                              defaultValue={get(
                                this.props,
                                "data.package.online_live_description"
                              )}
                              rows={5}
                            />

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey="online_live_description"
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label
                            t={t}
                            tKey="packages_form.thumbnail"
                            required={false}
                          />

                          <div className="AppForm__value">
                            <div className="AppForm__value__profile-image">
                              <div className="AppForm__value__profile-image__input">
                                <input
                                  type="file"
                                  className="AppInput"
                                  onChange={(e) => {
                                    this.setState({
                                      __file: get(e, "target.files.0"),
                                    })
                                  }}
                                />
                              </div>

                              {(get(
                                this.props,
                                "data.package_thumbnail_image_url"
                              ) ||
                                get(this.state, "__file")) && (
                                <img
                                  src={
                                    get(this.state, "__file")
                                      ? window.URL.createObjectURL(
                                          get(this.state, "__file")
                                        )
                                      : get(
                                          this.props,
                                          "data.package_thumbnail_image_url"
                                        )
                                  }
                                  className="AppForm__value__profile-image__img AppForm__value__profile-image__img--cover"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <FormInputEnableScreenshotRestrictions data={data} />

                {get(data, "agency_setting.enable_identity_verification") && (
                  <div className="AppForm__checkbox">
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="allow_identity_verification"
                      id="allow_identity_verification"
                      defaultChecked={get(
                        this.props,
                        "data.package.allow_identity_verification"
                      )}
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="allow_identity_verification"
                      >
                        {get(t, "packages_form.allow_identity_verification")}
                      </label>

                      <p className="AppForm__checkbox__p">
                        {get(
                          t,
                          "packages_form.allow_identity_verification_description"
                        )}
                      </p>
                    </div>
                  </div>
                )}

                {get(data, "agency_setting.enable_store_live_video") && (
                  <div className="AppForm__checkbox">
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="allow_monitor_video_record"
                      id="allow_monitor_video_record"
                      defaultChecked={
                        !isNil(get(data, "package.allow_monitor_video_record"))
                          ? get(data, "package.allow_monitor_video_record")
                          : get(data, "agency_setting.enable_store_live_video")
                      }
                    />

                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="allow_monitor_video_record"
                      >
                        {tt(t, "packages_form.allow_store_live_video")}

                        <a
                          className="AppForm__checkbox__help"
                          href="https://www.notion.so/629013cf75e842c4af1ca3dd1fdaf00e"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        />
                      </label>

                      <p className="AppForm__checkbox__p">
                        {tt(t, "packages_form.allow_store_live_video_detail")}
                      </p>
                    </div>
                  </div>
                )}

                {get(data, "agency_setting.enable_private_selling") && (
                  <div className="AppForm__checkbox">
                    <input
                      type="checkbox"
                      className="AppForm__checkbox__input"
                      name="allow_private_selling"
                      id="allow_private_selling"
                      defaultChecked={Boolean(
                        get(data, "package.allow_private_selling")
                      )}
                    />
                    <div className="AppForm__checkbox__wrapper">
                      <label
                        className="AppForm__checkbox__label"
                        htmlFor="allow_private_selling"
                      >
                        {tt(t, "packages_form.allow_private_selling")}
                      </label>

                      <p className="AppForm__checkbox__p">
                        {tt(t, "packages_form.allow_private_selling_detail")}
                      </p>
                    </div>
                  </div>
                )}

                {get(data, "agency_setting.enable_lottery") && (
                  <React.Fragment>
                    <h3 className="AppForm__h3">
                      {get(t, "packages_form.sub_title2")}
                    </h3>

                    <div
                      className={className("AppForm__checkbox", {
                        "AppForm__checkbox--disabled":
                          get(this.props, "data.is_edit_package_page") ||
                          this.state.__isCheckedHiddenInventory ||
                          get(this.state, "__passcodeAndSerialsChoice") ==
                            "serials",
                      })}
                    >
                      <input
                        type="checkbox"
                        className="AppForm__checkbox__input"
                        name="lottery"
                        id="lottery"
                        checked={get(this.state, "__isCheckedLottery")}
                        onChange={(e) => {
                          this.setState({
                            __isCheckedLottery: get(e, "target.checked"),
                          })
                        }}
                      />

                      <div className="AppForm__checkbox__wrapper">
                        <label
                          className="AppForm__checkbox__label"
                          htmlFor="lottery"
                        >
                          {get(t, "packages_form.label7")}
                        </label>

                        <p className="AppForm__checkbox__p">
                          {get(t, "packages_form.explanation7")}
                        </p>

                        <div className="AppForm__checkbox__content">
                          <div className="AppForm__checkbox">
                            <input
                              type="checkbox"
                              className="AppForm__checkbox__input"
                              name="enable_lottery_purchase_limit"
                              id="enable_lottery_purchase_limit"
                              checked={get(
                                this.state,
                                "__isCheckedLotteryPurchaseLimit"
                              )}
                              onChange={(e) => {
                                this.setState({
                                  __isCheckedLotteryPurchaseLimit: get(
                                    e,
                                    "target.checked"
                                  ),
                                })
                              }}
                            />

                            <div className="AppForm__checkbox__wrapper">
                              <label
                                className="AppForm__checkbox__label"
                                htmlFor="enable_lottery_purchase_limit"
                              >
                                {get(
                                  t,
                                  "packages_form.set_lottery_purchase_limit"
                                )}
                              </label>
                              <div className="AppForm__checkbox__content">
                                <div className="AppForm__row">
                                  <Label
                                    t={t}
                                    tKey="packages_form.lottery_purchase_limit"
                                  />

                                  {get(
                                    this.state,
                                    "__isCheckedLotteryPurchaseLimit"
                                  ) && (
                                    <div className="AppForm__value">
                                      <input
                                        type="number"
                                        className="AppInput"
                                        name="lottery_purchase_limit"
                                        defaultValue={get(
                                          this.props,
                                          "data.package.lottery_purchase_limit"
                                        )}
                                        min="1"
                                        max="9999"
                                        required
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Error
                          __errors={get(this.state, "__errors")}
                          eKey="lottery"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}

                <h3 className="AppForm__h3">
                  {get(t, "packages_form.label8")}
                </h3>

                <div className="AppPackagesBulkNew__spots">
                  {(this.state.__spotsExisting || []).map((x, i: number) => (
                    <div className="AppPackagesBulkNew__spot" key={i}>
                      {(() => {
                        if (
                          this.state.__passcodeAndSerialsChoice == "serials" ||
                          x?.booking_path ||
                          x?.bookings_reserving ||
                          (get(this.props, "data.package.subtype") ==
                            "lottery" &&
                            get(this.props, "data.package_sold")) ||
                          (!isNil(
                            get(this.props, "data.package.first_spot_fee_id")
                          ) &&
                            i == 0) ||
                          (!isNil(
                            get(this.props, "data.package.last_spot_fee_id")
                          ) &&
                            i == this.state.__spotsExisting?.length - 1)
                        ) {
                          return null
                        }

                        return (
                          <div
                            className={className(
                              "AppPackagesBulkNew__spot__header",
                              {
                                "AppPackagesBulkNew__spot__header--disabled":
                                  !x?._destroy &&
                                  this.isNotEnoughSpotOrExistingSpot(),
                              }
                            )}
                          >
                            <a
                              className="AppPackagesBulkNew__spot__header__left"
                              onClick={() => {
                                this.deleteSpot({spotId: x.id})
                              }}
                            >
                              <div
                                className={className(
                                  "AppForm__checkbox__input",
                                  {
                                    "AppForm__checkbox__input--checked":
                                      x?._destroy == 1,
                                  }
                                )}
                              ></div>

                              <span className="AppPackagesBulkNew__spot__header__left__span">
                                {tt(t, "spot_fields.frame", {count: i + 1})}
                              </span>
                            </a>

                            <a
                              className="AppPackagesBulkNew__spot__header__a"
                              onClick={() => {
                                this.deleteSpot({
                                  spotId: x.id,
                                  immediatelyShowPopup: true,
                                })
                              }}
                            >
                              {tt(t, "spot_fields.delete")}
                            </a>
                          </div>
                        )
                      })()}

                      <div className="AppPackagesBulkNew__spot__wrapper">
                        <div className="AppPackagesBulkNew__spot__content">
                          <time className="AppPackagesBulkNew__spot__content__time">
                            {momentTz(x.start_at).format("YYYY-MM-DD (ddd)")}
                          </time>

                          <p className="AppPackagesBulkNew__spot__content__p">
                            {x.start_at_and_end_at?.time}
                          </p>

                          <p className="AppPackagesBulkNew__spot__content__details">
                            {x.call_duration_humanize}
                          </p>
                        </div>

                        <div className="AppPackagesBulkNew__spot__price">
                          {(
                            x.point *
                            get(this.props, "data.point_to_price_rate")
                          )?.toLocaleString()}
                          {get(t, "spot_fields.yen")} /{" "}
                          {x.point?.toLocaleString()}pt
                        </div>
                      </div>

                      <React.Fragment>
                        {x?.booking_path ? (
                          <a className="Button" href={get(x, "booking_path")}>
                            {get(t, "spot_fields.check_booking")}
                          </a>
                        ) : x?.bookings_reserving ? (
                          <p>{get(t, "spot_fields.msg")}</p>
                        ) : null}
                      </React.Fragment>
                    </div>
                  ))}

                  {(() => {
                    const toBeDeletedSpots =
                      this.state.__spotsExisting?.filter?.((x) => x?._destroy)

                    return (
                      <React.Fragment>
                        {get(toBeDeletedSpots, "length", 0) > 0 && (
                          <a
                            className="Button Button--large Button--red"
                            onClick={() => {
                              this.setState({
                                __isDeletingSpots: true,
                              })
                            }}
                          >
                            {tt(t, "packages_bulk_new.delete_spots_modal.h3", {
                              count: get(toBeDeletedSpots, "length", 0),
                            })}
                          </a>
                        )}

                        <div
                          className={className("ModalDeletePackage", {
                            "ModalDeletePackage--active": get(
                              this.state,
                              "__isDeletingSpots"
                            ),
                          })}
                        >
                          <a
                            className="ModalDeletePackage__overlay"
                            onClick={() => {
                              this.setState({
                                __isDeletingSpots: false,
                              })
                            }}
                          />

                          <div className="ModalDeletePackage__panel">
                            <h3 className="ModalDeletePackage__h3">
                              {tt(
                                t,
                                "packages_bulk_new.delete_spots_modal.h3",
                                {
                                  count: get(toBeDeletedSpots, "length", 0),
                                }
                              )}
                            </h3>

                            <p className="ModalDeletePackage__p">
                              {tt(t, "packages_bulk_new.delete_spots_modal.p", {
                                count: get(toBeDeletedSpots, "length", 0),
                              })}
                            </p>

                            <a
                              className={className("Button Button--red", {
                                "Button--disabled": get(
                                  this.state,
                                  "__isLoading"
                                ),
                              })}
                              onClick={() => {
                                axios
                                  .delete(get(data, "delete_spot_url"), {
                                    spots: toBeDeletedSpots.map((x) => {
                                      return get(x, "id")
                                    }),
                                  })
                                  .then(() => {
                                    window.location.reload()
                                  })
                              }}
                            >
                              {tt(
                                t,
                                "packages_bulk_new.delete_spots_modal.submit"
                              )}
                            </a>

                            <a
                              className="ModalDeletePackage__cancel"
                              onClick={() => {
                                this.setState({
                                  __isDeletingSpots: false,
                                })
                              }}
                            >
                              {tt(
                                t,
                                "packages_bulk_new.delete_spots_modal.cancel"
                              )}
                            </a>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })()}

                  {get(this.state, "__spots").map((x: any, _i: number) => {
                    // We determine error position from `spots_errors`'s index.
                    const i = _i + (this.state.__spotsExisting?.length || 0)

                    return (
                      <div
                        className="AppPackagesBulkNew__spot"
                        key={get(x, "_id")}
                        data-id={get(x, "_id")}
                      >
                        <div className="AppForm__row">
                          <div className="AppForm__value">
                            <DateTimePicker
                              disabled={this.state.__isCheckedHiddenInventory}
                              t={get(
                                this.props,
                                "data.t.packages_form.calendar"
                              )}
                              __errors={get(this.state, "__errors")}
                              eKey={`spots_errors.${i}.base`}
                              $$defaultValue={moment(
                                get(x, "$$spots_attributes_start_at")
                              )}
                              onChange={({
                                __$$selectingDateTime,
                              }: {
                                __$$selectingDateTime: Moment
                              }) => {
                                this.setState(
                                  (prevState: NewAndEditPackageState) => ({
                                    __spots: get(prevState, "__spots").map(
                                      (y: any) => {
                                        if (get(x, "_id") == get(y, "_id")) {
                                          return {
                                            ...y,
                                            $$spots_attributes_start_at: moment(
                                              __$$selectingDateTime
                                            ),
                                          }
                                        }

                                        return y
                                      }
                                    ),
                                  })
                                )
                              }}
                              BEFORE_CALL_PREPARATION_TIME={
                                this.props.data?.BEFORE_CALL_PREPARATION_TIME
                              }
                              enableCreatableTime
                            />

                            <Error
                              __errors={get(this.state, "__errors")}
                              eKey={`spots_errors.${i}.base`}
                            />
                          </div>
                        </div>

                        <div className="AppForm__row">
                          <Label
                            t={t}
                            text={`${get(t, "spot_fields.time")} / ${get(
                              t,
                              "spot_fields.point"
                            )}`}
                            required={false}
                          />

                          <div className="AppForm__value">
                            <div className="AppForm__value__duration">
                              <div className="AppForm__value__duration__col">
                                <div className="AppForm__value__duration__col__wrapper">
                                  <select
                                    disabled={
                                      this.state.__isCheckedHiddenInventory
                                    }
                                    className={className("AppSelect", {
                                      "AppSelect--error": get(
                                        this.state,
                                        `__errors.spots_errors.${i}.seconds`
                                      ),
                                    })}
                                    value={get(x, "seconds") || ""}
                                    onChange={(e) => {
                                      const _value = get(e, "target.value")

                                      this.setState(
                                        (
                                          prevState: NewAndEditPackageState
                                        ) => ({
                                          __spots: get(
                                            prevState,
                                            "__spots"
                                          ).map((y: any) => {
                                            if (
                                              get(x, "_id") == get(y, "_id")
                                            ) {
                                              return {
                                                ...y,
                                                seconds: _value,
                                              }
                                            }

                                            return y
                                          }),
                                        })
                                      )
                                    }}
                                  >
                                    {availableDuration.map((y, j) => (
                                      <option key={j} value={y}>
                                        {this.formatDuration(y)}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <Error
                                  __errors={get(this.state, "__errors")}
                                  eKey={`spots_errors.${i}.seconds`}
                                />
                              </div>

                              <div className="AppForm__value__duration__col">
                                <div className="AppForm__value__duration__col__wrapper">
                                  <input
                                    type="number"
                                    disabled={
                                      this.state.__isCheckedHiddenInventory
                                    }
                                    className={className("AppInput", {
                                      "AppInput--error": get(
                                        this.state,
                                        `__errors.spots_errors.${i}.point`
                                      ),
                                    })}
                                    value={get(x, "point")}
                                    onChange={(e) => {
                                      const _value = get(e, "target.value")

                                      this.setState(
                                        (
                                          prevState: NewAndEditPackageState
                                        ) => ({
                                          __spots: get(
                                            prevState,
                                            "__spots"
                                          ).map((y: any) => {
                                            if (
                                              get(x, "_id") == get(y, "_id")
                                            ) {
                                              return {
                                                ...y,
                                                point: _value,
                                              }
                                            }

                                            return y
                                          }),
                                        })
                                      )
                                    }}
                                    min={this.getMinimumFee()}
                                  />

                                  <div className="AppForm__value__duration__span">
                                    pt
                                  </div>
                                </div>

                                <Error
                                  __errors={get(this.state, "__errors")}
                                  eKey={`spots_errors.${i}.point`}
                                />

                                <p className="AppForm__value__duration__p">
                                  {get(t, "spot_fields.rate")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {!this.state.__isCheckedHiddenInventory && (
                          <div className="Buttons Buttons--jc-c">
                            <a
                              className={className(
                                "AppPackagesBulkNew__spot__delete",
                                {
                                  "AppPackagesBulkNew__spot__delete--disabled":
                                    this.isNotEnoughSpotOrExistingSpot(),
                                }
                              )}
                              onClick={() => {
                                this.setState(
                                  (prevState: NewAndEditPackageState) => ({
                                    __spots: get(prevState, "__spots").filter(
                                      (y: any) => get(y, "_id") != get(x, "_id")
                                    ),
                                  })
                                )
                              }}
                            >
                              {get(t, "packages_edit.delete_spot")}
                            </a>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>

                <Error __errors={get(this.state, "__errors")} eKey="spots" />
                <Error __errors={get(this.state, "__errors")} eKey="interval" />

                {!(
                  this.state.__isCheckedHiddenInventory ||
                  (get(this.state, "__passcodeAndSerialsChoice") == "serials" &&
                    get(this.props, "data.is_edit_package_page"))
                ) && (
                  <div className="Buttons Buttons--jc-c">
                    <a
                      className="AppPackagesBulkNew__a"
                      onClick={() => {
                        this.setState((prevState: NewAndEditPackageState) => {
                          const lastSpot =
                            last(get(prevState, "__spots")) ||
                            last(prevState.__spotsExisting)

                          return {
                            __spots: [
                              ...get(prevState, "__spots"),
                              {
                                _id: +new Date(),
                                $$spots_attributes_start_at: (get(
                                  lastSpot,
                                  "start_at"
                                )
                                  ? momentTz(get(lastSpot, "start_at"))
                                  : moment(
                                      get(
                                        lastSpot,
                                        "$$spots_attributes_start_at"
                                      )
                                    )
                                ).add(
                                  Number(get(lastSpot, "interval", 0)) + // interval will be null for existing spots.
                                    Number(get(lastSpot, "seconds", 120)),
                                  "seconds"
                                ),
                                seconds: get(lastSpot, "seconds"),
                                interval: get(lastSpot, "interval", 0),
                                point: get(lastSpot, "point", 0),
                              },
                            ],
                          }
                        })
                      }}
                    >
                      {get(t, "packages_form.add_spot")}
                    </a>
                  </div>
                )}
              </React.Fragment>
            )}

            {get(this.state, "__packageTypeMode") == 1 && (
              <React.Fragment>
                <FormInputEnableChip data={data} />
                <FormInputEnableScreenshotRestrictions data={data} />
              </React.Fragment>
            )}

            <Error
              __errors={get(this.state, "__errors")}
              eKey="bulk_start_at"
            />
            <Error __errors={get(this.state, "__errors")} eKey="spots.base" />
            <Error __errors={get(this.state, "__errors")} eKey="base" />

            <div className="AppForm__row">
              <button
                className={className("Button", {
                  "Button--large": true,
                  "Button--disabled": get(this.state, "__isLoading"),
                })}
                cypress-target="new-package-submit"
              >
                {get(this.state, "__isLoading")
                  ? get(t, "form.loading")
                  : get(this.props, "data.is_edit_package_page")
                  ? get(t, "packages_edit_submit")
                  : get(t, "packages_new_submit")}
              </button>

              {!get(this.props, "data.is_edit_package_page") && (
                <React.Fragment>
                  {get(this.props, "data.preview_packages_path") && (
                    <a
                      className="Button Button--large Button--white-purple"
                      onClick={() => {
                        this.setState(
                          {
                            __previewFormData: null,
                          },
                          () => {
                            const _formData = this.generateFormData({
                              els: this.$formStep2.current?.elements,
                            })

                            const _params = {}

                            _formData.forEach((value, key) => {
                              _params[key] = value
                            })

                            this.setState(
                              {
                                __previewFormData: _params,
                              },
                              () => {
                                window.open("", "previewWindow")
                                this.$formPreview?.current?.submit?.()
                              }
                            )
                          }
                        )
                      }}
                    >
                      {get(t, "packages_form.preview")}
                    </a>
                  )}

                  <a
                    className="Button Button--large Button--white-purple"
                    cypress-target="new-package-back"
                    onClick={() => {
                      this.setState({
                        __stepIndex: 0,
                      })
                    }}
                  >
                    {get(t, "form.back")}
                  </a>

                  {get(this.props, "data.draft_url") && (
                    <a
                      className="AppPackagesBulkNew__draft"
                      onClick={() => {
                        this.setState(
                          {
                            __saveAsDraft: true,
                          },
                          () => {
                            this.onSubmitStep2({
                              els: this.$formStep2?.current?.elements,
                            })
                          }
                        )
                      }}
                    >
                      {tt(t, "packages_form.save_draft")}
                    </a>
                  )}
                </React.Fragment>
              )}
            </div>

            {get(this.props, "data.is_edit_package_page") &&
              !get(this.props, "data.package_sold") && (
                <React.Fragment>
                  <div className="Buttons Buttons--jc-c">
                    <a
                      className="AppPackagesBulkNew__delete"
                      onClick={() => {
                        this.setState({
                          __isDeleting: true,
                        })
                      }}
                    >
                      {get(t, "packages_edit.delete")}
                    </a>
                  </div>

                  <div
                    className={className("ModalDeletePackage", {
                      "ModalDeletePackage--active": get(
                        this.state,
                        "__isDeleting"
                      ),
                    })}
                  >
                    <a
                      className="ModalDeletePackage__overlay"
                      onClick={() => {
                        this.setState({
                          __isDeleting: false,
                        })
                      }}
                    />

                    <div className="ModalDeletePackage__panel">
                      <h3 className="ModalDeletePackage__h3">
                        {tt(t, "packages_bulk_new.delete_modal.h3")}
                      </h3>

                      <p className="ModalDeletePackage__p">
                        {tt(t, "packages_bulk_new.delete_modal.p")}
                      </p>

                      <a
                        className={className("Button Button--red", {
                          "Button--disabled": get(this.state, "__isLoading"),
                        })}
                        cypress-target="package-delete"
                        onClick={() => {
                          this.setState(
                            {
                              __isLoading: true,
                            },
                            () => {
                              axios
                                .delete(get(this.props, "data.package_path"))
                                .then((res: any) => {
                                  window.location.href = get(
                                    res,
                                    "data.redirect_url"
                                  )
                                })
                                .catch(() => {
                                  window.location.reload()
                                })
                            }
                          )
                        }}
                      >
                        {tt(t, "packages_bulk_new.delete_modal.submit")}
                      </a>

                      <a
                        className="ModalDeletePackage__cancel"
                        onClick={() => {
                          this.setState({
                            __isDeleting: false,
                          })
                        }}
                      >
                        {tt(t, "packages_bulk_new.delete_modal.cancel")}
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </form>
        </div>

        {get(this.props, "data.preview_packages_path") && (
          <form
            className="AppPackagesPreview"
            ref={this.$formPreview}
            target="previewWindow"
            method="post"
            action={get(this.props, "data.preview_packages_path")}
          >
            {this.state.__previewFormData && (
              <React.Fragment>
                {entries(this.state.__previewFormData)?.map?.((x, i) => (
                  <input key={i} name={x?.[0]} defaultValue={x?.[1]} />
                ))}
              </React.Fragment>
            )}
          </form>
        )}
      </React.Fragment>
    )
  }
}

export default NewAndEditPackage

const Error = ({__errors, eKey}: any) => {
  return (
    <React.Fragment>
      {get(__errors, eKey) && (
        <div className="attr_errors">
          <p className="attr_errors__error">{get(__errors, eKey)}</p>
        </div>
      )}
    </React.Fragment>
  )
}

const Label = ({t, tKey, text, required = true}: any) => {
  return (
    <div className="AppForm__label">
      <span className="AppForm__label__span">{text ? text : tt(t, tKey)}</span>

      {required && (
        <span className="AppForm__label__required">
          {get(t, "form.required")}
        </span>
      )}
    </div>
  )
}

const FormInputEnableChip = ({data}: any) => {
  return (
    <React.Fragment>
      {get(data, "agency_setting.enable_chip") && (
        <div className="AppForm__checkbox">
          <input
            type="checkbox"
            className="AppForm__checkbox__input"
            name="allow_chip"
            id="allow_chip"
            defaultChecked={get(data, "package.allow_chip", true)}
          />

          <div className="AppForm__checkbox__wrapper">
            <label className="AppForm__checkbox__label" htmlFor="allow_chip">
              {get(data, "t.packages_form.chip")}

              <a
                className="AppForm__checkbox__help"
                href="https://www.notion.so/b8528611a51949bfad12d3b8fa271f9d"
                target="_blank"
                rel="nofollow noopener noreferrer"
              />
            </label>

            <p className="AppForm__checkbox__p">
              {get(data, "t.packages_form.chip_detail")}
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const FormInputEnableScreenshotRestrictions = ({data}: any) => {
  return (
    <React.Fragment>
      {get(data, "agency_setting.enable_screenshot_restrictions") && (
        <div className="AppForm__checkbox">
          <input
            type="checkbox"
            className="AppForm__checkbox__input"
            name="screenshot_restrictions"
            id="screenshot_restrictions"
            defaultChecked={get(data, "package.screenshot_restrictions")}
          />

          <div className="AppForm__checkbox__wrapper">
            <label
              className="AppForm__checkbox__label"
              htmlFor="screenshot_restrictions"
            >
              {get(data, "t.packages_form.screenshot_restrictions")}

              <a
                className="AppForm__checkbox__help"
                href="https://www.notion.so/61720fab65274e199391af28d7976ae2"
                target="_blank"
                rel="nofollow noopener noreferrer"
              />
            </label>

            <p className="AppForm__checkbox__p">
              {get(data, "t.packages_form.screenshot_restrictions_description")}
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const ModalNewAndEditPackageProduct = ({
  __masterProducts,
  __masterProductsSelected,
  __isActiveModal,
  setState,
  t,
  master_products_path,
  suggestion_master_products_path,
}) => {
  const [__search, __searchSet] = React.useState("")
  const [__searchResult, __searchResultSet] = React.useState<MasterProduct[]>(
    []
  )

  const [__isLoading, __isLoadingSet] = React.useState(false)
  const [__isLoadingSearch, __isLoadingSearchSet] = React.useState(false)

  const ___searchTO = React.useRef<number>()

  const load = () => {
    __isLoadingSet(true)

    axios
      .get(master_products_path, {
        page: Number(__masterProducts.meta?.page || 0) + 1,
      })
      .then((res: any) => {
        setState({
          __masterProducts: {
            data: uniqBy(
              [...__masterProducts.data, ...res?.data?.master_products],
              (x: MasterProduct) => x.id
            ),
            meta: res?.data?.meta,
          },
        })
      })
      .finally(() => {
        __isLoadingSet(false)
      })
  }

  React.useEffect(() => {
    __searchResultSet([])
    window.clearTimeout(___searchTO.current)

    if (__search.length > 0) {
      __isLoadingSearchSet(true)

      ___searchTO.current = window.setTimeout(() => {
        axios
          .get(suggestion_master_products_path, {
            master_product_name: __search,
          })
          .then((res: any) => {
            const _masterProducts: MasterProduct[] = res?.data?.map(
              (x: any) => {
                return {
                  id: x?.[0],
                  name: x?.[1],
                }
              }
            )

            __searchResultSet(_masterProducts)

            // Also add search results to `__masterProducts`,
            // so if a user adds any searched results, we can show them properly.
            setState({
              __masterProducts: {
                ...__masterProducts,
                data: uniqBy(
                  [...__masterProducts.data, ..._masterProducts],
                  (x: MasterProduct) => x.id
                ),
              },
            })
          })
          .finally(() => {
            __isLoadingSearchSet(false)
          })
      }, 1000)
    }
  }, [__search])

  React.useEffect(() => {
    load()
  }, [])

  // Move this out of `<form />`, so pressing Enter
  // won't cause unintended form submission.
  return ReactDOM.createPortal(
    <div
      className={className("ModalNewAndEditPackageProduct", {
        "ModalNewAndEditPackageProduct--active": __isActiveModal,
      })}
    >
      <a
        className="ModalNewAndEditPackageProduct__overlay"
        onClick={() => {
          setState({
            __isActiveModal: false,
          })
        }}
      />

      <div className="ModalNewAndEditPackageProduct__panel">
        <div className="ModalNewAndEditPackageProduct__panel__header">
          <h4 className="ModalNewAndEditPackageProduct__panel__header__h4">
            {tt(t, "products.title")}
          </h4>

          <a
            className="ModalNewAndEditPackageProduct__panel__header__close"
            onClick={() => {
              setState({
                __isActiveModal: false,
              })
            }}
          />
        </div>

        <div className="ModalNewAndEditPackageProduct__panel__body">
          <input
            type="text"
            className="ModalNewAndEditPackageProduct__panel__body__search"
            placeholder={tt(t, "products.search_placeholder")}
            value={__search}
            onChange={(e) => {
              __searchSet(e?.target?.value)
            }}
          />

          <dl className="ModalNewAndEditPackageProduct__panel__body__dl">
            {(() => {
              const _masterProducts =
                __search?.length == 0
                  ? __masterProducts.data || []
                  : __searchResult

              return (
                <React.Fragment>
                  {_masterProducts.map((x: MasterProduct, i: number) => {
                    const _isSelected = __masterProductsSelected?.find(
                      (y: MasterProductSelected) => y.master_product_id == x.id
                    )

                    return (
                      <div
                        className="ModalNewAndEditPackageProduct__panel__body__dl__item"
                        key={i}
                      >
                        <dt className="ModalNewAndEditPackageProduct__panel__body__dl__item__dt">
                          {x.name}
                        </dt>

                        <a
                          className={className("Button Button--small", {
                            "Button--white-purple": _isSelected,
                          })}
                          onClick={() => {
                            const _masterProductsSelected: MasterProductSelected =
                              {
                                _id: +new Date(),
                                master_product_id: x.id,
                                amount: 1,
                              }

                            setState({
                              __masterProductsSelected: _isSelected
                                ? __masterProductsSelected?.filter(
                                    (y: MasterProductSelected) =>
                                      y.master_product_id != x.id
                                  )
                                : [
                                    ...__masterProductsSelected,
                                    _masterProductsSelected,
                                  ],
                            })
                          }}
                        >
                          {_isSelected
                            ? tt(t, "form.selected")
                            : tt(t, "form.select")}
                        </a>
                      </div>
                    )
                  })}
                </React.Fragment>
              )
            })()}

            {__search?.length > 0 && __isLoadingSearch && (
              <p className="ModalNewAndEditPackageProduct__panel__body__dl__p">
                Loading …
              </p>
            )}

            {__search?.length > 0 &&
              !__isLoadingSearch &&
              __searchResult?.length == 0 && (
                <p className="ModalNewAndEditPackageProduct__panel__body__dl__p">
                  No result.
                </p>
              )}

            {__search?.length == 0 &&
              __masterProducts.data?.length < __masterProducts.meta?.total && (
                <a
                  className={className(
                    "ModalNewAndEditPackageProduct__panel__body__dl__a",
                    {
                      "ModalNewAndEditPackageProduct__panel__body__dl__a--disabled":
                        __isLoading,
                    }
                  )}
                  onClick={() => {
                    load()
                  }}
                >
                  {__isLoading ? "Loading …" : tt(t, "load_more")}
                </a>
              )}
          </dl>
        </div>
      </div>
    </div>,
    document.querySelector("#modals")
  )
}
