import React from "react"
import PropTypes from "prop-types"
import {get, includes} from "lodash"
import className from "classnames"

import {axios, tt, stripeStyle, PAYMENTS_KONBINI_KEY} from "@lib/helpers"

class AppPurchaseRecord extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __pointDiff:
      get(this.props, "data.user_has_points") -
      get(this.props, "data.recording_fee"),
  }

  ___stripe = Stripe(get(this.props, "data.stripe_public_key"), {
    locale: get(this.props, "data.locale"),
  })

  ___cardElement = null

  book = () => {
    axios
      .post(get(this.props, "data.purchase_recording_booking_path"))
      .finally(() => {
        window.location.reload()
      })
  }

  purchasePoint = ({stripeData} = {}) => {
    this.setState(
      {
        __isLoading: true,
      },
      () => {
        let formData = {
          point_purchase: {
            point_to_purchase: Math.abs(get(this.state, "__pointDiff")),
          },
        }

        if (stripeData) {
          formData = {
            ...formData,
            ...stripeData,
          }
        }

        axios
          .post(get(this.props, "data.point_purchases_path"), formData)
          .then((res2) => {
            const id = get(res2, "data.data.id")

            const poller = () => {
              axios.get(`/point_purchases/${id}`).then((res3) => {
                if (includes(["success"], get(res3, "data.data.status"))) {
                  this.book()
                } else if (includes(["error"], get(res3, "data.data.status"))) {
                  window.location.reload()
                } else {
                  window.setTimeout(() => {
                    poller()
                  }, 1000)
                }
              })
            }

            poller()
          })
      }
    )
  }

  componentDidMount = () => {
    const elements = this.___stripe.elements()

    if (get(this.state, "__pointDiff") < 0) {
      if (!get(this.props, "data.default_payment_method_card")) {
        this.___cardElement = elements.create("card", {
          ...stripeStyle,
          hidePostalCode: true,
        })

        this.___cardElement.mount("#cardElement")

        this.___cardElement.on("change", (e) => {
          if (get(e, "error")) {
            this.setState({
              __cardElementErrors: get(e, "error.message"),
            })
          } else {
            this.setState({
              __cardElementErrors: null,
            })
          }
        })
      }
    }
  }

  render = () => {
    const {t, default_payment_method_card, payments_path} = get(
      this.props,
      "data"
    )

    return (
      <React.Fragment>
        <div className="AppPurchaseRecord__required-points">
          <p className="AppPurchaseRecord__required-points__p">
            {get(t, "current.required_points.p")}
          </p>

          <span className="AppPurchaseRecord__required-points__span">
            {get(this.props, "data.recording_fee")}pt
          </span>
        </div>

        {get(this.state, "__pointDiff") >= 0 ? (
          <form
            className="AppPurchaseRecord__buttons"
            onSubmit={(e) => {
              e.preventDefault()

              this.setState(
                {
                  __isLoading: true,
                },
                () => {
                  axios
                    .post(
                      get(this.props, "data.purchase_recording_booking_path")
                    )
                    .finally((e) => {
                      window.location.reload()
                    })
                }
              )
            }}
          >
            <Buttons
              t={t}
              __isLoading={get(this.state, "__isLoading")}
              btn_name={get(t, "current.submit")}
            />
          </form>
        ) : (
          <div className="AppPurchaseRecord__point">
            <h3 className="AppPurchaseRecord__point__h3">
              {get(t, "current.point.h3")}
            </h3>

            <p className="AppPurchaseRecord__point__p">
              {tt(t, "current.point.p", {
                point: get(this.props, "data.user_has_points"),
              })}
            </p>

            <p className="AppInput">
              {Math.abs(get(this.state, "__pointDiff"))}pt:{" "}
              {Math.abs(
                get(this.state, "__pointDiff") *
                  get(this.props, "data.point_rate")
              )}
              {get(t, "current.point.yen")}
            </p>

            <h3 className="AppPurchaseRecord__point__h3">
              {get(t, "current.point.payment_method")}
            </h3>

            {default_payment_method_card ? (
              <form
                className={className("AppForm", {
                  "AppForm--overlay": get(this.state, "__isLoading"),
                })}
                onSubmit={(e) => {
                  e.preventDefault()
                  this.purchasePoint()
                }}
              >
                <a
                  className="AppPayments__card AppPayments__card--single"
                  href={payments_path}
                >
                  <img
                    className="AppPayments__card__img"
                    src={get(default_payment_method_card, "img")}
                  />

                  <div className="AppPayments__card__right">
                    <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
                      <div className="AppPayments__card__right__top__wrapper">
                        <div className="AppPayments__card__right__top__number">
                          **** **** ****{" "}
                          {get(default_payment_method_card, "last4")}
                        </div>

                        <div className="AppPayments__card__right__top__brand">
                          {get(default_payment_method_card, "brand")}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

                <Buttons
                  t={t}
                  __isLoading={get(this.state, "__isLoading")}
                  btn_name={get(t, "current.submit")}
                />
              </form>
            ) : (
              <form
                className={className("AppForm", {
                  "AppForm--overlay": get(this.state, "__isLoading"),
                })}
                onSubmit={(e) => {
                  e.preventDefault()

                  this.setState(
                    {
                      __isLoading: true,
                    },
                    () => {
                      this.___stripe
                        .createToken(this.___cardElement)
                        .then((res) => {
                          const card_token = get(res, "token.id")

                          if (card_token) {
                            this.purchasePoint({
                              stripeData: {
                                stripe: {
                                  card_token,
                                },
                              },
                            })
                          } else {
                            this.setState({
                              __isLoading: false,
                            })
                          }
                        })
                    }
                  )
                }}
              >
                <div id="cardElement"></div>

                {get(this.state, "__cardElementErrors") && (
                  <div className="attr_errors">
                    <div className="attr_errors__error">
                      {get(this.state, "__cardElementErrors")}
                    </div>
                  </div>
                )}

                <Buttons
                  t={t}
                  __isLoading={get(this.state, "__isLoading")}
                  btn_name={get(t, "current.submit")}
                />
              </form>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default AppPurchaseRecord

const Buttons = ({t, __isLoading, btn_name}) => {
  return (
    <button className="Button Button--large" disabled={__isLoading}>
      {__isLoading ? get(t, "dictionary.loading") : btn_name}
    </button>
  )
}
