import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import moment from "moment"
import Countdown from "@components/molecules/Countdown"

type PropTypes = ComponentProps<typeof Countdown>

export default {
  title: "Molecules/Countdown",
  component: Countdown,
} as Meta

const Template: Story<PropTypes> = (args) => <Countdown {...args} />

export const Default = Template.bind({})
Default.args = {
  $$startAt: moment(),
  $$endAt: moment("2050-01-01"),
  current_time: undefined,
  hideAfterStart: false,
}
