import React from "react"
import get from "lodash/get"
import className from "classnames"
import * as __axios from "axios"

import {axios} from "@lib/helpers"

class AdminPublishersEditMemo extends React.Component {
  state = {
    __isActiveModal: false,
    memo: get(this.props, "data.memo"),
  }

  handleChange = (event) => {
    this.setState({memo: event.target.value})
  }

  changeMemo = (memo) => {
    axios
      .put(get(this.props, "paths"), {
        user: {
          memo: memo,
        },
      })
      .finally(() => {
        window.location.reload()
      })
  }

  render = () => {
    const publisher = get(this.props, "data.publisher")
    const t = get(this.props, "locale")
    return (
      <React.Fragment>
        <div className="Buttons">
          <a
            className="Button Button--small"
            onClick={() => {
              this.setState({
                __isActiveModal: true,
              })
            }}
          >
            {get(t, "memo")}
          </a>
        </div>
        <div
          className={className("ModalFormEditPublisherMemo", {
            "ModalFormEditPublisherMemo--active": get(
              this.state,
              "__isActiveModal"
            ),
          })}
        >
          <a
            className="ModalFormEditPublisherMemo__overlay"
            onClick={() => {
              this.setState({
                __isActiveModal: false,
              })
            }}
          />

          <div className="ModalFormEditPublisherMemo__panel">
            <h3 className="ModalFormEditPublisherMemo__panel__h3 ModalFormEditPublisherMemo__panel__h3--boarder">
              {get(t, "memo")}
            </h3>

            <div className="AppForm">
              <div className="Appform__row">
                <div className="Appform__label">
                  <span className="AppForm__label__span">メモの編集</span>
                  <br />
                  <textarea
                    className="Input"
                    name="memo"
                    rows="4"
                    defaultValue={get(this.props, "data.memo")}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <a
                className="Button Button--large"
                onClick={() => {
                  this.changeMemo(this.state.memo)
                }}
              >
                {get(t, "update_memo")}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AdminPublishersEditMemo
