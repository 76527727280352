import * as React from "react"
import classNames from "classnames"

type Option = {
  label: string
  value: string
}

type Action =
  | {type: "SEARCH"; value: string}
  | {type: "NAVIGATE"; option: Option}
  | {type: "CLEAR"}
  | {type: "SELECT_WITH_CLICK"; selected: Option}
  | {type: "SELECT_WITH_KEYBOARD"}
  | {type: "FOCUS"}
  | {type: "BLUR"}
  | {type: "ESCAPE"}

const SEARCH = "SEARCH"
const NAVIGATE = "NAVIGATE"
const CLEAR = "CLEAR"
const SELECT_WITH_CLICK = "SELECT_WITH_CLICK"
const SELECT_WITH_KEYBOARD = "SELECT_WITH_KEYBOARD"
const FOCUS = "FOCUS"
const BLUR = "BLUR"
const ESCAPE = "ESCAPE"

type State = {
  search: string
  selected: Option
  navigate: Option
  focus: boolean
}

const blankOption: Option = {label: "", value: ""}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case FOCUS:
      return {
        ...state,
        focus: true,
      }
    case SEARCH:
      return {
        ...state,
        search: action.value,
      }
    case BLUR:
    case ESCAPE:
      return {
        ...state,
        search: "",
        navigate: blankOption,
        focus: false,
      }
    case CLEAR:
      return {
        ...state,
        search: "",
        selected: blankOption,
        navigate: blankOption,
        focus: false,
      }
    case SELECT_WITH_CLICK:
      return {
        ...state,
        selected: action.selected,
        navigate: blankOption,
        focus: false,
      }
    case SELECT_WITH_KEYBOARD:
      return {
        ...state,
        selected: state.navigate,
        navigate: blankOption,
        focus: false,
      }
    case NAVIGATE:
      return {
        ...state,
        navigate: action.option,
      }
    default:
      return state
  }
}

type SearchableSelectProps = {
  options: Option[]
  placeholder: string
  name?: string
  id?: string
  noOptionMessage: string
  defaultValue?: string
}

const SearchableSelect = ({
  options,
  placeholder,
  name,
  id,
  noOptionMessage,
  defaultValue = "",
}: SearchableSelectProps): React.ReactElement => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      search: "",
      selected: {...blankOption},
      navigate: {...blankOption},
      focus: false,
    },
    (initialState) => {
      const option = options.find((o) => o.value === defaultValue)
      const selected = option ? option : initialState.selected

      return {
        ...initialState,
        selected,
      }
    }
  )

  const ref = React.useRef<HTMLDivElement>(null)

  // Handle click away
  React.useEffect(() => {
    const handler = (event) => {
      if (state.focus && ref.current && !ref.current.contains(event.target)) {
        dispatch({type: BLUR})
      }
    }

    document.addEventListener("click", handler, true)

    return () => {
      document.removeEventListener("click", handler, true)
    }
  }, [state.focus])

  // Handle click on label
  React.useEffect(() => {
    if (!id) {
      return
    }

    const $label = document.querySelector<HTMLLabelElement>(`label[for=${id}]`)
    const handler = (event: MouseEvent) => {
      event.stopPropagation()
      dispatch({type: FOCUS})
    }

    if ($label) {
      $label.addEventListener("click", handler)
    }

    return () => {
      if ($label) {
        $label.removeEventListener("click", handler)
      }
    }
  }, [id])

  const filterOptions = React.useMemo(() => {
    return options.filter((option) => {
      if (!state.search) {
        return true
      }

      return (
        option.label.toLowerCase().indexOf(state.search.toLocaleLowerCase()) >
        -1
      )
    })
  }, [options, state.search])

  const index = filterOptions.findIndex(
    ({value}) => value === state.navigate.value
  )

  const getNextOption = () => filterOptions[(index + 1) % filterOptions.length]
  const getPreviousOption = () =>
    filterOptions[(index - 1 + options.length) % filterOptions.length]

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "ArrowDown": {
        // Don't scroll the page
        event.preventDefault()
        const option = getNextOption()
        if (option) {
          dispatch({type: NAVIGATE, option})
        }
        break
      }

      case "ArrowUp": {
        // Don't scroll the page
        event.preventDefault()
        const option = getPreviousOption()
        if (option) {
          dispatch({type: NAVIGATE, option})
        }
        break
      }

      case "Escape":
        dispatch({type: ESCAPE})
        break

      case "Enter":
        event.preventDefault()
        if (state.navigate.value) {
          dispatch({type: SELECT_WITH_KEYBOARD})
        }
        break
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({type: SEARCH, value: event.target.value})
  }

  const renderOptions = () => {
    if (filterOptions.length === 0) {
      return (
        <li
          className="SearchableSelect__option SearchableSelect__option--no-option"
          tabIndex={-1}
        >
          {noOptionMessage}
        </li>
      )
    }

    return filterOptions.map((option) => (
      <Option
        key={option.value}
        onClick={(event) => {
          event.stopPropagation()
          dispatch({type: "SELECT_WITH_CLICK", selected: option})
        }}
        focused={option.value === state.navigate.value}
        selected={option.value === state.selected.value}
      >
        {option.label}
      </Option>
    ))
  }

  const {focus, selected} = state

  return (
    <div ref={ref} className="SearchableSelect">
      {name && <input type="hidden" name={name} value={selected.value} />}

      <div
        className={classNames({
          SearchableSelect__wrap: true,
          "SearchableSelect__wrap--focus": state.focus,
        })}
        onClick={() => dispatch({type: FOCUS})}
      >
        <div className="SearchableSelect__body">
          {focus ? (
            <React.Fragment>
              <input
                className="SearchableSelect__input"
                placeholder={selected.label || placeholder}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <IconClose
                className="SearchableSelect__icon"
                onClick={(event) => {
                  event.stopPropagation()
                  dispatch({type: "CLEAR"})
                }}
                cypress-target="clear-search"
              />
            </React.Fragment>
          ) : (
            <div className="SearchableSelect__value">
              {selected.label || placeholder}
            </div>
          )}
        </div>

        <IconChevronDown className="SearchableSelect__icon" />
      </div>

      {focus && (
        <ul className="SearchableSelect__options-list">{renderOptions()}</ul>
      )}
    </div>
  )
}

type OptionProps = {
  children: React.ReactNode
  focused?: boolean
  selected?: boolean
  onClick: (e: React.MouseEvent) => void
}

const Option = ({
  children,
  onClick,
  focused = false,
  selected = false,
}: OptionProps) => {
  const ref = React.useRef<HTMLLIElement>(null)

  React.useEffect(() => {
    if (focused && ref.current) {
      ref.current.scrollIntoView({block: "nearest"})
    }
  }, [focused])

  return (
    <li
      ref={ref}
      className={classNames({
        SearchableSelect__option: true,
        "SearchableSelect__option--selected": selected,
        "SearchableSelect__option--focused": focused,
      })}
      onClick={onClick}
      tabIndex={-1}
    >
      {children}
      {selected && <IconCheck className="SearchableSelect__icon" />}
    </li>
  )
}

type IconProps = {
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

const IconChevronDown = (props: IconProps) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
      <path strokeWidth="2" d="M19 9l-7 7-7-7" />
    </svg>
  )
}

const IconClose = (props: IconProps) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}

const IconCheck = (props: IconProps) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      />
    </svg>
  )
}

export default SearchableSelect
