import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"

import AppAlert from "@components/molecules/AppAlert"

type PropTypes = ComponentProps<typeof AppAlert>

export default {
  title: "Molecules/AppAlert",
  component: AppAlert,
} as Meta

const Template: Story<PropTypes> = (args) => <AppAlert {...args} />

export const Success = Template.bind({})
Success.args = {
  flash: [["success", "Card was brought."]],
  is_publisher_page: false,
}

export const Danger = Template.bind({})
Danger.args = {
  flash: [["danger", "Card was brought."]],
  is_publisher_page: true,
}
