import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"
import Loader from "@components/molecules/Loader"
import {axios} from "@lib/helpers"

type PropTypes = {
  text: string
  sns_src: string
  app_src: string
  mail_src: string
  t: any
}

const TalkCardShare: React.FC<PropTypes> = ({
  text,
  sns_src,
  app_src,
  mail_src,
  t,
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const [__isActive2Notice, __isActiveNoticeSet] = React.useState(false)
  const [__shortenUrl, __shortenUrlSet] = React.useState("")
  const [__showModal, __showModalSet] = React.useState(false)
  const [__qrcodeSrc, __qrcodeSrcSet] = React.useState("")

  /*
  // Disable this until our app supports dynamic links.
  // https://trello.com/c/05lRDzBF/1312-app-support-more-deep-link-paths-on-app?menu=filter&filter=member:phatodatawow
  const generateShortenUrl = async (url: string) => {
    return await axios.get("/shorten_words", {
      word: url,
    })
  }
  */

  const closeModal = () => {
    __showModalSet(false)
  }

  return (
    <div className="TalkCard__share">
      <div className="TalkCard__share__link">
        <a
          className="TalkCard__share__handle"
          onClick={async () => {
            __isActiveSet(true)

            __shortenUrlSet(sns_src)

            // Disable this until our app supports dynamic links.
            // https://trello.com/c/05lRDzBF/1312-app-support-more-deep-link-paths-on-app?menu=filter&filter=member:phatodatawow
            // const response = await generateShortenUrl(sns_src)
            // __shortenUrlSet(response.data.shorten_word)
          }}
        >
          {get(t, "share_to_SNS")}
        </a>
        <a
          className={className("TalkCard__share__overlay", {
            "TalkCard__share__overlay--active": __isActive,
          })}
          onClick={() => {
            __isActiveSet(false)
          }}
        />

        <div
          className={className("TalkCard__share__panel", {
            "TalkCard__share__panel--active": __isActive,
          })}
        >
          {__shortenUrl ? (
            <React.Fragment>
              <a
                className="TalkCard__share__panel__a"
                href={`http://twitter.com/intent/tweet?text=${text}&url=${__shortenUrl}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Twitter
              </a>
              <a
                className="TalkCard__share__panel__a"
                href={`https://social-plugins.line.me/lineit/share?url=${__shortenUrl}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                LINE
              </a>
              <a
                className="TalkCard__share__panel__a"
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => {
                  document.addEventListener(
                    "copy",
                    (e) => {
                      e.preventDefault()
                      e.clipboardData?.setData("text/plain", __shortenUrl)
                    },
                    {once: true}
                  )
                  document.execCommand("copy")
                  alert("コピーしました")
                }}
              >
                URLをコピー
              </a>
              <a
                className="TalkCard__share__panel__a"
                onClick={async (e) => {
                  e.preventDefault()
                  __showModalSet(true)
                  if (!__qrcodeSrc) {
                    const {data} = await axios.get("/qrcodes", {
                      link: __shortenUrl,
                    })
                    const svgUrl = URL.createObjectURL(
                      new Blob([data.qrcode], {type: "image/svg+xml"})
                    )
                    __qrcodeSrcSet(svgUrl)
                  }
                }}
              >
                QRコードを表示
              </a>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </div>

      <div className="TalkCard__share__link">
        <a
          className="TalkCard__share__handle"
          onClick={() => {
            __isActiveNoticeSet(true)
          }}
        >
          {get(t, "send_notice")}
        </a>

        <a
          className={className("TalkCard__share__overlay", {
            "TalkCard__share__overlay--active": __isActive2Notice,
          })}
          onClick={() => {
            __isActiveNoticeSet(false)
          }}
        />

        <div
          className={className("TalkCard__share__panel", {
            "TalkCard__share__panel--active": __isActive2Notice,
          })}
        >
          <a
            className="TalkCard__share__panel__a"
            href={app_src}
            rel="nofollow noopener noreferrer"
          >
            アプリで通知
          </a>
          <a
            className="TalkCard__share__panel__a"
            href={mail_src}
            rel="nofollow noopener noreferrer"
          >
            メールで通知
          </a>
        </div>
      </div>

      <div
        className={className("ModalFormInputProduct", {
          "ModalFormInputProduct--active": Boolean(__showModal),
        })}
      >
        <a className="ModalFormInputProduct__overlay" onClick={closeModal} />

        <div className="ModalFormInputProduct__panel">
          <button
            className="ModalFormInputProduct__close-button"
            onClick={closeModal}
          >
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <h3 className="ModalFormInputProduct__panel__h3 ModalFormInputProduct__panel__h3--border">
            QRコード
          </h3>

          {__qrcodeSrc ? (
            <React.Fragment>
              <img
                className="ModalFormInputProduct__qrcode"
                src={__qrcodeSrc}
                alt="QR Code"
              />

              <a className="Button" href={__qrcodeSrc} download>
                QRコードを保存
              </a>
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  )
}

export default TalkCardShare
