import React from "react"
import {toHalfWidth} from "@lib/helpers"

type PropTypes = Partial<{
  id: string
  value: string
  name: string
  placeholder: string
  rows: number
}>

const FormInputTextarea: React.FC<PropTypes> = ({
  id,
  value,
  name,
  placeholder,
  rows = 4,
}) => {
  return (
    <textarea
      id={id}
      className="Input"
      name={name}
      defaultValue={value}
      onBlur={(e) => {
        e.target.value = toHalfWidth(e.target.value)
      }}
      placeholder={placeholder}
      rows={rows}
    />
  )
}

export default FormInputTextarea
