import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const BulkNewInputPoint = ({value, errors, enable_freecall}) => {
  const [__value, __valueSet] = React.useState(value)

  return (
    <React.Fragment>
      <div className="AppForm__value__duration__col__wrapper">
        <input
          className={className("AppInput", {
            "AppInput--error": errors,
          })}
          type="number"
          name="package[bulk_points]"
          value={__value}
          onChange={(e) => {
            __valueSet(get(e, "target.value"))
          }}
        />

        <span className="AppForm__value__duration__span">pt</span>
      </div>

      {errors && (
        <div
          className="AppForm__value__errors"
          dangerouslySetInnerHTML={{__html: errors}}
        />
      )}

      {(() => {
        let text = `※ ${(Number(__value) * 100).toLocaleString()}円です。`
        let isError = false

        if (__value < 0) {
          isError = true
          if (enable_freecall) {
            text = "※ 0pt以上で設定してください"
          } else {
            text = "※ 1pt以上で設定してください"
          }
        } else if (__value == 0) {
          if (enable_freecall) {
            text = "※ 無料です"
          } else {
            isError = true
            text = "※ 1pt以上で設定してください"
          }
        }

        return (
          <p
            className={className("AppForm__value__duration__p", {
              "AppForm__value__duration__p--red": isError,
            })}
          >
            {text}
          </p>
        )
      })()}
    </React.Fragment>
  )
}

BulkNewInputPoint.propTypes = {}

export default BulkNewInputPoint
