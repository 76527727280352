import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const AppBookingsNewFlow = ({t}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const $el = React.useRef()

  React.useEffect(() => {
    const $content = $el.current.parentNode.parentNode.querySelector(
      ".AppBookingsNew__flow__content"
    )

    if (__isActive) {
      $content.classList.add("AppBookingsNew__flow__content--active")
    } else {
      $content.classList.remove("AppBookingsNew__flow__content--active")
    }
  }, [__isActive])

  return (
    <div className="AppBookingsNew__flow__actions" ref={$el}>
      <a
        className="AppBookingsNew__flow__actions__a"
        onClick={() => {
          __isActiveSet(!__isActive)
        }}
      >
        {__isActive ? get(t, "close") : get(t, "open")}
      </a>
    </div>
  )
}

AppBookingsNewFlow.propTypes = {}

export default AppBookingsNewFlow
