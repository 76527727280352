import React from "react"

type defaultCardProps = {
  path: string
  img: string
  last4: string
  brand: string
}

const DefaultCardLink = ({path, img, last4, brand}: defaultCardProps) => {
  return (
    <a className="AppPayments__card AppPayments__card--single" href={path}>
      <img className="AppPayments__card__img" src={img} />

      <div className="AppPayments__card__right">
        <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
          <div className="AppPayments__card__right__top__wrapper">
            <div className="AppPayments__card__right__top__number">
              **** **** **** {last4}
            </div>

            <div className="AppPayments__card__right__top__brand">{brand}</div>
          </div>
        </div>
      </div>
    </a>
  )
}

export default DefaultCardLink
