import React from "react"
// import PropTypes from "prop-types"
import get from "lodash/get"
import filter from "lodash/filter"
import find from "lodash/find"
import omitBy from "lodash/omitBy"
import max from "lodash/max"
import className from "classnames"
import "agora-rtc-sdk-ng"
import Cookies from "js-cookie"
import MobileDetect from "mobile-detect"

import {
  VIDEO_DEVICE_ID_KEY,
  AUDIO_DEVICE_ID_KEY,
  VIDEO_FLIP_X_KEY,
  beautyEffectOptions,
} from "@lib/helpers"

class AppBookingsTalk extends React.Component {
  static propTypes = {}

  static defaultProps = {}
  client = AgoraRTC.createClient({mode: "live", codec: "h264"})

  ___drawRef = React.createRef()
  $volumeMeter = React.createRef()

  state = {
    selfStream: null,
    selfVideo: null,
    selfAudio: null,
    isActivePanelAudio: false,
    isActivePanelVideo: false,
    audioinputs: null,
    audioinputCurrent: Cookies.get(AUDIO_DEVICE_ID_KEY)
      ? Cookies.get(AUDIO_DEVICE_ID_KEY)
      : null,
    videoinputs: null,
    videoinputCurrent: Cookies.get(VIDEO_DEVICE_ID_KEY)
      ? Cookies.get(VIDEO_DEVICE_ID_KEY)
      : null,
    MobileDetect: new MobileDetect(window.navigator.userAgent),
    isFlippedX: Cookies.get(VIDEO_FLIP_X_KEY) ? true : false,
  }

  isMobile = () => {
    return (
      get(this.state, "MobileDetect") &&
      get(this.state, "MobileDetect").mobile()
    )
  }

  playSelfStream = () => {
    window.navigator.mediaDevices
      .getUserMedia({
        audio: {
          deviceId: get(this.state, "audioinputCurrent")
            ? {
                exact: get(this.state, "audioinputCurrent"),
              }
            : undefined,
        },
        video: {
          deviceId: get(this.state, "videoinputCurrent")
            ? {
                exact: get(this.state, "videoinputCurrent"),
              }
            : undefined,
        },
      })
      .then(async (mediaStream) => {
        const audioMediaStreamTrack = mediaStream.getAudioTracks()[0]
        const videoMediaStreamTrack = mediaStream.getVideoTracks()[0]
        let localAudio = null
        let localVideo = null
        if (videoMediaStreamTrack.label.startsWith("OBS")) {
          localAudio = await AgoraRTC.createCustomAudioTrack({
            mediaStreamTrack: audioMediaStreamTrack,
          })
          localVideo = await AgoraRTC.createCustomVideoTrack({
            mediaStreamTrack: videoMediaStreamTrack,
          })
        } else {
          localAudio = await AgoraRTC.createMicrophoneAudioTrack()
          localVideo = await AgoraRTC.createCameraVideoTrack()
        }
        this.setState({selfVideo: localVideo, selfAudio: localAudio}, () => {
          document.getElementById("videoArea").innerHTML = ""
          localVideo.play("videoArea", {fit: "cover"})
        })

        const canvasWidth = this.$volumeMeter.current.width
        const canvasHeight = this.$volumeMeter.current.height
        const canvasCtx = this.$volumeMeter.current.getContext("2d")

        // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createAnalyser
        const audioCtx = new (window.AudioContext ||
          window.webkitAudioContext)()

        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 1024 // Any less than this makes the meter fickle. https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
        analyser.smoothingTimeConstant = 1
        const source = audioCtx.createMediaStreamSource(mediaStream)

        source.connect(analyser)

        const dataArray = new Uint8Array(analyser.frequencyBinCount)

        const draw = () => {
          analyser.getByteTimeDomainData(dataArray)

          // `getByteTimeDomainData` will get the whole waveform,
          // which we'd take the maximum value of it, treating the value as a peak of the wave.
          //
          // 255 is the maximum value of Uint8Array, so 127 being a centre,
          // which I'm guessing that equals to interger 0.
          //
          // In other words, if the environment is quiet, _max will be 127-128.
          // https://javascript.info/arraybuffer-binary-arrays
          const _max = (Math.max(max(dataArray) - 128, 0) * 2) / 255

          canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight)
          canvasCtx.fillStyle = "#5458e4"
          canvasCtx.fillRect(0, 0, canvasWidth * _max, canvasHeight)

          this.___drawRef.current = window.requestAnimationFrame(draw)
        }

        draw()
      })
      .catch(() => {
        if (
          Cookies.get(AUDIO_DEVICE_ID_KEY) ||
          Cookies.get(VIDEO_DEVICE_ID_KEY)
        ) {
          // A camera or microphone failed to start.
          // It can be because the device is unavailable or removed.
          // We then make an attempt to reset to default device ids.
          Cookies.remove(AUDIO_DEVICE_ID_KEY)
          Cookies.remove(VIDEO_DEVICE_ID_KEY)
          window.location.reload()
        }
      })
  }

  componentDidMount = () => {
    if (this.isMobile()) {
      return
    }

    window.navigator.mediaDevices
      .getUserMedia({audio: true, video: true})
      .finally(() => {
        window.navigator.mediaDevices
          .enumerateDevices()
          .then((mediaDevices) => {
            const videoinputs = filter(
              mediaDevices,
              (x) => get(x, "kind") == "videoinput"
            )

            const audioinputs = filter(
              mediaDevices,
              (x) => get(x, "kind") == "audioinput"
            )

            this.setState(
              (prevState) => ({
                videoinputs,
                videoinputCurrent: get(prevState, "videoinputCurrent")
                  ? get(prevState, "videoinputCurrent")
                  : get(videoinputs, "0.deviceId"),
                audioinputs,
                audioinputCurrent: get(prevState, "audioinputCurrent")
                  ? get(prevState, "audioinputCurrent")
                  : get(audioinputs, "0.deviceId"),
              }),
              async () => {
                const {appId, channelKey, token, uid} = get(this.props, "data")
                try {
                  const uid1 = await this.client.join(
                    appId,
                    channelKey,
                    token,
                    Number(uid)
                  )
                  console.log("join success")

                  this.playSelfStream()
                } catch (e) {
                  console.log("join failed", e)
                }
              }
            )
          })
      })
  }

  render = () => {
    const {t} = get(this.props, "data")

    if (this.isMobile()) {
      return null
    }

    return (
      <div className="AppBookingsShow__preview">
        <h3 className="AppBookingsShow__preview__h3">
          {get(t, "current.preview.title")}
        </h3>

        <div className="AppBookingsShow__preview__content">
          <div className="AppBookingsShow__preview__boxes">
            {[
              {
                h4: get(t, "current.preview.microphone.h4"),
                p: get(
                  find(
                    get(this.state, "audioinputs"),
                    (x) =>
                      get(x, "deviceId") == get(this.state, "audioinputCurrent")
                  ),
                  "label"
                ),
                h5: get(t, "current.preview.microphone.h5"),
                keyPanel: "isActivePanelAudio",
                keyInputs: "audioinputs",
                keyInputCurrent: "audioinputCurrent",
                keyCookie: AUDIO_DEVICE_ID_KEY,
                volumeMeter: (
                  <div className="AppBookingsShow__preview__box__volume-meter">
                    <h5 className="AppBookingsShow__preview__box__volume-meter__h5">
                      Volume
                    </h5>

                    <div className="AppBookingsShow__preview__box__volume-meter__bar">
                      <div className="AppBookingsShow__preview__box__volume-meter__bar__overlay">
                        {Array.from(Array(31)).map((_, i) => (
                          <div
                            className="AppBookingsShow__preview__box__volume-meter__bar__overlay__space"
                            key={i}
                          ></div>
                        ))}
                      </div>

                      <canvas
                        className="AppBookingsShow__preview__box__volume-meter__bar__canvas"
                        height="30"
                        ref={this.$volumeMeter}
                      />
                    </div>
                  </div>
                ),
              },
              {
                h4: get(t, "current.preview.camera.h4"),
                p: get(
                  find(
                    get(this.state, "videoinputs"),
                    (x) =>
                      get(x, "deviceId") == get(this.state, "videoinputCurrent")
                  ),
                  "label"
                ),
                h5: get(t, "current.preview.camera.h5"),
                keyPanel: "isActivePanelVideo",
                keyInputs: "videoinputs",
                keyInputCurrent: "videoinputCurrent",
                keyCookie: VIDEO_DEVICE_ID_KEY,
              },
            ].map((x, i) => (
              <div className="AppBookingsShow__preview__box" key={i}>
                <a
                  className="AppBookingsShow__preview__box__a"
                  onClick={() => {
                    this.setState({
                      [get(x, "keyPanel")]: true,
                    })
                  }}
                >
                  <div className="AppBookingsShow__preview__box__top">
                    <h4 className="AppBookingsShow__preview__box__h4">
                      {get(x, "h4")}
                    </h4>

                    <p className="AppBookingsShow__preview__box__p">
                      <span className="AppBookingsShow__preview__box__p__span">
                        {get(x, "p")}
                      </span>
                    </p>
                  </div>
                </a>

                {get(x, "volumeMeter") && get(x, "volumeMeter")}

                <div
                  className={className("AppBookingsShow__preview__box__panel", {
                    "AppBookingsShow__preview__box__panel--active": get(
                      this.state,
                      get(x, "keyPanel")
                    ),
                  })}
                >
                  <a
                    className="AppBookingsShow__preview__box__panel__overlay"
                    onClick={() => {
                      this.setState({
                        [get(x, "keyPanel")]: false,
                      })
                    }}
                  />

                  <div className="AppBookingsShow__preview__box__panel__container">
                    <div className="AppBookingsShow__preview__box__panel__panel">
                      <h5 className="AppBookingsShow__preview__box__panel__h5">
                        {get(x, "h5")}
                      </h5>

                      <div className="AppBookingsShow__preview__box__panel__links">
                        {(get(this.state, get(x, "keyInputs")) || []).map(
                          (y, j) => (
                            <a
                              key={j}
                              className={className(
                                "AppBookingsShow__preview__box__panel__links__a",
                                {
                                  "AppBookingsShow__preview__box__panel__links__a--active":
                                    get(y, "deviceId") ==
                                    get(this.state, get(x, "keyInputCurrent")),
                                }
                              )}
                              onClick={() => {
                                this.setState(
                                  {
                                    [get(x, "keyInputCurrent")]: get(
                                      y,
                                      "deviceId"
                                    ),
                                    [get(x, "keyPanel")]: false,
                                  },
                                  () => {
                                    Cookies.set(
                                      get(x, "keyCookie"),
                                      get(y, "deviceId")
                                    )

                                    window.setTimeout(() => {
                                      this.playSelfStream()
                                    }, 1000)
                                  }
                                )
                              }}
                            >
                              <span className="AppBookingsShow__preview__box__panel__links__a__span">
                                {get(y, "label")}
                              </span>
                            </a>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Disable flip function per https://trello.com/c/2B1dfR1u/1141-flip-horizontally-is-not-working-during-taking-on-web-call#comment-5fbca27d739f1c4b4ed62e69. */}
            {false && (
              <div className="AppBookingsShow__preview__box">
                <div className="AppBookingsShow__preview__box__top">
                  <h4 className="AppBookingsShow__preview__box__h4">
                    {get(this.props, "data.t.current.preview.flip.horizontal")}
                  </h4>

                  <input
                    type="checkbox"
                    className="AppForm__checkbox__input"
                    checked={get(this.state, "isFlippedX") || ""}
                    onChange={() => {
                      this.setState(
                        (prevState) => ({
                          isFlippedX: !prevState.isFlippedX,
                        }),
                        () => {
                          if (get(this.state, "isFlippedX")) {
                            Cookies.set(VIDEO_FLIP_X_KEY, 1)
                          } else {
                            Cookies.remove(VIDEO_FLIP_X_KEY)
                          }
                        }
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="AppBookingsShow__preview__video">
            <div className="AppBookingsShow__preview__video__wrapper">
              <svg
                className="AppBookingsShow__preview__video__svg"
                viewBox="0 0 1 1"
              />

              <div
                id="videoArea"
                className={className(
                  "AppBookingsShow__preview__video__video-area",
                  {
                    "AppBookingsShow__preview__video__video-area--flipped-x":
                      get(this.state, "isFlippedX"),
                  }
                )}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AppBookingsTalk
