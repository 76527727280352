import React from "react"
import PropTypes from "prop-types"
import className from "classnames"
import Loader from "./Loader"

type PropTypes = {
  show: boolean
  title: string
  onClose: () => void
  isLoading?: boolean
}

const ModalMenus: React.FC<PropTypes> = ({
  show,
  title,
  onClose,
  children,
  isLoading,
}) => {
  return (
    <div
      className={className("ModalMenus", {
        "ModalMenus--active": show,
      })}
    >
      <a className="ModalMenus__overlay" onClick={onClose} />

      <div className="ModalMenus__container">
        <div className="ModalMenus__header ModalMenus__header--border-bottom">
          <button
            className="ModalFormInputProduct__close-button"
            onClick={onClose}
          >
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h3 className="ModalMenus__title">{title}</h3>
        </div>

        <div className="ModalMenus__body">
          {isLoading ? <Loader /> : children}
        </div>
      </div>
    </div>
  )
}

export default ModalMenus
