import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputPostalCode from "@components/molecules/FormInputPostalCode"
import settingsLocale from "@locales/views/settings/ja.yml"

type PropTypes = ComponentProps<typeof FormInputPostalCode>

export default {
  title: "Molecules/FormInputPostalCode",
  component: FormInputPostalCode,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputPostalCode {...args} />

export const Default = Template.bind({})
Default.args = {
  value: "",
  name: "name",
  maxlength: 8,
  placeholder: "100-0002",
  data: {
    address_from_postcode_settings_user_path: "",
    t: {
      current: settingsLocale.ja.settings.users.edit_address,
    },
  },
}
