import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FeatureAnnouncementLink from "@components/molecules/FeatureAnnouncementLink"

type PropTypes = ComponentProps<typeof FeatureAnnouncementLink>

export default {
  title: "Molecules/FeatureAnnouncementLink",
  component: FeatureAnnouncementLink,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <FeatureAnnouncementLink {...args}>詳細はこちら</FeatureAnnouncementLink>
)

export const Default = Template.bind({})
Default.args = {
  url: "example.com",
  feature_id: "0",
}
