import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios} from "@lib/helpers"

const AdminAgenciesIndexMinimumFee = ({t, data}) => {
  const [__isChecked, __isCheckedSet] = React.useState(
    get(data, "enable_minimum_fee")
  )
  const [__value, __valueSet] = React.useState(get(data, "minimum_fee"))
  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <React.Fragment>
      <label className="CheckBox">
        <a
          disabled={__isLoading}
          className={className("CheckBox__input", {
            "CheckBox__input--checked": __isChecked,
          })}
          onClick={(e) => {
            __isLoadingSet(true)

            axios
              .put(`/admin/agencies/${get(data, "id")}/update_setting`, {
                setting: {
                  enable_minimum_fee: !__isChecked,
                },
              })
              .then((res) => {
                if (get(res, "data.result") == "success") {
                  __isCheckedSet(!__isChecked)
                }
              })
              .finally(() => {
                __isLoadingSet(false)
              })
          }}
        />
        <span className="CheckBox__span">
          {get(t, "page.minimum_fee_label")}
        </span>
      </label>

      <p className="CheckBox__p">{get(t, "page.minimum_fee_description")}</p>

      {__isChecked && (
        <form
          className="AdminAgenciesSettingsShow__select"
          onSubmit={(e) => {
            e.preventDefault()

            __isLoadingSet(true)

            axios
              .put(`/admin/agencies/${get(data, "id")}/update_setting`, {
                setting: {
                  minimum_fee: __value,
                },
              })
              .finally(() => {
                __isLoadingSet(false)
              })
          }}
        >
          <input
            type="number"
            className="Input"
            value={__value}
            min={0}
            onChange={(e) => {
              __valueSet(get(e, "target.value"))
            }}
          />

          <button className="Button">
            {get(t, "page.change_button_label")}
          </button>
        </form>
      )}
    </React.Fragment>
  )
}

AdminAgenciesIndexMinimumFee.propTypes = {}

export default AdminAgenciesIndexMinimumFee
