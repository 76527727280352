import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import placeholder from "@images/common/defaults/cover.png"

const FormInputImage = ({image, is_cover}) => {
  const $el = React.useRef()
  const [__file, __fileSet] = React.useState()

  React.useEffect(() => {
    // Find its parent `.AppForm__value__file`, then find a file input.
    const $input =
      $el.current.parentNode.parentNode.querySelector('input[type="file"]')

    if ($input) {
      $input.addEventListener("change", (e) => {
        __fileSet(get(e, "target.files.0"))
      })
    }
  }, [])

  const src = __file
    ? window.URL.createObjectURL(__file)
    : image
    ? image
    : placeholder

  return (
    <img
      ref={$el}
      src={src}
      className={className("AppForm__value__profile-image__img", {
        "AppForm__value__profile-image__img--cover": is_cover,
      })}
    />
  )
}

FormInputImage.propTypes = {}

export default FormInputImage
