import * as React from "react"
import className from "classnames"
import countryCodes from "country-codes-list"

import {tt, axios} from "@lib/helpers"

const AppMfaTwilioVerification = ({
  data,
}: {
  data: {
    current_user: {
      phone_locale: string
      phone_number: string
    }
    edit_account_settings_user_path: string
    twilio_request_code_mfa_path: string
    t: any
  }
}) => {
  const [__isActive, __isActiveSet] = React.useState(true)
  const [__isLoading, __isLoadingSet] = React.useState(false)
  const [__verificationResult, __verificationResultSet] = React.useState(null)

  const {t} = data

  return (
    <React.Fragment>
      <div
        className={className("ModalSmsVerification", {
          "ModalSmsVerification--active": __isActive,
        })}
      >
        <a
          className="ModalSmsVerification__overlay"
          onClick={() => {
            __isActiveSet(false)
          }}
        />

        <div className="ModalSmsVerification__panel">
          <div className="ModalSmsVerification__panel__header">
            <h4 className="ModalSmsVerification__panel__header__h4">
              {tt(t, "current.title")}
            </h4>
          </div>

          <div
            className={className("ModalSmsVerification__panel__body", {
              "ModalSmsVerification__panel__body--disabled": __isLoading,
            })}
          >
            <p className="ModalSmsVerification__panel__p">
              {tt(t, "current.body", {
                number: `(+${
                  countryCodes
                    ?.all?.()
                    ?.find(
                      (x: any) =>
                        x?.countryCode?.toLowerCase?.() ==
                        data.current_user.phone_locale
                    )?.countryCallingCode
                }) ${data.current_user.phone_number}`,
              })}
            </p>

            <div className="ModalSmsVerification__panel__buttons">
              <a
                className="Button"
                onClick={() => {
                  __isLoadingSet(true)

                  axios
                    .post(data.twilio_request_code_mfa_path)
                    .then((res: any) => {
                      if (res.status == 200) {
                        __verificationResultSet(res.data)
                        __isActiveSet(false)
                      }
                    })
                    .finally(() => {
                      __isLoadingSet(false)
                    })
                }}
              >
                {tt(t, "current.submit")}
              </a>

              <a
                className="Button Button--white Button--no-border"
                href={data.edit_account_settings_user_path}
              >
                {tt(t, "current.edit")}
              </a>
            </div>
          </div>
        </div>
      </div>

      {__verificationResult && (
        <p className="AppTwilioVerification__verification-code">
          {__verificationResult?.message}
        </p>
      )}
    </React.Fragment>
  )
}

export default AppMfaTwilioVerification
