import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios, stripeStyle} from "@lib/helpers"

const PaymentsNew = ({stripe_public_key, payments_path, t, locale}) => {
  const [__isLoading, __isLoadingSet] = React.useState(false)

  const [__cardNumberErrors, __cardNumberErrorsSet] = React.useState()
  const [__cardExpiryErrors, __cardExpiryErrorsSet] = React.useState()
  const [__cardCvcErrors, __cardCvcErrorsSet] = React.useState()

  const ___stripe = React.useRef()

  const ___cardNumber = React.useRef()
  const ___cardExpiry = React.useRef()
  const ___cardCvc = React.useRef()

  React.useEffect(() => {
    ___stripe.current = Stripe(stripe_public_key, {
      locale,
    })

    window.setTimeout(() => {
      const elements = ___stripe.current.elements()

      ___cardNumber.current = elements.create("cardNumber", stripeStyle)
      ___cardExpiry.current = elements.create("cardExpiry", stripeStyle)
      ___cardCvc.current = elements.create("cardCvc", stripeStyle)

      ___cardNumber.current.mount("#cardNumber")
      ___cardExpiry.current.mount("#cardExpiry")
      ___cardCvc.current.mount("#cardCvc")

      const callback = ({e, errorsSet}) => {
        if (get(e, "error")) {
          errorsSet(get(e, "error.message"))
        } else {
          errorsSet()
        }
      }

      ___cardNumber.current.on("change", (e) => {
        callback({e, errorsSet: __cardNumberErrorsSet})
      })

      ___cardExpiry.current.on("change", (e) => {
        callback({e, errorsSet: __cardExpiryErrorsSet})
      })

      ___cardCvc.current.on("change", (e) => {
        callback({e, errorsSet: __cardCvcErrorsSet})
      })
    }, 100)
  }, [])

  return (
    <div className="AppPaymentsNew">
      <form
        className={className("AppForm", {
          "AppForm--overlay": __isLoading,
        })}
        onSubmit={(e) => {
          e.preventDefault()

          __isLoadingSet(true)

          ___stripe.current
            .createPaymentMethod({
              type: "card",
              card: ___cardNumber.current,
              billing_details: {
                name: get(e, "target.elements.name.value"),
              },
            })
            .then((res) => {
              if (get(res, "error")) {
                __isLoadingSet(false)
                return
              }

              axios
                .post(payments_path, {
                  stripe: {
                    payment_method_id: get(res, "paymentMethod.id"),
                  },
                })
                .then((res2) => {
                  const wid = get(res2, "data.data.worker_id")

                  const poller = () => {
                    axios
                      .get(
                        `/payments/poll_worker/?type=attach&worker_id=${wid}`
                      )
                      .then((res3) => {
                        if (get(res3, "data.data.status") != "complete") {
                          window.setTimeout(() => {
                            poller()
                          }, 1000)
                        } else {
                          window.location.href = payments_path
                        }
                      })
                      .catch((e) => {
                        window.location.reload()
                      })
                  }

                  poller()
                })
                .catch((e) => {
                  window.location.reload()
                })
            })
        }}
      >
        {[
          {
            label: get(t, "page.label"),
            name: "name",
          },
          {
            label: get(t, "page.label2"),
            errors: __cardNumberErrors,
            id: "cardNumber",
          },
          {
            label: get(t, "page.label3"),
            errors: __cardExpiryErrors,
            id: "cardExpiry",
          },
          {
            label: get(t, "page.label4"),
            errors: __cardCvcErrors,
            id: "cardCvc",
          },
        ].map((x, i) => (
          <div className="AppForm__row" key={i}>
            <div className="AppForm__label">
              <span className="AppForm__label__span">{get(x, "label")}</span>

              <span className="AppForm__label__required">
                {get(t, "dictionary.required")}
              </span>
            </div>

            <div
              className={className("AppForm__value", {
                field_with_errors: get(x, "errors"),
              })}
            >
              {get(x, "name") ? (
                <input
                  type="text"
                  className="AppInput"
                  name={get(x, "name")}
                  required
                />
              ) : (
                <React.Fragment>
                  <div id={get(x, "id")}></div>

                  {get(x, "errors") && (
                    <div className="attr_errors">
                      <div className="attr_errors__error">
                        {get(x, "errors")}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        ))}

        <div className="AppForm__row">
          <button className="Button Button--large" disabled={__isLoading}>
            {__isLoading
              ? get(t, "dictionary.loading")
              : get(t, "dictionary.save")}
          </button>
        </div>

        <div className="AppForm__row">
          <a
            className="Button Button--large Button--white-purple"
            href={payments_path}
          >
            {get(t, "dictionary.back")}
          </a>
        </div>
      </form>
    </div>
  )
}

PaymentsNew.propTypes = {}

export default PaymentsNew
