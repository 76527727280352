import React, {useState, useRef, useEffect} from "react"
import {get, find} from "lodash"
import includes from "lodash/includes"
import className from "classnames"
import MobileDetect from "mobile-detect"

import {axios, tt} from "@lib/helpers"

import {NoNameFunctionReturnVoid} from "types/common"
import {formDataType, defaultGmoCardType} from "types/pointPurchase"
import {newCardStateType} from "types/creditCard"

import initCardState from "states/initCardState"

import {
  validateInput,
  newFormInputs,
  cardFormData,
} from "utils/creditCardValidator"
import fetchCardToken from "utils/fetchCardToken"
import DefaultCardLink from "components/creditCard/DefaultCardLink"
import {InlineFormInputs} from "components/creditCard/FormInputs"

type PointPackage = {
  id: string
  point: number
  price: number
}

type AppPointPurchasesGmoNewProps = {
  data: {
    shopid: string
    paths: {
      point_purchases_path: string
      terms_user_path: string
      privacy_policy_path: string
      new_inquiry_path: string
      payments_path: string
    }
    section: Map<string, string>
    dictionary: Map<string, string>
    t: any
    imgs: {
      google_pay: string
    }
    default_gmo_card: defaultGmoCardType
    point_packages: PointPackage[]
  }
}

type AppPointPurchasesGmoNewState = {
  isLoading: boolean
  pointPackage: PointPackage | undefined
}

const AppPointPurchasesGmoNew = ({data}: AppPointPurchasesGmoNewProps) => {
  const {
    shopid,
    paths,
    point_packages,
    section,
    dictionary,
    t,
    service_information,
    default_gmo_card,
  } = data

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [supportBrowserPayment, setSupportBrowserPayment] =
    useState<boolean>(false)
  const [isMobileChrome, setIsMobileChrome] = useState<boolean>(false)
  const [pointPackage, setPointPackage] = useState<PointPackage | undefined>(
    point_packages?.[0]
  )
  const [newCardState, setNewCardState] = useState<newCardStateType | null>(
    initCardState
  )
  const mobileDetect = new MobileDetect(window.navigator.userAgent)
  const paypalRef = React.useRef(null)

  useEffect(() => {
    setHasErrors(
      !!newCardState.cardNameErrors ||
        !!newCardState.cardNumberErrors ||
        !!newCardState.cardExpiryErrors ||
        !!newCardState.cardCvcErrors
    )
    // @ts-ignore
    if (typeof paypal != "undefined" && paypal) {
      if (window.paypalButton) window.paypalButton.close()
      // @ts-ignore
      window.paypalButton = paypal.Buttons({
        style: {
          height: 55,
        },
        createOrder: () => {
          return axios
            .post("/paypal_payments", {
              point_package_id: pointPackage?.id,
            })
            .then((res: any) => {
              return res?.data?.data?.order_id
            })
            .catch(() => {
              window.location.reload()
            })
        },
        onApprove: (data: any) => {
          setIsLoading(true)
          axios
            .post("/paypal_payments/capture", {
              order_id: data?.orderID,
            })
            .finally(() => {
              window.location.reload()
            })
        },
      })
      window.paypalButton.render(paypalRef.current)
    }
  }, [pointPackage, newCardState])

  const onFormInputsChangeHandler = (value: string, id: string) => {
    validateInput(value, id, newCardState, setNewCardState)
  }

  const purchasePoint = (path: string, formData: formDataType) => {
    setIsLoading(true)
    axios
      .post(path, formData)
      .then((res: any) => {
        const id = res?.data?.data?.id

        const poller = () => {
          axios
            .get(`/point_purchases/${id}`)
            .then((res2: any) => {
              if (includes(["success", "error"], res2?.data?.data?.status)) {
                window.location.reload()
              } else {
                window.setTimeout(() => {
                  poller()
                }, 1000)
              }
            })
            .catch(() => {
              window.location.reload()
            })
        }

        poller()
      })
      .catch(() => {
        window.location.reload()
      })
  }
  const newCardFormData = (token: string) => ({
    point_purchase: {
      point_package_id: pointPackage?.id,
    },
    card: cardFormData(token),
  })
  const defaultCardFormData: formDataType = {
    point_purchase: {
      point_package_id: pointPackage?.id,
    },
  }
  const submitCardFormData = (token: string) => {
    setIsLoading(true)
    axios
      .post(paths.pay_by_card_point_purchases_path, newCardFormData(token))
      .then((res: any) => {
        const id = res?.data?.data?.id

        const poller = () => {
          axios
            .get(`/point_purchases/${id}`)
            .then((res2: any) => {
              if (includes(["success", "error"], res2?.data?.data?.status)) {
                window.location.reload()
              } else {
                window.setTimeout(() => {
                  poller()
                }, 1000)
              }
            })
            .catch((error: any) => {
              alert(error?.response?.data?.result)
              window.location.reload()
            })
        }

        poller()
      })
      .catch((error: any) => {
        alert(error?.response?.data?.result)
        window.location.reload()
      })
  }

  return (
    <form
      className={className("AppForm", {
        "AppForm--overlay": isLoading,
      })}
      onSubmit={(e) => {
        e.preventDefault()

        if (default_gmo_card) {
          purchasePoint(paths.point_purchases_path, defaultCardFormData)
        } else {
          fetchCardToken(shopid, newCardState, submitCardFormData)
        }
      }}
    >
      <h3 className="AppForm__h3">{tt(t, "point_purchases.form.title")}</h3>

      <div className="AppForm__row">
        <div className="AppForm__label">
          <span className="AppForm__label__span">
            {tt(t, "point_purchases.form.label")}
          </span>

          <span className="AppForm__label__required">
            {tt(dictionary, "required")}
          </span>
        </div>

        <div className="AppForm__value">
          <div className="AppBookingsReserve__buttons">
            <select
              className="AppSelect"
              value={pointPackage?.id}
              data-point-value={pointPackage?.point}
              name="point"
              onChange={(e) => {
                const selectedPointPackage = find(
                  point_packages,
                  (x) => x.id == e.target.value
                )
                setPointPackage(selectedPointPackage)
              }}
            >
              {(point_packages || []).map((x: PointPackage, i: number) => (
                <option key={i} value={x.id}>
                  {x.point}pt: {x.price}
                  {tt(t, "point_purchases.form.yen")}
                </option>
              ))}
            </select>

            <div className="AppBookingsReserve__buttons__row">
              <div ref={paypalRef} className="AppBookingsReserve__button"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="AppForm__divider">
        <span className="AppForm__divider__span">{t.payment_form.or}</span>
      </div>

      <h3 className="AppForm__h3">{tt(t, "point_purchases.form.sub_title")}</h3>

      {default_gmo_card ? (
        <DefaultCardLink
          path={paths.payments_path}
          img={get(default_gmo_card, "img")}
          last4={get(default_gmo_card, "last4")}
          brand={get(default_gmo_card, "brand")}
        />
      ) : (
        <React.Fragment>
          <InlineFormInputs
            section={section}
            cardState={newCardState}
            formLabels={dictionary}
            onChangeHandler={onFormInputsChangeHandler}
          />
        </React.Fragment>
      )}

      <div className="AppForm__row">
        <div className="AppForm__value">
          <div className="AppForm__value__examples">
            <ul className="AppForm__value__examples__ul">
              <li className="AppForm__value__examples__ul__li">
                <a
                  className="AppForm__value__examples__ul__li__a"
                  href={paths.terms_user_path}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {tt(t, "agreement.terms_of_use")}
                </a>{" "}
                {tt(t, "agreement.content")}{" "}
                <a
                  className="AppForm__value__examples__ul__li__a"
                  href={paths.privacy_policy_path}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {tt(t, "agreement.privacy_policy")}
                </a>{" "}
                {tt(t, "agreement.content2")}
              </li>

              <li className="AppForm__value__examples__ul__li">
                {tt(t, "agreement.content3")} {service_information.service_name}{" "}
                {tt(t, "agreement.content4")}
              </li>

              <li className="AppForm__value__examples__ul__li">
                {tt(t, "agreement.content5")}
              </li>

              <li className="AppForm__value__examples__ul__li">
                {tt(t, "agreement.content6")}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="AppForm__row">
        <button
          className={className("Button Button--large", {
            "Button--disabled": isLoading || hasErrors,
          })}
          disabled={isLoading || hasErrors}
          cypress-target="submit"
        >
          {isLoading
            ? tt(dictionary, "loading")
            : tt(t, "point_purchases.form.submit")}
        </button>
      </div>

      <div className="AppForm__row">
        <p className="AppPointPurchasesNew__p">
          ポイント購入に関するお問い合わせは{" "}
          <a href={paths.new_inquiry_path}>こちら</a>
        </p>
      </div>
    </form>
  )
}

export default AppPointPurchasesGmoNew
