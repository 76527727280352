import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import moment from "moment"
import DatePicker from "@components/molecules/DatePicker"

type PropTypes = ComponentProps<typeof DatePicker>

export default {
  title: "Molecules/DatePicker",
  component: DatePicker,
} as Meta

const Template: Story<PropTypes> = (args) => <DatePicker {...args} />

export const Default = Template.bind({})
Default.args = {
  start_date: moment().subtract(1, "month").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  minimum_date: undefined,
  maximum_date: undefined,
  enable_future_dates: false,
  compared: undefined,
  alignLeft: undefined,
}
