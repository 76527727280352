import React from "react"
import {omitBy, omit} from "lodash"
import className from "classnames"
import moment from "moment-timezone"
import qs from "qs"
import {timeDays} from "d3-time"

const DatePicker: React.FC<{
  start_date: string
  end_date: string
  enable_future_dates?: boolean
  compared?: boolean
  alignLeft?: boolean
}> = ({start_date, end_date, enable_future_dates, compared, alignLeft}) => {
  const ___$$startDateProps = React.useRef<moment.Moment>(
    start_date ? moment(start_date) : moment()
  )

  const ___$$endDateProps = React.useRef<moment.Moment>(
    end_date ? moment(end_date) : moment()
  )

  const [__isActive, __isActiveSet] = React.useState(false)

  const [__$$startDateSelecting, __$$startDateSelectingSet] =
    React.useState<null | moment.Moment>(null)

  const [__$$endDateSelecting, __$$endDateSelectingSet] =
    React.useState<null | moment.Moment>(null)

  const [__$$selectedMonth, __$$selectedMonthSet] = React.useState(
    moment(___$$startDateProps.current)
  )

  const onChangeMonth = (direction: "before" | "prev") => {
    const targetMoment = moment(__$$selectedMonth)
    if (direction === "before") {
      __$$selectedMonthSet(targetMoment.subtract(1, "M"))
    } else if (direction === "prev") {
      __$$selectedMonthSet(targetMoment.add(1, "M"))
    }
  }

  const isSelectingStartDateIsBefore =
    __$$startDateSelecting &&
    __$$startDateSelecting.isBefore(__$$endDateSelecting, "day")

  const $$bufferDay = moment()

  return (
    <div className="DatePicker">
      <div className="DatePicker__handle">
        <a
          className={className("DatePicker__handle__a", {
            "DatePicker__handle__a--green": compared,
            "DatePicker__handle__a--cur-d": compared,
          })}
          onClick={() => {
            if (!compared) {
              __isActiveSet(true)
              __$$startDateSelectingSet(null)
            }
          }}
        >
          {`${___$$startDateProps.current?.format(
            "YYYY/MM/DD"
          )} – ${___$$endDateProps.current?.format("YYYY/MM/DD")}`}
        </a>

        {compared && (
          <a
            className="DatePicker__handle__close"
            href={`?${qs.stringify(
              omit(
                {
                  ...qs.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                  }),
                },
                ["compare"]
              )
            )}`}
          />
        )}
      </div>

      <a
        className={className("DatePicker__overlay", {
          "DatePicker__overlay--active": __isActive,
        })}
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div
        className={className("DatePicker__panel", {
          "DatePicker__panel--active": __isActive,
          "DatePicker__panel--left": alignLeft,
        })}
      >
        {(() => {
          const $$firstDayOfMonth = moment(__$$selectedMonth)
            .startOf("month")
            .startOf("week")

          const $$lastDayOfMonth = moment(__$$selectedMonth)
            .endOf("month")
            .endOf("week")

          const dayInterval = timeDays(
            $$firstDayOfMonth.toDate(),
            $$lastDayOfMonth.toDate()
          )

          return (
            <div className="DatePicker__panel__calendar">
              <div className="DatePicker__panel__calendar__header">
                <a
                  className="DatePicker__panel__calendar__header__a"
                  onClick={() => {
                    onChangeMonth("before")
                  }}
                >
                  前月
                </a>

                <strong className="DatePicker__panel__calendar__header__strong">
                  {moment(__$$selectedMonth).format("YYYY年MM月")}
                </strong>

                <a
                  className={className(
                    "DatePicker__panel__calendar__header__a",
                    {
                      "DatePicker__panel__calendar__header__a--disabled":
                        !enable_future_dates &&
                        __$$selectedMonth.isSame($$bufferDay, "month"),
                    }
                  )}
                  onClick={() => {
                    onChangeMonth("prev")
                  }}
                >
                  翌月
                </a>
              </div>

              <div className="DatePicker__panel__calendar__body">
                {["日", "月", "火", "水", "木", "金", "土"].map((day, i) => (
                  <span
                    className={className(
                      "DatePicker__panel__calendar__span",
                      {
                        "DatePicker__panel__calendar__span--saturday":
                          day === "土",
                      },
                      {
                        "DatePicker__panel__calendar__span--sunday":
                          day === "日",
                      }
                    )}
                    key={i}
                  >
                    {day}
                  </span>
                ))}

                {dayInterval.map((day, i) => (
                  <a
                    className={className("DatePicker__panel__calendar__a", {
                      "DatePicker__panel__calendar__a--today": moment(
                        day
                      ).isSame(moment(), "day"),
                      "DatePicker__panel__calendar__a--diff-month": !moment(
                        day
                      ).isSame(__$$selectedMonth, "month"),
                      "DatePicker__panel__calendar__a--in-between":
                        __$$startDateSelecting
                          ? isSelectingStartDateIsBefore
                            ? moment(day).isBetween(
                                __$$startDateSelecting,
                                __$$endDateSelecting,
                                "day",
                                "[]"
                              )
                            : moment(day).isBetween(
                                __$$endDateSelecting,
                                __$$startDateSelecting,
                                "day",
                                "[]"
                              )
                          : moment(day).isBetween(
                              ___$$startDateProps.current,
                              ___$$endDateProps.current,
                              "day",
                              "[]"
                            ),
                      "DatePicker__panel__calendar__a--selecting":
                        __$$startDateSelecting
                          ? moment(day).isSame(__$$startDateSelecting, "day")
                          : moment(day).isSame(
                              ___$$startDateProps.current,
                              "day"
                            ) ||
                            moment(day).isSame(
                              ___$$endDateProps.current,
                              "day"
                            ),
                      "DatePicker__panel__calendar__a--disabled":
                        !enable_future_dates &&
                        moment(day).isAfter($$bufferDay, "day"),
                    })}
                    key={i}
                    onClick={() => {
                      if (!__$$startDateSelecting) {
                        __$$startDateSelectingSet(moment(day))
                      }
                    }}
                    onMouseEnter={() => {
                      if (__$$startDateSelecting) {
                        __$$endDateSelectingSet(moment(day))
                      }
                    }}
                    onMouseLeave={() => {
                      if (__$$startDateSelecting) {
                        __$$endDateSelectingSet(null)
                      }
                    }}
                    href={
                      __$$startDateSelecting &&
                      __$$endDateSelecting &&
                      !__$$startDateSelecting.isSame(
                        __$$endDateSelecting,
                        "day"
                      )
                        ? `?${qs.stringify(
                            omitBy(
                              {
                                ...qs.parse(window.location.search, {
                                  ignoreQueryPrefix: true,
                                }),
                                start_date: (isSelectingStartDateIsBefore
                                  ? __$$startDateSelecting
                                  : __$$endDateSelecting
                                )?.format("YYYY-MM-DD"),
                                end_date: (isSelectingStartDateIsBefore
                                  ? __$$endDateSelecting
                                  : __$$startDateSelecting
                                )?.format("YYYY-MM-DD"),
                              },
                              (x) => x == null || x == undefined
                            )
                          )}`
                        : null
                    }
                  >
                    {moment(day).format("D")}
                  </a>
                ))}
              </div>
            </div>
          )
        })()}
      </div>
    </div>
  )
}

export default DatePicker
