import * as React from "react"
import className from "classnames"

import {tt} from "@lib/helpers"

const getWindowWidth = () => {
  return (
    window?.innerWidth ||
    document?.documentElement?.clientWidth ||
    document?.body?.clientWidth
  )
}

const AppRootAgencyEventDescription = ({
  data,
}: {
  data: {
    description: any
    t: any
  }
}) => {
  const [__isDisplayed, __isDisplayedSet] = React.useState(false)
  const [__isOverflow, __isOverflowSet] = React.useState(false)
  const [__isExpanded, __isExpandedSet] = React.useState(false)

  const ___$el = React.useRef<HTMLParagraphElement>()
  const ___windowWidth = React.useRef<number>()

  const {t} = data

  React.useEffect(() => {
    if (___$el?.current?.scrollHeight > ___$el?.current?.clientHeight) {
      __isOverflowSet(true)
    }
  }, [__isDisplayed])

  const ___onResizeTO = React.useRef<any>()
  const onResize = () => {
    const _w = getWindowWidth()

    if (_w != ___windowWidth.current) {
      ___windowWidth.current = _w

      __isDisplayedSet(false)
      __isOverflowSet(false)
      __isExpandedSet(false)

      window.clearTimeout(___onResizeTO.current)
      ___onResizeTO.current = window.setTimeout(() => {
        __isDisplayedSet(true)
      }, 200)
    }
  }

  React.useEffect(() => {
    __isDisplayedSet(true)

    ___windowWidth.current = getWindowWidth()
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <React.Fragment>
      <p
        ref={___$el}
        className={className("AppEvents__hero__p", {
          "AppEvents__hero__p--active": __isDisplayed,
          "AppEvents__hero__p--expanded": __isExpanded,
        })}
        dangerouslySetInnerHTML={{__html: data.description}}
      />

      {__isOverflow && (
        <div className="AppEvents__hero__read-more">
          <a
            className="AppEvents__hero__read-more__a"
            onClick={() => {
              __isExpandedSet(!__isExpanded)
            }}
          >
            {__isExpanded
              ? tt(t, "current.show_less")
              : tt(t, "current.read_more")}
          </a>
        </div>
      )}
    </React.Fragment>
  )
}

export default AppRootAgencyEventDescription
