import React, {useState, Fragment} from "react"
import PropTypes from "prop-types"

import ModalMenus from "@components/molecules/ModalMenus"

const VideoRecordingMenuButton = ({links, modalTitle}) => {
  const [__showModal, __showModalSet] = useState(false)
  const handleShowModal = () => __showModalSet(true)
  const handleCloseModal = () => __showModalSet(false)

  return (
    <Fragment>
      <a
        className="AppUsersVideoRecording__header__a"
        onClick={handleShowModal}
      >
        <svg width="18" height="5" viewBox="0 0 18 5" fill="currentColor">
          <circle cx="2.625" cy="2.625" r="1.875" />
          <circle cx="9.375" cy="2.625" r="1.875" />
          <circle cx="16.125" cy="2.625" r="1.875" />
        </svg>
      </a>

      <ModalMenus
        show={__showModal}
        title={modalTitle}
        onClose={handleCloseModal}
      >
        {links.map(({href, label}) => (
          <a key={label} href={href}>
            {label}
          </a>
        ))}
      </ModalMenus>
    </Fragment>
  )
}

VideoRecordingMenuButton.propTypes = {
  links: PropTypes.array.isRequired,
  modalTitle: PropTypes.string.isRequired,
}

export default VideoRecordingMenuButton
