import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const ModalIncompatibleBrowser = ({data}) => {
  const {t} = data
  const [__isActive, __isActiveSet] = React.useState(true)

  return (
    <div
      className={className("ModalIncompatibleBrowser", {
        "ModalIncompatibleBrowser--active": __isActive,
      })}
    >
      <a className="ModalIncompatibleBrowser__overlay" />

      <div className="ModalIncompatibleBrowser__panel">
        <h2 className="ModalIncompatibleBrowser__panel__h2">
          {get(t, "current.title")}
        </h2>

        <p className="ModalIncompatibleBrowser__panel__p">
          {get(t, "current.content")}
        </p>

        <ul className="ModalIncompatibleBrowser__panel__ul">
          {[
            {
              label: "iOS 11.0",
            },
            {
              label: "Android 5.0 (API 21)",
            },
          ].map((x, i) => (
            <li className="ModalIncompatibleBrowser__panel__ul__li" key={i}>
              {get(x, "label")}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

ModalIncompatibleBrowser.propTypes = {}

export default ModalIncompatibleBrowser
