import React from "react"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import className from "classnames"

import {axios} from "@lib/helpers"

import logoTalkPort from "@images/common/services/talkport/logo.png"
import logoSay from "@images/common/services/say/icon.png"

type NavLink = {
  active: boolean
  href: string
  label: string
  pinned: boolean
  method?: string
}

type PropTypes = {
  code: string
  nav_links: NavLink[]
  root_path: string
  agency_admin: boolean
  enable_admin_line_link: string
  create_package_link: string
  app_version: string
}

const Nav: React.FC<PropTypes> = ({
  code,
  nav_links = [],
  root_path,
  agency_admin,
  enable_admin_line_link,
  create_package_link,
  app_version,
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const logo = code == "talkport" ? logoTalkPort : logoSay

  return (
    <div className="Nav" data-version={app_version}>
      {!isEmpty(nav_links) && (
        <a
          className="Nav__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        />
      )}

      <a className="Nav__a" href={root_path}>
        <img
          className={className("Nav__a__img", {
            "Nav__a__img--alt": code != "talkport",
          })}
          src={logo}
        />
      </a>

      {(() => {
        const pinnedLinks = nav_links.filter((x) => get(x, "pinned"))

        if (isEmpty(pinnedLinks)) {
          return null
        }

        return (
          <div className="Nav__links">
            {pinnedLinks.map((x, i) => (
              <a
                key={i}
                className={className("Nav__links__a", {
                  "Nav__links__a--active": get(x, "active"),
                })}
                href={get(x, "href")}
              >
                {get(x, "label")}
              </a>
            ))}
          </div>
        )
      })()}

      <a
        className={className("Nav__overlay", {
          "Nav__overlay--active": __isActive,
        })}
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div
        className={className("Nav__panel", {
          "Nav__panel--active": __isActive,
        })}
      >
        <div className="Nav__panel__header">
          <img
            className={className("Nav__panel__header__img", {
              "Nav__panel__header__img--alt": code != "talkport",
            })}
            src={logo}
          />

          <p className="Nav__panel__header__p">admin</p>
        </div>

        {enable_admin_line_link && agency_admin && (
          <div className="Nav__panel__top">
            <div className="Nav__panel__top__a">
              <a href={create_package_link}>トーク作成</a>
            </div>
          </div>
        )}

        <div className="Nav__panel__links">
          <div className="Nav__panel__links__section">
            {nav_links.map((x, i) => {
              if (get(x, "method") == "delete") {
                return (
                  <a
                    key={i}
                    className="Nav__panel__links__a Nav__panel__links__a--red"
                    onClick={() => {
                      axios.delete(get(x, "href")).then((res: any) => {
                        window.location.href =
                          res?.data?.redirect_url || root_path
                      })
                    }}
                  >
                    {get(x, "label")}
                  </a>
                )
              }

              return (
                <a
                  key={i}
                  className={className("Nav__panel__links__a", {
                    "Nav__panel__links__a--active": get(x, "active"),
                  })}
                  href={get(x, "href")}
                >
                  {get(x, "label")}
                </a>
              )
            })}
          </div>

          {agency_admin && (
            <div className="Nav__panel__links__section">
              <a
                className="Nav__panel__links__a"
                href="https://www.notion.so/talkport-829a00c0b63b4fe884cb3d7aa679634c"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                配信者用ヘルプ
              </a>
            </div>
          )}
        </div>

        {enable_admin_line_link && agency_admin && (
          <div className="Nav__panel__bottom">
            <p className="Nav__panel__bottom__p">LINE@登録でもっと便利に！</p>
            <div className="Nav__panel__bottom__a">
              <a href="https://lin.ee/o82XMXL">LINE@に登録</a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Nav.propTypes = {}

export default Nav
