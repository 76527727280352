import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import ModalIncompatibleBrowser from "@components/molecules/ModalIncompatibleBrowser"
import sharedLocales from "@locales/views/shared/ja.yml"

type PropTypes = ComponentProps<typeof ModalIncompatibleBrowser>

export default {
  title: "Molecules/ModalIncompatibleBrowser",
  component: ModalIncompatibleBrowser,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <ModalIncompatibleBrowser {...args} />
)

export const Default = Template.bind({})
Default.args = {
  data: {
    t: {
      current: sharedLocales.ja.shared.incompatible_browser,
    },
  },
}
