import React from "react"
import PropTypes from "prop-types"
import {get, find, padStart} from "lodash"
import className from "classnames"
import moment from "moment-timezone"

import {tt, axios} from "@lib/helpers"

class AppLanePackagesPrepare extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  $$startAt = moment(get(this.props, "data.package.bulk_start_at"))
  $$endAt = moment(get(this.props, "data.package.bulk_end_at"))
  $$queueAble = moment(this.$$startAt).subtract(
    get(this.props, "data.QUEUEABLE_TIME"),
    "seconds"
  )

  state = {
    __activeSpot: null,
    __callEnded: false,
  }

  poller = async () => {
    if (moment().isBefore(this.$$queueAble)) {
      window.setTimeout(() => {
        this.poller()
      }, 1000)
    } else if (moment().isBefore(this.$$endAt)) {
      const res = await axios.get(
        get(this.props, "data.active_spot_lane_packages_package_path")
      )

      this.setState(
        {
          __activeSpot: get(res, "data"),
        },
        () => {
          window.setTimeout(() => {
            this.poller()
          }, 5000)
        }
      )
    } else {
      this.setState({
        __callEnded: true,
      })
    }
  }

  componentDidMount = () => {
    this.poller()
  }

  render = () => {
    const {t} = get(this.props, "data")

    return (
      <div className="AppBookingsShow__lane">
        {get(this.state, "__callEnded") ? (
          <div className="AppBookingsShow__lane__ended">
            <p className="AppBookingsShow__lane__ended__p">
              {tt(t, "current.ended.p")}
            </p>

            <small className="AppBookingsShow__lane__ended__small">
              {tt(t, "current.ended.small", {
                time: get(this.props, "data.start_at_and_end_at.time"),
              })}
            </small>
          </div>
        ) : (
          <React.Fragment>
            <Countdown data={get(this.props, "data")} />

            <div className="AppBookingsShow__lane__bottom">
              <p
                className="AppBookingsShow__lane__bottom__queue"
                dangerouslySetInnerHTML={{
                  __html: tt(t, "current.queue", {
                    count: get(this.state, "__activeSpot.every_spot.length", 0),
                  }),
                }}
              />

              <p className="AppBookingsShow__lane__bottom__p">
                {tt(t, "current.p")}
              </p>

              <small className="AppBookingsShow__lane__bottom__small">
                {tt(t, "current.small")}
              </small>

              {get(this.state, "__activeSpot.every_spot.length", 0) > 0 && (
                <React.Fragment>
                  {(() => {
                    const _spot = find(
                      get(this.props, "data.spots"),
                      (x) =>
                        get(x, "token") ==
                        get(this.state, "__activeSpot.every_spot.0")
                    )

                    return (
                      <div className="AppBookingsShow__lane__bottom__next">
                        <p className="AppBookingsShow__lane__bottom__next__p">
                          Next
                        </p>

                        <img
                          className="AppBookingsShow__lane__bottom__next__img"
                          src={get(_spot, "user.icon_image_url_with_fallback")}
                        />

                        <span className="AppBookingsShow__lane__bottom__next__span">
                          {get(_spot, "user.name")}
                        </span>
                      </div>
                    )
                  })()}
                </React.Fragment>
              )}

              {(get(this.props, "data.always_show_link") ||
                get(this.props, "data.enable_web_call")) && (
                <a
                  className="Button Button--green"
                  href={get(this.props, "data.link_path")}
                >
                  {tt(t, "button")}
                </a>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default AppLanePackagesPrepare

class Countdown extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  ___timerTO = React.createRef()

  state = {
    __counter: 0,
  }

  timer = () => {
    if (
      moment().isBefore(moment(get(this.props, "data.package.bulk_end_at")))
    ) {
      this.setState(
        (prevState, props) => ({
          __counter: prevState.__counter + 1,
        }),
        () => {
          this.___timerTO.current = window.setTimeout(() => {
            this.timer()
          }, 1000)
        }
      )
    }
  }

  componentDidMount = () => {
    this.timer()
  }

  componentWillUnmount = () => {
    window.clearTimeout(this.___timerTO.current)
  }

  render = () => {
    const {t} = get(this.props, "data")

    return (
      <div className="AppBookingsShow__lane__top">
        {(() => {
          const $$startAt = moment(
            get(this.props, "data.package.bulk_start_at")
          )
          const $$endAt = moment(get(this.props, "data.package.bulk_end_at"))
          const $$queueAble = moment($$startAt).subtract(
            get(this.props, "data.QUEUEABLE_TIME"),
            "seconds"
          )

          let text = "reception_start"
          let __duration = moment.duration($$queueAble.diff(moment()))

          if (moment().isAfter($$queueAble)) {
            text = "call_start"

            __duration = moment.duration(moment($$startAt).diff(moment()))
          }

          if (moment().isAfter($$startAt)) {
            text = "call_end"

            __duration = moment.duration(moment($$endAt).diff(moment()))
          }

          return (
            <React.Fragment>
              <p className="AppBookingsShow__lane__top__p">
                {tt(t, `current.top.${text}`)}
              </p>

              <time className="AppBookingsShow__lane__top__time">
                {__duration.hours()} : {padStart(__duration.minutes(), 2, "0")}{" "}
                : {padStart(__duration.seconds(), 2, "0")}
              </time>
            </React.Fragment>
          )
        })()}
      </div>
    )
  }
}
