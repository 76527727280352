import React from "react"
import {toHalfWidth} from "@lib/helpers"

type PropTypes = Partial<{
  value: string
  name: string
  placeholder: string
  klass: string
}>

const FormInputText: React.FC<PropTypes> = ({
  value,
  name,
  placeholder,
  klass,
}) => {
  return (
    <input
      className={klass ? klass : "Input"}
      type="text"
      name={name}
      defaultValue={value}
      onBlur={(e) => {
        e.target.value = toHalfWidth(e.target.value)
      }}
      placeholder={placeholder}
    />
  )
}

export default FormInputText
