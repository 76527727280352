import React from "react"
import PropTypes from "prop-types"

import {tt, axios} from "@lib/helpers"

const PaymentMethodField = ({
  payment_method,
  payment_notification_email,
  t,
  required,
}) => {
  const [__paymentMethod, __paymentMethodSet] = React.useState(
    payment_method || ""
  )
  const [__isCheck, __isCheckSet] = React.useState(
    Boolean(payment_notification_email)
  )

  React.useEffect(() => {
    const memoInput = document.getElementById("agency_memo")

    const handler = (e) => {
      const {value} = e.target
      const isContainKeywords =
        value.indexOf("請求書払い") > -1 || value.indexOf("CD販売") > -1

      if (isContainKeywords) {
        __paymentMethodSet("payment_notice")
      }
    }

    memoInput.addEventListener("blur", handler)

    return () => {
      memoInput.removeEventListener("blur", handler)
    }
  }, [])

  React.useEffect(() => {
    document
      .querySelector("#paymentNoticeArea")
      ?.classList?.toggle(
        "AdminForm__row--hidden",
        __paymentMethod == "invoice"
      )
  }, [__paymentMethod])

  const handlePaymentMethodChange = (e) => {
    __paymentMethodSet(e.target.value)
  }

  const handlePaymentNoticeChange = (e) => {
    __isCheckSet(e.target.checked)
  }

  const isPaymentNotification = __paymentMethod == "payment_notice"

  return (
    <React.Fragment>
      <div className="AdminForm__row AdminForm__row--no-bdb">
        <label className="AdminForm__label" htmlFor="agency_payment_method">
          <div className="AdminForm__label__span">{t.payment_method}</div>
          <div className="AdminForm__label__required">{required}</div>
        </label>

        <p className="AdminForm__description AdminForm__description--agency">
          {t.description.payment_method}
        </p>

        <div className="AdminForm__value">
          <select
            className="Select"
            name="agency[payment_method]"
            id="agency_payment_method"
            value={__paymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="payment_notice">{t.option.payment_notice}</option>
            <option value="invoice">{t.option.invoice_payment}</option>
          </select>
        </div>
      </div>

      {isPaymentNotification && (
        <div className="AdminForm__row AdminForm__row--no-bdb">
          <label className="CheckBox CheckBox--secondary">
            <input
              type="checkbox"
              onChange={handlePaymentNoticeChange}
              checked={__isCheck}
            />
            <div className="CheckBox__span">{t.send_payment_notification}</div>
          </label>
        </div>
      )}

      {isPaymentNotification && __isCheck && (
        <div className="AdminForm__row AdminForm__row--no-bdb AdminForm__row--pl">
          <label
            className="AdminForm__label"
            htmlFor="payment_notification_email"
          >
            <div className="AdminForm__label__span">
              {t.payment_notification_email}
            </div>
            <div className="AdminForm__label__required">{required}</div>
          </label>
          <div className="AdminForm__value">
            <input
              type="email"
              className="Input"
              name="agency[payment_notification_email]"
              id="payment_notification_email"
              defaultValue={payment_notification_email}
              placeholder="email@example.com"
              required
            />
          </div>
        </div>
      )}

      {(!isPaymentNotification || !__isCheck) && (
        <input
          type="hidden"
          name="agency[payment_notification_email]"
          value=""
        />
      )}
    </React.Fragment>
  )
}

PaymentMethodField.propTypes = {
  payment_method: PropTypes.string,
  payment_notification_email: PropTypes.string,
  t: PropTypes.object,
  required: PropTypes.string,
}

export default PaymentMethodField
