import React from "react"
import PropTypes from "prop-types"

import {tt, axios} from "@lib/helpers"

import FormErrorMessage from "@components/molecules/FormErrorMessage"

const SalesAgentField = ({
  sales_agent_name = "",
  t,
  error_sale_agent_name,
  placeholder,
  required,
}) => {
  const [__isCheck, __isCheckSet] = React.useState(Boolean(sales_agent_name))

  const handleSalesAgentChange = (e) => {
    __isCheckSet(e.target.checked)
  }

  return (
    <React.Fragment>
      <div className="AdminForm__row AdminForm__row--no-bdb">
        <label className="CheckBox CheckBox--secondary">
          <input
            type="checkbox"
            name="agency[sales_agent]"
            onChange={handleSalesAgentChange}
            checked={__isCheck}
          />
          <div className="CheckBox__span">{t.sales_agent}</div>
        </label>
      </div>

      {__isCheck ? (
        <div className="AdminForm__row AdminForm__row--no-bdb AdminForm__row--pl">
          <label className="AdminForm__label" htmlFor="sales_agent_name">
            <div className="AdminForm__label__span">{t.sales_agent_name}</div>
            <div className="AdminForm__label__required">{required}</div>
          </label>
          <div className="AdminForm__value">
            <input
              name="agency[sales_agent_name]"
              className="Input"
              id="sales_agent_name"
              maxLength="40"
              defaultValue={sales_agent_name}
              placeholder={placeholder}
              required
            />
            <FormErrorMessage message={error_sale_agent_name} />
          </div>
        </div>
      ) : (
        <input type="hidden" name="agency[sales_agent_name]" value="" />
      )}
    </React.Fragment>
  )
}

SalesAgentField.propTypes = {}

export default SalesAgentField
