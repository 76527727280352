import React from "react"
import find from "lodash/find"
import includes from "lodash/includes"
import className from "classnames"
import Cookies from "js-cookie"
import MobileDetect from "mobile-detect"

import {axios, PAYMENTS_KONBINI_KEY, stripeStyle, tt} from "@lib/helpers"

type PointPackage = {
  id: string
  point: number
  price: number
}

type AppPointPurchasesNewProps = {
  data: {
    paths: {
      point_purchases_path: string
      terms_user_path: string
      privacy_policy_path: string
      new_inquiry_path: string
      payments_path: string
    }
    t: any
    imgs: {
      google_pay: string
    }
    default_payment_method_card: {
      brand: string
      last4: string
      img: string
    } | null
    stripe_public_key: string
    locale: string
    point_packages: PointPackage[]
    service_information: {service_name: string}
    payment_methods_present: boolean
  }
}

type AppPointPurchasesNewState = {
  __isLoading: boolean
  __cardElementErrors: string | null

  __pointPackage: PointPackage | undefined

  __supportBrowserPayment: boolean
  __isMobileChrome: boolean
}

class AppPointPurchasesNew extends React.Component<
  AppPointPurchasesNewProps,
  AppPointPurchasesNewState
> {
  static propTypes = {}

  static defaultProps = {}

  state: AppPointPurchasesNewState = {
    __isLoading: false,
    __cardElementErrors: null,

    __pointPackage: this.props.data?.point_packages?.[0],

    __supportBrowserPayment: false,
    __isMobileChrome: false,
  }

  mobileDetect = new MobileDetect(window.navigator.userAgent)

  // @ts-ignore
  ___stripe = Stripe(this.props.data.stripe_public_key, {
    locale: this.props.data.locale,
  })

  ___paymentRequest = null
  ___cardElement = null

  purchasePoint = (args: {stripeData?: any} = {}) => {
    const {stripeData} = args

    this.setState(
      {
        __isLoading: true,
      },
      () => {
        let formData: {
          point_purchase: {
            point_package_id: string | undefined
            store?: string
          }
        } = {
          point_purchase: {
            point_package_id: this.state.__pointPackage?.id,
          },
        }

        if (Cookies.get(PAYMENTS_KONBINI_KEY)) {
          formData = {
            ...formData,
            point_purchase: {
              ...formData.point_purchase,
              store: Cookies.get(PAYMENTS_KONBINI_KEY),
            },
          }

          axios
            .post("/komoju_payments", formData)
            .then((res: any) => {
              window.location.href =
                res?.data?.data?.info?.payment_details?.instructions_url
            })
            .catch(() => {
              window.location.reload()
            })
        } else {
          if (stripeData) {
            formData = {
              ...formData,
              ...stripeData,
            }
          }

          axios
            .post(this.props.data.paths.point_purchases_path, formData)
            .then((res: any) => {
              const id = res?.data?.data?.id

              const poller = () => {
                axios
                  .get(`/point_purchases/${id}`)
                  .then((res2: any) => {
                    if (
                      includes(["success", "error"], res2?.data?.data?.status)
                    ) {
                      window.location.reload()
                    } else {
                      window.setTimeout(() => {
                        poller()
                      }, 1000)
                    }
                  })
                  .catch(() => {
                    window.location.reload()
                  })
              }

              poller()
            })
            .catch(() => {
              window.location.reload()
            })
        }
      }
    )
  }

  componentDidMount = () => {
    const elements = this.___stripe.elements()

    this.___paymentRequest = this.___stripe.paymentRequest({
      country: "JP",
      currency: "jpy",
      total: {
        label: this.props.data.t.point_purchases.form.title,
        amount: this.state.__pointPackage?.price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    // @ts-ignore
    this.___paymentRequest.canMakePayment().then((result: any) => {
      if (result) {
        const isMobileChrome = Boolean(
          this.mobileDetect.userAgent() &&
            this.mobileDetect.userAgent().toLowerCase() == "chrome"
        )

        this.setState(
          {
            __supportBrowserPayment: true,
            __isMobileChrome: isMobileChrome,
          },
          () => {
            if (isMobileChrome) {
              return
            }

            elements
              .create("paymentRequestButton", {
                paymentRequest: this.___paymentRequest,
                style: {
                  paymentRequestButton: {
                    height: "55px",
                  },
                },
              })
              .mount("#paymentRequestElement")
          }
        )
      }
    })

    // https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-complete-payment
    // @ts-ignore
    this.___paymentRequest.on(
      "paymentmethod",
      (ev: {
        paymentMethod: {id: string}
        complete: (arg0: string) => void
        methodName: string
      }) => {
        axios
          .post("/stripe_payment_intents", {
            point_package_id: this.state.__pointPackage?.id,
          })
          .then((res: any) => {
            const clientSecret = res?.data?.data?.payment_intent?.client_secret

            this.___stripe
              .confirmCardPayment(
                clientSecret,
                {payment_method: ev.paymentMethod.id},
                {handleActions: false}
              )
              .then((confirmResult: any) => {
                const _purchasePoint = ({charge_id}: any) => {
                  ev.complete("success")

                  this.purchasePoint({
                    stripeData: {
                      stripe: {
                        charge_id,
                        request_method: ev.methodName,
                      },
                    },
                  })
                }

                if (confirmResult.error) {
                  ev.complete("fail")
                } else if (
                  confirmResult.paymentIntent.status === "requires_action"
                ) {
                  this.___stripe
                    .confirmCardPayment(clientSecret)
                    .then((result: any) => {
                      if (!result.error) {
                        _purchasePoint({
                          charge_id: confirmResult.paymentIntent.id,
                        })
                      } else {
                        ev.complete("fail")
                      }
                    })
                } else {
                  _purchasePoint({charge_id: confirmResult.paymentIntent.id})
                }
              })
          })
          .catch(() => {
            ev.complete("fail")
          })
      }
    )

    // @ts-ignore
    if (typeof paypal != "undefined" && paypal) {
      // @ts-ignore
      paypal
        .Buttons({
          style: {
            height: 55,
          },
          createOrder: () => {
            return axios
              .post("/paypal_payments", {
                point_package_id: this.state.__pointPackage?.id,
              })
              .then((res: any) => {
                return res?.data?.data?.order_id
              })
              .catch(() => {
                window.location.reload()
              })
          },
          onApprove: (data: any) => {
            this.setState(
              {
                __isLoading: true,
              },
              () => {
                axios
                  .post("/paypal_payments/capture", {
                    order_id: data?.orderID,
                  })
                  .finally(() => {
                    window.location.reload()
                  })
              }
            )
          },
        })
        .render("#paypalButtonElement")
    }

    if (!this.props.data.default_payment_method_card) {
      this.___cardElement = elements.create("card", {
        ...stripeStyle,
        hidePostalCode: true,
      })

      // @ts-ignore
      this.___cardElement.mount("#cardElement")

      // @ts-ignore
      this.___cardElement.on("change", (e: any) => {
        if (e?.error) {
          this.setState({
            __cardElementErrors: e?.error?.message,
          })
        } else {
          this.setState({
            __cardElementErrors: null,
          })
        }
      })
    }
  }

  handleClickBrowserPayment = (e: any) => {
    e.preventDefault()

    // @ts-ignore
    this.___paymentRequest.show()
  }

  render = () => {
    const {
      paths,
      point_packages,
      t,
      service_information,
      default_payment_method_card,
    } = this.props.data

    return (
      <form
        className={className("AppForm", {
          "AppForm--overlay": this.state.__isLoading,
        })}
        onSubmit={(e) => {
          e.preventDefault()

          if (default_payment_method_card) {
            this.purchasePoint()
          } else {
            this.setState(
              {
                __isLoading: true,
              },
              () => {
                this.___stripe
                  .createToken(this.___cardElement)
                  .then((res: any) => {
                    const card_token = res?.token?.id

                    if (card_token) {
                      this.purchasePoint({
                        stripeData: {
                          stripe: {
                            card_token,
                          },
                        },
                      })
                    } else {
                      this.setState({
                        __isLoading: false,
                      })
                    }
                  })
              }
            )
          }
        }}
      >
        <h3 className="AppForm__h3">{tt(t, "point_purchases.form.title")}</h3>

        <div className="AppForm__row">
          <div className="AppForm__label">
            <span className="AppForm__label__span">
              {tt(t, "point_purchases.form.label")}
            </span>

            <span className="AppForm__label__required">
              {tt(t, "dictionary.form.required")}
            </span>
          </div>

          <div className="AppForm__value">
            <div className="AppBookingsReserve__buttons">
              <select
                className="AppSelect"
                value={this.state.__pointPackage?.id}
                data-point-value={this.state.__pointPackage?.point}
                name="point"
                onChange={(e) => {
                  const _pointPackage = find(
                    this.props.data.point_packages,
                    (x) => x.id == e.target.value
                  )

                  this.setState(
                    {
                      __pointPackage: _pointPackage,
                    },
                    () => {
                      // @ts-ignore
                      this.___paymentRequest.update({
                        total: {
                          label: tt(t, "point_purchases.form.title"),
                          amount: _pointPackage?.price,
                        },
                      })
                    }
                  )
                }}
              >
                {(point_packages || []).map((x: PointPackage, i: number) => (
                  <option key={i} value={x.id}>
                    {x.point}pt: {x.price}
                    {tt(t, "point_purchases.form.yen")}
                  </option>
                ))}
              </select>

              <div className="AppBookingsReserve__buttons__row">
                {this.state.__supportBrowserPayment ? (
                  this.state.__isMobileChrome ? (
                    <button
                      className="AppBookingsReserve__google-pay-button"
                      onClick={this.handleClickBrowserPayment}
                    >
                      <img
                        src={this.props.data?.imgs?.google_pay}
                        alt="Google Pay"
                      />{" "}
                      Pay
                    </button>
                  ) : (
                    <div
                      id="paymentRequestElement"
                      className="AppBookingsReserve__button"
                    ></div>
                  )
                ) : null}

                <div
                  id="paypalButtonElement"
                  className="AppBookingsReserve__button"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="AppForm__divider">
          <span className="AppForm__divider__span">{t.payment_form.or}</span>
        </div>

        <h3 className="AppForm__h3">
          {tt(t, "point_purchases.form.sub_title")}
        </h3>

        {Cookies.get(PAYMENTS_KONBINI_KEY) ? (
          <div className="AppPayments__card__right__top AppPayments__card__right__top--caret AppPayments__card__right__top--gc-1-3">
            <div className="AppPayments__card__right__top__wrapper">
              <div className="AppPayments__card__right__top__number">
                {tt(t, "point_purchases.form.konbini")}:{" "}
                <strong>
                  {tt(
                    t,
                    `point_purchases.new.${Cookies.get(PAYMENTS_KONBINI_KEY)}`
                  )}
                </strong>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            {default_payment_method_card ? (
              <a
                className="AppPayments__card AppPayments__card--single"
                href={paths.payments_path}
              >
                <img
                  className="AppPayments__card__img"
                  src={default_payment_method_card.img}
                />

                <div className="AppPayments__card__right">
                  <div className="AppPayments__card__right__top AppPayments__card__right__top--caret">
                    <div className="AppPayments__card__right__top__wrapper">
                      <div className="AppPayments__card__right__top__number">
                        **** **** **** {default_payment_method_card.last4}
                      </div>

                      <div className="AppPayments__card__right__top__brand">
                        {default_payment_method_card.brand}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ) : (
              <React.Fragment>
                <div id="cardElement"></div>

                {this.state.__cardElementErrors && (
                  <div className="attr_errors">
                    <div className="attr_errors__error">
                      {this.state.__cardElementErrors}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <div className="AppForm__row">
          <div className="AppForm__value">
            <div className="AppForm__value__examples">
              <ul className="AppForm__value__examples__ul">
                <li className="AppForm__value__examples__ul__li">
                  <a
                    className="AppForm__value__examples__ul__li__a"
                    href={paths.terms_user_path}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {tt(t, "agreement.terms_of_use")}
                  </a>{" "}
                  {tt(t, "agreement.content")}{" "}
                  <a
                    className="AppForm__value__examples__ul__li__a"
                    href={paths.privacy_policy_path}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {tt(t, "agreement.privacy_policy")}
                  </a>{" "}
                  {tt(t, "agreement.content2")}
                </li>

                <li className="AppForm__value__examples__ul__li">
                  {tt(t, "agreement.content3")}{" "}
                  {service_information.service_name}{" "}
                  {tt(t, "agreement.content4")}
                </li>

                <li className="AppForm__value__examples__ul__li">
                  {tt(t, "agreement.content5")}
                </li>

                <li className="AppForm__value__examples__ul__li">
                  {tt(t, "agreement.content6")}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="AppForm__row">
          <button
            className={className("Button Button--large", {
              "Button--disabled": this.state.__isLoading,
            })}
            cypress-target="submit"
          >
            {this.state.__isLoading
              ? tt(t, "dictionary.form.loading")
              : tt(t, "point_purchases.form.submit")}
          </button>
        </div>

        <div className="AppForm__row">
          <p className="AppPointPurchasesNew__p">
            ポイント購入に関するお問い合わせは{" "}
            <a href={paths.new_inquiry_path}>こちら</a>
          </p>
        </div>
      </form>
    )
  }
}

export default AppPointPurchasesNew
