import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputTextarea from "@components/molecules/FormInputTextarea"

type PropTypes = ComponentProps<typeof FormInputTextarea>

export default {
  title: "Molecules/FormInputTextarea",
  component: FormInputTextarea,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputTextarea {...args} />

export const Default = Template.bind({})
Default.args = {
  id: "user_publisher_attributes_description",
  value: "example",
  name: "user[description]",
  placeholder: "This is placeholder",
}
