import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"
import Cookies from "js-cookie"
import * as queryString from "query-string"

import {axios, PAYMENTS_KONBINI_KEY} from "@lib/helpers"

const GMOIndex = ({konbini_options, cards, paths, t, enable_komoju}) => {
  const [__isActivePanel, __isActivePanelSet] = React.useState(
    Cookies.get(PAYMENTS_KONBINI_KEY) ? "konbini" : "cc"
  )

  const [__selectKonbini, __selectKonbiniSet] = React.useState(
    Cookies.get(PAYMENTS_KONBINI_KEY)
  )

  React.useEffect(() => {
    if (!enable_komoju && Cookies.get(PAYMENTS_KONBINI_KEY)) {
      Cookies.remove(PAYMENTS_KONBINI_KEY)
      window.location.reload()
    }
  }, [])

  return (
    <div className="AppPayments">
      <h1 className="App__h1">{get(t, "current.title")}</h1>

      <div className="AppPayments__boxes">
        <div className="AppPayments__box">
          {enable_komoju && (
            <a
              className={className("AppPayments__label", {
                "AppPayments__label--active": __isActivePanel == "cc",
              })}
              onClick={() => {
                __isActivePanelSet("cc")
                Cookies.remove(PAYMENTS_KONBINI_KEY)
              }}
            >
              <span className="AppPayments__label__span">
                {get(t, "current.creditcard_payment")}
              </span>
            </a>
          )}

          <div
            className={className("AppPayments__box__content", {
              "AppPayments__box__content--active": __isActivePanel == "cc",
            })}
          >
            <div className="AppPayments__cards">
              <div className="AppPayments__cards__wrapper">
                {cards.map((x, i) => (
                  <div className="AppPayments__card" key={i}>
                    <img
                      className="AppPayments__card__img"
                      src={get(x, "img")}
                    />

                    <div className="AppPayments__card__right">
                      <div className="AppPayments__card__right__top">
                        <div className="AppPayments__card__right__top__wrapper">
                          <div className="AppPayments__card__right__top__number">
                            **** **** **** {get(x, "last4")}
                          </div>
                        </div>

                        {get(x, "is_default") && (
                          <img
                            className="AppPayments__card__right__top__default"
                            src={get(x, "is_default")}
                          />
                        )}
                      </div>

                      <div className="AppPayments__card__right__buttons">
                        {!get(x, "is_default") && (
                          <a
                            className="AppPayments__card__right__buttons__a"
                            onClick={() => {
                              axios.put(get(x, "link_action")).then(() => {
                                window.location.reload()
                              })
                            }}
                          >
                            {get(t, "current.make_default")}
                          </a>
                        )}

                        <Delete
                          t={get(t, "dictionary")}
                          href={get(x, "delete_link")}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <a
                className="Button Button--large Button--white-purple"
                href={get(paths, "new_payment_path")}
              >
                {get(t, "current.add_creditcard")}
              </a>
            </div>
          </div>
        </div>

        {enable_komoju && (
          <div className="AppPayments__box">
            <a
              className={className("AppPayments__label", {
                "AppPayments__label--active": __isActivePanel == "konbini",
              })}
              onClick={() => {
                __isActivePanelSet("konbini")
                Cookies.set(PAYMENTS_KONBINI_KEY, get(konbini_options, "0"))
              }}
            >
              <span className="AppPayments__label__span">
                {get(t, "current.konbini_payment")}
              </span>
            </a>

            <div
              className={className("AppPayments__box__content", {
                "AppPayments__box__content--active":
                  __isActivePanel == "konbini",
              })}
            >
              <div className="AppPayments__konbini">
                <p className="AppPayments__konbini__p">
                  {get(t, "current.konbini_p")}
                </p>

                <select
                  className="AppSelect"
                  value={__selectKonbini}
                  onChange={(e) => {
                    const value = get(e, "target.value")

                    __selectKonbiniSet(value)
                    Cookies.set(PAYMENTS_KONBINI_KEY, value)
                  }}
                >
                  {konbini_options.map((x, i) => (
                    <option key={i} value={x}>
                      {get(t, `current.${x}`)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        <a className="Button Button--large" href={get(paths, "back_path")}>
          {get(t, "dictionary.back")}
        </a>
      </div>
    </div>
  )
}

GMOIndex.propTypes = {}

export default GMOIndex

const Delete = ({t, href}) => {
  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <a
      className={className("AppPayments__card__right__buttons__a", {
        "AppPayments__card__right__buttons__a--red": true,
        "AppPayments__card__right__buttons__a--overlay": __isLoading,
      })}
      disabled={__isLoading}
      onClick={() => {
        if (confirm(get(t, "delete_confirm"))) {
          __isLoadingSet(true)

          axios.delete(href).then((res) => {
            const wid = get(res, "data.data.worker_id")

            console.log(wid)
            const poller = () => {
              axios
                .get(`/payments/poll_worker/?type=detach&worker_id=${wid}`)
                .then((res2) => {
                  if (get(res2, "data.data.status") != "complete") {
                    window.setTimeout(() => {
                      poller()
                    }, 1000)
                  } else {
                    window.location.reload()
                  }
                })
            }

            poller()
          })
        }
      }}
    >
      {__isLoading ? get(t, "loading") : get(t, "delete")}
    </a>
  )
}
