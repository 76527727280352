import React from "react"
import className from "classnames"

const TalkHistoryItemAction = ({
  data,
}: {
  data: {links: {label: string; href: string}[]}
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)

  return (
    <div className="AppUsersTalkHistory__dl__item__top__action">
      <a
        className="AppUsersTalkHistory__dl__item__top__action__handle"
        onClick={() => {
          __isActiveSet(!__isActive)
        }}
      />

      <div
        className={className(
          "AppUsersTalkHistory__dl__item__top__action__panel",
          {
            "AppUsersTalkHistory__dl__item__top__action__panel--active":
              __isActive,
          }
        )}
      >
        {data?.links?.map((x, i) => (
          <a
            key={i}
            className="AppUsersTalkHistory__dl__item__top__action__panel__a"
            href={x?.href}
          >
            {x?.label}
          </a>
        ))}
      </div>
    </div>
  )
}

TalkHistoryItemAction.propTypes = {}

export default TalkHistoryItemAction
