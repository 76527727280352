import React, {useState, useRef, useEffect} from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import omitBy from "lodash/omitBy"
import isNil from "lodash/isNil"
import className from "classnames"
import includes from "lodash/includes"
import Cookies from "js-cookie"
import MobileDetect from "mobile-detect"
import validator from "validator"

import {axios, tt} from "@lib/helpers"

import {NoNameFunctionReturnVoid} from "types/common"
import {formDataType, defaultGmoCardType} from "types/pointPurchase"
import {newCardStateType} from "types/creditCard"

import initCardState from "states/initCardState"

import {
  validateInput,
  newFormInputs,
  cardFormData,
} from "utils/creditCardValidator"
import fetchCardToken from "utils/fetchCardToken"
import DefaultCardLink from "components/creditCard/DefaultCardLink"
import {InlineFormInputs} from "components/creditCard/FormInputs"

type withProductType = {
  title: string
  context: string
}
type AppGMOBookingsReserveProps = {
  data: {
    shopid: string
    spot_token: string
    booking_token: string
    purchasable: {
      bookings_path: string
      spot_token: string
      btn_name: string
    }
    user_has_points: number
    required_point: number
    point_rate: number
    section: Map<string, string>
    dictionary: Map<string, string>
    t: {
      current: string
      point_purchase_form: string
      shipping: string
      payment_form: string
    }
    btn_name: string
    proceed_path: string
    point_purchases_path: string
    pay_by_card_point_purchases_path: string
    default_gmo_card: defaultGmoCardType
    payments_path: string
    validate_before_create_bookings_path: string
    with_product: Array<withProductType>
    package_title: string
    agency_setting: any
    allow_coupon: boolean
  }
}

const AppGMOBookingsReserve = ({data}: AppGMOBookingsReserveProps) => {
  const {
    shopid,
    spot_token,
    booking_token,
    purchasable,
    user_has_points,
    required_point,
    point_rate,
    section,
    dictionary,
    t,
    btn_name,
    proceed_path,
    point_purchases_path,
    pay_by_card_point_purchases_path,
    default_gmo_card,
    payments_path,
    validate_before_create_bookings_path,
    with_product,
    package_title,
    agency_setting,
    allow_coupon,
  } = data

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [supportBrowserPayment, setSupportBrowserPayment] =
    useState<boolean>(false)
  const [isMobileChrome, setIsMobileChrome] = useState<boolean>(false)

  const [newCardState, setNewCardState] = useState<newCardStateType | null>(
    initCardState
  )

  const [couponDiscount, setCouponDiscount] = useState<number | null>(null)
  const [couponCode, setCouponCode] = useState<string>("")
  const [couponIsLoading, setCouponIsLoading] = useState<boolean>(false)
  const [couponErrors, setCouponErrors] = useState<string>(null)
  const [pointDiff, setPointDiff] = useState<number>(
    user_has_points - required_point
  )
  const mobileDetect = new MobileDetect(window.navigator.userAgent)
  const paypalRef = React.useRef(null)

  useEffect(() => {
    setHasErrors(
      !!newCardState.cardNameErrors ||
        !!newCardState.cardNumberErrors ||
        !!newCardState.cardExpiryErrors ||
        !!newCardState.cardCvcErrors
    )
    // @ts-ignore
    if (typeof paypal != "undefined" && paypal) {
      if (window.paypalButton) window.paypalButton.close()
      // @ts-ignore
      window.paypalButton = paypal.Buttons({
        style: {
          height: 55,
        },
        onClick: async (data, actions) => {
          return axios
            .get(validate_before_create_bookings_path, {
              spot_token: spot_token,
              booking_token: booking_token,
            })
            .catch((e) => {
              window.setTimeout(() => {
                window.location.reload()
              }, 1000)

              return actions.reject()
            })
        },
        createOrder: () => {
          return axios
            .post("/paypal_payments", {
              point_to_purchase: Math.abs(pointDiff),
            })
            .then((res: any) => {
              return res?.data?.data?.order_id
            })
            .catch(() => {
              window.location.reload()
            })
        },
        onApprove: (data: any) => {
          setIsLoading(true)
          axios
            .post("/paypal_payments/capture", {
              order_id: data?.orderID,
            })
            .finally(() => {
              window.location.reload()
            })
        },
      })
      window.paypalButton.render(paypalRef.current)
    }
  }, [pointDiff, newCardState])

  const onFormInputsChangeHandler = (value: string, id: string) => {
    validateInput(value, id, newCardState, setNewCardState)
  }

  const defaultCardFormData: formDataType = {
    point_purchase: {
      point_to_purchase: Math.abs(pointDiff),
    },
  }
  const newCardFormData = (token: string) => ({
    point_purchase: {
      point_to_purchase: Math.abs(pointDiff),
    },
    card: cardFormData(token),
  })
  const getCouponCodeAsAParam = () => {
    return omitBy(
      {
        coupon_code: couponDiscount ? couponCode : null,
      },
      (x) => !x
    )
  }

  const book = () => {
    axios
      .post(data.proceed_path, getCouponCodeAsAParam())
      .then((res4) => {
        window.location.href = get(res4, "data.data.redirect_url")
      })
      .catch((e) => {
        window.location.reload()
      })
  }

  const validate = ({callback: Function}) => {
    setIsLoading(true)
    axios
      .get(validate_before_create_bookings_path, {
        spot_token: spot_token,
        booking_token: booking_token,
      })
      .then(() => {
        callback()
      })
      .catch((e) => {
        window.location.reload()
      })
  }
  const purchasePoint = (path: string, formData: formDataType) => {
    setIsLoading(true)
    axios
      .post(path, formData)
      .then((res2) => {
        const id = get(res2, "data.data.id")

        const poller = () => {
          axios.get(`/point_purchases/${id}`).then((res3) => {
            if (includes(["success"], get(res3, "data.data.status"))) {
              book()
            } else if (includes(["error"], get(res3, "data.data.status"))) {
              window.location.reload()
            } else {
              window.setTimeout(() => {
                poller()
              }, 1000)
            }
          })
        }

        poller()
      })
      .catch((e) => {
        alert(`${get(t, "point_purchase_error")} ${e?.response?.data?.result}`)
        window.location.reload()
      })
  }
  const purchasePointByDefault = () => {
    purchasePoint(point_purchases_path, defaultCardFormData)
  }
  const purchasePointByNew = (token: string) => {
    purchasePoint(pay_by_card_point_purchases_path, newCardFormData(token))
  }
  const submitCardFormData = (token: string) => {
    axios
      .get(validate_before_create_bookings_path, {
        spot_token: spot_token,
        booking_token: booking_token,
      })
      .then(() => {
        purchasePointByNew(token)
      })
      .catch((e) => {
        window.location.reload()
      })
  }

  return (
    <React.Fragment>
      <div className="AppBookingsReserve__points-required">
        <div className="AppBookingsReserve__points-required__row">
          <p className="AppBookingsReserve__points-required__p">
            {get(t, "current.current_point")}
          </p>

          <strong className="AppBookingsReserve__points-required__strong">
            {user_has_points}pt
          </strong>
        </div>

        <div className="AppBookingsReserve__points-required__row">
          <p className="AppBookingsReserve__points-required__p">
            {get(t, "current.required_point")}
          </p>

          <strong className="AppBookingsReserve__points-required__strong">
            -{required_point}pt
          </strong>
        </div>

        {couponDiscount && (
          <div className="AppBookingsReserve__points-required__row">
            <p className="AppBookingsReserve__points-required__p">
              {get(t, "current.coupon_discount")}
            </p>

            {(() => {
              ;<strong className="AppBookingsReserve__points-required__strong">
                {~~(couponDiscount * required_point) / 100}pt
              </strong>
            })()}
          </div>
        )}

        <div className="AppBookingsReserve__points-required__row AppBookingsReserve__points-required__row--total">
          <p className="AppBookingsReserve__points-required__p">
            {get(t, "current.after_purchasing_point")}
          </p>

          <strong className="AppBookingsReserve__points-required__strong">
            {pointDiff}
            pt
          </strong>
        </div>
      </div>

      {with_product && (
        <React.Fragment>
          {get(with_product, "label").map((x, i) => (
            <div className="AppBookingsReserve__box" key={i}>
              <h3 className="AppBookingsReserve__h3">{get(x, "title")}</h3>

              <a
                className="AppBookingsReserve__p AppBookingsReserve__p--caret"
                href={get(with_product, "shipping_bookings_path")}
              >
                <span className="AppBookingsReserve__p__span">
                  {get(x, "context")}
                </span>
              </a>
            </div>
          ))}

          <div className="AppBookingsReserve__cautions">
            {(get(t, "current.shipping.cautions") || []).map((x, i) => (
              <p className="AppBookingsReserve__cautions__p" key={i}>
                {x}
              </p>
            ))}
          </div>
        </React.Fragment>
      )}

      {agency_setting && agency_setting.enable_coupon && allow_coupon && (
        <div className="AppBookingsReserve__box">
          <h3 className="AppBookingsReserve__h3">
            {tt(t, "point_purchase_form.coupon_title")}
          </h3>

          <p className="AppBookingsReserve__p">
            {tt(t, "point_purchase_form.coupon_description")}
          </p>

          <form
            className="AppBookingsReserve__coupon"
            disabled={!isNil(couponDiscount) || couponIsLoading}
            onSubmit={(e) => {
              e.preventDefault()

              setCouponErrors(null)
              setCouponIsLoading(true)
              axios
                .get(`/coupons/${couponCode}`, {
                  spot_token: spot_token,
                })
                .then((res) => {
                  const discount = get(res, "data.data.discount")
                  const change = ~~((discount * required_point) / 100)
                  const updatedPointDiff = pointDiff + change
                  setCouponDiscount(discount)
                  setPointDiff(updatedPointDiff)
                })
                .catch((e) => {
                  setCouponErrors(get(e, "response.data.result"))
                })
                .finally(() => {
                  setCouponIsLoading(false)
                })
            }}
          >
            <div className="AppBookingsReserve__coupon__wrapper">
              <input
                type="text"
                className="AppBookingsReserve__coupon__input"
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value)
                }}
                required
              />

              <button
                className="AppBookingsReserve__coupon__button"
                disabled={couponCode.length == 0}
              >
                {tt(t, "point_purchase_form.coupon_button")}
              </button>
            </div>

            {couponErrors && (
              <p className="AppBookingsReserve__coupon__errors">
                {tt(t, `current.coupon_errors.${couponErrors}`)}
              </p>
            )}
          </form>
        </div>
      )}

      {pointDiff >= 0 ? (
        <form
          className={className("AppForm", {
            "AppForm--overlay": isLoading,
          })}
          onSubmit={(e) => {
            e.preventDefault()

            setIsLoading(true)
            axios
              .post(get(purchasable, "bookings_path"), getCouponCodeAsAParam())
              .then((res) => {
                window.location.href = get(res, "data.data.redirect_url")
              })
              .catch((e) => {
                window.location.reload()
              })
          }}
        >
          <button className="Button Button--large" disabled={isLoading}>
            {isLoading
              ? get(dictionary, "loading")
              : get(purchasable, "btn_name")}
          </button>
        </form>
      ) : (
        <React.Fragment>
          <div className="AppBookingsReserve__box">
            <h3 className="AppBookingsReserve__h3">
              {get(t, "point_purchase_form.purchase_point")}
            </h3>

            <p className="AppBookingsReserve__p">
              {tt(t, "point_purchase_form.explanation", {
                user_has_points,
              })}
            </p>

            <div className="AppBookingsReserve__buttons">
              <p className="AppInput">
                {Math.abs(pointDiff)}pt: {Math.abs(pointDiff * point_rate)}
                {get(t, "current.yen")}
              </p>
              <div className="AppBookingsReserve__buttons__row">
                <div
                  ref={paypalRef}
                  className="AppBookingsReserve__button"
                ></div>
              </div>
            </div>
          </div>

          <div className="AppForm__divider">
            <span className="AppForm__divider__span">
              {tt(t, "payment_form.or")}
            </span>
          </div>

          <div className="AppBookingsReserve__box">
            <h3 className="AppBookingsReserve__h3">
              {get(t, "point_purchase_form.payment_info")}
            </h3>

            {default_gmo_card ? (
              <form
                className={className("AppForm", {
                  "AppForm--overlay": isLoading,
                })}
                onSubmit={(e) => {
                  e.preventDefault()

                  setIsLoading(true)
                  axios
                    .get(validate_before_create_bookings_path, {
                      spot_token: spot_token,
                      booking_token: booking_token,
                    })
                    .then(() => {
                      purchasePointByDefault()
                    })
                    .catch((e) => {
                      window.location.reload()
                    })
                }}
              >
                <DefaultCardLink
                  path={payments_path}
                  img={get(default_gmo_card, "img")}
                  last4={get(default_gmo_card, "last4")}
                  brand={get(default_gmo_card, "brand")}
                />

                <div className="AppForm__row">
                  <button className="Button Button--large" disabled={isLoading}>
                    {isLoading ? get(dictionary, "loading") : btn_name}
                  </button>
                </div>
              </form>
            ) : (
              <form
                className={className("AppForm", {
                  "AppForm--overlay": isLoading,
                })}
                onSubmit={(e) => {
                  e.preventDefault()

                  setIsLoading(true)
                  fetchCardToken(shopid, newCardState, submitCardFormData)
                }}
              >
                <InlineFormInputs
                  section={section}
                  cardState={newCardState}
                  formLabels={dictionary}
                  onChangeHandler={onFormInputsChangeHandler}
                />
                <div className="AppForm__row">
                  <button
                    className="Button Button--large"
                    disabled={isLoading || hasErrors}
                  >
                    {isLoading ? get(dictionary, "loading") : btn_name}
                  </button>
                </div>
              </form>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AppGMOBookingsReserve
