import React from "react"
import PropTypes from "prop-types"
import className from "classnames"
import {arc} from "d3-shape"
import moment from "moment-timezone"
import padStart from "lodash/padStart"

type PropTypes = {
  $$startAt: moment.Moment
  $$endAt: moment.Moment
  current_time?: moment.MomentInput | null
}

const DialCountdown: React.FC<PropTypes> = ({
  $$startAt,
  $$endAt,
  current_time = null,
}) => {
  const RADIUS = 60

  const [__intervalId, __setIntervalId] = React.useState<number>()
  const [__currentTime, __setCurrentTime] = React.useState<moment.Moment>(
    moment()
  )

  const currentTimeDiff = React.useMemo(
    () => moment(current_time).diff(moment()),
    [current_time]
  )
  const duration = React.useMemo(() => {
    const diff = $$endAt.diff(__currentTime)
    const momentDuration = moment.duration(diff)
    return momentDuration.milliseconds() < 0
      ? moment.duration(0)
      : momentDuration
  }, [$$endAt, __currentTime])
  const percent = React.useMemo(
    () => duration.asSeconds() / $$endAt.diff($$startAt, "seconds"),
    [duration, $$endAt, $$startAt]
  )
  const isLessThanThreshold = React.useMemo(
    () => duration.asSeconds() <= 15,
    [duration]
  )
  const d3GuageBase = React.useMemo(() => {
    const thickness = 8
    return arc<null>()
      .innerRadius(RADIUS - thickness)
      .outerRadius(RADIUS)
      .cornerRadius(thickness)
  }, [RADIUS])
  const d3Gauge = React.useMemo(
    () => d3GuageBase.startAngle(0).endAngle(Math.PI * 2)(null) || "",
    [d3GuageBase]
  )
  const d3GaugeCurrent = React.useMemo(
    () =>
      d3GuageBase.startAngle(Math.PI * 2).endAngle(Math.PI * 2 * (1 - percent))(
        null
      ) || "",
    [d3GuageBase, percent]
  )

  // timer
  React.useEffect(() => {
    if (__intervalId) {
      if (duration.milliseconds() <= 0) {
        window.clearInterval(__intervalId)
      }
    } else {
      const tick = () => {
        __setCurrentTime(moment().add(currentTimeDiff, "milliseconds"))
      }
      __setIntervalId(window.setInterval(tick, 1000))
    }
  }, [currentTimeDiff, __intervalId, duration, $$endAt, __currentTime])

  // render
  return __currentTime.isBetween($$startAt, $$endAt) ? (
    <div
      className={className("DialCountdown", {
        "DialCountdown--red": isLessThanThreshold,
      })}
    >
      <p className="DialCountdown__p">
        {isLessThanThreshold ? (
          <React.Fragment>{duration.seconds()}</React.Fragment>
        ) : (
          <React.Fragment>
            {duration.minutes()}:
            {padStart(duration.seconds().toString(), 2, "0")}
          </React.Fragment>
        )}
      </p>

      <svg
        className="DialCountdown__svg"
        width={RADIUS * 2}
        height={RADIUS * 2}
      >
        <g transform={`translate(${RADIUS}, ${RADIUS})`}>
          <g className="DialCountdown__gauges">
            <path d={d3Gauge} className="DialCountdown__gauge" />
            <path
              d={d3GaugeCurrent}
              className="DialCountdown__gauge DialCountdown__gauge--current"
            />
          </g>
        </g>
      </svg>
    </div>
  ) : null
}

export default DialCountdown
