import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios} from "@lib/helpers"

const AdminAgenciesIndex = ({data}) => {
  const [__isChecked, __isCheckedSet] = React.useState(
    Boolean(get(data, "active"))
  )
  const [__isLoading, __isLoadingSet] = React.useState(false)

  return (
    <React.Fragment>
      <a
        disabled={__isLoading}
        className={className("CheckBox__input", {
          "CheckBox__input--checked": __isChecked,
        })}
        onClick={(e) => {
          __isLoadingSet(true)

          axios
            .put(`/admin/agencies/${get(data, "id")}/update_setting`, {
              setting: {
                [get(data, "key")]: !__isChecked,
              },
            })
            .then((res) => {
              if (get(res, "data.result") == "success") {
                __isCheckedSet(!__isChecked)
              }
            })
            .finally(() => {
              __isLoadingSet(false)
            })
        }}
      />
    </React.Fragment>
  )
}

AdminAgenciesIndex.propTypes = {}

export default AdminAgenciesIndex
