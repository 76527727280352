import React from "react"
import PropTypes from "prop-types"
import className from "classnames"

import {tt} from "@lib/helpers"

const AccountSettingsSubmitButton = ({t}) => {
  const [__showModal, __showModalSet] = React.useState(false)
  const [__customId, __customIdSet] = React.useState("")

  React.useEffect(() => {
    const $customIdInput = document.getElementById(
      "user_publisher_attributes_custom_id"
    )
    const handler = (e) => __customIdSet(e.target.value)
    $customIdInput.addEventListener("blur", handler)

    return () => {
      $customIdInput.removeEventListener("blur", handler)
    }
  }, [])

  const handleSubmit = (e) => {
    const $customIdInput = document.getElementById(
      "user_publisher_attributes_custom_id"
    )

    if (!$customIdInput.value.trim()) {
      return
    }

    e.preventDefault()
    __showModalSet(true)
  }

  const hideModal = (e) => {
    e.preventDefault()
    __showModalSet(false)
  }

  return (
    <React.Fragment>
      <button type="submit" className="Button" onClick={handleSubmit}>
        {t.save}
      </button>

      <ModalConfirmation
        show={__showModal}
        onClose={hideModal}
        t={t}
        customId={__customId}
      />
    </React.Fragment>
  )
}

AccountSettingsSubmitButton.propTypes = {
  t: PropTypes.object,
}

const ModalConfirmation = ({show, onClose, t, customId}) => {
  React.useEffect(() => {
    const handler = (e) => {
      // Escape key
      if (e.keyCode === 27) {
        onClose(e)
      }
    }
    document.addEventListener("keydown", handler)

    return () => {
      document.removeEventListener("keydown", handler)
    }
  }, [])

  return (
    <div
      className={className("ModalMenus ModalMenus--confirmation", {
        "ModalMenus--active": show,
      })}
    >
      <a className="ModalMenus__overlay" onClick={onClose} />

      <div className="ModalMenus__container">
        <div className="ModalMenus__body">
          <h3>{tt(t, "title", {custom_id: customId})}</h3>
          <p>{t.body}</p>
          <button type="submit" className="Button">
            {t.ok}
          </button>
          <button className="Button Button--link" onClick={onClose}>
            {t.cancel}
          </button>
        </div>
      </div>
    </div>
  )
}

ModalConfirmation.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.object,
  agencyId: PropTypes.string,
}

export default AccountSettingsSubmitButton
