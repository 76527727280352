import * as React from "react"
import className from "classnames"
import {get, filter, padStart} from "lodash"
import faker from "faker"
import moment from "moment"

import {tt, axios, formInputsToObject} from "@lib/helpers"

import FormErrorMessage from "@components/molecules/FormErrorMessage"
import DateTimePicker from "@components/molecules/DateTimePicker"

const AdminMasterProductsForm = ({
  data,
}: {
  data: {
    master_product: null | {
      formats: string[]
      id: number
      jancode: string
      name: string
      number: string
      publisher_name: string
      released_at: string
    }
    master_product_type:
      | null
      | "MediaProduct"
      | "OtherProduct"
      | "OldMigratedProduct"
    action: string
    method: "post" | "put"
    t: {
      product: any
      dictionary_form: any
      packages_form: any
    }
  }
}) => {
  const [__errors, __errorsSet] = React.useState<
    | undefined
    | {
        [key: string]: string
      }
  >()

  const [__type, __typeSet] = React.useState<null | string>(
    data.master_product_type
  )

  const {t} = data

  return (
    <form
      className="AdminForm"
      onSubmit={(e) => {
        e.preventDefault()

        const elements = (e?.target as HTMLFormElement)?.elements
        const inputs = formInputsToObject({elements})

        axios?.[data.method]?.(data.action, {
          master_product: {
            type: __type,
            formats: filter(elements?.["formats[]"], (x) => x.checked)?.map(
              (x) => x.value
            ),
            name: inputs?.name,
            number: inputs?.number,
            jancode: inputs?.jancode,
            publisher_name: inputs?.publisher_name,
            released_at: inputs?.released_at,
          },
        })
          .then((res: any) => {
            window.location.href = res?.data?.redirect_url
          })
          .catch((e: any) => {
            __errorsSet(e?.response?.data.errors)
          })
      }}
    >
      <div className="AdminForm__row AdminForm__row--no-bdb">
        <div className="AdminForm__label">
          <span className="AdminForm__label__span">
            {tt(t, "product.type")}
          </span>

          <span className="AdminForm__label__required">
            {tt(t, "dictionary_form.required")}
          </span>
        </div>

        <div className="AdminForm__value">
          <div className="AdminMasterProductsForm__radios">
            {[
              {
                label: tt(t, "product.type_media_product"),
                key: "MediaProduct",
              },
              {
                label: tt(t, "product.type_other_product"),
                key: "OtherProduct",
              },
            ].map((x, i) => (
              <label
                className={className("Radio", {
                  "Radio--disabled": data?.master_product?.id
                    ? data?.master_product_type != "OldMigratedProduct"
                    : false,
                })}
                key={i}
              >
                <input
                  type="radio"
                  className="Radio__input"
                  name="type"
                  value={x.key}
                  checked={x.key == __type}
                  onChange={(e) => {
                    __typeSet(e?.target?.value)
                  }}
                />

                <span className="Radio__span">{x.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      {__type == "MediaProduct" && (
        <React.Fragment>
          <div className="AdminForm__row AdminForm__row--no-bdb">
            <div className="AdminForm__label">
              <span className="AdminForm__label__span">
                {tt(t, "product.format")}
              </span>

              <span className="AdminForm__label__required">
                {tt(t, "dictionary_form.required")}
              </span>
            </div>

            <div className="AdminForm__value">
              <div className="AdminMasterProductsForm__checkboxes">
                {[
                  {
                    label: "CD",
                  },
                  {
                    label: "BD",
                  },
                  {
                    label: "DVD",
                  },
                  {
                    label: "BOOK",
                  },
                ].map((x, i) => (
                  <label className="CheckBox" key={i}>
                    <input
                      type="checkbox"
                      className="CheckBox__input"
                      name="formats[]"
                      value={x.label}
                      defaultChecked={data.master_product?.formats?.includes(
                        x.label
                      )}
                    />

                    <span className="CheckBox__span">
                      {x.label.toUpperCase()}
                    </span>
                  </label>
                ))}
              </div>

              <FormErrorMessage message={__errors?.formats} />
            </div>
          </div>

          <InputText
            t={t}
            fKey="name"
            __errors={__errors}
            defaultValue={data.master_product?.name}
          />

          <InputText
            t={t}
            fKey="number"
            __errors={__errors}
            defaultValue={data.master_product?.number}
          />

          <InputText
            t={t}
            fKey="jancode"
            __errors={__errors}
            defaultValue={
              data.master_product?.jancode
                ? data.master_product?.jancode
                : get(window, "gon.env") == "development"
                ? padStart(
                    faker.datatype.number(9999999999999).toString(),
                    13,
                    "0"
                  )
                : ""
            }
          />

          <InputText
            t={t}
            fKey="publisher_name"
            __errors={__errors}
            defaultValue={data.master_product?.publisher_name}
          />

          <div className="AdminForm__row AdminForm__row--no-bdb">
            <div className="AdminForm__label">
              <span className="AdminForm__label__span">
                {tt(t, `product.released_at`)}
              </span>
            </div>

            <div className="AdminForm__value">
              <div className="AdminMasterProductsForm__date">
                <DateTimePicker
                  t={data.t.packages_form.calendar}
                  name="released_at"
                  $$defaultValue={
                    data.master_product?.released_at
                      ? moment(data.master_product?.released_at)
                      : moment()
                  }
                  enableTime={false}
                  klassName="Input"
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {__type == "OtherProduct" && (
        <React.Fragment>
          <InputText
            t={t}
            fKey="name"
            __errors={__errors}
            defaultValue={data.master_product?.name}
          />
        </React.Fragment>
      )}

      {__type && (
        <div className="AdminForm__row AdminForm__row--no-bdb">
          <button className="Button" cypress-target="submit">
            {tt(t, "dictionary_form.save")}
          </button>
        </div>
      )}
    </form>
  )
}

export default AdminMasterProductsForm

const InputText = ({t, fKey, __errors, defaultValue = null}) => {
  return (
    <div className="AdminForm__row AdminForm__row--no-bdb">
      <div className="AdminForm__label">
        <span className="AdminForm__label__span">
          {tt(t, `product.${fKey}`)}
        </span>

        <span className="AdminForm__label__required">
          {tt(t, "dictionary_form.required")}
        </span>
      </div>

      <div className="AdminForm__value">
        <input
          type="text"
          className={className("Input", {
            "Input--error": __errors?.[fKey],
          })}
          name={fKey}
          defaultValue={defaultValue}
        />

        <FormErrorMessage message={__errors?.[fKey]} />
      </div>
    </div>
  )
}
