import * as React from "react"

const InputSerialCode = ({
  name,
  id,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>): React.ReactElement => {
  const __maskInputRef = React.useRef<HTMLInputElement>(null)
  const SEPARATOR = "-"

  const mask = (text: string) => {
    const limit = 4
    const output = Array.from(text).reduce((accm, char, index) => {
      if (index !== 0 && index % limit === 0) {
        accm += SEPARATOR
      }
      return accm + char
    }, "")
    return output
  }

  const unmask = (value: string) => value.replaceAll(SEPARATOR, "")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unmaskValue = unmask(e.target.value)
    if (__maskInputRef.current) {
      __maskInputRef.current.value = unmaskValue
    }
    e.target.value = mask(unmaskValue)
  }

  return (
    <React.Fragment>
      <input {...rest} onChange={handleInputChange} />
      <input type="hidden" name={name} id={id} ref={__maskInputRef} />
    </React.Fragment>
  )
}

export default InputSerialCode
