import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import moment from "moment"
import DialCountdown from "@components/molecules/DialCountdown"

type PropTypes = ComponentProps<typeof DialCountdown>

export default {
  title: "Molecules/DialCountdown",
  component: DialCountdown,
  argTypes: {
    current_time: {
      control: {
        type: "date",
        value: new Date(),
      },
    },
  },
  parameters: {
    backgrounds: {
      default: "dark",
    },
  },
} as Meta

const Template: Story<PropTypes> = (args) => <DialCountdown {...args} />
const $$startAt = moment().subtract(-5, "second")
// note: Storybooks have a bug that it cause build errors when using something named "Add" method in story files. So, use negatize subtract methods.
const $$endAt = moment().subtract(-75, "second")

export const Default = Template.bind({})
Default.args = {
  $$startAt,
  $$endAt,
}

export const WithServerCurrentTime = Template.bind({})
WithServerCurrentTime.args = {
  $$startAt,
  $$endAt,
  current_time: moment().toDate(),
}
