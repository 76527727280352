import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import TalkHistoryItemAction from "@components/molecules/TalkHistoryItemAction"

type PropTypes = ComponentProps<typeof TalkHistoryItemAction>

export default {
  title: "Molecules/TalkHistoryItemAction",
  component: TalkHistoryItemAction,
} as Meta

const Template: Story<PropTypes> = (args) => <TalkHistoryItemAction {...args} />

export const Default = Template.bind({})
Default.args = {
  data: {
    links: [...new Array(5)].map((_, index) => {
      return {
        label: `item${index}`,
        href: `/item${index}`,
      }
    }),
  },
}
