import React from "react"
import get from "lodash/get"
import className from "classnames"
import * as queryString from "query-string"

import {tt, formInputsToObject} from "@lib/helpers"

const AdminKomojuPaymentsIndexFilter = ({
  admin_komoju_payments_url,
  purchaser_name,
  order_number,
  receipt_number,
  status,
  purchaser_name_translation,
  order_number_translation,
  receipt_number_translation,
  status_translation,
  t,
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)

  return (
    <div className="Filter">
      <div
        className={className("Filter__panel", {
          "Filter__panel--active": __isActive,
        })}
      >
        <div className="Filter__panel__status">
          <p className="Filter__form__label__span">{tt(t, "spots.status")}</p>

          {purchaser_name && (
            <p className="Filter__panel__status__p">
              {purchaser_name_translation}：<strong>{purchaser_name}</strong>
            </p>
          )}

          {order_number && (
            <p className="Filter__panel__status__p">
              {order_number_translation}：<strong>{order_number}</strong>
            </p>
          )}

          {receipt_number && (
            <p className="Filter__panel__status__p">
              {receipt_number_translation}：<strong>{receipt_number}</strong>
            </p>
          )}

          <p className="Filter__panel__status__p">
            {status_translation}：<strong>{status ? status : "すべて"}</strong>
          </p>
        </div>

        <a
          className="Filter__panel__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        >
          <span
            className={className("Filter__panel__handle__span", {
              "Filter__panel__handle__span--active": __isActive,
            })}
          >
            {tt(t, "spots.filter.handle")}
          </span>
        </a>

        <form
          className={className("Filter__form", {
            "Filter__form--active": __isActive,
          })}
          onSubmit={(e) => {
            e.preventDefault()

            window.location.href = `?${queryString.stringify(
              formInputsToObject({elements: get(e, "target.elements")})
            )}&tab=1`
          }}
        >
          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {purchaser_name_translation}
            </span>

            <div className="Filter__form__label__input">
              <input
                type="text"
                name="purchaser_name"
                defaultValue={purchaser_name}
              />
            </div>
          </label>

          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {order_number_translation}
            </span>

            <div className="Filter__form__label__input">
              <input
                type="text"
                name="order_number"
                defaultValue={order_number}
              />
            </div>
          </label>

          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {receipt_number_translation}
            </span>

            <div className="Filter__form__label__input">
              <input
                type="text"
                name="receipt_number"
                defaultValue={receipt_number}
              />
            </div>
          </label>

          <label className="Filter__form__label">
            <span className="Filter__form__label__span">
              {status_translation}
            </span>

            <div className="Filter__form__label__input">
              <select
                type="text"
                name="status"
                className="Select"
                defaultValue={status}
              >
                <option value="">すべて</option>

                {[
                  "authorized",
                  "captured",
                  "canceled",
                  "expired",
                  "refunded",
                ].map((x, i) => (
                  <option value={x} key={i}>
                    {x}
                  </option>
                ))}
              </select>
            </div>
          </label>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span Filter__form__label__span--no-smo">
              &nbsp;
            </span>

            <div className="Filter__form__label__input">
              <div className="Buttons">
                <button className="Button Button--small">検索</button>

                <a
                  className="Button Button--small Button--white"
                  href={admin_komoju_payments_url}
                >
                  クリア
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AdminKomojuPaymentsIndexFilter.propTypes = {}

export default AdminKomojuPaymentsIndexFilter
