import React from "react"
import get from "lodash/get"

class AdminPublishersClipboardCopyButton extends React.Component {
  copyCheckedPublisher = () => {
    const checked = []
    document.querySelectorAll("[name='memo']").forEach((el) => {
      if (el.checked) {
        checked.push(el.value)
      }
    })
    return navigator.clipboard.writeText(checked.join("\n")).then(() => {
      alert(get(this.props, "locale.copy_success"))
    })
  }
  render = () => {
    const t = get(this.props, "locale")
    return (
      <div>
        <a
          className="Button Button--small"
          onClick={() => this.copyCheckedPublisher()}
        >
          {get(t, "copy_memo")}
        </a>
      </div>
    )
  }
}

export default AdminPublishersClipboardCopyButton
