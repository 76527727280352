import * as React from "react"
// import className from 'classnames'

import {getFeatureAnnouncemenIds, setFeatureAnnouncemenIds} from "@lib/helpers"

const FeatureAnnouncementLink = ({href, klass, text, latestAnnouncementId}) => {
  return (
    <a
      className={klass}
      href={href}
      target="_blank"
      rel="nofollow noopener noreferrer"
      onClick={(e) => {
        setFeatureAnnouncemenIds({
          featureAnnouncemenIds: getFeatureAnnouncemenIds(),
          latestAnnouncementId,
          e,
        })
      }}
    >
      {text}
    </a>
  )
}

export default FeatureAnnouncementLink
