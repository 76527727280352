import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputImage from "@components/molecules/FormInputImage"

type PropTypes = ComponentProps<typeof FormInputImage>

export default {
  title: "Molecules/FormInputImage",
  component: FormInputImage,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputImage {...args} />

export const Default = Template.bind({})
Default.args = {
  image: "https://placeimg.com/640/480/1",
}

export const Cover = Template.bind({})
Cover.args = {
  image: "https://placeimg.com/640/480/1",
  type: "cover",
}
