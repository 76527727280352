import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const AppPublisherLiveDetailIdVerifition = ({data}) => {
  return (
    <div className="AppPublisher__package__id-verification">
      <h3 className="AppPublisher__package__id-verification__h3">
        こちらのトークの購入には <br />
        本人確認が必要です
      </h3>

      <p className="AppPublisher__package__id-verification__p">
        本人確認には身分証明書が必要になります
      </p>

      <a className="Button" href={get(data, "identity_verification_mfa_path")}>
        本人確認に進む
      </a>
    </div>
  )
}

AppPublisherLiveDetailIdVerifition.propTypes = {}

export default AppPublisherLiveDetailIdVerifition
