import React from "react"
import PropTypes from "prop-types"
import className from "classnames"

import {toHalfWidth} from "@lib/helpers"

type PropTypes = Partial<{
  name: string
  klass: string
  placeholder: string
  autoComplete: string
}>

const FormInputPassword: React.FC<PropTypes> = ({
  name,
  klass,
  placeholder,
  autoComplete,
}) => {
  const [__isEyeVisible, __isEyeVisibleSet] = React.useState(false)

  return (
    <div className="Eye">
      <input
        className={klass}
        name={name}
        placeholder={placeholder}
        type={__isEyeVisible ? "text" : "password"}
        onBlur={(e) => {
          e.target.value = toHalfWidth(e.target.value)
        }}
        autoComplete={autoComplete}
      />

      <a
        className={className("Eye__a", {
          "Eye__a--active": __isEyeVisible,
          "Eye__a--tight": klass == "Input",
        })}
        onClick={() => {
          __isEyeVisibleSet(!__isEyeVisible)
        }}
      />
    </div>
  )
}

export default FormInputPassword
