import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import ModalSelectPublishers from "@components/molecules/ModalSelectPublishers"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof ModalSelectPublishers>

export default {
  title: "Molecules/ModalSelectPublishers",
  component: ModalSelectPublishers,
} as Meta

const Template: Story<PropTypes> = (args) => (
  <div>
    <ModalSelectPublishers {...args} />
    <div id="modals" />
  </div>
)

export const Default = Template.bind({})
Default.args = {
  data: {
    selected_publisher_ids: [],
    name: "name",
    publishers: [
      {
        access_scope: "public",
        agency_id: 1,
        created_at: "2021-05-19T16:51:38.147+09:00",
        custom_id: "",
        description: "example description 1",
        icon_image_key: null,
        id: 1,
        name: "佐藤太郎",
        token: "09da229b",
        updated_at: "2021-05-19T16:51:38.147+09:00",
        user_id: 1,
      },
      {
        access_scope: "public",
        agency_id: 2,
        created_at: "2021-06-19T16:51:38.147+09:00",
        custom_id: "",
        description: "example description 2",
        icon_image_key: null,
        id: 2,
        name: "Adam Smith",
        token: "09da229b",
        updated_at: "2021-06-19T16:51:38.147+09:00",
        user_id: 2,
      },
    ],
    t: packageLocale.ja.packages,
  },
}
