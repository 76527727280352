import React from "react"
import get from "lodash/get"
import className from "classnames"

import {axios} from "@lib/helpers"

class AdminSpotsSelectSupportStatus extends React.Component {
  static propTypes = {}

  static defaultProps = {}

  state = {
    __currentStatus: get(this.props, "spot.support_status"),
  }

  render = () => {
    const options = get(this.props, "options")

    return (
      <div className="Table__td__select">
        <span
          className={className("Button Button--xsmall", {
            "Button--white-purple":
              get(this.state, "__currentStatus") == options[0],
            "Button--orange": get(this.state, "__currentStatus") == options[1],
          })}
        >
          {get(
            this.props,
            `locale.status.${get(this.state, "__currentStatus")}`
          )}
        </span>

        <select
          className="Table__td__select__input"
          name="status"
          value={get(this.state, "__currentStatus")}
          onChange={(e) => {
            const _value = get(e, "target.value")

            axios
              .put(get(this.props, "paths.update_spot_path"), {
                spot: {
                  support_status: _value,
                },
              })
              .then((res) => {
                if (get(res, "data.success")) {
                  this.setState({
                    __currentStatus: _value,
                  })
                }
              })
          }}
        >
          {options.map((value, i) => (
            <option key={i} value={value}>
              {get(this.props, `locale.status.${value}`)}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default AdminSpotsSelectSupportStatus
