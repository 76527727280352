import React from "react"
import get from "lodash/get"
import className from "classnames"

import {tt} from "@lib/helpers"

const FormInputImage = ({image, edit_path, logo = false, t}) => {
  const $el = React.useRef()
  const [__file, __fileSet] = React.useState()

  React.useEffect(() => {
    // Find its parent `.AppForm__value__file`, then find a file input.
    const $input =
      $el.current.parentNode.parentNode.querySelector('input[type="file"]')

    if ($input) {
      $input.addEventListener("change", (e) => {
        __fileSet(get(e, "target.files.0"))
      })
    }
  }, [])

  const src = __file ? window.URL.createObjectURL(__file) : image ? image : null

  return (
    <React.Fragment>
      <img
        ref={$el}
        src={src}
        className={className("AppForm__value__profile-image__img", {
          "AppForm__value__profile-image__img--cover": true,
          "AppForm__value__profile-image__img--packages": true,
          "AppForm__value__profile-image__img--logo": logo,
          "AppForm__value__profile-image__img--hidden": !src,
        })}
      />

      {!__file && image && edit_path && (
        <a
          className="Button Button--white-purple AppForm__value__profile-image__a"
          href={edit_path}
        >
          {tt(t, "current.crop_image")}
        </a>
      )}
    </React.Fragment>
  )
}

FormInputImage.propTypes = {}

export default FormInputImage
