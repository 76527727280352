import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

import {axios, tt} from "@lib/helpers"

const DraftPackage = ({data}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const [__packages, __packagesSet] = React.useState({
    isLoading: true,
  })

  React.useEffect(() => {
    axios.get(get(data, "draft_packages_path")).then((res) => {
      __packagesSet({
        isLoading: false,
        data: get(res, "data"),
      })
    })
  }, [])

  if (get(__packages, "data.length", 0) == 0) {
    return null
  }

  const {t} = data

  return (
    <div className="DraftPackage">
      <a
        className="DraftPackage__a"
        onClick={() => {
          __isActiveSet(true)
        }}
      >
        {tt(t, "current.a", {count: get(__packages, "data.length", 0)})}
      </a>

      <div
        className={className("DraftPackage__modal", {
          "DraftPackage__modal--active": __isActive,
        })}
      >
        <a
          className="DraftPackage__modal__overlay"
          onClick={() => {
            __isActiveSet(false)
          }}
        />

        <div className="DraftPackage__modal__panel">
          <div className="DraftPackage__modal__panel__header">
            <h4 className="DraftPackage__modal__panel__header__h4">
              {tt(t, "current.h4")}
            </h4>

            <a
              className="DraftPackage__modal__panel__header__close"
              onClick={() => {
                __isActiveSet(false)
              }}
            />
          </div>

          <dl className="DraftPackage__modal__panel__dl">
            {(get(__packages, "data") || []).map((x, i) => (
              <div key={i} className="DraftPackage__modal__panel__dl__item">
                <a
                  className="DraftPackage__modal__panel__dl__item__dt"
                  href={get(x, "new_package_path")}
                >
                  {get(x, "record.title")}
                </a>

                <ul className="TalkCard__top__ul">
                  {(get(x, "labels") || []).map((y, j) => (
                    <li className="TalkCard__top__ul__li" key={j}>
                      {y}
                    </li>
                  ))}
                </ul>

                <dd className="DraftPackage__modal__panel__dl__item__dd">
                  {get(x, "start_at_and_end_at.date")}
                </dd>

                <time className="DraftPackage__modal__panel__dl__item__time">
                  {get(x, "start_at_and_end_at.time")}
                </time>

                <div className="DraftPackage__modal__panel__dl__item__buttons">
                  <a
                    className="DraftPackage__modal__panel__dl__item__buttons__a"
                    onClick={() => {
                      if (confirm(tt(t, "current.confirm"))) {
                        axios
                          .delete(get(x, "draft_package_path"))
                          .finally(() => {
                            window.location.reload()
                          })
                      }
                    }}
                  >
                    {tt(t, "current.delete")}
                  </a>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

DraftPackage.propTypes = {}

export default DraftPackage
