import * as React from "react"
import get from "lodash/get"
import map from "lodash/map"
import className from "classnames"
import "agora-rtc-sdk-ng"
import moment from "moment-timezone"

import {axios} from "@lib/helpers"

import Countdown from "@components/molecules/Countdown"

type Props = {
  publisherName: string
  userName: string
  data: {
    spotId: number
    spotToken: string
    appId: string
    channelKey: string
    token: string
    uid: number
    publisherUid: number
    startAt: string
    seconds: number
    lane_package_status_admin_spot_path: string
  }
  muteOnStart: boolean
  t: {
    [key: string]: any
  }
}

type State = {
  __activeSpotStartAt: string | null
  __activeSpotEndAt: string | null
  __streams: any
}

// We need to for a class-style React component here.
// Because if we use `React.useState` to store streams, when we need to mute/unmute
// we'll face a closure problem that `client.on` will be called redundantly.
// Though we can store streams in `React.useRef`, but that would separate
// the stream and its audio/video state in different places.
// Implementing this way and relying on `componentDidMount`
// is more straightforward.
class AdminSpotsShowLane extends React.Component<Props, State> {
  static defaultProps = {
    muteOnStart: false,
  }

  client = AgoraRTC.createClient({mode: "live", codec: "h264"})

  $$startAtSubtracted = moment(get(this.props, "data.startAt")).subtract(
    5,
    "minutes"
  )
  $$endAt = moment(get(this.props, "data.startAt")).add(
    get(this.props, "data.seconds"),
    "seconds"
  )

  ___$videos = React.createRef<HTMLDivElement>()

  state = {
    __activeSpotStartAt: null,
    __activeSpotEndAt: null,
    __streams: {},
  }

  getVideoId = (streamId: number): string => {
    return `video-${get(this.props, "data.spotId")}-${streamId}`
  }

  leave = () => {
    this.setState(
      {
        __streams: {},
      },
      () => {
        this.client.leave()
        if (this.___$videos.current) {
          this.___$videos.current.innerHTML = ""
        }
      }
    )
  }

  poller = async () => {
    const res = await axios.get(
      get(this.props, "data.lane_package_status_admin_spot_path")
    )

    const _startAt = get(res, "data.start_at")
    const _endAt = get(res, "data.end_at")

    if (_startAt && _endAt) {
      this.setState(
        {
          __activeSpotStartAt: moment(_startAt * 1000).toISOString(),
          __activeSpotEndAt: moment(_endAt * 1000).toISOString(),
        },
        () => {
          window.setTimeout(async () => {
            try {
              const uid1 = await this.client.join(
                appId,
                channelKey,
                token,
                Number(uid)
              )
              console.log("join success")
            } catch (e) {
              console.log("join failed", e)
            }
          }, moment.duration(moment(get(this.state, "__activeSpotStartAt")).subtract(5, "seconds").diff(moment())).as("milliseconds"))

          window.setTimeout(() => {
            this.leave()
          }, moment.duration(moment(get(this.state, "__activeSpotEndAt")).diff(moment())).as("milliseconds"))
        }
      )
    } else if (get(res, "data.status") != "done") {
      if (
        moment().isBetween(
          moment(get(this.props, "data.startAt")),
          this.$$endAt
        )
      ) {
        // Since the call already started, we need to be aware of the incoming queue
        // immediately. So we shorten the polling duration here.
        window.setTimeout(() => {
          this.poller()
        }, 5000)
      } else if (moment().isBetween(this.$$startAtSubtracted, this.$$endAt)) {
        window.setTimeout(() => {
          this.poller()
        }, 30000)
      }
    }
  }

  componentDidMount = () => {
    if (moment().isAfter(this.$$endAt)) {
      return
    }

    window.setTimeout(
      this.poller,
      Math.max(moment(this.$$startAtSubtracted).diff(moment()), 0)
    )

    window.setTimeout(this.leave, moment(this.$$endAt).diff(moment()))

    this.client.on("user-published", async (remoteUser, mediaType) => {
      const stream = await this.client.subscribe(remoteUser, mediaType)

      if (stream) {
        if (mediaType == "video") {
          const $video = document.createElement("div")
          const _id = this.getVideoId(stream._uintId)
          $video.setAttribute("id", _id)
          $video.setAttribute("class", "AdminSpotsShow__video")
          $video.setAttribute("style", `order: ${stream._uintId}`) // The order of appended videos and items in this.state may not match naturally, so we need to sort by their IDs.
          if (!document.querySelector(_id)) {
            if (this.___$videos.current) {
              this.___$videos.current.appendChild($video)
            }
          }
          stream.play(_id, {muted: this.props.muteOnStart})
        }
        if (mediaType == "audio") {
          this.setState((prevState) => ({
            ...prevState,
            __streams: {
              ...get(prevState, "__streams"),
              [stream.streamId]: {
                _id: stream._uintId,
                _stream: stream,
              },
            },
          }))
        }
      }
    })

    this.client.on("user-unpublished", async (remoteUser, mediaType) => {
      const stream = await this.client.unsubscribe(remoteUser, mediaType)
      if (stream) {
        this.setState((prevState) => ({
          __streams: {
            ...prevState.__streams,
            [stream.streamId]: null,
          },
        }))
        stream.stop()
        if (mediaType == "video") {
          const $video = document.querySelector(
            `#${this.getVideoId(stream._uintId)}`
          )

          if ($video) {
            if (this.___$videos.current) {
              this.___$videos.current.removeChild($video)
            }
          }
        }
      }
    })
  }

  render = () => {
    return (
      <div className="AdminSpotsShow__data">
        <div className="AdminSpotsShow__videos" ref={this.___$videos}></div>

        <div className="AdminSpotsShow__infos">
          {map(get(this.state, "__streams"), (x: any, i) => {
            const _streamState = x

            if (!get(x, "_id")) {
              return null
            }

            return (
              <div
                className="AdminSpotsShow__info"
                key={i}
                style={{
                  order: get(x, "_id"), // Same order problem.
                }}
              >
                {_streamState && (
                  <div className="Buttons">
                    <a
                      className={className("Button", {
                        "Button--small": true,
                        "Button--white": true,
                      })}
                      onClick={() => {
                        const _remoteUser = _streamState._remoteUser

                        _remoteUser._audioTrack.stop()
                      }}
                    >
                      {get(this.props, "t.mute")}
                    </a>

                    <a
                      className={className("Button", {
                        "Button--small": true,
                      })}
                      onClick={() => {
                        const _remoteUser = _streamState._remoteUser

                        _remoteUser._audioTrack.play()
                      }}
                    >
                      {get(this.props, "t.unmute")}
                    </a>
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <Countdown
          $$startAt={moment(get(this.props, "data.startAt"))}
          $$endAt={this.$$endAt}
        />
      </div>
    )
  }
}

export default AdminSpotsShowLane
