import React from "react"
import Cropper from "cropperjs"
import className from "classnames"
import {entries} from "lodash"

import {_axios} from "@lib/helpers"

import "cropperjs/dist/cropper.css"

class FormInputImageCropper extends React.Component<
  {
    data: {
      aspect_ratio?: number
      image: string
      update_path: string
      back_path?: string
      params_key: string
      form_data?: {
        [key: string]: string
      }
      t: {
        current: {
          crop: string
        }
      }
    }
  },
  {__isLoading: boolean}
> {
  ___$el = React.createRef<HTMLImageElement>()
  ___cropper: Cropper | null = null

  state = {
    __isLoading: false,
  }

  componentDidMount = () => {
    if (this.___$el?.current) {
      this.___cropper = new Cropper(this.___$el.current, {
        viewMode: 3,
        aspectRatio: this.props?.data?.aspect_ratio || 4,
        autoCropArea: 1,
      })
    }
  }

  render = () => {
    return (
      <div className="FormInputImageCropper">
        <div className="FormInputImageCropper__wrapper">
          <img
            ref={this.___$el}
            src={this.props.data?.image}
            className="FormInputImageCropper__img"
          />
        </div>

        <div className="FormInputImageCropper__buttons">
          <a
            className={className("Button", {
              "Button--disabled": this.state.__isLoading,
            })}
            onClick={() => {
              this.setState(
                {
                  __isLoading: true,
                },
                () => {
                  this.___cropper
                    ?.getCroppedCanvas()
                    .toBlob((blob: Blob | null) => {
                      const _formData = new FormData()

                      _formData.append(
                        "authenticity_token",
                        document
                          .querySelector('meta[name="csrf-token"]')
                          ?.getAttribute("content") || ""
                      )

                      _formData.append(
                        this.props.data?.params_key,
                        blob as Blob
                      )

                      if (this.props?.data?.form_data) {
                        entries(this.props?.data?.form_data).forEach(
                          ([_key, _value]) => {
                            _formData.append(_key, _value)
                          }
                        )
                      }

                      _axios
                        .put(this.props.data?.update_path, _formData)
                        .finally(() => {
                          window.location.reload()
                        })
                    })
                }
              )
            }}
          >
            {this.state.__isLoading
              ? "Loading …"
              : this.props?.data?.t?.current?.crop}
          </a>

          {this.props?.data?.back_path && (
            <a
              className="Button Button--white"
              href={this.props?.data?.back_path}
            >
              戻る
            </a>
          )}
        </div>
      </div>
    )
  }
}

export default FormInputImageCropper
