import React from "react"
import queryString from "query-string"

type PropTypes = {
  data: {
    year_months: string[]
    year_month: string
  }
  tab?: number
}

const YearMonthSelect: React.FC<PropTypes> = ({data, tab}) => {
  const {year_months, year_month} = data

  const [selectYearMonth, setSelectYearMonth] = React.useState(year_month)

  React.useEffect(() => {
    if (year_month !== selectYearMonth) {
      const query = `?${queryString.stringify({
        ...queryString.parse(window.location.search),
        year_month: selectYearMonth,
      })}`

      if (window.location.pathname == "/ja/admin/sales_reports") {
        const changedQuery = query.replace(/(&tab=0|&tab=1|&tab=2|&tab=3)/g, "")
        window.location.href = changedQuery + "&tab=" + (tab || "")
      } else {
        window.location.href = query
      }
    }
  }, [year_month, selectYearMonth, tab])

  return (
    <div className="AdminSalesReportsIndexOwner__select">
      <select
        className="AdminSalesReportsIndexOwner__select__input"
        value={selectYearMonth}
        onChange={(e) => setSelectYearMonth(e.target.value)}
      >
        {year_months.map((x, i) => (
          <option key={i}>{x}</option>
        ))}
      </select>
    </div>
  )
}

export default YearMonthSelect
