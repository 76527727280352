import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import last from "lodash/last"
import dropRight from "lodash/dropRight"
import lowerCase from "lodash/lowerCase"
import className from "classnames"

import {axios, toHalfWidth} from "@lib/helpers"

const FormInputPostalCode = ({
  value,
  name,
  klass,
  maxlength,
  placeholder,
  data,
}) => {
  const ___$input = React.useRef()

  return (
    <div className="AppForm__value__postcode">
      <input
        className={klass ? klass : "Input"}
        type="text"
        name={name}
        ref={___$input}
        defaultValue={value}
        onBlur={(e) => {
          e.target.value = toHalfWidth(e.target.value)
        }}
        onKeyDown={(e) => {
          const key = lowerCase(get(e, "key"))

          if (
            !(
              key.match(/\d/) ||
              key == "backspace" ||
              key == "tab" ||
              key == "enter"
            )
          ) {
            e.preventDefault()
            return false
          }
        }}
        placeholder={placeholder}
        maxLength={maxlength}
      />

      <button
        className="Button Button--white-purple"
        onClick={(e) => {
          e.preventDefault()

          axios
            .post(get(data, "address_from_postcode_settings_user_path"), {
              postcode: get(___$input, "current.value"),
            })
            .then((res) => {
              let addresses = get(res, "data")

              if (get(addresses, "length") > 1) {
                addresses = get(addresses, "0")
              }

              const $form = get(
                ___$input,
                "current.parentNode.parentNode.parentNode.parentNode.parentNode"
              )

              $form.querySelector('[name="user[prefecture]"]').value = get(
                addresses,
                "prefecture"
              )

              $form.querySelector('[name="user[city]"]').value = get(
                addresses,
                "city"
              )

              $form.querySelector('[name="user[address1]"]').value = get(
                addresses,
                "town"
              )
            })
            .catch((e) => {})
        }}
      >
        {get(data, "t.current.auto_fill")}
      </button>
    </div>
  )
}

FormInputPostalCode.propTypes = {}

export default FormInputPostalCode
