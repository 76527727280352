import React from "react"
import {get, padStart, isNil} from "lodash"
import className from "classnames"
import moment, {Moment} from "moment"

import {tt} from "@lib/helpers"

type DateTimePickerProps = {
  onChange?: ({__$$selectingDateTime}: {__$$selectingDateTime: Moment}) => void
  t: any
  name?: string
  $$defaultValue: Moment
  __errors?: {
    [key: string]: string
  } | null
  eKey?: string
  disabled?: boolean
  enableCreatableTime?: boolean
  enableTime?: boolean
  updateStatesPerPropsChange?: boolean
  BEFORE_CALL_PREPARATION_TIME?: number
  klassName?: string
  enablePast?: boolean
}

type DateTimePickerState = {
  __$$selectingDateTime: Moment
  __$$selectingMonth: Moment
  __isActive: boolean
}

class DateTimePicker extends React.Component<
  DateTimePickerProps,
  DateTimePickerState
> {
  state: DateTimePickerState = {
    __$$selectingDateTime: this.props.$$defaultValue,
    __$$selectingMonth: moment(this.props.$$defaultValue)
      .startOf("month")
      .add(1, "day"), // To escape from TZ hell, e.g. Japan and Thailand will think a start of month to be a different month.
    __isActive: false,
  }

  onChangeMonth = ({op}: {op: "subtract" | "add"}) => {
    this.setState((prevState: DateTimePickerState) => ({
      __$$selectingMonth: moment(prevState.__$$selectingMonth)[op](1, "month"),
    }))
    this.setState({})
  }

  componentDidUpdate = (
    prevProps: DateTimePickerProps,
    prevState: DateTimePickerState
  ) => {
    if (this.props.onChange) {
      if (
        +prevState.__$$selectingDateTime != +this.state.__$$selectingDateTime
      ) {
        this.props.onChange({
          __$$selectingDateTime: this.state.__$$selectingDateTime,
        })
      }
    }

    if (this.props.updateStatesPerPropsChange) {
      if (+prevProps.$$defaultValue != +this.props.$$defaultValue) {
        this.setState({
          __$$selectingDateTime: this.props.$$defaultValue,
        })
      }
    }
  }

  render = () => {
    const {
      t,
      name,
      __errors,
      eKey,
      disabled,
      enableTime = true,
      klassName = "AppInput",
    } = this.props

    // @ts-ignore
    const gon = window.gon

    return (
      <div
        className={className("DateTimePicker", {
          "DateTimePicker--disabled": disabled,
        })}
      >
        <input
          type="hidden"
          name={name}
          value={this.state.__$$selectingDateTime?.format(
            "YYYY-MM-DD HH:mm:ss"
          )}
        />

        <span
          className={className(klassName, {
            [`${klassName}--error`]: get(__errors, eKey),
          })}
          onClick={() => {
            this.setState((prevState: DateTimePickerState) => ({
              __isActive: !prevState.__isActive,
            }))
          }}
        >
          {this.state.__$$selectingDateTime?.format(
            enableTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
          )}
        </span>

        <a
          className={className("DateTimePicker__overlay", {
            "DateTimePicker__overlay--active": this.state.__isActive,
          })}
          onClick={() => {
            this.setState(() => ({
              __isActive: false,
            }))
          }}
        />

        <div
          className={className("DateTimePicker__panel", {
            "DateTimePicker__panel--active": this.state.__isActive,
          })}
        >
          {(() => {
            const $$firstDayOfMonth = moment(this.state.__$$selectingMonth)
              .startOf("month")
              .startOf("week")

            const $$lastDayOfMonth = moment(this.state.__$$selectingMonth)
              .endOf("month")
              .endOf("week")

            let dayInterval: Date[] = []
            const $$dayRunner = moment($$firstDayOfMonth)

            do {
              dayInterval = [...dayInterval, $$dayRunner.toDate()]

              $$dayRunner.add(1, "day")
            } while (!$$dayRunner.isAfter($$lastDayOfMonth, "day"))

            return (
              <div className={className("DateTimePicker__calendar", {})}>
                <div className="DateTimePicker__calendar__header">
                  <a
                    className={className(
                      "DateTimePicker__calendar__header__a",
                      {
                        "DateTimePicker__calendar__header__a--disabled":
                          !this.props.enablePast &&
                          this.state.__$$selectingMonth?.isSame(
                            moment(),
                            "month"
                          ),
                      }
                    )}
                    onClick={() => {
                      this.onChangeMonth({op: "subtract"})
                    }}
                  >
                    {tt(t, "prev")}
                  </a>

                  <strong className="DateTimePicker__calendar__header__strong">
                    {moment(this.state.__$$selectingMonth).format("YYYY MMM")}
                  </strong>

                  <a
                    className="DateTimePicker__calendar__header__a"
                    onClick={() => {
                      this.onChangeMonth({op: "add"})
                    }}
                  >
                    {tt(t, "next")}
                  </a>
                </div>

                <div className="DateTimePicker__calendar__body">
                  {(tt(t, "day_of_week") || []).map((x: string, i: number) => (
                    <span className="DateTimePicker__calendar__span" key={i}>
                      {x}
                    </span>
                  ))}

                  {dayInterval.map((x, i) => (
                    <a
                      className={className("DateTimePicker__calendar__a", {
                        "DateTimePicker__calendar__a--today": moment(x).isSame(
                          moment(),
                          "day"
                        ),
                        "DateTimePicker__calendar__a--selecting": moment(
                          x
                        ).isSame(
                          moment(this.state.__$$selectingDateTime),
                          "day"
                        ),
                        "DateTimePicker__calendar__a--diff-month": !moment(
                          x
                        ).isSame(
                          moment(this.state.__$$selectingMonth),
                          "month"
                        ),
                        "DateTimePicker__calendar__a--disabled":
                          !this.props.enablePast &&
                          moment(x).isBefore(moment(), "day"),
                      })}
                      key={i}
                      onClick={() => {
                        this.setState((prevState: DateTimePickerState) => ({
                          __$$selectingDateTime: moment(x).set({
                            hour: moment(
                              prevState.__$$selectingDateTime
                            ).hour(),
                            minute: moment(
                              prevState.__$$selectingDateTime
                            ).minute(),
                          }),
                        }))
                      }}
                      data-format={moment(x).format("YYYY-MM-DD")}
                    >
                      {moment(x).format("D")}
                    </a>
                  ))}
                </div>
              </div>
            )
          })()}

          {enableTime && (
            <React.Fragment>
              {(() => {
                const $$creatableTime = moment()
                  .add(
                    gon?.env == "development" &&
                      !isNil(this.props.BEFORE_CALL_PREPARATION_TIME)
                      ? this.props.BEFORE_CALL_PREPARATION_TIME
                      : 300,
                    "seconds"
                  )
                  .startOf("minute")

                return (
                  <div className="DateTimePicker__time">
                    <select
                      className="Select"
                      value={moment(this.state.__$$selectingDateTime).hour()}
                      onChange={(e) => {
                        const _value = e?.target?.value
                        const $$dest = moment(this.state.__$$selectingDateTime)
                          .hour(Number(_value))
                          .startOf("minute")

                        this.setState(() => ({
                          __$$selectingDateTime:
                            this.props.enableCreatableTime &&
                            $$creatableTime.isAfter($$dest)
                              ? $$creatableTime
                              : $$dest,
                        }))
                      }}
                    >
                      {Array.from(Array(24)).map((_, i) => {
                        if (
                          this.props.enableCreatableTime &&
                          moment(this.state.__$$selectingDateTime)
                            .hour(i)
                            .endOf("hour")
                            .isBefore($$creatableTime)
                        ) {
                          return null
                        }

                        return <option key={i}>{i}</option>
                      })}
                    </select>

                    <span className="DateTimePicker__time__span">:</span>

                    <select
                      className="Select"
                      value={moment(this.state.__$$selectingDateTime).minute()}
                      onChange={(e) => {
                        const _value = e?.target?.value
                        const $$dest = moment(this.state.__$$selectingDateTime)
                          .minute(Number(_value))
                          .startOf("minute")

                        this.setState(() => ({
                          __$$selectingDateTime:
                            this.props.enableCreatableTime &&
                            $$creatableTime.isAfter($$dest)
                              ? $$creatableTime
                              : $$dest,
                        }))
                      }}
                    >
                      {Array.from(Array(60)).map((_, i) => {
                        if (
                          this.props.enableCreatableTime &&
                          moment(this.state.__$$selectingDateTime)
                            .minute(i)
                            .isBefore($$creatableTime)
                        ) {
                          return null
                        }

                        return (
                          <option key={i} value={i}>
                            {padStart(i.toString(), 2, "0")}
                          </option>
                        )
                      })}
                    </select>

                    <span className="DateTimePicker__time__span">:</span>

                    <select
                      className="Select"
                      value={moment(this.state.__$$selectingDateTime).seconds()}
                      onChange={(e) => {
                        const _value = e?.target.value
                        const $$dest = moment(
                          this.state.__$$selectingDateTime
                        ).second(Number(_value))

                        this.setState(() => ({
                          __$$selectingDateTime:
                            this.props.enableCreatableTime &&
                            $$creatableTime.isAfter($$dest)
                              ? $$creatableTime
                              : $$dest,
                        }))
                      }}
                    >
                      {[0, 15, 30, 45].map((x, i) => {
                        if (
                          this.props.enableCreatableTime &&
                          moment(this.state.__$$selectingDateTime)
                            .second(x)
                            .isBefore($$creatableTime)
                        ) {
                          return null
                        }

                        return (
                          <option key={i} value={x}>
                            {padStart(x.toString(), 2, "0")}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )
              })()}
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default DateTimePicker
