import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import FormInputImageCropper from "@components/molecules/FormInputImageCropper"
import adminLocale from "@locales/views/admin/ja.yml"

type PropTypes = ComponentProps<typeof FormInputImageCropper>

export default {
  title: "Molecules/FormInputImageCropper",
  component: FormInputImageCropper,
} as Meta

const Template: Story<PropTypes> = (args) => <FormInputImageCropper {...args} />

export const Default = Template.bind({})
Default.args = {
  data: {
    image: "https://http.cat/200",
    agency_update_admin_events_path: "example.com",
    params_key: "event[header_image]",
    t: {
      current: {
        crop: adminLocale.ja.admin.events.agency_edit_cover.crop,
      },
    },
  },
}
