import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const ModalAbout = ({data}) => {
  const {code, step_files, t} = data

  const [__isActive, __isActiveSet] = React.useState(true)
  const [__indexImage, __indexImageSet] = React.useState(0)

  return (
    <div
      className={className("ModalAbout", {
        "ModalAbout--active": __isActive,
      })}
    >
      <a className="ModalAbout__overlay" />

      <div className="ModalAbout__panel">
        <div className="ModalAbout__panel__images">
          {step_files.map((x, i) => (
            <div
              className={className("ModalAbout__panel__images__item", {
                "ModalAbout__panel__images__item--active": i == __indexImage,
              })}
            >
              <img className="ModalAbout__panel__images__item__img" src={x} />
            </div>
          ))}
        </div>

        <div className="ModalAbout__panel__nav">
          {step_files.map((x, i) => (
            <a
              className={className("ModalAbout__panel__nav__a", {
                "ModalAbout__panel__nav__a--active": i == __indexImage,
              })}
              onClick={() => {
                __indexImageSet(i)
              }}
            />
          ))}
        </div>

        <div className="ModalAbout__panel__buttons">
          {__indexImage == get(step_files, "length") - 1 ? (
            <a
              className="Button"
              onClick={() => {
                __isActiveSet(false)
              }}
            >
              {get(t, "start")}
            </a>
          ) : (
            <>
              <a
                className="Button Button--white-purple"
                onClick={() => {
                  __indexImageSet(__indexImage + 1)
                }}
              >
                {get(t, "next")}
              </a>

              <a
                className="Button"
                onClick={() => {
                  __isActiveSet(false)
                }}
              >
                {get(t, "skip")}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ModalAbout.propTypes = {}

export default ModalAbout
