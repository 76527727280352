import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import forEach from "lodash/forEach"
import map from "lodash/map"
import some from "lodash/some"
import className from "classnames"

const AppUsersNew = ({}) => {
  const $el = React.useRef()

  React.useEffect(() => {
    const $form = get($el, "current.parentNode.parentNode")
    const $inputs = $form.querySelectorAll(".AppForm__checkbox__input")
    const $submit = $form.querySelector('input[type="submit"]')

    forEach($inputs, ($input) => {
      $input.addEventListener("change", (e) => {
        $submit.disabled = some(
          map($inputs, ($inputs) => get($inputs, "checked") == false)
        )
      })
    })
  }, [])

  return <div className="AppUsersNew__react" ref={$el}></div>
}

AppUsersNew.propTypes = {}

export default AppUsersNew
