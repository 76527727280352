import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import forEach from "lodash/forEach"
import className from "classnames"

const PackagesBulkNewForm = ({}) => {
  const $el = React.useRef()
  const $form = React.useRef()

  React.useEffect(() => {
    $form.current = $el.current.parentNode.parentNode
    const $radioNumber = $form.current.querySelector(
      "#bulk_generation_type-number"
    )
    const $radioEndAt = $form.current.querySelector(
      "#bulk_generation_type-end_at"
    )

    forEach($form.current.querySelectorAll('input[type="radio"]'), ($radio) => {
      $radio.addEventListener("change", (e) => {
        if (get(e, "target.id") == "package_bulk_generation_type_number") {
          $radioEndAt.classList.add("AppForm__row--hidden")
          $radioNumber.classList.remove("AppForm__row--hidden")
        } else {
          $radioEndAt.classList.remove("AppForm__row--hidden")
          $radioNumber.classList.add("AppForm__row--hidden")
          $form.current.querySelector("#package_bulk_number").value = ""
        }
      })
    })

    window.setTimeout(() => {
      // Force check first radio, so we won't have a problem with back button.
      $form.current.querySelector(
        "#package_bulk_generation_type_number"
      ).checked = true
    }, 100)
  }, [])

  return <div className="AppPackagesBulkNew__react" ref={$el}></div>
}

PackagesBulkNewForm.propTypes = {}

export default PackagesBulkNewForm
