import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import TalkCardShare from "@components/molecules/TalkCardShare"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof TalkCardShare>

export default {
  title: "Molecules/TalkCardShare",
  component: TalkCardShare,
} as Meta

const Template: Story<PropTypes> = (args) => <TalkCardShare {...args} />

export const Default = Template.bind({})
Default.args = {
  text: "postmessage",
  sns_src: "example.com",
  app_src: "example.com",
  mail_src: "mailto:example@example.com",
  t: packageLocale.ja.packages.index,
}
