import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"
import MobileDetect from "mobile-detect"

const ShowTalkLink = ({data}) => {
  const [__isShowLink, __isShowLinkSet] = React.useState(false)

  React.useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent)

    if (
      get(data, "enable_web_call") &&
      (get(data, "is_publisher") ||
        (!get(data, "is_publisher") && md.mobile()) ||
        get(window.gon, "env") == "development")
    ) {
      __isShowLinkSet(true)
    }
  }, [])

  if (!__isShowLink) {
    return null
  }

  return (
    <div className="TalkCard__bottom__talk-link">
      <a
        className={className("Button", {
          "Button--green": get(data, "green"),
        })}
        href={get(data, "href")}
      >
        {get(data, "label")}
      </a>
    </div>
  )
}

ShowTalkLink.propTypes = {}

export default ShowTalkLink
