import React from "react"
import get from "lodash/get"
import className from "classnames"

import {tt} from "@lib/helpers"
import {useSearchSuggestion} from "@lib/hooks"

const AdminUsersIndexFilter = ({
  admin_users_url,
  suggestion_url,
  agency_id,
  agencies = [],
  publisher_name,
  purchaser_name,
  email,
  user_id,
  t,
}) => {
  const [__isActive, __isActiveSet] = React.useState(false)

  return (
    <div className="Filter">
      <div
        className={className("Filter__panel", {
          "Filter__panel--active": __isActive,
        })}
      >
        <div className="Filter__panel__status">
          <p className="Filter__form__label__span">{tt(t, "spots.status")}</p>

          <p className="Filter__panel__status__p">
            {tt(t, "current.filter.agency")}：
            <strong>
              {agency_id
                ? agencies?.find((x) => x?.id == agency_id)?.name
                : tt(t, "current.filter.agency_all")}
            </strong>
          </p>

          {publisher_name && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.filter.publisher_name")}：
              <strong>{publisher_name}</strong>
            </p>
          )}

          {purchaser_name && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.filter.user_name")}：
              <strong>{purchaser_name}</strong>
            </p>
          )}

          {email && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.filter.email")}：<strong>{email}</strong>
            </p>
          )}

          {user_id && (
            <p className="Filter__panel__status__p">
              {tt(t, "current.filter.user_id")}：<strong>{user_id}</strong>
            </p>
          )}
        </div>

        <a
          className="Filter__panel__handle"
          onClick={() => {
            __isActiveSet(!__isActive)
          }}
        >
          <span
            className={className("Filter__panel__handle__span", {
              "Filter__panel__handle__span--active": __isActive,
            })}
          >
            {tt(t, "spots.filter.handle")}
          </span>
        </a>

        <form
          className={className("Filter__form", {
            "Filter__form--active": __isActive,
          })}
        >
          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.agency")}
            </span>

            <div className="Filter__form__label__input">
              <select
                type="text"
                name="agency_id"
                className="Select"
                defaultValue={agency_id}
              >
                <option value="">{tt(t, "current.filter.agency_all")}</option>

                {agencies.map((x, i) => (
                  <option value={get(x, "id")} key={i}>
                    {get(x, "name")}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.publisher_name")}
            </span>

            <Filter
              query_key="publisher_name"
              query={publisher_name}
              suggestion_url={suggestion_url}
            />
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.user_name")}
            </span>

            <Filter
              query_key="purchaser_name"
              query={purchaser_name}
              suggestion_url={suggestion_url}
            />
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.email")}
            </span>

            <Filter
              query_key="email"
              query={email}
              suggestion_url={suggestion_url}
            />
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span">
              {tt(t, "current.filter.user_id")}
            </span>

            <div className="Filter__form__label__input">
              <input type="text" name="user_id" defaultValue={user_id} />
            </div>
          </div>

          <div className="Filter__form__label">
            <span className="Filter__form__label__span Filter__form__label__span--no-smo">
              &nbsp;
            </span>

            <div className="Filter__form__label__input">
              <div className="Buttons">
                <button className="Button Button--small">
                  {tt(t, "current.filter.submit")}
                </button>

                <a
                  className="Button Button--small Button--white"
                  href={admin_users_url}
                >
                  {tt(t, "current.filter.clear")}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

AdminUsersIndexFilter.propTypes = {}

export default AdminUsersIndexFilter

const Filter = ({query, query_key, suggestion_url}) => {
  const {__text, __textSet, __isSearchingSet, $results} = useSearchSuggestion({
    query,
    query_key,
    suggestion_url,
    doNotSubmit: true,
  })

  return (
    <div className="SearchSuggestion__form__label__input SearchSuggestion__form__label__input--brown">
      <input
        type="text"
        name={query_key}
        autoComplete="false"
        value={__text}
        onChange={(e) => {
          __isSearchingSet(true)
          __textSet(get(e, "target.value"))
        }}
      />

      {$results}
    </div>
  )
}
