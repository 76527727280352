import React from "react"
import get from "lodash/get"
import * as __axios from "axios"

import {axios} from "@lib/helpers"

class AdminBookingsEditSpotMemo extends React.Component {
  state = {
    __isEditing: false,
  }

  save = (e) => {
    e.preventDefault()

    axios
      .put(get(this.props, "paths.update_spot_path"), {
        spot: {
          memo: get(e, "target.elements.memo.value"),
        },
      })
      .then((_res) => {
        window.location.reload()
      })
  }

  render = () => {
    const t = get(this.props, "locale")

    return (
      <React.Fragment>
        <div className="AdminForm__row">
          {!get(this.state, "__isEditing") ? (
            <div className="AdminForm__memo">
              <div className="AdminForm__value">
                {get(this.props, "data.spot.memo")}
              </div>

              <a
                className="AdminForm__memo__edit-button"
                onClick={() => {
                  this.setState({
                    __isEditing: true,
                  })
                }}
              >
                {get(t, "edit")}
              </a>
            </div>
          ) : (
            <form className="AdminForm__memo-form" onSubmit={this.save}>
              <textarea
                className="AppInput"
                name="memo"
                defaultValue={get(this.props, "data.spot.memo")}
              />

              <button className="Button">{get(t, "save")}</button>

              <a
                className="Button Button--white-purple"
                onClick={(e) => {
                  this.setState({
                    __isEditing: false,
                  })
                }}
              >
                {get(t, "cancel")}
              </a>
            </form>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default AdminBookingsEditSpotMemo
