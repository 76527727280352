import React from "react"
import PropTypes from "prop-types"
import className from "classnames"
import get from "lodash/get"

const ReadPublisherDescription = ({description}) => {
  const [__height, __heightSet] = React.useState("auto")
  const $el = React.useRef()

  React.useEffect(() => {
    if (get($el, "current.clientHeight") > 100) {
      __heightSet("8em")
    }
  }, [])

  return (
    <div
      className="AppPublisher__description"
      style={{
        height: __height,
      }}
    >
      <p
        className="AppPublisher__description__p"
        dangerouslySetInnerHTML={{__html: description}}
        ref={$el}
      ></p>

      {__height != "auto" && (
        <a
          className="AppPublisher__description__more"
          onClick={() => {
            __heightSet("auto")
          }}
        >
          <div className="AppPublisher__description__btn">続きを読む</div>
        </a>
      )}
    </div>
  )
}

ReadPublisherDescription.propTypes = {}

export default ReadPublisherDescription
