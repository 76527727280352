import React, {ComponentProps} from "react"
import {Meta, Story} from "@storybook/react"
import DraftPackage from "@components/molecules/DraftPackage"
import packageLocale from "@locales/views/packages/ja.yml"

type PropTypes = ComponentProps<typeof DraftPackage>

export default {
  title: "Molecules/DraftPackage",
  component: DraftPackage,
} as Meta

const Template: Story<PropTypes> = (args) => <DraftPackage {...args} />

export const Default = Template.bind({})
Default.args = {
  data: {
    draft_packages_path: "",
    t: packageLocale.ja.packages.draft,
  },
}
