import React from "react"
import ReactDOM from "react-dom"
import get from "lodash/get"
import find from "lodash/find"
import join from "lodash/join"
import isArray from "lodash/isArray"
import filter from "lodash/filter"
import className from "classnames"

import {axios, formInputsToObject} from "@lib/helpers"

const ModalSelectPublishers = ({data}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const [__selectedIds, __selectedIdsSet] = React.useState(() =>
    get(data, "selected_publisher_ids")
      ? get(data, "selected_publisher_ids").map((x: any) => Number(x))
      : []
  )

  return (
    <React.Fragment>
      <input
        type="hidden"
        name={get(data, "name")}
        value={join(__selectedIds, ",") || ""}
      />

      <div className="AdminAgenciesPointManagement__row">
        <div className="AdminAgenciesPointManagement__label">
          {get(data, "t.bulk_new.label7")}
        </div>

        <div className="AdminAgenciesPointManagement__value">
          <a
            className="AppProduct__a AppProduct__a--caret"
            onClick={() => {
              __isActiveSet(true)
            }}
          >
            {get(__selectedIds, "length") == 0 ? (
              <span className="AppProduct__select">
                {get(data, "t.bulk_new.label8")}
              </span>
            ) : (
              <div className="AppProduct__select">
                {__selectedIds.map((x: any, i: number) => (
                  <p key={i}>
                    {get(
                      find(get(data, "publishers"), (y) => get(y, "id") == x),
                      "name"
                    )}
                  </p>
                ))}
              </div>
            )}
          </a>
        </div>
      </div>

      <Modal
        data={data}
        __isActive={__isActive}
        __isActiveSet={__isActiveSet}
        __selectedIds={__selectedIds}
        __selectedIdsSet={__selectedIdsSet}
      />
    </React.Fragment>
  )
}

ModalSelectPublishers.propTypes = {}

export default ModalSelectPublishers

const Modal = ({
  data,
  __isActive,
  __isActiveSet,
  __selectedIds,
  __selectedIdsSet,
}) => {
  const [__text, __textSet] = React.useState("")
  const [__filteredNames, __filteredNamesSet] = React.useState()

  // <AdminSpotsNewPackage /> already has a <form />,
  // so we need to teleport this elsewhere.
  return ReactDOM.createPortal(
    <div
      className={className("ModalSelectPublishers", {
        "ModalSelectPublishers--active": __isActive,
      })}
    >
      <a
        className="ModalSelectPublishers__overlay"
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div className="ModalSelectPublishers__panel">
        <div className="AdminForm">
          <div className="AdminAgenciesPointManagement__form">
            <h2 className="AdminAgenciesPointManagement__h1">配信者を選択</h2>

            <div className="SearchSuggestion">
              <form
                className="SearchSuggestion__form"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault()
                  e.stopPropagation() // Not to invoke outer form in create package.

                  axios
                    .get(get(data, "suggestion_url"), {
                      ...formInputsToObject({
                        elements: get(e, "target.elements"),
                      }),
                    })
                    .then((res) => {
                      __filteredNamesSet(get(res, "data"))
                    })
                }}
              >
                <label className="SearchSuggestion__form__label">
                  <span className="SearchSuggestion__form__label__span">
                    {get(data, "t.select_user.search_placeholder")}
                  </span>

                  <div className="SearchSuggestion__form__label__input">
                    <input
                      type="text"
                      name="publisher_name"
                      value={__text}
                      onChange={(e) => {
                        __textSet(get(e, "target.value"))
                      }}
                    />
                  </div>
                </label>

                <div className="SearchSuggestion__form__label">
                  <span className="SearchSuggestion__form__label__span SearchSuggestion__form__label__span--no-stal">
                    &nbsp;
                  </span>

                  <div className="SearchSuggestion__form__label__input">
                    <div className="Buttons">
                      <button className="Button Button--small">検索</button>

                      <a
                        className="Button Button--small Button--white"
                        onClick={() => {
                          __textSet("")
                          __filteredNamesSet()
                        }}
                      >
                        クリア
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="AdminAgenciesPointManagement__users">
              {(() => {
                let _publishers = get(data, "publishers") || []

                if (isArray(__filteredNames)) {
                  _publishers = filter(_publishers, (x) =>
                    find(__filteredNames, (y) => y == get(x, "name"))
                  )
                }

                return (
                  <React.Fragment>
                    {_publishers.map((x, i) => (
                      <div className="AppProduct__a" key={i}>
                        <span className="AppProduct__span">
                          {get(x, "name")}
                        </span>

                        {find(__selectedIds, (y) => y == get(x, "id")) ? (
                          <a
                            className="Button Button--white-purple Button--small"
                            onClick={() => {
                              __selectedIdsSet(
                                filter(__selectedIds, (y) => y != get(x, "id"))
                              )
                            }}
                          >
                            選択済み
                          </a>
                        ) : (
                          <a
                            className="Button Button--small"
                            onClick={() => {
                              __selectedIdsSet([...__selectedIds, get(x, "id")])
                            }}
                          >
                            {get(data, "t.select_user.select_button")}
                          </a>
                        )}
                      </div>
                    ))}
                  </React.Fragment>
                )
              })()}
            </div>

            <a
              className="Button"
              onClick={() => {
                __isActiveSet(false)
              }}
            >
              決定
            </a>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#modals")
  )
}
