import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import className from "classnames"

const PurchaseRecordVideo = ({data}) => {
  const [__isActive, __isActiveSet] = React.useState(false)
  const ___$video = React.useRef()

  const {t} = data

  return (
    <div className="AppPurchaseRecord__ss">
      {get(data, "src") ? (
        <React.Fragment>
          <video controls={__isActive} ref={___$video}>
            <source src={get(data, "src")} type="video/mp4" />
          </video>

          {!__isActive && (
            <a
              className="AppPurchaseRecord__ss__panel"
              onClick={() => {
                if (___$video.current) {
                  ___$video.current.play()
                  __isActiveSet(true)
                }
              }}
            >
              <p className="AppPurchaseRecord__ss__panel__p">
                {get(t, "current.ss.p")}
              </p>

              <small className="AppPurchaseRecord__ss__panel__small">
                {get(t, "current.ss.small")}
              </small>
            </a>
          )}
        </React.Fragment>
      ) : (
        <p className="AppPurchaseRecord__ss__placeholder">
          {get(t, "current.ss.placeholder")}
        </p>
      )}
    </div>
  )
}

PurchaseRecordVideo.propTypes = {}

export default PurchaseRecordVideo
