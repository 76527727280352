import * as React from "react"
// import className from 'classnames'
import moment from "moment"

import {
  axios,
  getFeatureAnnouncemenIds,
  setFeatureAnnouncemenIds,
} from "@lib/helpers"

import FeatureAnnouncementLink from "@components/molecules/FeatureAnnouncementLink"

type FeatureAnnouncementNotificationState = {
  __latestAnnouncement: {
    created_at: string
    id: string
    released_at: string
    title: string
    updated_at: string
    url: string
  } | null
}

class FeatureAnnouncementNotification extends React.Component<
  {
    latest_admin_feature_announcements_path: string
  },
  FeatureAnnouncementNotificationState
> {
  static defaultProps = {}

  state: FeatureAnnouncementNotificationState = {
    __latestAnnouncement: null,
  }

  componentDidMount = async () => {
    const {data} = await axios.get(
      this.props.latest_admin_feature_announcements_path
    )

    this.setState({
      __latestAnnouncement: data?.feature,
    })
  }

  render = () => {
    const featureAnnouncemenIds = getFeatureAnnouncemenIds()

    if (
      !this.state.__latestAnnouncement ||
      featureAnnouncemenIds.find(
        (x: string) => x == this.state.__latestAnnouncement?.id
      )
    ) {
      return null
    }

    return (
      <div className="FeatureAnnouncementNotification">
        <p className="FeatureAnnouncementNotification__p">
          <time className="FeatureAnnouncementNotification__p__time">
            {moment(this.state.__latestAnnouncement?.released_at).format(
              "YYYY.MM.DD"
            )}
          </time>

          <strong className="FeatureAnnouncementNotification__p__strong">
            {this.state.__latestAnnouncement?.title}
          </strong>

          <FeatureAnnouncementLink
            href={this.state.__latestAnnouncement?.url}
            klass="FeatureAnnouncementNotification__p__a"
            text="詳細はこちら"
            latestAnnouncementId={this.state.__latestAnnouncement?.id}
          />
        </p>

        <a
          className="FeatureAnnouncementNotification__a"
          onClick={() => {
            setFeatureAnnouncemenIds({
              featureAnnouncemenIds,
              latestAnnouncementId: this.state.__latestAnnouncement?.id,
            })

            window.location.reload()
          }}
        />
      </div>
    )
  }
}

export default FeatureAnnouncementNotification
