import React from "react"
import get from "lodash/get"
import map from "lodash/map"
// import className from "classnames"
import {format} from "d3-format"
import queryString from "query-string"

import {tt, axios} from "@lib/helpers"

import Graph from "@components/molecules/Graph"

const AdminPublishersShow = ({
  data,
}: {
  data: {
    publisher: any
    publisher_gifts: {
      icon_image_url_with_fallback: string
      user_name: string
      admin_user_path: string
      title?: string
      message?: string
    }[]
    distinguish_admin_publisher_path: string
    past_index_packages_path: string
    followers_users_path: string
    spot_requests_path: string
    t: any
  }
}) => {
  const {publisher, t} = data

  return (
    <div className="AdminAgenciesDetails">
      <div className="kontainer">
        <div className="AdminAgenciesDetails__wrapper">
          <div className="AdminAgenciesDetails__header">
            <img
              className="AdminAgenciesDetails__header__img"
              src={get(data, "publisher_icon_image_url_with_fallback")}
            />

            <div className="AdminAgenciesDetails__header__right">
              <h2 className="AdminAgenciesDetails__header__right__h2">
                {get(publisher, "name")}
              </h2>

              <p className="AdminAgenciesDetails__header__right__p">
                <a
                  className="AdminAgenciesDetails__header__right__p__a"
                  href={get(data, "publisher_agency_path")}
                >
                  {get(data, "publisher_agency.name")}
                </a>
              </p>
            </div>

            <a className="Button" href={get(data, "page_path")}>
              {tt(t, "agencies_details.details.go_to_publisher_page")}
            </a>
          </div>

          <p
            className="AdminAgenciesDetails__p"
            dangerouslySetInnerHTML={{
              __html: get(data, "publisher_description"),
            }}
          />

          <div className="AdminAgenciesDetails__details">
            <p className="AdminAgenciesDetails__details__p">
              {tt(t, "agencies_details.details.start_date")}：
              {get(data, "stats_numbers.joined_since")}
            </p>

            {false && (
              <p className="AdminAgenciesDetails__details__p">
                {tt(t, "agencies_details.details.last_login")}：TBD
              </p>
            )}
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.talkport.h3")}
            </h3>

            <dl className="AdminAgenciesDetails__section__dl">
              {[
                {
                  dt: tt(t, "current.sections.talkport.data.0"),
                  dd: get(
                    data,
                    "stats_numbers.total_packages_count",
                    0
                  ).toLocaleString(),
                  redirect_to: data.past_index_packages_path,
                },
                {
                  dt: tt(t, "current.sections.talkport.data.1"),
                  dd: get(
                    data,
                    "stats_numbers.total_spots_count",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talkport.data.2"),
                  dd: `${get(
                    data,
                    "stats_numbers.spots_sold_count",
                    0
                  ).toLocaleString()} <small>(${
                    get(data, "stats_numbers.total_spots_count") == 0
                      ? 0
                      : format(".3")(
                          (get(data, "stats_numbers.spots_sold_count") /
                            get(data, "stats_numbers.total_spots_count")) *
                            100
                        )
                  }%)</small>`,
                },
                {
                  dt: tt(t, "current.sections.talkport.data.3"),
                  dd: get(
                    data,
                    "stats_numbers.total_sales",
                    0
                  ).toLocaleString(),
                },
                {
                  dt: tt(t, "current.sections.talkport.data.4"),
                  dd: get(data, "stats_numbers.fans_count", 0).toLocaleString(),
                  redirect_to: data.followers_users_path,
                },
                {
                  dt: tt(t, "current.sections.talkport.data.5"),
                  dd: get(
                    data,
                    "stats_numbers.talk_requests_count",
                    0
                  ).toLocaleString(),
                  redirect_to: data.spot_requests_path,
                },
              ].map((x, i) => (
                <div
                  className="AdminAgenciesDetails__section__dl__item"
                  key={i}
                >
                  {x.redirect_to && (
                    <a
                      className="AdminAgenciesDetails__section__dl__item__a"
                      href={queryString.stringifyUrl({
                        url: data.distinguish_admin_publisher_path,
                        query: {
                          redirect_to: x.redirect_to,
                          distinguish_back_path: window.location.pathname,
                        },
                      })}
                    />
                  )}

                  <dt className="AdminAgenciesDetails__section__dl__item__dt">
                    {get(x, "dt")}
                  </dt>

                  <dd
                    className="AdminAgenciesDetails__section__dl__item__dd"
                    dangerouslySetInnerHTML={{__html: get(x, "dd")}}
                  />
                </div>
              ))}
            </dl>
          </div>

          <div className="AdminAgenciesDetails__section">
            <h3 className="AdminAgenciesDetails__section__h3">
              {tt(t, "current.sections.sales.h3")}
            </h3>

            <AdminPublishersShowSales data={data} />
          </div>

          <div className="AdminAgenciesDetails__section">
            <div className="AdminAgenciesDetails__section__header">
              <h3 className="AdminAgenciesDetails__section__h3">
                {tt(t, "current.sections.gifts.h3")}
              </h3>

              <p className="AdminAgenciesDetails__section__p">
                {tt(t, "current.sections.gifts.p")}
              </p>
            </div>

            {data.publisher_gifts?.length ? (
              <div className="AppUsersTalkHistory__dl">
                {data.publisher_gifts.map((x, i) => (
                  <div className="AppUsersTalkHistory__dl__item" key={i}>
                    <div className="AppUsersTalkHistory__dl__item__top">
                      <a href={x.admin_user_path}>
                        <img
                          className="AppUsersTalkHistory__dl__item__img"
                          src={x.icon_image_url_with_fallback}
                        />
                      </a>

                      <div className="AppUsersTalkHistory__dl__item__center">
                        <dt className="AppUsersTalkHistory__dl__item__dt">
                          <a href={x.admin_user_path}>{x.user_name}</a>
                        </dt>

                        {x.title && (
                          <dd className="AppUsersTalkHistory__dl__item__dd">
                            {x.title}
                          </dd>
                        )}
                      </div>
                    </div>

                    {x.message && (
                      <p className="AppUsersTalkHistory__dl__item__memo">
                        {x.message}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="Placeholder">
                {tt(t, "agencies_details.placeholder")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

AdminPublishersShow.propTypes = {}

export default AdminPublishersShow

type AdminPublishersShowSalesState = {
  __sales: {
    isLoading: boolean
    data?: {
      values: any
      key: string
    }[]
  }
}

class AdminPublishersShowSales extends React.Component<
  {data: any},
  AdminPublishersShowSalesState
> {
  static propTypes = {}

  static defaultProps = {}

  state: AdminPublishersShowSalesState = {
    __sales: {
      isLoading: true,
    },
  }

  componentDidMount = () => {
    axios
      .get(get(this.props, "data.sales_admin_publisher_path"))
      .then((res: any) => {
        this.setState({
          __sales: {
            isLoading: false,
            data: map(get(res, "data"), (x) => {
              return {
                values: [get(x, "value")],
                key: get(x, "date"),
              }
            }),
          },
        })
      })
  }

  render = () => {
    return (
      <Graph
        initialHeight={220}
        data={get(this.state, "__sales.data") || []}
        isLoaded={!get(this.state, "__sales.isLoading")}
      />
    )
  }
}
