import * as React from "react"
import className from "classnames"

import {tt} from "@lib/helpers"

const AppSettingsUsersEditAccountModalPhoneVerification = ({
  data,
}: {
  data: {t: any}
}) => {
  const [__isActive, __isActiveSet] = React.useState(true)

  const {t} = data

  return (
    <div
      className={className("ModalSmsVerification", {
        "ModalSmsVerification--active": __isActive,
      })}
    >
      <a
        className="ModalSmsVerification__overlay"
        onClick={() => {
          __isActiveSet(false)
        }}
      />

      <div className="ModalSmsVerification__panel">
        <div className="ModalSmsVerification__panel__header">
          <h4 className="ModalSmsVerification__panel__header__h4">
            {tt(t, "current.title")}
          </h4>
        </div>

        <p className="ModalSmsVerification__panel__p">
          {tt(t, "current.body")}
        </p>

        <div className="ModalSmsVerification__panel__buttons">
          <a
            className="Button"
            onClick={() => {
              __isActiveSet(false)
            }}
          >
            {tt(t, "current.button")}
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppSettingsUsersEditAccountModalPhoneVerification
